import React, { Fragment } from "react";

export function IntroVideoModal({ onClose }) {
    return (
        <Fragment>
            <form>
                <div className="justify-center mt-12 flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-full mx-auto max-w-3xl">
                        <div className="border-0 relative flex flex-col w-full bg-transparent outline-none focus:outline-none">
                            {/*header*/}
                            <div className="flex items-start justify-between p-2  rounded-t">
                                <button
                                    className="p-2 ml-auto border-0 text-red float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={onClose}
                                >
                                    <span className="text-white h-6 w-6 text-2xl block outline-none focus:outline-none">
                                        x
                                    </span>
                                </button>
                            </div>

                            {/*body*/}
                            <div className="py-3 px-6 lg:px-8 h-96">
                                <div className="items-center grid gap-6 mb-6 md:grid-cols-1">
                                    <iframe
                                        className="sm:w-[350px] md:w-[727px] lg:w-[727px] h-[409px] mx-auto"
                                        //width="727"
                                        //height="409"
                                        src="https://www.youtube.com/embed/XNPUpmFd-BY"
                                        title="Wone App Introduction"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowfullscreen
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </Fragment>
    );
}
