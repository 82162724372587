import React from "react";
import { Link } from "react-router-dom";
import ImageSkeleton from "../Skeletons/imageSkeleton";

export default function ResourceArticle({
    id,
    title,
    description,
    imageUrl = null,
}) {
    return (
        <div>
            <div className="block rounded-lg bg-white shadow-lg">
                <Link to={`/resources/article/${id}`}>
                    {imageUrl !== null ? (
                        <img
                            className="rounded-t-lg w-full h-48 object-cover cursor-pointer"
                            src={imageUrl}
                            alt=""
                        />
                    ) : (
                        <div className="flex items-center justify-center w-full h-48 bg-gray-300 rounded-t-lg">
                            <ImageSkeleton />
                        </div>
                    )}
                </Link>

                <div className="p-4 pb-8">
                    <h5 className="mb-2 text-xl font-medium leading-tight text-neutral-800 text-justify">
                        {title}
                    </h5>
                    <p
                        className="mb-4 text-base text-neutral-600 only-3-lines text-justify"
                        dangerouslySetInnerHTML={{ __html: description }}
                    />
                    <Link
                        to={`/resources/article/${id}`}
                        className="w-auto mt-2 text-white bg-fuchsia-800 hover:bg-green-800 font-bold rounded-lg text-sm px-5 py-2.5 text-center"
                    >
                        Read More
                    </Link>
                </div>
            </div>
        </div>
    );
}
