import { Fragment } from "react";
import { Footer } from "./footer";
import { LandingNav } from "./landingNav";

export function AboutWone() {
    return (
        <Fragment>
            <div>
                <div className="bg-indigo-50">
                    <LandingNav />
                </div>
                <div className="py-16">
                    <h1 className="text-5xl font-bold text-center">
                    About WONE
                    </h1>
                    <div className="px-14">

                        <p className="py-6">
                        <b>WONE</b> is a powerful tool that helps 
                        individuals ease their transition into a new city 
                        through integrated resources and features. 
                        Our web and mobile platform provide a one-stop 
                        shop for everything you need to know about your new city. 
                        From local events and activities 
                        to finding the best neighborhoods to rent in, 
                        and even employment opportunities, 
                        WONE has you covered. 
                        But more than just a platform, 
                        WONE is a community. We believe in forging connections with locals, 
                        cultural experiences, and personal growth 
                        to make a new city feel like home. 
                        Our commitment to integration, self-sufficiency, 
                        and in-depth knowledge of the city sets WONE apart. 
                        We offer all the necessary resources in one place, 
                        making it easy for you to get the best answers and solutions to your questions about the new city anytime, 
                        anywhere, and on any device. 
                        Join the WONE community today and start exploring your new city with confidence;
                        experience the sense of belonging and excitement that comes with moving to a new place.  
                        </p>
                        <h2 className="text-2xl font-bold">
                        Vision
                        </h2>
                        <p className="py-6">
                        Create elusive opportunities for our members through diversity and inclusion.
                        </p>
                        <h2 className="text-2xl font-bold">
                        Mission
                        </h2>
                        <p className="py-6">
                        Our mission is to strategically 
                        support and create tactical 
                        channels and resources 
                        that stimulate self-discovery, 
                        development, and transformation 
                        success for individuals 
                        through value-adding 
                        real-life solution-oriented technologies.
                        </p>
                        <h2 className="text-2xl font-bold">
                        Who are we?
                        </h2>
                        <h2 className="text-2xl font-bold">
                        Founder
                        </h2>
                        <p className="py-6">
                        <b>Isabelle</b>, once an immigrant herself, 
                        knows firsthand the challenges faced
                         by those moving to a new city. 
                         Despite these difficulties, 
                         she was determined to succeed 
                         and realized that building networks 
                         and finding support was key. 
                         Her passion for helping fellow immigrants 
                         and minority groups led her to found 
                         Djigui - an organization providing 
                         critical services to those facing similar challenges. 
                         But Isabelle's dreams didn't stop there - 
                         she established WONE to amplify 
                         Djigui's vision and take it to the next level.
                        </p>
                        <p className="6">
                        <b>WONE</b> is more than just a platform - 
                        it's a community on a mission 
                        to guide the next generation toward integration, 
                        success, and economic growth. 
                        It serves as an inspiration 
                        and beacon of hope for those looking 
                        to make a change in their life. 
                        Isabelle's journey is a testament 
                        to the amazing things that happen 
                        when people come together to support one another. 
                        <b>Join WONE today and help us empower the next generation.
                        Together, we can create a more inclusive future for everyone.</b>
                        </p>
                       </div> 
                </div>
                <div>
                    <Footer />
                </div>
            </div>
        </Fragment>
    );
}
