import http from '../../global/http-common'

class JobSeekerListService {
    getAll() {
        return http.get("/JobSeekerLists");
    }
    
    get(id) {
        return http.get(`/JobSeekerLists/${id}`);
    }
    create(data) {
        return http.post("/JobSeekerLists", data);
    }

    update(id, data) {
        return http.put(`/JobSeekerLists/${id}`, data);
    }

    delete(id) {
        return http.delete(`/JobSeekerLists/${id}`);
    }

    // check if an applicant already applied for a job
    checkIfSelected(postId){
        return http.get(`/JobSeekerLists/Check/${postId}`);
    }

}

export default new JobSeekerListService();