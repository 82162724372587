import React, { Fragment, useEffect, useState } from "react";
import { showError } from "../../global/alertService";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { STRIPE_PUBLISHABLE_KEY } from "../../global/api-keys";
import EventDataService from "../../services/Event/event.service";
import EventParticipantService from "../../services/Event/eventParticipants.service";
import { featureService } from "../../services/Feature/feature.service";
import { toLocalEventDate } from "../../utils/evant-date";
import { toLocalEventTime } from "../../utils/event-time";
import { CrossIcon } from "../Icons/crossIcon";
import { EventParticipatePaypal } from "../Payment/eventParticipatePaypal";
import { EventParticipateStripe } from "../Payment/eventParticipateStripe";

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const EventDetail = (props) => {
    const mySwal = withReactContent(Swal);
    const navigate = useNavigate();
    const [interestModal, setInterestModal] = useState(false);

    const [eventId, setEventId] = useState(null);
    const [eventById, setEventById] = useState(null);
    const [participantNo, setParticipantNo] = useState(null);

    const [orderId, setOrderId] = useState("");
    const [payerID, setPayerID] = useState("");
    const [stripePaymentId, setStripePaymentId] = useState("");
    const [paidFor, setPaidFor] = useState(false);
    const [showStripeModal, setShowStripeModal] = useState(false);
    const [isParticipated, setIsParticipated] = useState(false);
    const [entryFee, setEntryFee] = useState(0);

    const loggedInUser = localStorage.getItem("authToken");
    const isPaymentNeeded = featureService.shouldShowContent("Event Payment");

    useEffect(() => {
        if (props.detailEventId > 0) {
            getEventById(props.detailEventId);
            getParticipantNo(props.detailEventId);
            checkIfParticipated(props.detailEventId);
        }
        //console.debug(props)
    }, [props]);

    const getEventById = (id) => {
        //console.debug(id);
        EventDataService.get(id).then((res) => {
            setEventById(res.data);
            setEntryFee(res.data.entryFee);
        });
    };

    const checkIfParticipated = (eventId) => {
        EventParticipantService.checkParticipant(eventId).then((res) => {
            //console.log(eventId, res.data)
            setIsParticipated(res.data);
        });
    };

    const getParticipantNo = (eventid) => {
        //console.debug(eventid);
        EventParticipantService.get(eventid).then((res) => {
            setParticipantNo(res.data);
        });
    };

    const saveEventParticipant = (event) => {
        event.preventDefault();
        //console.debug(event);
        const eventParticipantData = {
            eventId: eventId,
            paypalOrderId: orderId,
            paypalPayerID: payerID,
            stripeOrderId: stripePaymentId,
        };
        //console.log(eventParticipantData);
        EventParticipantService.create(eventParticipantData).then(
            (response) => {
                //console.debug(response);
                //showSuccess(`Your request is received successfully`);
                setInterestModal(false);
                setShowStripeModal(false);
                navigate("/events");
                //window.location.reload(true);
            }
        );
    };

    const handleApprope = (orderID) => {
        setPaidFor(true);
    };

    if (paidFor) {
        const eventParticipantData = {
            eventId: eventId,
            paypalOrderId: orderId,
            paypalPayerID: payerID,
            stripeOrderId: stripePaymentId,
        };
        EventParticipantService.create(eventParticipantData)
            .then((response) => {
                mySwal
                    .fire({
                        title: <strong>Success</strong>,
                        html: <i>Your request is received successfully</i>,
                        icon: "success",
                    })
                    .then(() => {
                        window.location.reload();
                        //navigate(`/events`);
                        setInterestModal(false);
                        setShowStripeModal(false);
                    });
            })
            .catch((err) => {
                showError("Something went wrong");
                setInterestModal(false);
                setShowStripeModal(false);
            });
    }

    const stripeModal = (
        <>
            <div className="fixed inset-0 z-50 overflow-y-auto">
                <div
                    className="fixed inset-0 w-full h-full bg-black opacity-40"
                    onClick={() => setShowStripeModal(false)}
                ></div>
                <div className="flex items-center min-h-screen px-4 py-8">
                    <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                        <div
                            onClick={() => setShowStripeModal(false)}
                            className="absolute top-5 right-5 cursor-pointer"
                        >
                            <CrossIcon />
                        </div>
                        <div className="mt-4 text-center sm:text-left border-dashed">
                            <Elements stripe={stripePromise}>
                                {eventId > 0 ? (
                                    <EventParticipateStripe
                                        eventId={eventId}
                                        setStripePaymentId={setStripePaymentId}
                                        handleApprope={handleApprope}
                                        setShowStripeModal={setShowStripeModal}
                                    />
                                ) : (
                                    <></>
                                )}
                            </Elements>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

    return (
        <Fragment>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-full my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-3xl font-semibold">
                                Event Detail
                            </h3>

                            <button
                                className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={props.onClose}
                            >
                                <CrossIcon />
                            </button>
                        </div>

                        {/*body*/}
                        <div className="pt-6 px-6 lg:px-8">
                            <form>
                                <div >
                                    <div
                                        className="w-full h-52 mb-4"
                                        style={{
                                            backgroundImage: `url("${eventById?.imgUrl}")`,
                                            backgroundColor: "#cccccc",
                                            backgroundPosition: "center center",
                                            backgroundSize: "cover",
                                            backgroundRepeat: "no-repeat",
                                        }}
                                    >
                                        {/* <img className='w-full h-56' src={eventById?.imgUrl} alt="event img" /> */}
                                    </div>
                                    <div className="px-5 pb-5 overflow-y-auto max-h-72">
                                        <div>
                                            <h5 className="text-xl font-semibold tracking-tight mt-2 text-gray-900">
                                                {eventById?.name}
                                            </h5>

                                            <div className="flex justify-between items-center">
                                                <span>
                                                    {toLocalEventDate(
                                                        eventById?.eventDate
                                                    )}
                                                </span>
                                                <span>
                                                    {toLocalEventTime(
                                                        eventById?.eventDate
                                                    )}
                                                </span>
                                            </div>

                                            <p>
                                                {eventById?.location}{" "}
                                                {eventById?.city}{" "}
                                                {eventById?.state}{" "}
                                                {eventById?.country}
                                            </p>
                                            <p>
                                                {participantNo} People
                                                Interested.
                                            </p>
                                        </div>
                                        <div className="flex justify-between items-center mt-2 ">
                                            <span className="text-xl font-bold text-gray-900">
                                                Description
                                            </span>
                                        </div>
                                        <p className=" font-normal text-gray-700">
                                            {eventById?.description}
                                        </p>
                                        <div className="flex justify-between items-center mt-2 ">
                                            <span className="text-xl font-bold text-gray-900">
                                                Organiser
                                            </span>
                                        </div>
                                        <p className=" font-normal text-gray-700">
                                            {eventById?.organiser}{" "}
                                        </p>
                                        <div className="flex justify-between items-center mt-2 ">
                                            <span className="text-xl font-bold text-gray-900">
                                                Activities
                                            </span>
                                        </div>
                                        <p className=" font-normal text-gray-700">
                                            {eventById?.activities}
                                        </p>
                                        <div className="flex justify-between items-center mt-2 ">
                                            <span className="text-xl font-bold text-gray-900">
                                                Entry Fee
                                            </span>
                                        </div>
                                        <p className=" font-normal text-gray-700">
                                            <b> {entryFee === 0 || null ? 'Free' : entryFee}</b>
                                        </p>
                                    </div>
                                </div>
                            </form>
                        </div>

                        {/*footer*/}
                        <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                            <button
                                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={props.onClose}
                            >
                                Close
                            </button>
                            {loggedInUser &&
                                featureService.shouldShowContent(
                                    "Event Participant"
                                ) ? (
                                isParticipated ? (
                                    <button
                                        className="bg-green-800 text-white hover:bg-fuchsia-800 font-bold  text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 pointer-events-none"
                                        disabled
                                    >
                                        Already Registered
                                    </button>
                                ) : (
                                    <button
                                        className="bg-fuchsia-800 text-white hover:bg-green-800 font-bold  text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        onClick={() => {
                                            setInterestModal(true);
                                            setEventId(eventById?.id);
                                        }}
                                    >
                                        Interested
                                    </button>
                                )
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>

            {/* ========interestModal=========*/}
            {interestModal ? (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-full my-6 mx-auto max-w-3xl">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-3xl font-semibold">
                                        {eventById?.name}
                                    </h3>

                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setInterestModal(false)}
                                    >
                                        <CrossIcon />
                                    </button>
                                </div>

                                {/*body*/}

                                <div className="py-6 px-6 lg:px-8">
                                    <input
                                        name="eventId"
                                        type="hidden"
                                        value={eventId}
                                    />
                                    <input
                                        name="userId"
                                        type="hidden"
                                        value={6}
                                    />
                                    <div style={{ marginTop: "10px" }}>
                                        <div className="px-5 pb-5 text-center">
                                            {(!isPaymentNeeded ||
                                                entryFee === 0) && (
                                                    <p className="text-2xl text-gray">
                                                        Are you interested to
                                                        participate this event ?
                                                    </p>
                                                )}

                                            {isPaymentNeeded &&
                                                entryFee > 0 && (
                                                    <p className="text-2xl text-gray">
                                                        Please pay with a
                                                        payment method to
                                                        participate.
                                                    </p>
                                                )}
                                        </div>
                                    </div>

                                    {/*</form>*/}
                                </div>

                                {(!isPaymentNeeded || entryFee === 0) && (
                                    <form onSubmit={saveEventParticipant}>
                                        {/*footer*/}

                                        <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                            <button
                                                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                type="button"
                                                onClick={() =>
                                                    setInterestModal(false)
                                                }
                                            >
                                                No
                                            </button>
                                            <button
                                                className="bg-fuchsia-800 text-white hover:bg-green-800 font-bold  text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                type="submit"
                                            >
                                                Yes
                                            </button>
                                        </div>
                                    </form>
                                )}

                                {isPaymentNeeded && entryFee > 0 && (
                                    <>
                                        <div className="flex flex-wrap px-6 py-4 border-t border-solid border-slate-200">
                                            <div
                                                className="sm:w-full md:w-full lg:w-1/2 px-1"
                                                style={{
                                                    zIndex: showStripeModal
                                                        ? 0
                                                        : 1,
                                                }}
                                            >
                                                <EventParticipatePaypal
                                                    eventName={eventById?.name}
                                                    price={eventById?.entryFee}
                                                    setOrderId={setOrderId}
                                                    setPayerID={setPayerID}
                                                    handleApprope={
                                                        handleApprope
                                                    }
                                                />
                                            </div>
                                            <div className="sm:w-full md:w-full lg:w-1/2 px-1">
                                                <button
                                                    type="button"
                                                    className="focus:outline-none text-black font-semibold rounded px-5 py-2.5 mr-2 mb-2 h-12 w-full text-lg stripe-card-pay-button text-center"
                                                    onClick={() =>
                                                        setShowStripeModal(true)
                                                    }
                                                >
                                                    Credit / Debit Card
                                                </button>
                                                {showStripeModal && stripeModal}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}

            {/*=================*/}
        </Fragment>
    );
};
export default EventDetail;
