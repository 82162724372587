import { Fragment } from "react";

export function AfterSignUp() {
    return (
        <Fragment>
            <div className="text-center">
                <h1 className="text-3xl font-bold pt-20">
                    Sign Up Successfully!!
                </h1>
                <p className="text-xl mt-6 text-fuchsia-800">
                    Please check your email for confirming your email address.
                </p>
            </div>
        </Fragment>
    );
}
