import React, { useEffect, useState } from "react";

export default function Paginate({
    totalItems,
    currentItemsCount,
    currentPageNumber,
    pageSize,
    setCurrentPageNumber,
}) {
    const [pager, setPager] = useState([]);

    useEffect(() => {
        let startPage = currentPageNumber - 3;
        let endPage = currentPageNumber + 3;
        let totalPages = Math.ceil(totalItems / pageSize);

        if (startPage <= 0) {
            endPage = endPage - startPage + 1;
            startPage = 1;
        }

        if (endPage > totalPages) {
            endPage = totalPages;
            startPage = 1;
            if (endPage > 7) {
                startPage = endPage - 6;
            }
        }

        const test = [];
        for (let i = startPage; i <= endPage; i++) {
            test.push(i);
        }
        setPager(test);
    }, [currentPageNumber, pageSize, totalItems, currentItemsCount]);

    return (
        <div className="grid gap-4 lg:grid-cols-3 sm:grid-cols-1">
            <div className="flex justify-start items-center">
                <p className="font-medium lg:block sm:hidden">
                    {`Showing ${
                        totalItems > 0
                            ? (currentPageNumber - 1) * pageSize + 1
                            : 0
                    } to
                ${
                    totalItems > 0
                        ? (currentPageNumber - 1) * pageSize + currentItemsCount
                        : 0
                } 
                from ${totalItems} results`}
                </p>
            </div>

            <div className="flex justify-center">
                <div
                    className={`px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg font-medium ${
                        currentPageNumber > 1
                            ? "cursor-pointer hover:bg-fuchsia-800 hover:text-white"
                            : "cursor-not-allowed"
                    }`}
                    onClick={() => setCurrentPageNumber(1)}
                >
                    First
                </div>
                <ul className="flex justify-center items-center">
                    {pager.map((item) => {
                        return (
                            <li key={item}>
                                <div
                                    className={`px-3 py-2 leading-tight text-gray-500 border border-gray-300 hover:bg-fuchsia-800 hover:text-white cursor-pointer font-medium ${
                                        currentPageNumber === item
                                            ? "bg-fuchsia-800 text-white"
                                            : ""
                                    }`}
                                    onClick={() => setCurrentPageNumber(item)}
                                >
                                    {item}
                                </div>
                            </li>
                        );
                    })}
                </ul>
                <div
                    className={`px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg font-medium ${
                        currentPageNumber < Math.ceil(totalItems / pageSize)
                            ? "cursor-pointer hover:bg-fuchsia-800 hover:text-white"
                            : "cursor-not-allowed"
                    }`}
                    onClick={() =>
                        setCurrentPageNumber(Math.ceil(totalItems / pageSize))
                    }
                >
                    Last
                </div>
            </div>

            <div className="flex justify-center items-center">
                <p className="font-medium lg:hidden md:block">
                    {`Showing ${
                        totalItems > 0
                            ? (currentPageNumber - 1) * pageSize + 1
                            : 0
                    } to
                ${
                    totalItems > 0
                        ? (currentPageNumber - 1) * pageSize + currentItemsCount
                        : 0
                } 
                from ${totalItems} results`}
                </p>
            </div>
        </div>
    );
}
