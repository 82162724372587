import { useEffect, useState } from "react";
import { showSuccess } from "../../global/alertService";
import { authService } from "../../services/Account/auth.service";

export function OriginLanguageAndCountry({ profile }) {
    const [origin, setOrigin] = useState([]);

    useEffect(() => {
        setOrigin({
            nativeCountry: profile?.nativeCountry,
            nativeLanguage: profile?.nativeLanguage,
        });
    }, [profile]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setOrigin({ ...origin, [name]: value });
    };

    const update = () => {
        authService.updateOrigin(origin).then((res) => {
            showSuccess("Updated successfully.");
        });
    };
    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                update();
            }}
        >
            <div className="block p-6 rounded-lg shadow-lg bg-gray-50 mt-6">
                <h5 className="text-gray-900 text-xl leading-tight font-medium mb-2">
                    Native Country & Language
                </h5>
                <h1 className="mb-2 mt-4 font-bold">Native Country</h1>
                <input
                    name="nativeCountry"
                    value={origin?.nativeCountry}
                    onChange={handleInputChange}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                />
                <h1 className="mb-2 mt-4 font-bold">Native Language</h1>
                <input
                    name="nativeLanguage"
                    value={origin?.nativeLanguage}
                    onChange={handleInputChange}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                />
                <div className="text-right">
                    <button
                        type="submit"
                        className="mt-4 text-white bg-fuchsia-800  hover:bg-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    >
                        Update
                    </button>
                </div>
            </div>
        </form>
    );
}
