import { Fragment } from "react";
import { toLocalEventTime } from "../../utils/event-time";

export function RecentChats({ recentChats, onSelectConversation }) {
    return (
        <Fragment>
            <div className=" border flex flex-col h-full">
                <h1 className="px-3 py-4 font-bold text-xl">Recent Chat</h1>
                <div className=" flex-1 overflow-y-auto">
                    {recentChats?.map((chat, index) => {
                        return (
                            <div
                                key={index}
                                className="px-3 flex items-center bg-grey-light cursor-pointer"
                            >
                                <div>
                                    {chat?.imgUrl != null ? (
                                        <img
                                            className="h-12 w-12 rounded-full"
                                            src={chat?.imgUrl}
                                            alt=""
                                        />
                                    ) : (
                                        <svg
                                            className=" bg-green-400 w-12 h-12 rounded-full text-gray-400"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                                                clipRule="evenodd"
                                            ></path>
                                        </svg>
                                    )}
                                </div>
                                <div className="ml-4 flex-1 border-b border-grey-lighter py-4">
                                    <div
                                        onClick={() =>
                                            onSelectConversation(chat?.id)
                                        }
                                        className="flex items-bottom justify-between"
                                    >
                                        {chat.hasUnread === false ? (
                                            <>
                                                <p className="text-grey-darkest">
                                                    {chat.name}
                                                </p>
                                                <div>
                                                    <p className="text-xs text-grey-darkest">
                                                        {new Date(
                                                            chat?.sentAt
                                                        ).toLocaleTimeString()}
                                                    </p>{" "}
                                                    <p className="text-xs text-grey-darkest">
                                                        {new Date(
                                                            chat?.sentAt
                                                        ).toLocaleDateString()}
                                                    </p>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <p className="text-grey-darkest font-bold text-fuchsia-600">
                                                    {chat.name}
                                                </p>
                                                <div>
                                                    <p className="text-xs text-grey-darkest font-bold text-fuchsia-600">
                                                        {toLocalEventTime(
                                                            chat?.sentAt
                                                        )}
                                                    </p>{" "}
                                                    <p className="text-xs text-grey-darkest font-bold text-fuchsia-600">
                                                        {new Date(
                                                            chat?.sentAt
                                                        ).toLocaleDateString()}
                                                    </p>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <p className="text-grey-dark mt-1 text-sm">
                                        {/* {chat?.messages[0]?.message} */}
                                    </p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </Fragment>
    );
}
