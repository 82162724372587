import React, { Fragment } from "react";
import { VideoPlayer } from "./videoPlayer";

export function VideoModal({ src, onClose }) {
    return (
        <Fragment>
            <div className="justify-center mt-12 flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-full mx-auto max-w-3xl">
                    {/*content*/}
                    <div className="border-0 relative flex flex-col w-full bg-transparent outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-end">
                            <button
                                className="p-2 ml-auto border-0 float-right leading-none font-semibold outline-none focus:outline-none"
                                onClick={onClose}
                            >
                                <span className="text-white h-6 w-6 text-2xl block outline-none focus:outline-none mr-4">
                                    x
                                </span>
                            </button>
                        </div>

                        {/*body*/}
                        <div className="py-3 px-6 lg:px-8 h-96">
                            <div className="items-center grid gap-6 mb-6 md:grid-cols-1">
                                <VideoPlayer src={src} />
                            </div>
                        </div>
                        {/*footer*/}
                    </div>
                </div>
            </div>
            <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </Fragment>
    );
}
