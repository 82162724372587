import { DownloadIcon } from "../Icons/downloadIcon";

export function FileDownloader({ source , height = null }) {
    return (
        <div className={`flex flex-col justify-center items-center text-center ${height ? height : "h-96"}`}>
            <h4 className="text-lg">
                Click the icon to download.
            </h4>
            <a
                className="mt-2 border-2 border-[#DFC8E6] rounded-full p-2"
                href={source}
                download rel="noreferrer"
            >
                <DownloadIcon />
            </a>
        </div>
    );
}
