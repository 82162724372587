import { HubConnectionBuilder } from "@microsoft/signalr";
import { React, useEffect, useState, useRef, useLayoutEffect } from "react";
import { ChatContainer } from "../../components/Chat/chatContainer";
import { People } from "../../components/Chat/people";
import { RecentChats } from "../../components/Chat/recentChats";
import { CHAT_HUB_URL } from "../../global/http-common";
import { conversationService } from "../../services/Chat/conversation.service";

function Chat() {
    const [currentConveration, setCurrentConversation] = useState(null);
    const [conversations, setConversations] = useState([]);
    const [receivedMessage, setReceivedMessage] = useState(null);
    const [sentMessage, setSentMessage] = useState(null);
    const [reloadRecent, setReloadRecent] = useState(true);
    const [currentScreen, setCurrentScreen] = useState("people");
    const chatContainerRef = useRef(null);
    useEffect(() => {
        const connection = new HubConnectionBuilder()
            .withUrl(CHAT_HUB_URL, {
                accessTokenFactory: () => localStorage.getItem("authToken"),
            })
            .withAutomaticReconnect()
            .build();

        connection
            .start()
            .then((result) => {
                //console.log("Connected to chat");
            })
            .catch((e) => console.log("Connection failed: ", e));

        connection.on("ReceiveMessage", (message) => {
            setReceivedMessage(message);
        });
    }, []);

    useEffect(() => {
        if (reloadRecent) {
            conversationService.getRecent().then((res) => {
                setConversations(res.data);
            });

            setReloadRecent(false);
        }
    }, [reloadRecent]);

    useEffect(() => {
        if (receivedMessage) {
            if (receivedMessage.conversationId === currentConveration?.id) {
                selectConversation(receivedMessage.conversationId);
            } else {
                setReloadRecent(true);
            }
        }
        // eslint-disab3le-next-line react-hooks/exhaustive-deps
    }, [receivedMessage]);

    useEffect(() => {
        if (currentConveration) {
            selectConversation(currentConveration?.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sentMessage]);

    const selectUser = (user) => {
        conversationService.getByUserId(user?.id).then((res) => {
            setCurrentConversation(res.data);
            setCurrentScreen("chat"); //add
           
        });
    };

    const selectConversation = (conversationId) => {
        conversationService.get(conversationId).then((res) => {
            setCurrentConversation(res.data);
            setReloadRecent(true);
            setCurrentScreen("chat"); //add
        });
    };

    const newMessageSent = (message) => {
        setSentMessage(message);
    };

    const handleBackButton = () => {
        setCurrentScreen("people");
    };

    const scrollToBottom = () => {
        //chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        if (chatContainerRef.current) {
            const chatContainer = chatContainerRef.current;
            chatContainer.scrollTo({
              top: chatContainer.scrollHeight,
              behavior: "smooth",
            });
        }
    };

    useEffect(() => {
        if (currentScreen === "chat") {
            scrollToBottom();
        }
    }, [currentScreen]);
   

    return (
        <>
            <div className=" w-full overflow-y-hidden notranslate">
                <div
                    style={{ height: "86vh" }}
                    className="mt-20 pt-4 ml-6 mr-2 lg:flex  md:hidden sm:hidden"
                >
                    <div className="w-1/4">
                        <People onClick={selectUser} />
                    </div>
                    <div className="w-2/4">
                        <ChatContainer
                            conversation={currentConveration}
                            onMessageSent={newMessageSent}
                        />
                    </div>
                    <div className="w-1/4">
                        <RecentChats
                            recentChats={conversations}
                            onSelectConversation={selectConversation}
                        />
                    </div>
                </div>
            </div>
            {/*-Mobile view-*/}

            <div className="mt-20 pb-6 pt-4 mx-4 lg:hidden flex-col overflow-y-hidden notranslate">
                {currentScreen === "people" && (
                    <div
                        style={{ height: "600px" }}
                        className="w-full lg:hidden mb-4 md:mb-0 overflow-y-auto "
                    >
                        <People onClick={selectUser} />
                    </div>
                )}

                {currentScreen === "chat" && (
                    <div
                        className="w-full lg:hidden md:mt-4 "
                    >
                        <div ref={chatContainerRef}
                            style={{ height: "500px", overflowY: "scroll" }}
                        >
                            <ChatContainer
                                conversation={currentConveration}
                                onMessageSent={newMessageSent}
                            />
                        </div>

                        <button onClick={handleBackButton}>
                            
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6 "
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                                />
                            </svg>
                        </button>

                    </div>
                )}

                {currentScreen === "people" && (
                    <div
                        style={{ height: "300px" }}
                        className="w-full lg:hidden md:mt-4 overflow-y-auto"
                    >
                        <RecentChats
                            recentChats={conversations}
                            onSelectConversation={selectConversation}
                        />
                    </div>
                )}
            </div>
        </>
    );
}

export default Chat;
