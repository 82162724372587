import { Fragment } from "react";
import { Footer } from "./footer";
import { LandingNav } from "./landingNav";

export function Hub() {
    return (
        <Fragment>
            <div>
                <div className="bg-indigo-50">
                    <LandingNav />
                </div>
                <div className="py-16">
                    <h1 className="text-5xl font-bold text-center">
                        Djigui – (HUB)
                    </h1>
                    <div className="px-14">

                        <p className="py-6">
                            <b>Djigui HUB</b> is more than just a platform
                            – it is a unique resource
                            that offers dedicated support
                            to help you reach your full potential.
                            Whether you need guidance on resources,
                            job portfolio building,
                            or making meaningful connections,
                            our physical team is on-hand to provide long-term support,
                            empowering you to integrate into your community and
                            achieve financial security.
                            Say goodbye to stress and hello to a bright future with Djigui HUB. 
                        </p>
                        
                        <p className="py-6">
                        In addition to our existing Homeownership, 
                        financial literacy, credit counseling, 
                        and other services, 
                        we are thrilled 
                        to introduce our new learning section 
                        within the Djigui app. 
                        Here you can create an account 
                        and access additional support via chat, 
                        forum, email, or phone call – giving you the flexibility 
                        and convenience to get the help you need when you need it. 
                        We have also integrated a form for users to reach out to our team, 
                        ensuring prompt and personalized assistance every step of the way.
                        </p>
                        
                        <p className="py-6">
                        Overall, <b>Djigui HUB</b> is the ultimate resource 
                        to help you thrive and succeed, 
                        with comprehensive support 
                        and guidance tailored to your unique needs. 
                        Join us today and take the first step 
                        towards building a brighter financial future.
                        </p>
                       </div> 
              
                    
                </div>
                <div>
                    <Footer />
                </div>
            </div>
        </Fragment>
    );
}
