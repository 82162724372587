import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
    createContext,
    Fragment,
    useContext,
    useEffect,
    useState,
} from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { STRIPE_PUBLISHABLE_KEY } from "../../global/api-keys";
import { Enums } from "../../global/enums";
import { subscriptionService } from "../../services/Account/subscription.service";
import { enumService } from "../../services/Common/enum.service";
import { subscriptionMembershipService } from "../../services/SubscriptionMembership/subscriptionMembership.service";
import { CrossIcon } from "../Icons/crossIcon";
import { SubscriptionUpgradeAndExtendPaypal } from "../Payment/subscriptionUpgradeAndExtendPaypal";
import { SubscriptionUpgradeAndExtendStripe } from "../Payment/subscriptionUpgradeAndExtendStripe";
import { ProfileReloadContext } from "./profileView";

export const MembershipUpgradeContext = createContext();
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

export function UpgradeAndExtendMembership({ type }) {
    const mySwal = withReactContent(Swal);
    const currentUserEmail = localStorage.getItem("email");
    const initialFormState = {
        membershipType: "",
        subscriptionCycle: "",
    };
    const [data, setData] = useState(initialFormState);
    const [memberships, setMemberships] = useState([]);
    const [subscriptions, setSubscriptions] = useState([]);
    const [showStripeModal, setShowStripeModal] = useState(false);

    const [subscriptionCycle, setSubscriptionCycle] = useState(0);
    const [membershipType, setMembershipType] = useState(0);

    const [price, setPrice] = useState(0);
    const [paypalOrderId, setPaypalOrderId] = useState("");
    const [stripeOrderId, setStripeOrderId] = useState("");
    const [isUpgraded, setIsUpgraded] = useState(false);

    const { setReloadView } = useContext(ProfileReloadContext);

    // calling the service while isUpgraded is true
    useEffect(() => {
        if (isUpgraded === true) {
            upgradeMember();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUpgraded]);

    // feed dropdown values
    useEffect(() => {
        getMembershipsType();
        getSubscriptions();
    }, []);

    // get price on membershipType and subscriptionType changes
    useEffect(() => {
        const membershipNumber = parseInt(data.membershipType);
        const subscriptionNumber = parseInt(data.subscriptionCycle);
        if (
            !Number.isNaN(membershipNumber) &&
            !Number.isNaN(subscriptionNumber)
        ) {
            if (membershipNumber > 0 && subscriptionNumber > 0) {
                const submitData = {
                    membershipType: membershipNumber,
                    subscriptionCycle: subscriptionNumber,
                };
                subscriptionMembershipService
                    .getMembershipPrice(submitData)
                    .then((response) => {
                        setPrice(response.data?.amount);
                        setSubscriptionCycle(subscriptionNumber);
                        setMembershipType(membershipNumber);
                    });
            }
        }
    }, [data.membershipType, data.subscriptionCycle]);

    // get membershipTypes
    const getMembershipsType = () => {
        enumService.get(Enums.MEMBERSHIP_TYPE).then((response) => {
            setMemberships(response.data);
        });
    };

    // get subscriptionCycles
    const getSubscriptions = () => {
        enumService.get(Enums.SUBSCRIPTION_CYCLE).then((response) => {
            setSubscriptions(response.data);
        });
    };

    // handle changes for membershipType and subscriptionCycle
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setData({ ...data, [name]: value });
    };

    // handle submit events
    const upgradeMember = () => {
        subscriptionService.get().then((res) => {
            if (res.data === "" || res.data === null) {
                subscriptionService
                    .create({
                        userId: 0,
                        paypalOrderId: paypalOrderId,
                        stripeOrderId: stripeOrderId,
                        subscriptionCycle: subscriptionCycle,
                        membershipType: membershipType,
                        isFreeTrial: false,
                    })
                    .then((res) => {
                        mySwal
                            .fire({
                                title: <strong>Success</strong>,
                                html: <i>{res.data}</i>,
                                icon: "success",
                            })
                            .then(() => {
                                setShowStripeModal(false);
                                // setReloadView(true);
                                window.location.reload();
                            });
                    });
            } else {
                subscriptionService
                    .update({
                        userId: 0,
                        paypalOrderId: paypalOrderId,
                        stripeOrderId: stripeOrderId,
                        subscriptionCycle: subscriptionCycle,
                        membershipType: membershipType,
                        isFreeTrial: false,
                    })
                    .then((res) => {
                        mySwal
                            .fire({
                                title: <strong>Success</strong>,
                                html: <i>{res.data}</i>,
                                icon: "success",
                            })
                            .then(() => {
                                setShowStripeModal(false);
                                // setReloadView(true);
                                window.location.reload();
                            });
                    });
            }
        });
    };

    const stripeModal = (
        <div className="fixed inset-0 z-50 overflow-y-auto">
            <div
                className="fixed inset-0 w-full h-full bg-black opacity-40"
                onClick={() => setShowStripeModal(false)}
            ></div>
            <div className="flex items-center min-h-screen px-4 py-8">
                <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                    <div
                        onClick={() => setShowStripeModal(false)}
                        className="absolute top-4 right-6 cursor-pointer"
                    >
                        <CrossIcon />
                    </div>
                    <div className="mt-4 text-center sm:text-left border-dashed">
                        <Elements stripe={stripePromise}>
                            <SubscriptionUpgradeAndExtendStripe
                                price={price}
                                type={type}
                                setShowStripeModal={setShowStripeModal}
                            />
                        </Elements>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <Fragment>
            <MembershipUpgradeContext.Provider
                value={{
                    setPaypalOrderId,
                    setStripeOrderId,
                    setIsUpgraded,
                }}
            >
                <div>
                    <div>
                        <label className="block mb-2 text-sm font-medium text-gray-900">
                            Membership Type
                        </label>
                        <select
                            required
                            onChange={handleInputChange}
                            name="membershipType"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                        >
                            <option value="0">
                                -- select membership type --
                            </option>
                            {memberships?.map((membership) => (
                                <option
                                    value={membership.key}
                                    key={membership.key}
                                >
                                    {membership.value}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="mt-2">
                        <label className="block mb-2 text-sm font-medium text-gray-900">
                            Subscription Cycle
                        </label>
                        <select
                            required
                            onChange={handleInputChange}
                            name="subscriptionCycle"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                        >
                            <option value="0">
                                -- select Subscription Cycle --
                            </option>
                            {subscriptions?.map((subscription) => (
                                <option
                                    value={subscription.key}
                                    key={subscription.key}
                                >
                                    {subscription.value}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="mt-2">
                        <label className="block mb-2 text-sm font-medium text-gray-900">
                            Subscription Fee
                        </label>
                        <input
                            type="text"
                            value={price}
                            onChange={(e) => null}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                        />
                    </div>
                </div>
                <h1 className="text-lg font-medium mb-4 mt-2">
                    Choose Payment Method
                </h1>
                {price > 0 ? (
                    <div className="flex flex-wrap">
                        <div
                            className="w-full"
                            style={{
                                zIndex: showStripeModal ? 0 : 1,
                            }}
                        >
                            <SubscriptionUpgradeAndExtendPaypal price={price} />
                        </div>
                        <div className="w-full">
                            <button
                                type="button"
                                className="focus:outline-none text-black font-semibold rounded px-5 py-2.5 mr-2 mb-2 h-12 w-full text-lg stripe-card-pay-button text-center"
                                onClick={() => setShowStripeModal(true)}
                            >
                                Credit / Debit Card
                            </button>
                        </div>
                    </div>
                ) : (
                    <></>
                )}
                {showStripeModal && stripeModal}
            </MembershipUpgradeContext.Provider>
        </Fragment>
    );
}
