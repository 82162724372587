import { React} from 'react';
import TrainingEdit from "../../components/Trining/trainingEdit"
import ProfileCard from '../../components/Profile/profileCard'
import { useParams } from "react-router";

function TrainingEditList() {
    const { id } = useParams()
  return (
    <div>

      <div className="flex flex-row flex-wrap mt-20 ">
        <div className="md:w-full sm:w-full lg:w-1/4 xl:w-1/4 2xl:w-1/4 ">
          <div className="block p-6 rounded-lg shadow-lg bg-gray-50 m-6">
            <ProfileCard />
          </div>
        </div>

        <div className="mt-6 md:w-full sm:w-full lg:w-3/4 xl:w-3/4 2xl:w-3/4 mb-6 lg:pr-6">

          <div className="p-6 rounded-lg shadow-lg bg-gray-50 ">
            <TrainingEdit id={id}/>
          </div>

        </div>
        
      </div>
     
    </div>
  )

}
export default TrainingEditList