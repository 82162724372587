import http from '../../global/http-common'
const route = '/Events';
class EventDataService {
   /* getAll() {
        return http.get("/Events");
    }*/
    getAll(countryId, stateId, cityId) {
        if (countryId) {
            if(stateId){
                if(cityId){
                    return http.get(route + "?cityId=" + cityId);
                }
                else
                {
                    return http.get(route + "?stateId=" + stateId);
                }
            }
            else{
                return http.get(route + "?countryId=" + countryId);
            }
            
        }
        return http.get(route);
    }
        
    
    GetEventsByUser () {
        return http.get('Events/GetEventsByUser');
    }
    GetEventsByCity (id) {
        return http.get(`/Events/GetEventsByCity/${id}`);
    }
    get(id) {
        return http.get(`/Events/${id}`);
    }
    create(data) {
        return http.post("/Events", data);
    }

    update(id, data) {
        return http.put(`/Events/${id}`, data);
    }

    delete(id) {
        return http.delete(`/Events/${id}`);
    }

}

export default new EventDataService();