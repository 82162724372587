// ### development api keys ### //
// export const STRIPE_PUBLISHABLE_KEY =
//     "pk_test_51MGyhBJ0ahKKnT9iRJVMvE0VOLoJRNS0SiEbeia4vboknA0pIaUqhZNbIRqy09GuxxYe6r6KdDqoSHvqVDoN3ZK300Bkjq5sZ7";
// export const STRIPE_SECRET_KEY =
//     "sk_test_51MGyhBJ0ahKKnT9iUVLxec8a59PSl9SaMHtykQnhnVipSvfOm3ZSiyPOiiMZF4vS2QdvZLAq54bw7icwdPTojuH300BKc2D5b6";
// export const PAYPAL_CLIENT_ID = "AWgZp6j0Nezcx0BOIBFnUVmm9dBBiYKwrlBHtY9HcLwo5eGoHbBF88IZ4zXzlBe_lgGf5eOMeRvw9tyR";

//export const GOOGLE_MAP_API = "AIzaSyDvXhDsrWNyYoS0SRNEwdRgqIC5C8vkTME";
//export const LANGUAGE_TRANSLATE_API = "AIzaSyACT7H0S5EASZZF9iM3nrSC_s2IKQXWlDQ";

// ### production api keys ### //

export const STRIPE_PUBLISHABLE_KEY =
    "pk_live_51HkHQBLq2Vx8ZQ6XOW9ouUn1w2KvzQarZl2SszwXs2pDKNU3RcwQqd5eFV493METSMG9Sjpu5TD8DYqBA0usJc1W00tAxq8MGK";
export const STRIPE_SECRET_KEY =
    "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";
export const PAYPAL_CLIENT_ID =
    "AVmMd1dXK95MOXau3Xddoo_vXeKb7wfEqz9l0V0nlJrNfrWM7P2nUwrk8k2HX4_YwfvMzJ3gJIHvdZa7";

export const GOOGLE_MAP_API = "AIzaSyBTDkLLBENYYkVbRjJDnwT4kByux_hnQUs";
export const LANGUAGE_TRANSLATE_API = "AIzaSyBTDkLLBENYYkVbRjJDnwT4kByux_hnQUs";
