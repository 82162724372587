import http from '../../global/http-common'
const route = '/TrainingContentDets/PreviewAvailable';
class TrainingContentDetService {
    getAll() {
        return http.get("/TrainingContentDets");
    }
    getPreviewAll(id) {
        return http.get(`${route}/${id}`);
    }
    get(id) {
        return http.get(`/TrainingContentDets/${id}`);
    }
    create(data) {
        return http.post("/TrainingContentDets", data);
    }

    update(id, data) {
        return http.put(`/TrainingContentDets/${id}`, data);
    }

    delete(id) {
        return http.delete(`/TrainingContentDets/${id}`);
    }

}

export default new TrainingContentDetService();