import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { resourceService } from "../../services/Resource/resource.service";
import ResourceArticle from "./resourceArticle";
import ResourseFile from "./resourseFile";
import ShowFileModal from "./showFileModal";
export default function ResourceView() {
    const { id } = useParams();
    const [resource, setResouce] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [source, setSource] = useState(null);
    const [topic, setTopic] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");

    const [resourceArticles, setResourceArticles] = useState([]);
    const [filteredArticles, setFilteredArticles] = useState([]);

    useEffect(() => {
        if (id != null && id > 0) getResource(id);
    }, [id]);

    const getResource = (id) => {
        resourceService
            .getById(id)
            .then((response) => {
                setResouce(response.data);
                setResourceArticles(response.data.articles);
                setFilteredArticles(response.data.articles);
                //console.log(response.data.articles);
            })
            .catch((err) => console.log(err));
    };

    // filtering degrees on search input
    useEffect(() => {
        //console.log(filteredItems)
        if (searchTerm !== "") {
            const filteredData = resourceArticles.filter((item) => {
                return Object.values(item)
                    .join("")
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase());
            });
            setFilteredArticles(filteredData);
        } else {
            setFilteredArticles([...resourceArticles]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm]);

    return (
        <div className="mt-20 pt-4 mx-4 px-4">
            <div className="flex md:flex-row sm:flex-col">
                <div className="md:w-1/3 sm:w-full mt-4">
                    <img
                        src={resource.imgUrl}
                        alt="resouce"
                        className="w-full h-60 object-cover rounded-md"
                    />
                </div>

                <div className="md:w-2/3 sm:w-full mt-4 md:pl-4 flex flex-col">
                    <p className="text-3xl font-medium">{resource.name}</p>
                    <p className="mt-2 text-gray-900 truncate">
                        Category: {resource.resourcesCategory}
                    </p>

                    <div className="mt-1 grid md:grid-cols-2 sm:grid-cols-1">
                        {resource?.phoneNo && resource?.phoneNo !== "null" && (
                            <div className="text-gray-900 truncate">
                                Phone no: {resource.phoneNo}
                            </div>
                        )}
                        {resource?.gmail && resource?.gmail !== "null" && (
                            <div className="text-gray-900 truncate">
                                Email: {resource.gmail}
                            </div>
                        )}
                    </div>
                    {resource?.location && resource?.location !== "null" && (
                        <p className="text-gray-900 truncate">
                            Location: {resource?.location}
                            {","} {resource?.city}
                            {","} {resource?.state}
                            {","} {resource?.country}
                        </p>
                    )}
                    {resource?.websiteUrl &&
                        resource?.websiteUrl !== "null" && (
                            <p className="mt-1 text-gray-900 truncate">
                                Website:{" "}
                                <a
                                    href={resource?.websiteUrl}
                                    className="inline-flex items-center font-medium text-fuchsia-600 hover:underline"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {resource?.websiteUrl}
                                </a>
                            </p>
                        )}
                    <div className="grid md:grid-cols-2 sm:grid-cols-1">
                        {resource?.facebookUrl &&
                            resource?.facebookUrl !== "null" && (
                                <p className="text-gray-900 truncate">
                                    FaceBook:{" "}
                                    <a
                                        href={`https://www.facebook.com/${resource?.facebookUrl}`}
                                        className="inline-flex items-center font-medium text-fuchsia-600 hover:underline"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {resource?.facebookUrl}
                                    </a>
                                </p>
                            )}
                        {resource?.linkedInUrl &&
                            resource?.linkedInUrl !== "null" && (
                                <p className="text-gray-900 truncate">
                                    LinkedIn:{" "}
                                    <a
                                        href={`https://www.linkedin.com/in/${resource?.linkedInUrl}`}
                                        className="inline-flex items-center font-medium text-fuchsia-600 hover:underline"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {resource?.linkedInUrl}
                                    </a>
                                </p>
                            )}
                        {resource?.twitterUrl &&
                            resource?.twitterUrl !== "null" && (
                                <p className="text-gray-900 truncate">
                                    Twitter:{" "}
                                    <a
                                        href={`https://www.twitter.com/${resource?.twitterUrl}`}
                                        className="inline-flex items-center font-medium text-fuchsia-600 hover:underline"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {resource?.twitterUrl}
                                    </a>
                                </p>
                            )}
                        {resource?.instagramUrl &&
                            resource?.instagramUrl !== "null" && (
                                <p className="text-gray-900 truncate">
                                    Instragram:{" "}
                                    <a
                                        href={`https://www/instagram.com/${resource?.instagramUrl}`}
                                        className="inline-flex items-center font-medium text-fuchsia-600 hover:underline"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {resource?.instagramUrl}
                                    </a>
                                </p>
                            )}
                    </div>
                </div>
            </div>

            <div className="w-full mt-6 text-justify">
                <p dangerouslySetInnerHTML={{ __html: resource.description }} />
            </div>

            <div className="my-8">
                <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-4">
                    <h5 className="mb-4 text-2xl font-bold leading-tight text-neutral-800">
                        Articles
                    </h5>
                    <div></div>
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 float-right"
                        placeholder="Search articles"
                    />
                </div>

                {filteredArticles.length > 0 ? (
                    <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {filteredArticles?.map((article) => {
                            return (
                                <ResourceArticle
                                    key={article.id}
                                    id={article.id}
                                    title={article.title}
                                    description={article.description}
                                    imageUrl={article.imageUrl}
                                />
                            );
                        })}
                    </div>
                ) : (
                    <div className="flex items-center justify-center">
                        <p>No articles found</p>
                    </div>
                )}
            </div>

            {resource?.resourceContents?.length > 0 && (
                <div className="my-2 mt-6 mb-8">
                    <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-4">
                        <h5 className="text-2xl font-bold leading-tight text-neutral-800">
                            Attached files
                        </h5>
                    </div>
                    <div className="grid md:grid-cols-4 sm:grid-cols-2 items-center gap-4">
                        {resource?.resourceContents?.map((item) => {
                            return (
                                <ResourseFile
                                    key={item.fileUrl}
                                    setShowModal={setShowModal}
                                    setSource={setSource}
                                    setTopic={setTopic}
                                    fileUrl={item.fileUrl}
                                    topic={item.topic}
                                />
                            );
                        })}
                    </div>
                </div>
            )}

            {showModal ? (
                <ShowFileModal
                    src={source}
                    topic={topic}
                    onClose={() => {
                        setShowModal(false);
                    }}
                />
            ) : null}
        </div>
    );
}
