import { useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { showError } from "../../global/alertService";
import { authService } from "../../services/Account/auth.service";

export function ResetPassword() {
    const mySwal = withReactContent(Swal);

    const currentUserEmail = localStorage.getItem("email");
    const [password, setPassword] = useState({
        email: currentUserEmail,
        oldPassword: "",
        newPassword: "",
    });
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState(false);

    // handle new password change event
    const handlePasswordChange = (e) => {
        const { name, value } = e.target;
        setPassword({
            ...password,
            [name]: value,
        });
    };

    // reset password input fields
    const resetPasswordInputFiedls = () => {
        setConfirmPassword("");
        setPassword({
            ...password,
            oldPassword: "",
            newPassword: "",
        });
    };

    // check if newPassword and confirmPassword is matched
    const handleCofirmPassword = (e) => {
        let text = e.target.value;
        setConfirmPassword(text);
        if (text === "") {
            setError(false);
        } else if (text !== password.newPassword) {
            setError(true);
        } else {
            setError(false);
        }
    };

    // form submit event for password change
    const handleSubmit = (e) => {
        e.preventDefault();
        if (
            password.oldPassword.length < 5 ||
            password.newPassword.length < 5
        ) {
            showError("Password length must be atleast 5 characters!");
            return;
        }
        if (password.newPassword === confirmPassword) {
            authService
                .resetPassword(password)
                .then((res) => {
                    mySwal
                        .fire({
                            title: <strong>Success</strong>,
                            html: <i>{res.data}</i>,
                            icon: "success",
                        })
                        .then(() => {
                            resetPasswordInputFiedls();
                        });
                })
                .catch((err) => {
                    //console.log(err);
                });
        } else {
            showError("New and confirm passwords do not match!");
            return;
        }
    };

    return (
        <div className="block p-6 rounded-lg shadow-lg bg-gray-50 mt-6 ml-6">
            <h5 className="text-gray-900 text-xl leading-tight font-medium mb-4">
                Password Information
            </h5>
            <form className="mb-4" onSubmit={handleSubmit}>
                <div className="grid gap-3 md:grid-cols-2">
                    <div>
                        <label className="block mb-2 text-sm font-medium text-gray-900 ">
                            Current Password
                        </label>
                        <input
                            name="oldPassword"
                            value={password.oldPassword}
                            onChange={handlePasswordChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                            required
                        />
                    </div>
                    <div>
                        <label className="block mb-2 text-sm font-medium text-gray-900 ">
                            New Password
                        </label>
                        <input
                            name="newPassword"
                            value={password.newPassword}
                            onChange={handlePasswordChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                            required
                        />
                    </div>
                    <div>
                        <label className="block mb-2 text-sm font-medium text-gray-900 ">
                            Confirm Password
                        </label>
                        <input
                            name="confirmPassword"
                            value={confirmPassword}
                            onChange={handleCofirmPassword}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                            required
                        />
                        {error && (
                            <span className="text-red-500">
                                New password and confirm new password do not
                                match
                            </span>
                        )}
                    </div>
                </div>
                <div className="text-right">
                    <button
                        type="submit"
                        className="text-white mt-2 bg-fuchsia-800  hover:bg-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    >
                        Update Password
                    </button>
                </div>
            </form>
        </div>
    );
}
