import http from '../../global/http-common'

const route = '/ResourceCategories';

export const resourceCategoriesService = {
    getAll: function getAll() {
        return http.get(route);
    },

    get: function get(id) {
        return http.get(`${route}/${id}`);
    },


}