import React, { Fragment, useEffect, useState } from "react";
import { showError } from "../../global/alertService";
import TrainingDataService from "../../services/Training/training.service";
import TrainingContentService from "../../services/Training/trainingcontents.eervice";
//import TrainingContentDetCreateForm from './contentDetCreateForm'
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export default function TrainingContentCreateForm() {
    const mySwal = withReactContent(Swal);
    const navigate = useNavigate();

    const [chapterName, setChapterName] = useState(null);
    const [content, setContent] = useState(null);

    const [courses, setCourses] = useState(null);
    const [trainingId, setTrainingId] = useState(null);

    useEffect(() => {
        TrainingDataService.getAll().then((response) => {
            //console.debug(response);
            setCourses(response.data);
            //console.log(response.data);
        });
    }, []);

    const saveTrainingContent = (event) => {
        event.preventDefault();
        //console.debug(event);
        const formData = {
            chapterName: chapterName,
            content: content,
            trainingId: trainingId,
        };
        //console.log(formData);
        TrainingContentService.create(formData)

            .then((response) => {
                mySwal
                    .fire({
                        title: <strong>Success</strong>,
                        html: <i>Record saved successfully</i>,
                        icon: "success",
                    })
                    .then(() => {
                        navigate(`/trainingContent`);
                        window.location.reload();
                    });
            })
            .catch((err) => {
                showError("Something went wrong");
            });
    };

    //----------

    return (
        <Fragment>
            <form onSubmit={saveTrainingContent}>
                {/*body*/}
                <div className="flex flex-col ">
                    <h1 className="text-2xl font-bold mb-8">
                        Create Course Chapter
                    </h1>

                    <div className="grid gap-6 mb-6 md:grid-cols-3">
                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900">
                                Training Course
                            </label>
                            <select
                                onChange={(e) =>
                                    setTrainingId(parseInt(e.target.value, 10))
                                }
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                required
                            >
                                <option value="">-- Select Course --</option>
                                {courses?.map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {" "}
                                        {item.courseName}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900">
                                Chapter
                            </label>
                            <input
                                onChange={(e) => setChapterName(e.target.value)}
                                type="text"
                                placeholder="Chapter1"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                required
                            />
                        </div>

                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900">
                                Content
                            </label>
                            <textarea
                                onChange={(e) => setContent(e.target.value)}
                                rows={2}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                required
                            />
                        </div>
                    </div>
                    {/*<ContentTable />*/}
                    {/*<ContentDetTable />*/}
                </div>

                <div className="text-right">
                    <button
                        type="submit"
                        className="inline-flex mt-6 items-center px-5 py-2.5 text-sm font-medium text-center text-white bg-fuchsia-800 rounded-lg focus:ring-4 focus:ring-blue-200 hover:bg-green-800"
                    >
                        Save
                    </button>
                </div>
            </form>
            {/*<TrainingContentDetCreateForm />*/}
        </Fragment>
    );
}
