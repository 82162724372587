import ReactPaginate from "react-paginate";
import "./pagination.css";

export const Pagination = ({ handlePageChange, pageCount }) => {
    const containerClass = "inline-flex items-center -space-x-px";
    const linkClass =
        "px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 font-medium";
    const activeLinkClass = "activeLink";

    return (
        <div className="mt-4 mb-8 text-center">
            <ReactPaginate
                breakLabel="..."
                nextLabel="Next >>"
                onPageChange={(e) => handlePageChange(e)}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="<< Prev"
                renderOnZeroPageCount={null}
                containerClassName={containerClass}
                pageClassName=""
                pageLinkClassName={linkClass}
                nextLinkClassName={linkClass}
                previousLinkClassName={linkClass}
                activeLinkClassName={activeLinkClass}
                disabledClassName="cursor-not-allowed"
                disabledLinkClassName="cursor-not-allowed"
            />
        </div>
    );
};
