import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ProfileCard from "../../components/Profile/profileCard";
import { showError } from "../../global/alertService";
import { cityService } from "../../services/City/cities.service";
import { countryService } from "../../services/Country/countries.service";
import EventDataService from "../../services/Event/event.service";
import { stateService } from "../../services/State/states.service";
import { getFormData } from "../../utils/getFormData";

export function EventForm(props) {
    const mySwal = withReactContent(Swal);
    const navigate = useNavigate();
    const initialFormState = {
        name: "",
        imgUrl: null,
        description: "",
        location: "",
        eventDate: "",
        organiser: "",
        activities: "",
        entryFee: "",
    };

    const [data, setData] = useState(initialFormState);
    const [countries, setCountries] = useState([]);
    const [countryWiseStates, setCountryWiseStates] = useState([]);
    const [stateWiseCities, setStateWiseCities] = useState([]);
    const [isUpdate, setIsUpdate] = useState(false);

    const { id } = useParams();

    useEffect(() => {
        getCountries();
        if (id > 0) {
            setIsUpdate(true);
            getEvent(id);
        } else {
            setIsUpdate(false);
            setData(initialFormState);
        }
    }, [id, props]);

    const getCountries = () => {
        countryService.getAll().then((response) => {
            // console.debug(response)
            setCountries(response.data);
        });
    };
    const getStates = (countryId) => {
        stateService.getByCountry(countryId).then((res) => {
            //console.debug(res)
            setCountryWiseStates(res.data);
        });
    };
    const getCities = (stateId) => {
        cityService.getByState(stateId).then((res) => {
            //console.debug(res)
            setStateWiseCities(res.data);
        });
    };
    const getEvent = (id) => {
        EventDataService.get(id).then((res) => {
            //console.debug(res)
            setData(res.data);
            getStates(res.data.countryId);
            getCities(res.data.stateId);
        });
    };

    const handleInputChange = (event) => {
        const { name, value } = event?.target;
        if (name === "image") {
            setData({
                ...data,
                image: event.target?.files[0],
            });
        } else {
            setData({
                ...data,
                [name]: value,
            });
        }
    };
    const saveEvent = () => {
        //console.debug(data);
        const formData = getFormData(data);
        EventDataService.create(formData)
            .then((response) => {
                mySwal
                    .fire({
                        title: <strong>Success</strong>,
                        html: <i>Event saved successfully.</i>,
                        icon: "success",
                    })
                    .then(() => {
                        setData(initialFormState);
                        navigate(`/eventIndexList`);
                    });
            })
            .catch((err) => {
                showError("Something went wrong");
                console.log(err);
            });
    };

    const updateEvent = (id) => {
        const formData = getFormData(data);
        EventDataService.update(id, formData)
            .then((response) => {
                mySwal
                    .fire({
                        title: <strong>Success</strong>,
                        html: <i>Record updated successfully.</i>,
                        icon: "success",
                    })
                    .then(() => {
                        setData(initialFormState);
                        setIsUpdate(false);
                        navigate(`/eventIndexList`);
                    });
            })
            .catch((err) => {
                showError("Something went wrong");
                console.log(err);
            });
    };

    return (
        <Fragment>
            <div className="flex flex-row flex-wrap mt-20 ">
                <div className="md:w-full sm:w-full lg:w-1/4 xl:w-1/4 2xl:w-1/4 ">
                    <div className="block p-6 rounded-lg shadow-lg bg-gray-50 m-6">
                        <ProfileCard />
                    </div>
                </div>
                <div className="mt-6 md:w-full sm:w-full lg:w-3/4 xl:w-3/4 2xl:w-3/4 mb-6 lg:pr-6">
                    <div className="p-6 rounded-lg shadow-lg bg-gray-50 ">
                        {/*<div className="flex flex-col m-4">*/}
                        <h1 className="font-bold text-xl mb-6">Event Form</h1>
                        <div className="mb-4 mr-4 w-full bg-gray-50 rounded-lg border border-gray-200">
                            <form
                                onSubmit={(event) => {
                                    event.preventDefault();
                                    if (!isUpdate) {
                                        saveEvent();
                                    } else {
                                        updateEvent(id);
                                    }
                                }}
                                className="m-4"
                            >
                                <div className="grid gap-3 mb-4 md:grid-cols-4">
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            Name
                                        </label>
                                        <input
                                            name="name"
                                            value={data.name}
                                            onChange={handleInputChange}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            Event Date
                                        </label>
                                        <input
                                            name="eventDate"
                                            value={data.eventDate}
                                            onChange={handleInputChange}
                                            type="datetime-local"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            House/Apt No.
                                        </label>
                                        <input
                                            name="location"
                                            value={data.location}
                                            onChange={handleInputChange}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            Country{" "}
                                        </label>
                                        <select
                                            name="countryId"
                                            value={data.countryId}
                                            onChange={(e) => {
                                                getStates(e.target.value);
                                                handleInputChange(e);
                                            }}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                            required
                                        >
                                            <option value="">
                                                -- Select Country --
                                            </option>
                                            {countries?.map((item) => (
                                                <option
                                                    key={item.id}
                                                    value={item.id}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            State{" "}
                                        </label>
                                        <select
                                            name="stateId"
                                            value={data.stateId}
                                            onChange={(e) => {
                                                console.debug(e.target);
                                                getCities(e.target.value);
                                                handleInputChange(e);
                                            }}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                            required
                                        >
                                            <option value="">
                                                -- Select State --
                                            </option>
                                            {countryWiseStates?.map((item) => (
                                                <option
                                                    key={item.id}
                                                    value={item.id}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            City{" "}
                                        </label>
                                        <select
                                            name="cityId"
                                            value={data.cityId}
                                            onChange={handleInputChange}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                            required
                                        >
                                            <option value="">
                                                -- Select City --
                                            </option>
                                            {stateWiseCities?.map((item) => (
                                                <option
                                                    key={item.id}
                                                    value={item.id}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            Description
                                        </label>
                                        <textarea
                                            name="description"
                                            value={data.description}
                                            onChange={handleInputChange}
                                            rows={4}
                                            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                            placeholder="Write event description..."
                                            defaultValue={""}
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            Activities
                                        </label>
                                        <textarea
                                            name="activities"
                                            value={data.activities}
                                            onChange={handleInputChange}
                                            rows={4}
                                            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                            placeholder="Write event activities..."
                                            defaultValue={""}
                                        />
                                    </div>
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            Organiser
                                        </label>
                                        <textarea
                                            name="organiser"
                                            value={data.organiser}
                                            onChange={handleInputChange}
                                            rows={4}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                            placeholder="Write event organiser..."
                                            defaultValue={""}
                                        />
                                    </div>

                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            Image Url
                                        </label>
                                        <input
                                            name="image"
                                            type="file"
                                            onChange={handleInputChange}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                        />
                                    </div>

                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            Entry Fee
                                        </label>
                                        <input
                                            name="entryFee"
                                            value={data.entryFee}
                                            type="text"
                                            onChange={handleInputChange}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-row justify-end items-center">
                                    <div>
                                        <Link
                                            to="/eventIndexList"
                                            className="px-10 mr-4 hover:text-green-800 "
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth={1.5}
                                                stroke="currentColor"
                                                className="w-6 h-6 "
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                                                />
                                            </svg>
                                        </Link>
                                    </div>
                                    <div>
                                        <button
                                            className="text-right bg-fuchsia-800 text-white hover:bg-green-800 font-bold  text-sm px-6 py-2.5 mb-4 rounded shadow hover:shadow-lg outline-none focus:outline-none inline-block"
                                            type="submit"
                                        >
                                            {isUpdate ? "Update" : "Save"}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
