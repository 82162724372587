import { useEffect, useState } from "react";
import { SubscriptionEnd } from "./subscriptionEnd";
import { subscriptionService } from "../../services/Account/subscription.service";
import { UpgradeAndExtendMembership } from "./upgradeAndExtendMembership";

export function UserType({ profile }) {
    const [premiumUser, setPremiumUser] = useState(null);
    const [showUpgrade, setShowUpgrade] = useState(false);
    const [membershipType, setMembershipType] = useState(null);
    const [subscriptionCycle, setSubscriptionCycle] = useState(null);

    useEffect(() => {
        //console.log(profile);
        setPremiumUser(profile?.isPremiumUser);
        getMembership();
    }, [profile]);
    const getMembership = () => {
        subscriptionService.get().then((res) => {
            setMembershipType(res.data?.membershipType);
            setSubscriptionCycle(res.data?.subscriptionCycle);
        });
    };
    return (
        <>
            {premiumUser && (
                <div className="block p-6 rounded-lg shadow-lg bg-gray-50 mt-6">
                    <SubscriptionEnd />
                </div>
            )}
            <div className="block p-6 rounded-lg shadow-lg bg-gray-50 mt-6">
                <h1 className="text-xl font-bold mb-2">
                    User Type: {premiumUser ? "Premium" : "Standard"}
                </h1>

                {premiumUser ? (
                    <>
                        <p className="mb-1">
                            <strong>
                                Membership type:
                                <>
                                    {membershipType === 1 && " Student"}
                                    {membershipType === 2 && " Worker"}
                                    {membershipType === 3 && " Employer"}
                                    {membershipType === 4 && " General"}
                                </>
                            </strong>
                        </p>
                        <p className="mb-2">
                            <strong>
                                Subscription cycle:
                                <>
                                    {subscriptionCycle === 1 && " Monthly"}
                                    {subscriptionCycle === 3 && " Quarter"}
                                    {subscriptionCycle === 6 && " Half Yearly"}
                                    {subscriptionCycle === 12 && " Yearly"}
                                    {subscriptionCycle === 0 && " Free Trial"}
                                </>
                            </strong>
                        </p>
                        <h3>Thank you for choosing premium membership.</h3>
                    </>
                ) : (
                    <>
                        <div>
                            <h4 className="text-lg font-medium">
                                Premium user benefits:
                            </h4>
                            <p className="w-96 mt-2 text-sm text-gray-500 mb-4">
                                Our premium services give premium access to
                                Employers to advertise job openings, find
                                qualified talents complete a prescreening of the
                                qualified candidate on our career portal.
                                Receive additional support through (Djigui HUB)
                                providing one on one assistance with the
                                services you need and for users to gain access
                                to all training materials for free or at a
                                discounted price
                            </p>
                        </div>
                        <div className="text-right mb-4">
                            <button
                                onClick={() => setShowUpgrade((prev) => !prev)}
                                type="button"
                                className="text-white bg-fuchsia-800  hover:bg-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                            >
                                Upgrade Membership
                            </button>
                        </div>
                    </>
                )}

                {!premiumUser && showUpgrade && (
                    <UpgradeAndExtendMembership type={"Standard"} />
                )}
            </div>
        </>
    );
}
