import { Fragment, useState } from "react";

export function BackButton() {
    const [visible, setVisible] = useState(false);

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 400) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    window.addEventListener("scroll", toggleVisible);
    return (
        <Fragment>
            <div className="text-right">
                <button
                    onClick={scrollToTop}
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="light"
                    style={{
                        position: "fixed",
                        bottom: "24px",
                        right: "24px",
                        display: visible ? "inline" : "none",
                    }}
                    className="inline-block p-2 border-2 border-fuchsia-700 transition duration-150 ease-in-out mx-1"
                >
                    <svg
                        className="w-7 h-7 text-fuchsia-700"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 384 512"
                    >
                        <path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z" />
                    </svg>
                </button>
            </div>
        </Fragment>
    );
}
