import React, { useState } from "react";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { showError } from "../../global/alertService";
import TrainingDataService from "../../services/Training/training.service";

export default function TrainingCreateForm() {
    const mySwal = withReactContent(Swal);
    const navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);

    const [courseName, setCourseName] = useState(null);
    const [description, setDescription] = useState(null);
    const [instructorId, setInstructorId] = useState(null);
    const [price, setPrice] = useState(null);
    const [imgUrl, setImgUrl] = useState(null);
    //const [chapterName, setChapterName] = useState(null);

    const saveTraining = (event) => {
        event.preventDefault();
        console.debug(event);
        console.log(imgUrl);
        let formData = new FormData();
        formData.append("courseName", courseName);
        formData.append("description", description);
        formData.append("instructorId", 1); //
        formData.append("price", price);
        formData.append("image", imgUrl);

        //console.log(formData);
        TrainingDataService.create(formData)

            .then((response) => {
                mySwal
                    .fire({
                        title: <strong>Success</strong>,
                        html: <i>Record saved successfully</i>,
                        icon: "success",
                    })
                    .then(() => {
                        navigate(`/trainingContent`);
                        window.location.reload();
                    });
            })
            .catch((err) => {
                showError("Something went wrong");
            });
    };

    //----------

    return (
        <>
            <form onSubmit={saveTraining}>
                {/*body*/}
                <div className="flex flex-col">
                    <h1 className="text-2xl font-bold mb-8">
                        Create Training Course
                    </h1>

                    <div className="grid gap-6 mb-6 md:grid-cols-4">
                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900">
                                Course Name
                            </label>
                            <input
                                onChange={(e) => setCourseName(e.target.value)}
                                type="text"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                required
                            />
                        </div>

                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900">
                                Description
                            </label>
                            <textarea
                                onChange={(e) => setDescription(e.target.value)}
                                rows={2}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                required
                            />
                        </div>

                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900">
                                Price
                            </label>
                            <input
                                onChange={(e) => setPrice(e.target.value)}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                required
                            />
                        </div>

                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900">
                                Image
                            </label>
                            <input
                                type="file"
                                onChange={(e) => setImgUrl(e.target.files[0])}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                required
                            />
                        </div>
                    </div>
                </div>

                <div className="text-right">
                    <button
                        type="submit"
                        className="inline-flex mt-6 items-center px-5 py-2.5 text-sm font-medium text-center text-white bg-fuchsia-800 rounded-lg focus:ring-4 focus:ring-blue-200 hover:bg-green-800"
                    >
                        Save
                    </button>
                </div>
            </form>
        </>
    );
}
