import http from "../../global/http-common";
const route = "/Posts/Comments";

export const PostCommentService = {
    getAll: function getAll() {
        return http.get(route);
    },
    get: function get(id) {
        return http.get(`${route}/${id}`);
    },
    create: function create(data) {
        // data.forEach((value, key) => {
        //     console.debug(key, value)
        // });
        return http.post(route, data);
    },
    update: function update(id, data) {
        return http.put(`${route}/${id}`, data);
    },
    delete: function deleteOne(id) {
        return http.delete(`${route}/${id}`);
    },
    deleteAll: function deleteAll() {
        return http.delete(route + "/DeleteAll");
    },
};
