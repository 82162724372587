import http from '../../global/http-common'

class EventParticipantService {
    getAll() {
        return http.get("/EventParticipants");
    }
    get(id) {
        return http.get(`/EventParticipants/${id}`);
    }
    create(data) {
        return http.post("/EventParticipants", data);
    }

    update(id, data) {
        return http.put(`/EventParticipants/${id}`, data);
    }

    delete(id) {
        return http.delete(`/EventParticipants/${id}`);
    }

    checkParticipant(eventId){
        return http.get(`/EventParticipants/CheckParticipant/${eventId}`);
    }

    get(eventid) {
        return http.get(`/EventParticipants/${eventid}`);
    }

}

export default new EventParticipantService();