import http from "../../global/http-common";

const route = "/SubscriptionMemberships";

export const subscriptionMembershipService = {
    getAll: function getAll() {
        return http.get(route);
    },
    create: function create(data) {
        return http.post(route, data);
    },
    getMembershipPrice: function getMembershipPrice(data) {
        //console.log(data);
        return http.get(
            `${route}/${data.membershipType}/${data.subscriptionCycle}`,
            data
        );
    },
    getForEmployeer: function getForEmployeer() {
        return http.get(route + "/SingUpForEmployeer");
    },
};
