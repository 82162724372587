import http from "../../global/http-common";

const route = "/EmployerDesignations";

export const EmployerDesignationService = {
    getAll: function getAll() {
        return http.get(route);
    },
    get: function get(id) {
        return http.get(`${route}/${id}`);
    },
};
