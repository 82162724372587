import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { useContext } from "react";
import { showError } from "../../global/alertService";
import { PAYPAL_CLIENT_ID } from "../../global/api-keys";
import { MembershipUpgradeContext } from "../Profile/upgradeAndExtendMembership";

export function SubscriptionUpgradeAndExtendPaypal({ price }) {
    const { setPaypalOrderId, setIsUpgraded } = useContext(
        MembershipUpgradeContext
    );

    return (
        <div className="w-full">
            <PayPalScriptProvider
                options={{
                    "client-id": PAYPAL_CLIENT_ID,
                }}
            >
                <PayPalButtons
                    fundingSource="paypal"
                    style={{
                        height: 48,
                    }}
                    createOrder={(data, actions) => {
                        return actions.order.create({
                            purchase_units: [
                                {
                                    description: "Subscription purchase",
                                    amount: {
                                        value: price,
                                    },
                                },
                            ],
                        });
                    }}
                    onApprove={function (data, actions) {
                        setPaypalOrderId(data.orderID);
                        setIsUpgraded(true);
                        //console.log(data);
                    }}
                    onError={(err) => {
                        showError("Transaction error!");
                        console.log(err);
                    }}
                />
            </PayPalScriptProvider>
        </div>
    );
}
