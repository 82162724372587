import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { showSuccess } from "../../global/alertService";
import { authService } from "../../services/Account/auth.service";

export function ResetPassword(props) {
    const [data, setData] = useState([]);
    const navigate = useNavigate();

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setData({ ...data, [name]: value });
    };
    function onSubmitResetForm() {
        const resetData = {
            email: data.email,
            password: data.password,
        };
        authService.resetPassword(resetData).then((res) => {
            console.debug(res.data);
            authService.signout();
            setData(null);
            showSuccess("Your password is reset successfully.");
            //window.location.reload()
        });
    }

    return (
        <Fragment>
            <div className="flex flex-col items-center mt-24">
                <h1 className="mb-8 text-4xl font-bold text-fuchsia-800">
                    {" "}
                    Reset Password!
                </h1>
                {/*<h2 className="text-2xl font-bold mt-4 mb-1">Please enter your email address.</h2>
                <h2 className="text-2xl font-bold  mb-8">We will reset your password now.</h2>*/}
                <form
                    onSubmit={(event) => {
                        event.preventDefault();
                        onSubmitResetForm();
                    }}
                >
                    <div>
                        <label className="block mb-4 text-sm font-medium text-gray-900">
                            Email
                        </label>
                        <input
                            value={data?.email}
                            onChange={handleInputChange}
                            type="email"
                            name="email"
                            className="bg-gray-50 w-96 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                            placeholder="Enter your email"
                            required
                        />
                    </div>
                    <div>
                        <label className="block mb-4 mt-4 text-sm font-medium text-gray-900">
                            Password
                        </label>
                        <input
                            value={data?.password}
                            onChange={handleInputChange}
                            type="password"
                            name="password"
                            placeholder="••••••••"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            required
                        />
                    </div>
                    <div>
                        <label className="block mb-4 mt-4 text-sm font-medium text-gray-900">
                            Confirm Password
                        </label>
                        <input
                            type="password"
                            name="password"
                            placeholder="••••••••"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full mt-6 text-white bg-fuchsia-800 hover:bg-green-800 font-bold rounded-lg text-xl px-5 py-2.5 text-center "
                    >
                        Reset Password
                    </button>
                </form>
            </div>
        </Fragment>
    );
}
