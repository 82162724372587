import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ProfileCard from "../../components/Profile/profileCard";
import { showError } from "../../global/alertService";
import jobCategoriesService from "../../services/Category/jobCategories.service";
import { cityService } from "../../services/City/cities.service";
import { countryService } from "../../services/Country/countries.service";
import EmployerJobPostService from "../../services/EmployerJob/employerjob.service";
import { stateService } from "../../services/State/states.service";
import { toLocalDate } from "../../utils/date-utils";

export function EmployerJobPostForm(props) {
    const mySwal = withReactContent(Swal);
    const navigate = useNavigate();

    const initialFormState = {
        jobTitle: "",
        jobLocation: "",
        status: "",
        educationalRequirement: "",
        jobResponsibility: "",
        experience: "",
        skills: "",
        vacancy: "",
        publishedDate: "",
        applicationDeadline: "",
        employerName: "",
        employerLink: "",
        benefits: "",
        salary: "",
        cityId: "",
        jobCategoryId: "",
    };

    const [data, setData] = useState(initialFormState);
    const [categories, setCategories] = useState(null);

    const [countries, setCountries] = useState([]);
    const [countryWiseStates, setCountryWiseStates] = useState([]);
    const [stateWiseCities, setStateWiseCities] = useState([]);
    const [isUpdate, setIsUpdate] = useState(false);

    const { id } = useParams();
    // Get the current date
    const date = new Date();
    date.setDate(date.getDate() + 1);

    const currentDate = date.toISOString().split('T')[0];

    //const currentDate = new Date().toISOString().split('T')[0];
    //console.log(currentDate);

    useEffect(() => {
        getCategories();
        getCountries();
        if (id > 0) {
            setIsUpdate(true);
            getEmployerJobPost(id);
        } else {
            setIsUpdate(false);
            setData(initialFormState);
        }
    }, [id, props]);

    const getCategories = () => {
        jobCategoriesService.getAll().then((response) => {
            //console.debug(response);
            setCategories(response.data);
            //console.log(response.data);
        });
    };
    const getCountries = () => {
        countryService.getAll().then((response) => {
            // console.debug(response)
            setCountries(response.data);
        });
    };
    const getStates = (countryId) => {
        stateService.getByCountry(countryId).then((res) => {
            //console.debug(res)
            setCountryWiseStates(res.data);
        });
    };
    const getCities = (stateId) => {
        cityService.getByState(stateId).then((res) => {
            //console.debug(res)
            setStateWiseCities(res.data);
        });
    };
    const getEmployerJobPost = (id) => {
        EmployerJobPostService.get(id).then((res) => {
            //console.debug(res)
            setData(res.data);
            getStates(res.data.countryId);
            getCities(res.data.stateId);
        });
    };

    const handleInputChange = (event) => {
        const { name, value } = event?.target;
        setData({
            ...data,
            [name]: value,
        });
    };
    const saveEmployerJobPost = () => {
        if (data.applicationDeadline === "") {
            data.applicationDeadline = null;
        }
        //console.log(data)
        EmployerJobPostService.create(data)
            .then((response) => {
                mySwal
                    .fire({
                        title: <strong>Success</strong>,
                        html: <i>Employer Job Post saved successfully.</i>,
                        icon: "success",
                    })
                    .then(() => {
                        setData(initialFormState);
                        navigate(`/employerJobPostIndexList`);
                    });
            })
            .catch((err) => {
                showError("Something went wrong");
                console.log(err);
            });
    };

    const updateEmployerJobPost = (id) => {
        if (data.applicationDeadline === "") {
            data.applicationDeadline = null;
        }
        EmployerJobPostService.update(id, data)
            .then((response) => {
                mySwal
                    .fire({
                        title: <strong>Success</strong>,
                        html: <i>Employer Job Post updated successfully.</i>,
                        icon: "success",
                    })
                    .then(() => {
                        setData(initialFormState);
                        setIsUpdate(false);
                        navigate(`/employerJobPostIndexList`);
                    });
            })
            .catch((err) => {
                showError("Something went wrong");
                console.log(err);
            });
    };

    return (
        <Fragment>
            <div className="flex flex-row flex-wrap mt-20 ">
                <div className="md:w-full sm:w-full lg:w-1/4 xl:w-1/4 2xl:w-1/4 ">
                    <div className="block p-6 rounded-lg shadow-lg bg-gray-50 m-6">
                        <ProfileCard />
                    </div>
                </div>
                <div className="mt-6 md:w-full sm:w-full lg:w-3/4 xl:w-3/4 2xl:w-3/4 mb-6 lg:pr-6">
                    <div className="p-6 rounded-lg shadow-lg bg-gray-50 ">
                        <h1 className="font-bold text-xl mb-6">
                            Employer Job Post Form
                        </h1>
                        <div className="mb-4 mr-4 w-full bg-gray-50 rounded-lg border border-gray-200">
                            <form
                                onSubmit={(event) => {
                                    event.preventDefault();
                                    if (!isUpdate) {
                                        saveEmployerJobPost();
                                    } else {
                                        updateEmployerJobPost(id);
                                    }
                                }}
                                className="m-4"
                            >
                                <div className="grid gap-3 mb-4 md:grid-cols-4">
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            Job Title
                                        </label>
                                        <input
                                            name="jobTitle"
                                            value={data.jobTitle}
                                            onChange={handleInputChange}
                                            type="text"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            Job Category
                                        </label>
                                        <select
                                            name="jobCategoryId"
                                            value={data?.jobCategoryId}
                                            onChange={handleInputChange}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                            required
                                        >
                                            <option value="">
                                                -- Select Category --
                                            </option>
                                            {categories?.map((item) => (
                                                <option
                                                    key={item.id}
                                                    value={item.id}
                                                >
                                                    {" "}
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            Company Name
                                        </label>
                                        <input
                                            name="employerName"
                                            value={data.employerName}
                                            onChange={handleInputChange}
                                            type="text"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            Employer Link
                                        </label>
                                        <input
                                            name="employerLink"
                                            value={data.employerLink}
                                            onChange={handleInputChange}
                                            type="text"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                        />
                                    </div>
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            Experience
                                        </label>
                                        
                                         <textarea
                                            name="experience"
                                            value={data.experience}
                                            onChange={handleInputChange}
                                            rows={2}
                                            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                            required
                                        />
                                    </div>

                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            Job Responsibility
                                        </label>
                                        <textarea
                                            name="jobResponsibility"
                                            value={data.jobResponsibility}
                                            onChange={handleInputChange}
                                            rows={2}
                                            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            Benefits
                                        </label>
                                        <textarea
                                            name="benefits"
                                            value={data.benefits}
                                            onChange={handleInputChange}
                                            rows={2}
                                            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                        />
                                    </div>
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            Education
                                        </label>
                                        <textarea
                                            name="educationalRequirement"
                                            value={data.educationalRequirement}
                                            onChange={handleInputChange}
                                            rows={2}
                                            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                            required
                                        />
                                    </div>

                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            Skills
                                        </label>
                                        <textarea
                                            name="skills"
                                            value={data.skills}
                                            onChange={handleInputChange}
                                            rows={2}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                        />
                                    </div>
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            No of Vacancy
                                        </label>
                                        <input
                                            name="vacancy"
                                            value={data.vacancy}
                                            onChange={handleInputChange}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            Date Published
                                        </label>
                                        <input
                                            name="publishedDate"
                                            value={toLocalDate(
                                                data.publishedDate
                                            )}
                                            onChange={handleInputChange}
                                            type="date"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                            //max={currentDate}
                                            required
                                        />
                                        {data.publishedDate && data.publishedDate >= currentDate && (
                                            <p className="text-red-500 text-sm mt-2">
                                                The selected date must be in the past or today.
                                            </p>
                                        )}
                                    </div>
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            Application Deadline
                                        </label>
                                        <input
                                            name="applicationDeadline"
                                            value={toLocalDate(
                                                data.applicationDeadline
                                            )}
                                            onChange={handleInputChange}
                                            type="date"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                            //min={currentDate}
                                            //required
                                        />
                                        {/*{data.applicationDeadline && data.applicationDeadline < currentDate && (*/}
                                        {(data.applicationDeadline && !isUpdate ) && data.applicationDeadline < currentDate && (
 
                                            <p className="text-red-500 text-sm mt-2">
                                                The selected date must be in the future.
                                            </p>
                                        )}
                                    </div>
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            Salary
                                        </label>
                                        <input
                                            name="salary"
                                            value={data.salary}
                                            onChange={handleInputChange}
                                            type="text"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                        />
                                    </div>

                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            Employment Status
                                        </label>
                                        <select
                                            name="status"
                                            value={data.status}
                                            onChange={handleInputChange}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                            required
                                        >
                                            <option value="">
                                                -- Select Status --
                                            </option>
                                            <option value="Full-Time">
                                                Full-Time
                                            </option>
                                            <option value="Part-Time">
                                                Part-Time
                                            </option>
                                        </select>
                                    </div>
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            Address
                                        </label>
                                        <input
                                            name="jobLocation"
                                            value={data.jobLocation}
                                            onChange={handleInputChange}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                            defaultValue={""}
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            Country{" "}
                                        </label>
                                        <select
                                            name="countryId"
                                            value={data.countryId}
                                            onChange={(e) => {
                                                getStates(e.target.value);
                                                handleInputChange(e);
                                            }}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                            required
                                        >
                                            <option value="">
                                                -- Select Country --
                                            </option>
                                            {countries?.map((item) => (
                                                <option
                                                    key={item.id}
                                                    value={item.id}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            State{" "}
                                        </label>
                                        <select
                                            name="stateId"
                                            value={data.stateId}
                                            onChange={(e) => {
                                                console.debug(e.target);
                                                getCities(e.target.value);
                                                handleInputChange(e);
                                            }}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                            required
                                        >
                                            <option value="">
                                                -- Select State --
                                            </option>
                                            {countryWiseStates?.map((item) => (
                                                <option
                                                    key={item.id}
                                                    value={item.id}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            City{" "}
                                        </label>
                                        <select
                                            name="cityId"
                                            value={data.cityId}
                                            onChange={handleInputChange}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                            required
                                        >
                                            <option value="">
                                                -- Select City --
                                            </option>
                                            {stateWiseCities?.map((item) => (
                                                <option
                                                    key={item.id}
                                                    value={item.id}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="flex flex-row justify-end items-center">
                                    <div>
                                        <Link
                                            to="/employerJobPostIndexList"
                                            className="px-10 mr-4 hover:text-green-800 "
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth={1.5}
                                                stroke="currentColor"
                                                className="w-6 h-6 "
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                                                />
                                            </svg>
                                        </Link>
                                    </div>
                                    <div>
                                        <button
                                            className="text-right bg-fuchsia-800 text-white hover:bg-green-800 font-bold  text-sm px-6 py-2.5 mb-4 rounded shadow hover:shadow-lg outline-none focus:outline-none inline-block"
                                            type="submit"
                                        >
                                            {isUpdate ? "Update" : "Save"}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
