import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { resourceService } from "../../services/Resource/resource.service";

export default function ResourceArticleView() {
    const { id } = useParams();
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [imageUrl, setImgageUrl] = useState(null);

    useEffect(() => {
        if (id !== null && id > 0) {
            getArticleById(id);
        }
    }, [id]);

    const getArticleById = (id) => {
        resourceService.getArticleById(id).then((res) => {
            setTitle(res.data.title);
            setDescription(res.data.description);
            setImgageUrl(res.data.imageUrl);
        });
    };

    return (
        <div className="mt-20 pt-8 mx-auto px-4 sm:w-full md:w-2/3">
            {imageUrl && (
                <img
                    src={imageUrl}
                    alt=""
                    className="w-full lg:h-72 md:h-60 sm:h-48 object-cover rounded-lg mb-4"
                />
            )}
            <h5 className="mb-2 text-2xl font-medium leading-tight text-neutral-800 w-full">
                {title}
            </h5>
            <p
                className="mb-4 text-base text-neutral-600 w-full text-justify"
                dangerouslySetInnerHTML={{ __html: description }}
            />
        </div>
    );
}
