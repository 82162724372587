import data from "@emoji-mart/data";
import EmojiPicker from "@emoji-mart/react";
import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { showConfirmation, showSuccess } from "../../global/alertService";
import { PostCommentService } from "../../services/Post/postComment.service";
import { classNames } from "../../utils/className-utils";
import { toLocalEventTime } from "../../utils/event-time";

export function Comments({ comment, setFetchingComment }) {
    const [editCommentModal, setEditCommentModal] = useState(false);
    const [editComment, setEditComment] = useState({
        comment: "",
        image: null,
        postId: 0,
    });
    const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
    const [image, setImage] = useState(null);

    const currentUserEmail = localStorage.getItem("email");

    useEffect(() => {
        //console.debug(fetchingComment);
    }, [comment]);

    const onEmojiClick = (e) => {
        //console.debug(e.native)
        let sym = e.unified.split("-");
        let codesArray = [];
        sym.forEach((el) => codesArray.push("0x" + el));
        let emoji = String.fromCodePoint(...codesArray);
        document.getElementById("editComment").value =
            document.getElementById("editComment").value + emoji;
        setEditComment({
            ...editComment,
            comment: editComment.comment + emoji,
        });
        // console.debug(emoji)
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        //console.debug(name, value, files);
        if (name === "image") {
            setEditComment({
                ...editComment,
                image: event.target?.files[0],
            });
            setImage(event.target.files[0]);
        } else {
            setEditComment({
                ...editComment,
                [name]: value,
            });
        }
    };
    const onGet = (id) => {
        PostCommentService.get(id).then((res) => {
            //console.debug(res.data)
            setEditComment(res.data);
        });
    };

    const editPostComment = (id) => {
        const formData = new FormData();
        formData.append("comment", editComment.comment);
        formData.append("image", editComment.image);
        formData.append("postId", editComment.postId);
        PostCommentService.update(id, formData).then((res) => {
            setEditCommentModal(false);
            setFetchingComment(true);
        });
    };

    const deleteComment = (id) => {
        showConfirmation().then((result) => {
            if (result.isConfirmed) {
                PostCommentService.delete(id).then((response) => {
                    // console.debug(response)
                    showSuccess("Deleted successfully.");
                    // getPosts() TODO:
                    setFetchingComment(true);
                });
            } else {
                showSuccess("Not deleted", "Ok");
            }
        });
    };

    return (
        <Fragment>
            <div className="mb-2">
                <div className="flex flex-col justify-between p-2 leading-normal">
                    <div className=" min-w-0 max-w-lg">
                        <div className="flex justify-between">
                            <div className=" flex items-center flex-row ">
                                {comment?.profilePic != null ? (
                                    <img
                                        className="w-6 h-6 justify-center items-center rounded-full"
                                        src={comment?.profilePic}
                                        alt=""
                                    />
                                ) : (
                                    <svg
                                        className="bg-green-400 w-6 h-6 text-gray-400 rounded-full"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                                            clipRule="evenodd"
                                        ></path>
                                    </svg>
                                )}
                                {/* <img
                                    className="w-8 h-8 justify-center items-center rounded-full"
                                    src="https://mdbootstrap.com/img/new/standard/nature/010.webp"
                                    alt=""
                                /> */}
                                <div className="text-sm ml-2 font-medium text-gray-900 truncate">
                                    {comment?.firstName + " "}
                                    {comment?.lastName}
                                </div>
                                <div className="ml-4 text-sm font-normal">
                                    {" "}
                                    {new Date(
                                        comment?.createdAtUtc
                                    ).toLocaleDateString()}{" "}
                                    {toLocalEventTime(comment?.createdAtUtc)}
                                </div>
                            </div>
                            <Menu
                                as="div"
                                className=" items-center justify-end rounded-b"
                            >
                                <div>
                                    <Menu.Button aria-hidden="true">
                                        <svg
                                            className="w-4 h-4"
                                            aria-hidden="true"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"></path>
                                        </svg>
                                    </Menu.Button>
                                </div>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="absolute z-10 mt-2 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1">
                                            {currentUserEmail ===
                                            comment?.email ? (
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <button
                                                            onClick={() => {
                                                                onGet(
                                                                    comment?.id
                                                                );
                                                                setEditCommentModal(
                                                                    true
                                                                );
                                                            }}
                                                            className={classNames(
                                                                active
                                                                    ? "bg-gray-100 text-gray-900"
                                                                    : "text-gray-700",
                                                                " px-4 py-2 text-sm w-full flex items-center space-x-3"
                                                            )}
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                strokeWidth={
                                                                    1.5
                                                                }
                                                                stroke="currentColor"
                                                                className="w-6 h-6"
                                                            >
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                                                />
                                                            </svg>
                                                            <span>Edit</span>
                                                        </button>
                                                    )}
                                                </Menu.Item>
                                            ) : null}
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <button
                                                        onClick={() => {
                                                            deleteComment(
                                                                comment?.id
                                                            );
                                                        }}
                                                        className={classNames(
                                                            active
                                                                ? "bg-gray-100 text-gray-900 w-full"
                                                                : "text-gray-700",
                                                            "px-4 py-2 text-sm w-full flex items-center space-x-3"
                                                        )}
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth={1.5}
                                                            stroke="currentColor"
                                                            className="w-6 h-6"
                                                        >
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                                            />
                                                        </svg>
                                                        <span>Delete</span>
                                                    </button>
                                                )}
                                            </Menu.Item>
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>
                        <div className="flex-1 ml-10">
                            <p className="text-sm font-medium mb-2 text-gray-800 truncate">
                                {comment?.comment}
                            </p>
                            <img
                                className="max-w-10 max-h-10 rounded-lg "
                                src={comment?.imgUrl}
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                {editCommentModal && (
                    <>
                        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative w-auto my-6 mx-auto max-w-md">
                                {/*content*/}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                    {/*header*/}
                                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                        <h3 className="text-3xl font-semibold">
                                            Edit
                                        </h3>
                                        <button
                                            className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                            onClick={() =>
                                                setEditCommentModal(false)
                                            }
                                        >
                                            <span className=" text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                                                ×
                                            </span>
                                        </button>
                                    </div>
                                    {/*body*/}
                                    <form
                                        onSubmit={(event) => {
                                            event.preventDefault();
                                            editPostComment(editComment?.id);
                                        }}
                                    >
                                        <div className="flex items-center py-2 px-3 rounded-lg mt-4">
                                            <label
                                                htmlFor="img"
                                                className="inline-flex justify-center p-2 text-gray-500 rounded-lg cursor-pointer hover:text-gray-900 hover:bg-gray-100"
                                            >
                                                <svg
                                                    aria-hidden="true"
                                                    className="w-6 h-6"
                                                    fill="currentColor"
                                                    viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                                        clipRule="evenodd"
                                                    ></path>
                                                </svg>
                                                <span className="sr-only">
                                                    Upload image
                                                </span>
                                                <input
                                                    name="image"
                                                    type="file"
                                                    id="img"
                                                    accept=".png,.jpeg,.jpg"
                                                    style={{ display: "none" }}
                                                    onChange={handleInputChange}
                                                />
                                            </label>
                                            <button
                                                onClick={() => {
                                                    setIsEmojiPickerOpen(
                                                        !isEmojiPickerOpen
                                                    );
                                                }}
                                                type="button"
                                                className="p-2 text-gray-500 rounded-lg cursor-pointer hover:text-gray-900 hover:bg-gray-100"
                                            >
                                                <svg
                                                    aria-hidden="true"
                                                    className="w-6 h-6"
                                                    fill="currentColor"
                                                    viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 100-2 1 1 0 000 2zm7-1a1 1 0 11-2 0 1 1 0 012 0zm-.464 5.535a1 1 0 10-1.415-1.414 3 3 0 01-4.242 0 1 1 0 00-1.415 1.414 5 5 0 007.072 0z"
                                                        clipRule="evenodd"
                                                    ></path>
                                                </svg>
                                                <span className="sr-only">
                                                    Add emoji
                                                </span>
                                            </button>
                                            <textarea
                                                rows="1"
                                                name="comment"
                                                id="editComment"
                                                value={editComment?.comment}
                                                onChange={handleInputChange}
                                                type="text"
                                                className="block mx-4 p-2.5 w-96 text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                                placeholder="Your comment..."
                                            ></textarea>

                                            <button
                                                type="submit"
                                                className="inline-flex justify-center p-4 mb-6 text-fuchsia-800 rounded-full cursor-pointer hover:bg-blue-100"
                                            >
                                                <svg
                                                    aria-hidden="true"
                                                    className="w-6 h-6 rotate-90"
                                                    fill="currentColor"
                                                    viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                                                </svg>
                                            </button>
                                        </div>

                                        {image && (
                                            <div className="relative px-4">
                                                <div className="flex justify-center">
                                                    <img
                                                        className=" h-40 w-40 rounded-lg "
                                                        src={URL.createObjectURL(
                                                            image
                                                        )}
                                                        alt=""
                                                    />
                                                </div>

                                                <button
                                                    onClick={() => {
                                                        setImage(null);
                                                    }}
                                                    className="absolute text-xl top-0 right-0 m-2 bg-white rounded-full p-2 inline-flex items-center justify-center text-gray-500 hover:text-gray-500 hover:bg-gray-100  "
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth={1.5}
                                                        stroke="currentColor"
                                                        className="w-6 h-6"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M6 18L18 6M6 6l12 12"
                                                        />
                                                    </svg>
                                                </button>
                                            </div>
                                        )}
                                        {isEmojiPickerOpen && (
                                            <div className="absolute z-10 h-96 w-96">
                                                <EmojiPicker
                                                    data={data}
                                                    onEmojiSelect={onEmojiClick}
                                                />
                                            </div>
                                        )}
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                )}
            </div>
        </Fragment>
    );
}
