export function Services() {
    return (
        <div className="pb-16">
            <h1
                className="font-bold text-4xl pt-16 text-black text-center "
                id="serviceSection"
            >
                How It Works
            </h1>
            <p className="text-center pt-10">
                If you are new to our platform and wondering how to use it. This
                short overview might be helpful for you to understand how
                different features actually work.
            </p>
            <div
                className="pt-12 flex lg:flex-row items-center lg:space-x-14 sm:flex-col"
                id="resourceTab"
            >
                <div className="flex-1">
                    <h1 className="font-bold text-4xl ">Resources</h1>
                    <div className="flex-1 mt-6 sm:block lg:hidden ">
                        <img src="./images_v1/resources.png" alt="" />
                    </div>
                    <p className="my-auto text-justify mt-6">
                        What includes with the resources we opened for you?
                    </p>
                    <ul className="space-y-1 list-inside mt-4">
                        <li className="flex items-center">
                            <svg
                                className="w-4 h-4 mr-2 text-purple-700 flex-shrink-0"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                            </svg>
                            Useful information of different sectors
                        </li>
                        <li className="flex items-center">
                            <svg
                                className="w-4 h-4 mr-2 text-purple-700 flex-shrink-0"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                            </svg>
                            Offers and discounts
                        </li>
                        <li className="flex items-center">
                            <svg
                                className="w-4 h-4 mr-2 text-purple-700 flex-shrink-0"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                            </svg>
                            Third-party learning materials
                        </li>
                        <li className="flex items-center">
                            <svg
                                className="w-4 h-4 mr-2 text-purple-700 flex-shrink-0"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                            </svg>
                            Career guidelines
                        </li>
                        <li className="flex items-center">
                            <svg
                                className="w-4 h-4 mr-2 text-purple-700 flex-shrink-0"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                            </svg>
                            Financial management tips
                        </li>
                    </ul>
                    <div className="mt-6">
                        <a
                            href="/signIn"
                            className="text-purple-600 flex items-center"
                        >
                            See it in action
                            <svg
                                className="w-4 h-4 ml-2 text-purple-700 flex-shrink-0"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                            </svg>
                        </a>
                    </div>
                </div>

                <div className="flex-1 sm:hidden lg:block  w-full">
                    <img
                        src="./images_v1/resources.png"
                        className="w-full"
                        alt=""
                    />
                </div>
            </div>

            <div
                className="pt-24 flex items-center lg:flex-row lg:space-x-14 sm:flex-col"
                id="eventsTab"
            >
                <div className="flex-1 sm:hidden lg:block w-full">
                    <img
                        src="./images_v1/events.png"
                        className="w-full"
                        alt=""
                    />
                </div>
                <div className="flex-1">
                    <h1 className="font-bold text-4xl ">Events</h1>
                    <div className="flex-1 mt-6 sm:block lg:hidden ">
                        <img src="./images_v1/events.png" alt="" />
                    </div>
                    <p className="my-auto text-justify mt-6">
                        What’s the specialty of our events feature?
                    </p>
                    <ul className="space-y-1 list-inside mt-4">
                        <li className="flex items-center">
                            <svg
                                className="w-4 h-4 mr-2 text-purple-700 flex-shrink-0"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                            </svg>
                            Browse community events based on your location
                        </li>
                        <li className="flex items-center">
                            <svg
                                className="w-4 h-4 mr-2 text-purple-700 flex-shrink-0"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                            </svg>
                            Attend an event you are interested in
                        </li>
                        <li className="flex items-center">
                            <svg
                                className="w-4 h-4 mr-2 text-purple-700 flex-shrink-0"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                            </svg>
                            Create an event you want to host
                        </li>
                        <li className="flex items-center">
                            <svg
                                className="w-4 h-4 mr-2 text-purple-700 flex-shrink-0"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                            </svg>
                            See upcoming events of your topic of interest
                        </li>
                        <li className="flex items-center">
                            <svg
                                className="w-4 h-4 mr-2 text-purple-700 flex-shrink-0"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                            </svg>
                            Get involved in an event
                        </li>
                    </ul>
                    <div className="mt-6">
                        <a
                            href="/signIn"
                            className="text-purple-600 flex items-center"
                        >
                            See it in action
                            <svg
                                className="w-4 h-4 ml-2 text-purple-700 flex-shrink-0"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                            </svg>
                        </a>
                    </div>
                </div>
            </div>

            <div
                className="pt-24 flex items-center lg:flex-row lg:space-x-14 sm:flex-col"
                id="learningTab"
            >
                <div className="flex-1">
                    <h1 className="font-bold text-4xl ">{/*Wone Academy*/}Learning</h1>
                    <div className="flex-1 mt-6 sm:block lg:hidden ">
                        <img src="./images_v1/learning.png" alt="" />
                    </div>
                    <p className="my-auto text-justify mt-6">
                        How Wone Academy can be your learning companion?
                    </p>
                    <ul className="space-y-1 list-inside mt-4">
                        <li className="flex items-center">
                            <svg
                                className="w-4 h-4 mr-2 text-purple-700 flex-shrink-0"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                            </svg>
                            See all the courses/lessons available
                        </li>
                        <li className="flex items-center">
                            <svg
                                className="w-4 h-4 mr-2 text-purple-700 flex-shrink-0"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                            </svg>
                            Free and Paid Courses to be enrolled
                        </li>
                        <li className="flex items-center">
                            <svg
                                className="w-4 h-4 mr-2 text-purple-700 flex-shrink-0"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                            </svg>
                            Study any course that you might be interested in
                        </li>
                        <li className="flex items-center">
                            <svg
                                className="w-4 h-4 mr-2 text-purple-700 flex-shrink-0"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                            </svg>
                            Fully self-learning platform
                        </li>
                        <li className="flex items-center">
                            <svg
                                className="w-4 h-4 mr-2 text-purple-700 flex-shrink-0"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                            </svg>
                            You're also eligible to teach by creating your own
                            courses
                        </li>
                    </ul>
                    <div className="mt-6">
                        <a
                            href="/signIn"
                            className="text-purple-600 flex items-center"
                        >
                            See it in action
                            <svg
                                className="w-4 h-4 ml-2 text-purple-700 flex-shrink-0"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                            </svg>
                        </a>
                    </div>
                </div>

                <div className="flex-1 sm:hidden lg:block  w-full">
                    <img
                        src="./images_v1/learning.png"
                        className="w-full"
                        alt=""
                    />
                </div>
            </div>

            <div
                className="pt-24 flex items-center lg:flex-row lg:space-x-14 sm:flex-col"
                id="careerTab"
            >
                <div className="flex-1 sm:hidden lg:block  w-full">
                    <img
                        src="./images_v1/career.png"
                        className="w-full"
                        alt=""
                    />
                </div>
                <div className="flex-1">
                    <h1 className="font-bold text-4xl ">Career</h1>
                    <div className="flex-1 mt-6 sm:block lg:hidden">
                        <img src="./images_v1/career.png" alt="" />
                    </div>
                    <p className="my-auto text-justify mt-6">
                        Our career option opens the door for everyone
                    </p>
                    <ul className="space-y-1 list-inside mt-4">
                        <li className="flex items-center">
                            <svg
                                className="w-4 h-4 mr-2 text-purple-700 flex-shrink-0"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                            </svg>
                            Job seekers can look for a job
                        </li>
                        <li className="flex items-center">
                            <svg
                                className="w-4 h-4 mr-2 text-purple-700 flex-shrink-0"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                            </svg>
                            Browse jobs by your location, job category and
                            keywords
                        </li>
                        <li className="flex items-center">
                            <svg
                                className="w-4 h-4 mr-2 text-purple-700 flex-shrink-0"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                            </svg>
                            Apply for an open position
                        </li>
                        <li className="flex items-center">
                            <svg
                                className="w-4 h-4 mr-2 text-purple-700 flex-shrink-0"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                            </svg>
                            Employer can also post job
                        </li>
                        <li className="flex items-center">
                            <svg
                                className="w-4 h-4 mr-2 text-purple-700 flex-shrink-0"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                            </svg>
                            You can also create your talent profile
                        </li>
                    </ul>
                    <div className="mt-6">
                        <a
                            href="/signIn"
                            className="text-purple-600 flex items-center"
                        >
                            See it in action
                            <svg
                                className="w-4 h-4 ml-2 text-purple-700 flex-shrink-0"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
            <div
                className="pt-24 flex items-center lg:flex-row lg:space-x-14 sm:flex-col "
                id="hostingTab"
            >
                <div className="flex-1">
                    <h1 className="font-bold text-4xl ">{/*Host*/}Connect</h1>
                    <div className="flex-1 mt-6 sm:block lg:hidden">
                        <img src="./images_v1/hosting.png" alt="" />
                    </div>
                    <p className="my-auto text-justify mt-6 ">
                        Confused about what does {/*Host*/}Connect means at Wone?
                    </p>
                    <ul className="space-y-1 list-inside mt-4">
                        <li className="flex items-center">
                            <svg
                                className="w-4 h-4 mr-2 text-purple-700 flex-shrink-0"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                            </svg>
                            Find a connector of a city you are moving to
                        </li>
                        <li className="flex items-center">
                            <svg
                                className="w-4 h-4 mr-2 text-purple-700 flex-shrink-0"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                            </svg>
                            Share knowledge with your connector
                        </li>
                        <li className="flex items-center">
                            <svg
                                className="w-4 h-4 mr-2 text-purple-700 flex-shrink-0"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                            </svg>
                            Become a connector to expect guest with long term
                            benefits
                        </li>
                        <li className="flex items-center">
                            <svg
                                className="w-4 h-4 mr-2 text-purple-700 flex-shrink-0"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                            </svg>
                            Create your connecting profile
                        </li>
                        <li className="flex items-center">
                            <svg
                                className="w-4 h-4 mr-2 text-purple-700 flex-shrink-0"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                            </svg>
                            You may enlist or delist yourself anytime
                        </li>
                    </ul>
                    <div className="mt-6">
                        <a
                            href="/signIn"
                            className="text-purple-600 flex items-center"
                        >
                            See it in action
                            <svg
                                className="w-4 h-4 ml-2 text-purple-700 flex-shrink-0"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                            </svg>
                        </a>
                    </div>
                </div>
                <div className="flex-1 sm:hidden lg:block  w-full">
                    <img
                        src="./images_v1/hosting.png"
                        className="w-full"
                        alt=""
                    />
                </div>
            </div>

            <div
                className="pt-24 flex items-center lg:flex-row lg:space-x-14 sm:flex-col"
                id="chatTab"
            >
                <div className="flex-1 sm:hidden lg:block  w-full">
                    <img src="./images_v1/chat.png" className="w-full" alt="" />
                </div>
                <div className="flex-1">
                    <h1 className="font-bold text-4xl ">Chat</h1>
                    <div className="flex-1 mt-6 sm:block lg:hidden">
                        <img src="./images_v1/chat.png" alt="" />
                    </div>
                    <p className="my-auto text-justify mt-6">
                        Take advantage of our real time communication feature
                    </p>
                    <ul className="space-y-1 list-inside mt-4">
                        <li className="flex items-center">
                            <svg
                                className="w-4 h-4 mr-2 text-purple-700 flex-shrink-0"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                            </svg>
                            Chat with anyone on Wone
                        </li>
                        <li className="flex items-center">
                            <svg
                                className="w-4 h-4 mr-2 text-purple-700 flex-shrink-0"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                            </svg>
                            Search for users for sending a message
                        </li>
                        <li className="flex items-center">
                            <svg
                                className="w-4 h-4 mr-2 text-purple-700 flex-shrink-0"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                            </svg>
                            Find online users
                        </li>
                        <li className="flex items-center">
                            <svg
                                className="w-4 h-4 mr-2 text-purple-700 flex-shrink-0"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                            </svg>
                            Text, Emoji, Image and File Attachment facilities
                        </li>
                        <li className="flex items-center">
                            <svg
                                className="w-4 h-4 mr-2 text-purple-700 flex-shrink-0"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                            </svg>
                            Don't miss to ask any important question to an user
                        </li>
                    </ul>
                    <div className="mt-6">
                        <a
                            href="/signIn"
                            className="text-purple-600 flex items-center"
                        >
                            See it in action
                            <svg
                                className="w-4 h-4 ml-2 text-purple-700 flex-shrink-0"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                            </svg>
                        </a>
                    </div>
                </div>
            </div>

            <div
                className="pt-24 flex items-center lg:flex-row lg:space-x-14 sm:flex-col"
                id="languageTab"
            >
                <div className="flex-1">
                    <h1 className="font-bold text-4xl ">Translate</h1>
                    <div className="flex-1 mt-6 sm:block lg:hidden ">
                        <img src="./images_v1/language.png" alt="" />
                    </div>
                    <p className="my-auto text-justify mt-6">
                        Overcoming the barrier of understanding other’s
                        language.
                    </p>
                    <ul className="space-y-1 list-inside mt-4">
                        <li className="flex items-center">
                            <svg
                                className="w-4 h-4 mr-2 text-purple-700 flex-shrink-0"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                            </svg>
                            Translate anything to your language
                        </li>
                        <li className="flex items-center">
                            <svg
                                className="w-4 h-4 mr-2 text-purple-700 flex-shrink-0"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                            </svg>
                            Super easy to use
                        </li>
                        <li className="flex items-center">
                            <svg
                                className="w-4 h-4 mr-2 text-purple-700 flex-shrink-0"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                            </svg>
                            No need to leave Wone and vist other portal to
                            translate
                        </li>
                        <li className="flex items-center">
                            <svg
                                className="w-4 h-4 mr-2 text-purple-700 flex-shrink-0"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                            </svg>
                            Translates any text instantly
                        </li>
                        <li className="flex items-center">
                            <svg
                                className="w-4 h-4 mr-2 text-purple-700 flex-shrink-0"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                            </svg>
                            Useful when interacting with people from other
                            nationality.
                        </li>
                    </ul>
                    <div className="mt-6">
                        <a
                            href="/translate"
                            className="text-purple-600 flex items-center"
                        >
                            See it in action
                            <svg
                                className="w-4 h-4 ml-2 text-purple-700 flex-shrink-0"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                            </svg>
                        </a>
                    </div>
                </div>

                <div className="flex-1 sm:hidden lg:block w-full">
                    <img
                        src="./images_v1/language.png"
                        className="w-full"
                        alt=""
                    />
                </div>
            </div>

            <div
                className="pt-24 flex items-center lg:flex-row lg:space-x-14 sm:flex-col"
                id="geolocationTab"
            >
                <div className="flex-1 sm:hidden lg:block w-full">
                    <img
                        src="./images_v1/geolocation.png"
                        className="w-full"
                        alt=""
                    />
                </div>
                <div className="flex-1">
                    <h1 className="font-bold text-4xl ">Geolocation</h1>
                    <div className="flex-1 mt-6 sm:block lg:hidden">
                        <img src="./images_v1/geolocation.png" alt="" />
                    </div>
                    <p className="my-auto text-justify mt-6">
                        Finding important places and route made easy.
                    </p>
                    <ul className="space-y-1 list-inside mt-4">
                        <li className="flex items-center">
                            <svg
                                className="w-4 h-4 mr-2 text-purple-700 flex-shrink-0"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                            </svg>
                            Enter your location
                        </li>
                        <li className="flex items-center">
                            <svg
                                className="w-4 h-4 mr-2 text-purple-700 flex-shrink-0"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                            </svg>
                            See different route and destination
                        </li>
                        <li className="flex items-center">
                            <svg
                                className="w-4 h-4 mr-2 text-purple-700 flex-shrink-0"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                            </svg>
                            Navigating to an important place is super easy
                        </li>
                        <li className="flex items-center">
                            <svg
                                className="w-4 h-4 mr-2 text-purple-700 flex-shrink-0"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                            </svg>
                            See any offer by your location
                        </li>
                        <li className="flex items-center">
                            <svg
                                className="w-4 h-4 mr-2 text-purple-700 flex-shrink-0"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                            </svg>
                            Useful for finding the right information
                        </li>
                    </ul>
                    <div className="mt-6">
                        <a
                            href="/map"
                            className="text-purple-600 flex items-center"
                        >
                            See it in action
                            <svg
                                className="w-4 h-4 ml-2 text-purple-700 flex-shrink-0"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}
