import http from "../../global/http-common";
const route = "/Stripe";

export const StripePaymentService = {
    createSubscriptionIntent: function create(data) {
        return http.post(route + '/CreateSubscriptionIntent', data)
    },
    createTrainingIntent: function create(data) {
        return http.post(route + '/CreateTrainingIntent', data)
    },
    createEventIntent: function create(data) {
        return http.post(route + '/CreateEventIntent', data)
    },
};
