import { useEffect, useState } from "react";
import Ads from "../../components/Ads/ads";
import PostCard from "../../components/NewsFeed/postCard";
import { StatusCard } from "../../components/NewsFeed/statusCard";
import ProfileCard from "../../components/Profile/profileCard";
import { SubscriptionEndNotify } from "../../components/Profile/subscriptionEndNotify";
import { PostService } from "../../services/Post/post.service";

function NewsFeeds() {
    const [selectedPostCategory, setSelectedPostCategory] = useState(null);
    const [posts, setPosts] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const [fetchingPosts, setFetchingPosts] = useState(false);

    const getPosts = (selectedPostCategory) => {
        PostService.getAll(selectedPostCategory).then((response) => {
            setPosts(response.data);
        });
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    function handleScroll() {
        if (
            window.innerHeight + document.documentElement.scrollTop !==
            document.documentElement.offsetHeight
        )
            return;
        setIsFetching(true);
    }
    useEffect(() => {
        if (!isFetching) return;
        PostService.getPosts(
            selectedPostCategory,
            Math.min(...posts.map((o) => o.id))
        ).then((response) => {
            setPosts((x) => [...posts, ...response.data]);
        });
        setIsFetching(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetching]);

    useEffect(() => {
        getPosts(selectedPostCategory);
        setFetchingPosts(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPostCategory, fetchingPosts]);

    useEffect(() => {
        let element = document.getElementById("google_translate_element");
        element.style.display = "block";
        return () => {
            element.style.display = "none";
        };
    }, []);

    return (
        <div className="mt-4 flex flex-col md:flex-row pt-4">
            <div className="md:basis-1/4 mt-14">
                <div className="block p-6 rounded-lg shadow-lg bg-gray-50 m-6">
                    <ProfileCard
                        setPostCategoryId={(categoryId) => {
                            setSelectedPostCategory(categoryId);
                        }}
                    />
                </div>
            </div>
            <div className="md:basis-2/4 md:mr-6 flex flex-col">
                <div className="p-4 rounded-lg shadow-lg bg-gray-50 mt-4 md:mt-20">
                    <PostCard
                        selectedCategoryId={selectedPostCategory}
                        onNewPost={(newPost) => {
                            setPosts((current) => [newPost, ...current]);
                        }}
                    />
                </div>
                <div>
                    {posts.map((post, index) => {
                        return (
                            <StatusCard
                                key={index}
                                post={post}
                                setFetchingPosts={(fetchingPosts) => {
                                    setFetchingPosts(fetchingPosts);
                                }}
                            />
                        );
                    })}
                </div>
                <div className="mt-4 mb-4 font-bold">
                    {isFetching && "Loading...."}
                </div>
            </div>
            {/* <div className="basis-3/4 mr-6 md:hidden sm:block">
                    <div className="p-4 rounded-lg shadow-lg bg-gray-50 mt-20">
                        <PostCard
                            selectedCategoryId={selectedPostCategory}
                            onNewPost={(newPost) => {
                                setPosts((current) => [...current, newPost]);
                            }}
                        />
                    </div>
                    <div>
                        {posts.map((post, index) => {
                            return <StatusCard key={index} post={post} />;
                        })}
                    </div>
                    <div className="mt-4 mb-4 font-bold">
                        {isFetching && "Loading...."}
                    </div>
                </div> */}
            <div className="md:basis-1/4 mt-4 md:mt-20 md:block pr-2">
                <SubscriptionEndNotify />
                <Ads />
            </div>
        </div>
    );
}
export default NewsFeeds;
