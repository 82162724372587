import TrainingContentService from "../../services/Training/trainingcontents.eervice";
//import TrainingDataService from '../../services/Training/training.service'
import RegisteredTrainingService from "../../services/Training/registeredtraining.service";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { showError } from "../../global/alertService";
import { STRIPE_PUBLISHABLE_KEY } from "../../global/api-keys";
import { CrossIcon } from "../Icons/crossIcon";
import TrainingBuyPaypal from "../Payment/trainingBuyPaypal";
import { TrainingBuyStripe } from "../Payment/trainingBuyStripe";

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const EnrollTrainingCard = (props) => {
    const mySwal = withReactContent(Swal);
    const navigate = useNavigate();

    const [data, setData] = useState(null);
    const [orderId, setOrderId] = useState("");
    const [payerID, setPayerID] = useState("");
    const [stripePaymentId, setStripePaymentId] = useState("");
    const [paidFor, setPaidFor] = useState(false);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        //console.log(props)
        if (props.training.id > 0) {
            getTrainingDetail(props.training.id);
        }
    }, [props]);

    const getTrainingDetail = (id) => {
        TrainingContentService.get("?trainingId=" + id).then((response) => {
            setData(response.data);
        });
    };

    const handleApprope = (orderID) => {
        setPaidFor(true);
    };

    if (paidFor) {
        const registeredTrainingData = {
            trainingId: props.training.id,
            paypalOrderId: orderId,
            paypalPayerID: payerID,
            stripePaymentId: stripePaymentId,
        };
        RegisteredTrainingService.create(registeredTrainingData)
            .then((response) => {
                mySwal
                    .fire({
                        title: <strong>Success</strong>,
                        html: <i>Thank you for purchasing from Djigui.</i>,
                        icon: "success",
                    })
                    .then(() => {
                        navigate(`/courseContentVideo/${props.training.id}`);
                    });
            })
            .catch((err) => {
                showError("Something went wrong");
                setShowModal(false);
            });
    }

    const stripeModal = (
        <>
            <div className="fixed inset-0 z-50 overflow-y-auto">
                <div
                    className="fixed inset-0 w-full h-full bg-black opacity-40"
                    onClick={() => setShowModal(false)}
                ></div>
                <div className="flex items-center min-h-screen px-4 py-8">
                    <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                        <div
                            onClick={() => setShowModal(false)}
                            className="absolute top-5 right-5 cursor-pointer"
                        >
                            <CrossIcon />
                        </div>
                        <div className="mt-4 text-center sm:text-left border-dashed">
                            <Elements stripe={stripePromise}>
                                {props.training.id > 0 ? (
                                    <TrainingBuyStripe
                                        trainingId={props.training.id}
                                        setStripePaymentId={setStripePaymentId}
                                        handleApprope={handleApprope}
                                        setShowModal={setShowModal}
                                    />
                                ) : (
                                    <></>
                                )}
                            </Elements>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

    return (
        <>
            <div className=" p-6 rounded-lg shadow-lg bg-gray-50 mt-6">
                <div
                    className="w-full h-72"
                    style={{
                        backgroundImage: `url("${props.training?.imgUrl}")`,
                        backgroundColor: "#cccccc",
                        backgroundPosition: "center top",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                    }}
                ></div>

                <div className="p-6">
                    <h5 className="text-gray-900 text-xl font-bold mt-2 mb-2">
                        {props.training?.courseName}
                    </h5>
                    <p className="text-gray-500 mb-2"></p>
                    <hr />
                    <div className="flex justify-between items-center mt-4">
                        <span className="text-base font-medium text-gray-900">
                            <b>Instructor:</b> {props.training?.firstName + " "}
                            {props.training?.lastName}
                        </span>
                        <span className="text-base font-bold text-gray-900">
                            Buy For : ${props.training?.price}
                        </span>
                    </div>

                    <div>
                        <span className="mt-4 text-gray-900 text-xl font-bold">
                            Description:
                        </span>
                        <p className="text-gray-700 text-base mt-2 mb-4">
                            {props.training?.description}
                        </p>
                    </div>

                    <hr />
                </div>

                <div className="flex flex-wrap">
                    <div
                        className="sm:w-full md:w-full lg:w-1/2 px-1"
                        style={{
                            zIndex: showModal ? 0 : 1,
                        }}
                    >
                        <TrainingBuyPaypal
                            courseName={props.training.courseName}
                            price={props.training.price}
                            setOrderId={setOrderId}
                            setPayerID={setPayerID}
                            handleApprope={handleApprope}
                        />
                    </div>
                    <div className="sm:w-full md:w-full lg:w-1/2 px-1">
                        <button
                            type="button"
                            className="focus:outline-none text-black font-semibold rounded px-5 py-2.5 mr-2 mb-2 h-12 w-full text-lg stripe-card-pay-button text-center"
                            onClick={() => setShowModal(true)}
                        >
                            Credit / Debit Card
                        </button>
                        {showModal && stripeModal}
                    </div>
                </div>
            </div>
        </>
    );
};

export default EnrollTrainingCard;
