import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SingleEvent from "../../components/Events/singleEvent";
import { Pagination } from "../../components/Pagination/pagination";
import { cityService } from "../../services/City/cities.service";
import { countryService } from "../../services/Country/countries.service";
import EventDataService from "../../services/Event/event.service";
import { featureService } from "../../services/Feature/feature.service";
import { stateService } from "../../services/State/states.service";

const Events = () => {
    const itemsPerPage = 12;
    const [allEvents, setAllEvents] = useState([]);
    const [itemOffset, setItemOffset] = useState(0);

    const [searchTerm, setSearchTerm] = useState("");
    const [filteredItems, setFilteredItems] = useState([]);

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = filteredItems.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(filteredItems.length / itemsPerPage);
    //--

    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [countryId, setCountryId] = useState(null);
    const [stateId, setStateId] = useState(null);
    const [cityId, setCityId] = useState(null);

    useEffect(() => {
        getAllEvents(countryId, stateId, cityId);
        getCountries();
    }, [countryId, stateId, cityId]);
    //---
    const getCountries = () => {
        countryService.getAll().then((response) => {
            setCountries(response.data);
        });
    };

    const getStates = (countryId) => {
        stateService.getByCountry(countryId).then((res) => {
            setStates(res.data);
        });
    };

    const getCities = (stateId) => {
        cityService.getByState(stateId).then((res) => {
            setCities(res.data);
        });
    };

    const getEventsByCity = (cityId) => {
        EventDataService.GetEventsByCity(cityId).then((res) => {
            //console.debug(res)
            setAllEvents(res.data);
            //console.log(res.data)
        });
    };
    //--------

    useEffect(() => {
        setFilteredItems([...allEvents]);
    }, [allEvents]);

    // filtering events on search input
    useEffect(() => {
        if (searchTerm !== "") {
            const filteredData = allEvents.filter((item) => {
                return Object.values(item)
                    .join(" ")
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase());
            });
            setFilteredItems(filteredData);
        } else {
            setFilteredItems([...allEvents]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm]);

    // getting all events from database
    const getAllEvents = async (countryId, stateId, cityId) => {
        await EventDataService.getAll(countryId, stateId, cityId).then(
            (response) => {
                setAllEvents(response.data);
            }
        );
    };

    // update events if an event is deleted
    const updateEvents = useCallback((id) => {
        setAllEvents((allEvents) =>
            allEvents.filter((event) => event.id !== id)
        );
    }, []);

    // handle plage change in pagination
    const handlePageChange = (e) => {
        const newOffset = (e.selected * itemsPerPage) % allEvents.length;
        setItemOffset(newOffset);
    };

    // component to show items
    const Items = ({ currentItems }) => {
        return (
            <>
                {currentItems &&
                    currentItems.map((event) => (
                        <SingleEvent
                            event={event}
                            key={event.id}
                            updateEvents={updateEvents}
                        />
                    ))}
            </>
        );
    };

    return (
        <div className="flex flex-col mt-20 pt-4">
            <div className="px-4 text-gray-900 ">
                {featureService.shouldShowContent("Event Manage") ? (
                    <div>
                        <Link
                            to={`/event/create`}
                            className="mb-4 inline-flex items-center  mt-6 px-5 py-2.5 text-sm font-medium text-center text-white bg-gradient-to-r from-purple-500 to-pink-500 rounded-lg  hover:bg-gradient-to-l"
                        >
                            Create Event
                        </Link>
                    </div>
                ) : (
                    <></>
                )}

                <div className="mb-4 grid gap-3 mr-6 md:grid-cols-3 sm:grid-cols-1">
                    <div>
                        <select
                            name="countryId"
                            onChange={(e) => {
                                getStates(e.target.value);
                                setCountryId(e.target.value);
                            }}
                            className="bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg block w-full p-2.5"
                        >
                            <option value="">Select Country</option>
                            {countries?.map((countries) => (
                                <option key={countries.id} value={countries.id}>
                                    {countries.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <select
                            name="stateId"
                            onChange={(e) => {
                                getCities(e.target.value);
                                setStateId(e.target.value);
                            }}
                            className="bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg block w-full p-2.5"
                        >
                            <option value="">Select State</option>
                            {states?.map((state) => (
                                <option key={state.id} value={state.id}>
                                    {state.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <select
                            name="cityId"
                            onChange={(e) => {
                                setCityId(e.target.value);
                            }}
                            className="bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg block w-full p-2.5"
                        >
                            <option value="">Select City</option>
                            {cities?.map((city) => (
                                <option key={city.id} value={city.id}>
                                    {city.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="flex flex-wrap">
                    <div className="font-bold text-3xl text-fuchsia-800 md:w-3/5 sm:w-full lg:w-2/3 xl:w-2/3 2xl:w-2/3 sm:text-center md:text-left mb-4">
                        Upcoming Events...
                    </div>

                    <div className="md:w-2/5 sm:w-full lg:w-1/3 xl:w-1/3 2xl:w-1/3 text-base text-fuchsia-800">
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 float-right mb-4"
                            placeholder="Search events"
                        />
                    </div>
                </div>
                <div className="text-gray-500 font-medium text-xl mb-4">
                    Find out about events in your city.
                </div>
            </div>

            <div className="flex flex-col">
                <div className="flex flex-wrap justify-around">
                    <Items currentItems={currentItems} />
                </div>
                <Pagination
                    handlePageChange={handlePageChange}
                    pageCount={pageCount}
                />
            </div>
        </div>
    );
};

export default Events;
