import { Menu, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { showConfirmation, showSuccess } from "../../global/alertService";
import SeekerJobPostService from "../../services/SeekerJob/seekerJob.service";
import { classNames } from "../../utils/className-utils";
import { SeekerJobPostDetails } from "./seekerJobPostDetails";

export function SingleSeekerJobPost({ jobpost, updateJobPosts }) {
    const mySwal = withReactContent(Swal);
    const currentUserEmail = localStorage.getItem("email");

    const [showModal, setShowModal] = useState(false);
    const [editShowModal, setEditShowModal] = useState(false);
    const [detailSeekerJobPostId, setDetailSeekerJobPostId] = useState(null);
    const [editableSeekerJobPostId, setEditableSeekerJobPostId] =
        useState(null);

    const deleteSeekerJobPost = (id) => {
        showConfirmation().then((result) => {
            if (result.isConfirmed) {
                SeekerJobPostService.delete(id).then((response) => {
                    mySwal
                        .fire({
                            title: <strong>Success</strong>,
                            html: <i>Deleted successfully</i>,
                            icon: "success",
                        })
                        .then(() => {
                            updateJobPosts(id);
                        });
                });
            } else {
                showSuccess("Not deleted", "Ok");
            }
        });
    };

    return (
        <Fragment>
            <div
                key={jobpost.id}
                className="w-full mb-6 max-w-sm bg-gray-50 shadow-md"
            >
                <div className="flex justify-between jobposts-center">
                    <div className="overflow-hidden relative w-10 h-10 bg-gray-100 rounded-full m-8">
                        {jobpost?.imgUrl != null ? (
                            <img
                                className="w-12 h-12 justify-center jobposts-center rounded-full"
                                src={jobpost?.imgUrl}
                                alt=""
                            />
                        ) : (
                            <svg
                                className="absolute  bg-blue-400 w-12 h-12 text-gray-400"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                        )}
                    </div>

                    {currentUserEmail === jobpost?.email ? (
                        <Menu
                            as="div"
                            className="flex jobposts-center justify-end mt-2 mr-4 rounded-b"
                        >
                            <div>
                                <Menu.Button aria-hidden="true">
                                    <svg
                                        className="w-4 h-4"
                                        aria-hidden="true"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"></path>
                                    </svg>
                                </Menu.Button>
                            </div>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="absolute z-10 mt-6 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="py-1">
                                        <Menu.Item>
                                            {({ active }) => (
                                                <Link
                                                    to={{
                                                        pathname: `/seekerJobPost/update/${jobpost?.id}`,
                                                    }}
                                                    className={classNames(
                                                        active
                                                            ? "bg-gray-100 text-gray-900"
                                                            : "text-gray-700",
                                                        " px-4 py-2 text-sm w-full flex items-center space-x-3"
                                                    )}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth={1.5}
                                                        stroke="currentColor"
                                                        className="w-6 h-6"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                                        />
                                                    </svg>
                                                    <span>Edit</span>
                                                </Link>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <button
                                                    onClick={() => {
                                                        deleteSeekerJobPost(
                                                            jobpost.id
                                                        );
                                                    }}
                                                    className={classNames(
                                                        active
                                                            ? "bg-gray-100 text-gray-900 w-full"
                                                            : "text-gray-700",
                                                        "px-4 py-2 text-sm w-full flex jobposts-center space-x-3"
                                                    )}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth={1.5}
                                                        stroke="currentColor"
                                                        className="w-6 h-6"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                                        />
                                                    </svg>
                                                    <span>Delete</span>
                                                </button>
                                            )}
                                        </Menu.Item>
                                    </div>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    ) : null}
                </div>

                <div className="px-5 pb-5">
                    <div>
                        <h5 className="text-xl font-semibold tracking-tight mt-4 text-gray-900">
                            {jobpost?.firstName + " "}
                            {jobpost?.lastName}
                        </h5>
                        <span>{jobpost.jobTitle}</span>
                    </div>

                    <div className="mt-4">
                        <div>
                            <span className="text-m font-semibold tracking-tight mt-4 text-gray-900">
                                Address:{" "}
                            </span>
                            <span className=" text-gray-700 ">
                                {jobpost?.location}
                                {} {jobpost?.city}
                                {","} {jobpost?.state}
                                {","} {jobpost?.country}
                            </span>
                        </div>
                        <div>
                            <span className="text-m font-semibold tracking-tight mt-4 text-gray-900">
                                Contact No:{" "}
                            </span>
                            <span className=" text-gray-700 ">
                                {jobpost?.contactNo}
                            </span>
                        </div>
                        <div>
                            <span className="text-m font-semibold tracking-tight mt-4 text-gray-900">
                                Email:{" "}
                            </span>
                            <span className=" text-gray-700 ">
                                {jobpost?.email}
                            </span>
                        </div>
                        <div>
                            <span className="text-m font-semibold tracking-tight mt-4 text-gray-900">
                                Facebook:{" "}
                            </span>
                            <a
                                href={jobpost?.facebookUrl}
                                className="inline-flex jobposts-center font-medium text-fuchsia-600 hover:underline"
                            >
                                {jobpost?.facebookUrl}
                            </a>
                        </div>
                        <div>
                            <span className="text-m font-semibold tracking-tight mt-4 text-gray-900">
                                LinkdIn:{" "}
                            </span>
                            <a
                                href={jobpost?.linkedInUrl}
                                className="inline-flex jobposts-center font-medium text-fuchsia-600 hover:underline"
                            >
                                {jobpost?.linkedInUrl}
                            </a>
                        </div>
                    </div>
                    <div className="flex justify-between jobposts-center mt-4">
                        <span className="text-sm text-gray-900 truncate font-bold">
                            Experience:
                            <p className="text-sm font-medium">
                                {jobpost.experience}
                            </p>
                        </span>
                        <span className="text-sm text-gray-900 truncate font-bold">
                            Expected Responsibility:
                            <p className="text-sm font-medium">
                                {jobpost.skills}
                            </p>
                        </span>
                    </div>

                    <div className="mt-6">
                        <button
                            onClick={() => {
                                setDetailSeekerJobPostId(jobpost.id);
                                setShowModal(true);
                            }}
                            className=" w-full py-2 px-3 text-sm font-medium text-center text-white  rounded-lg bg-fuchsia-800  hover:bg-green-800"
                        >
                            Details
                        </button>
                    </div>
                </div>
            </div>
            {showModal ? (
                <SeekerJobPostDetails
                    detailSeekerJobPostId={detailSeekerJobPostId}
                    onClose={() => {
                        setShowModal(false);
                    }}
                />
            ) : null}
        </Fragment>
    );
}
