import { Fragment, useEffect, useState } from "react";
import { InstractorInfo } from "../../components/Profile/instractorInfo";
import { showSuccess } from "../../global/alertService";
import { authService } from "../../services/Account/auth.service";
import { cityService } from "../../services/City/cities.service";
import { countryService } from "../../services/Country/countries.service";
import { featureService } from "../../services/Feature/feature.service";
import { stateService } from "../../services/State/states.service";
import { EmployerDesignationService } from "../../services/Designation/designation.service";
import { CompanyInfosService } from "../../services/CompanyInformation/companyInformation.service";

export function CompanyInformation({ profile }) {
    const [profileGeneralInfo, setProfileGeneralInfo] = useState({});
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [showInstractor, setShowInstractor] = useState(false);
    const initialFormState = {
        name: "",
        employerDesignationId: "",
        companyName: "",
        companyWebsite: "",
        email: "",
        phoneNumber: "",
    };
    const [companyInfo, setCompanyInfo] = useState(initialFormState);
    const [designations, setDesignations] = useState([]);

    useEffect(() => {
        getCountries();
        if (profile?.email) {
            setProfileGeneralInfo({
                firstName: profile?.firstName,
                lastName: profile?.lastName,
                email: profile?.email,
                phoneNumber: profile?.phoneNumber,
                dateOfBirth: profile?.dateOfBirth,
                gender: profile?.gender,
                countryId: profile?.countryId,
                stateId: profile?.stateId,
                cityId: profile?.cityId,
                location: profile?.location,
                isHost: profile?.isHost,
                isInstractor: profile?.isInstractor,
                relationshipId: profile?.relationshipId,
                degreeId: profile?.degreeId,
                numberOfChildren: profile?.numberOfChildren,
            });
            if (profile.countryId != null) {
                getStates(profile?.countryId);
            }
            if (profile.stateId != null) {
                getCities(profile?.stateId);
            }
            setShowInstractor(profile?.isInstractor);
        }
    }, [profile]);

    const handleInputChange = (event) => {
        const { name, value } = event?.target;
        setProfileGeneralInfo({ ...profileGeneralInfo, [name]: value });
    };

    const getCountries = () => {
        countryService.getAll().then((response) => {
            setCountries(response.data);
        });
    };

    const getStates = (countryId) => {
        stateService.getByCountry(countryId).then((res) => {
            setStates(res.data);
        });
    };

    const getCities = (stateId) => {
        cityService.getByState(stateId).then((res) => {
            setCities(res.data);
        });
    };

    useEffect(() => {
        getDesignation();
        getCompanyInfo();
    }, []);

    const handleInputChangeForCompany = (event) => {
        const { name, value } = event.target;
        setCompanyInfo({
            ...companyInfo,
            [name]: value,
        });
    };

    const getDesignation = () => {
        EmployerDesignationService.getAll().then((response) => {
            setDesignations(response.data);
        });
    };
    const getCompanyInfo = () => {
        CompanyInfosService.get().then((response) => {
            setCompanyInfo(response.data);
        });
    };
    const saveCompanyInfo = () => {
        CompanyInfosService.create(companyInfo).then((res) => {
            getCompanyInfo();
        });
    };

    const updateCompanyInfo = () => {
        if (companyInfo.employerDesignationId === "") {
            companyInfo.employerDesignationId = null;
        }
        CompanyInfosService.update(companyInfo).then((res) => {
            setCompanyInfo(res.data);
            getCompanyInfo();
        });
    };

    const updateProfile = () => {
        if (profileGeneralInfo.countryId === "0") {
            profileGeneralInfo.countryId = null;
        }
        if (profileGeneralInfo.stateId === "0") {
            profileGeneralInfo.stateId = null;
        }
        if (profileGeneralInfo.cityId === "") {
            profileGeneralInfo.cityId = null;
        }
        authService.updateProfile(profileGeneralInfo).then((res) => {
            updateCompanyInfo();
            showSuccess("Updated successfully.");
        });
    };

    return (
        <Fragment>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    updateProfile();
                }}
            >
                <div className="block p-6 rounded-lg shadow-lg bg-gray-50 mt-6 ml-6">
                    <h5 className="text-gray-900 text-xl leading-tight font-medium mb-4">
                        Employer Profile Set Up
                    </h5>
                    <div className="grid gap-3 mb-4 md:grid-cols-2">
                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 ">
                                First Name
                            </label>
                            <input
                                name="firstName"
                                maxLength="20"
                                value={profileGeneralInfo?.firstName}
                                onChange={handleInputChange}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                required
                            />
                        </div>
                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 ">
                                Last Name
                            </label>
                            <input
                                name="lastName"
                                maxLength="20"
                                value={profileGeneralInfo?.lastName}
                                onChange={handleInputChange}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                required
                            />
                        </div>
                        {/* <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 ">
                                Email
                            </label>
                            <input
                                name="email"
                                value={profileGeneralInfo?.email}
                                onChange={handleInputChange}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                disabled
                            />
                        </div> */}
                        {/* <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 ">
                                Phone No
                            </label>
                            <input
                                name="PhoneNumber"
                                value={profileGeneralInfo?.phoneNumber}
                                onChange={handleInputChange}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                            />
                        </div> */}

                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 ">
                                Country of Residence
                            </label>
                            <select
                                name="countryId"
                                value={profileGeneralInfo?.countryId}
                                onChange={(e) => {
                                    if (e.target.value === "0") {
                                        profileGeneralInfo.stateId = null;
                                        profileGeneralInfo.cityId = null;
                                    }
                                    getStates(e.target.value);
                                    getCities(e.target.value);

                                    handleInputChange(e);
                                }}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                            >
                                <option value="0">-- Select Country --</option>
                                {countries?.map((countries) => (
                                    <option
                                        key={countries.id}
                                        value={countries.id}
                                    >
                                        {countries.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 ">
                                State{" "}
                            </label>
                            <select
                                name="stateId"
                                value={profileGeneralInfo?.stateId}
                                onChange={(e) => {
                                    if (e.target.value === "0") {
                                        profileGeneralInfo.cityId = null;
                                    }
                                    getCities(e.target.value);
                                    handleInputChange(e);
                                }}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                            >
                                <option value="0">-- Select State --</option>
                                {states?.map((state) => (
                                    <option key={state.id} value={state.id}>
                                        {state.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 ">
                                City{" "}
                            </label>
                            <select
                                name="cityId"
                                value={profileGeneralInfo?.cityId}
                                onChange={(e) => {
                                    handleInputChange(e);
                                }}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                            >
                                <option value="">-- Select City --</option>
                                {cities?.map((city) => (
                                    <option key={city.id} value={city.id}>
                                        {city.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 ">
                                House/Apt No.
                            </label>
                            <input
                                name="location"
                                value={profileGeneralInfo?.location}
                                onChange={handleInputChange}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                            />
                        </div>
                    </div>
                    <div className="grid gap-3 mb-4 md:grid-cols-2">
                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 ">
                                Company Name
                            </label>
                            <input
                                name="companyName"
                                value={companyInfo?.companyName}
                                onChange={handleInputChangeForCompany}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-r-md block w-full p-2.5"
                                required
                            />
                        </div>
                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 ">
                                Company Website URL
                            </label>
                            <div className="m-0 p-0 flex">
                                <input
                                    name="companyWebsite"
                                    value={companyInfo?.companyWebsite}
                                    onChange={handleInputChangeForCompany}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-r-md block w-full p-2.5"
                                />
                            </div>
                        </div>
                        {/*<div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 ">
                                Name of the person setting up the profile
                            </label>
                            <div className="m-0 p-0 flex">
                                <input
                                    name="name"
                                    value={companyInfo?.name}
                                    onChange={handleInputChangeForCompany}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-r-md block w-full p-2.5"
                                />
                            </div>
                                </div>*/}
                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 ">
                                Title
                            </label>
                            <div className="m-0 p-0 flex">
                                <select
                                    name="employerDesignationId"
                                    value={companyInfo?.employerDesignationId}
                                    onChange={handleInputChangeForCompany}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                >
                                    <option value="">-- Select Title --</option>
                                    {designations?.map((designation) => (
                                        <option
                                            key={designation.id}
                                            value={designation.id}
                                        >
                                            {designation.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 ">
                                Email
                            </label>
                            <div className="m-0 p-0 flex">
                                <input
                                    name="email"
                                    value={companyInfo?.email}
                                    onChange={handleInputChangeForCompany}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-r-md block w-full p-2.5"
                                />
                            </div>
                        </div>
                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 ">
                                Phone No.
                            </label>
                            <div className="m-0 p-0 flex">
                                <input
                                    name="phoneNumber"
                                    value={companyInfo?.phoneNumber}
                                    onChange={handleInputChangeForCompany}
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md block w-full p-2.5"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="grid gap-3 mb-4 md:grid-cols-2">
                        {featureService.shouldShowContent("Be Instructor") ? (
                            <div>
                                <span>
                                    If you want to be instructor
                                    <input
                                        name="isInstractor"
                                        onChange={(e) => {
                                            //e.preventDefault();
                                            setProfileGeneralInfo({
                                                ...profileGeneralInfo,
                                                isInstractor: e.target.checked,
                                            });
                                            setShowInstractor(e.target.checked);
                                        }}
                                        checked={
                                            profileGeneralInfo?.isInstractor
                                        }
                                        type="checkbox"
                                        className="ml-4"
                                    />
                                </span>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className="text-right">
                        <button
                            type="submit"
                            className="text-white bg-fuchsia-800  hover:bg-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                        >
                            Update
                        </button>
                    </div>
                </div>
            </form>
            {showInstractor && <InstractorInfo />}
        </Fragment>
    );
}
