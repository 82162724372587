import React, { useEffect, useState } from "react";
import { subscriptionService } from "../../services/Account/subscription.service";
import { authService } from "../../services/Account/auth.service";
import { BellIcon } from "../Icons/bellIcon";
import { CrossIcon } from "../Icons/crossIcon";

export function SubscriptionEndNotify() {
    const [subscriptionEndTime, setSubscriptionEndTime] = useState(null);
    const [isPremiumUser, setIsPremiumUser] = useState(false);
    const [showToast, setShowToast] = useState(true);
    const [daysLeft, setDaysLeft] = useState(null);

    useEffect(() => {
        getProfile();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPremiumUser]);

    useEffect(() => {
        subscriptionEndTime !== null && calculateDays();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscriptionEndTime]);

    const calculateDays = () => {
        const d = new Date().toLocaleDateString();

        let date1 = new Date(subscriptionEndTime);
        let date2 = new Date(d);
        setDaysLeft((date1 - date2) / (1000 * 3600 * 24));
    };

    const getProfile = () => {
        subscriptionService.get().then((res) => {
            //console.debug(res.data.subscriptionExpired);
            setSubscriptionEndTime(
                new Date(res.data?.subscriptionExpired).toLocaleDateString()
            );
        });
        authService.profile().then((response) => {
            setIsPremiumUser(response.data?.isPremiumUser);
        });
    };

    const notificationToast = (
        <div className="text-white p-2 border-0 rounded bg-red-500 relative">
            <button className="absolute bg-transparent text-2xl font-semibold leading-none  outline-none focus:outline-none pointer-events-none top-2 left-2">
                <BellIcon />
            </button>
            <button
                className="absolute bg-transparent text-2xl font-semibold leading-none outline-none focus:outline-none top-2 right-2"
                onClick={() => setShowToast(false)}
            >
                <CrossIcon />
            </button>
            <span className="inline-block align-middle pt-6 px-2">
                Only {daysLeft} day(s) left for your premium membership. Please
                extend time in profile page.
            </span>
        </div>
    );

    return showToast && isPremiumUser && daysLeft < 7 ? (
        notificationToast
    ) : (
        <></>
    );
}
