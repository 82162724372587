import http from '../../global/http-common'

class RegisteredTrainingService {
    getAll() {
        return http.get("/RegisteredTraining");
    }
    get(id) {
        return http.get(`/RegisteredTraining/${id}`);
    }
    getRegisteredUser(id) {
        return http.get(`/RegisteredTraining/GetRegisteredUser/${id}`);
    }
    create(data) {
        return http.post("/RegisteredTraining", data);
    }

    update(id, data) {
        return http.put(`/RegisteredTraining/${id}`, data);
    }

    delete(id) {
        return http.delete(`/RegisteredTraining/${id}`);
    }
    
    getRegisteredTraining(trainingId, email){
        return http.get(`/RegisteredTraining/RegisteredTraining/${trainingId}/${email}`);
    }

    updateUserRating(data){
        //console.log(data);
        return http.patch(`/RegisteredTraining/UpdateUserRating/${data.trainingId}/${data.email}`, data);
    }

    unRegisterTraining(trainingId, email){
        return http.delete(`/RegisteredTraining/UnRegisterTraining/${trainingId}/${email}`);
    }

}

export default new RegisteredTrainingService();