import EventDataService from '../../services/Event/event.service'
import { useState, useEffect,Fragment} from 'react'
import { Link } from "react-router-dom";

export default function Ads()
{
  const [data, setData] = useState(null);
  useEffect(() => {
    getEvents()
  }, []);


  const getEvents = () => {
    EventDataService.getAll()
      .then((response) => {
        //console.debug(response);
        setData(response.data);

      });
  }
    return(
      <Fragment>

      { data?.slice(0, 3).map((item) => (
        <div key={item.id} className="block p-4 rounded-lg shadow-lg bg-gray-50 mt-6 mr-6 mb-6">
           <img className='w-full h-50 mb-4' src={item?.imgUrl} alt="" />
            <h5 className="text-gray-900 text-xl leading-tight font-medium mb-2">{item.name}</h5>
            <p className="text-gray-700 text-base mb-4">
            {item?.description}
            </p>
            <div className="text-right">
              <Link to={`/events`} type="button" className="text-white bg-fuchsia-800  hover:bg-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center">View</Link>
            </div>
          </div>
    ))}
    </Fragment>
    )
}