import { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { showError, showSuccess } from "../../global/alertService";
import { authService } from "../../services/Account/auth.service";
import { CompanyInfosService } from "../../services/CompanyInformation/companyInformation.service";

export function Signup() {
    const [credentialData, setCredentialData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        isPremiumUser: false,
        isEnable: true,
        isEmployeer: false,
    });
    const initialFormState = {
        name: "",
        employerDesignationId: "",
        companyName: "",
        companyWebsite: "",
        email: "",
        phoneNumber: "",
        userId: 0,
    };
    const [companyInfo, setCompanyInfo] = useState(initialFormState);
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState(false);
    const [confirmPassword, setconfirmPassword] = useState("");
    const [confirmationLink, setConfirmationLink] = useState("");

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setCredentialData({ ...credentialData, [name]: value });
    };
    const handleInputChangeForCompany = (event) => {
        const { name, value } = event.target;
        setCompanyInfo({
            ...companyInfo,
            [name]: value,
        });
    };
    const handleCofirmPassword = (e) => {
        setconfirmPassword(e.target.value);
        if (e.target.value !== credentialData.password) {
            setErrorMessage(true);
        } else {
            setErrorMessage(false);
        }
    };

    const checkIfNull = (e) => {
        let text = e.target.value;
        if (text === "") {
            setErrorMessage(false);
        }
    };
    const saveCompanyInfo = () => {
        if (companyInfo.employerDesignationId === "") {
            companyInfo.employerDesignationId = null;
        }
        const id = localStorage.getItem("id");
        companyInfo.userId = id;
        CompanyInfosService.create(companyInfo);
    };
    function onPaymentDone() {
        authService
            .signup(credentialData)
            .then((res) => {
                setCredentialData({
                    firstName: "",
                    lastName: "",
                    email: "",
                    password: "",
                    isPremiumUser: false,
                    isEnable: true,
                    isEmployeer: false,
                });
                setCompanyInfo({
                    name: "",
                    employerDesignationId: "",
                    companyName: "",
                    companyWebsite: "",
                    email: "",
                    phoneNumber: "",
                });
                showSuccess(" ", res.data);
                //setConfirmationLink(res.data);
            })
            .catch((error) => {
                // if (error.data === undefined) {
                //     showInfo(" ", "Email is already taken.");
                // }
            });
    }

    function onSignupForEmployeer() {
        let singUpData = {
            firstName: credentialData.firstName,
            lastName: credentialData.lastName,
            email: credentialData.email,
            password: credentialData.password,
            isPremiumUser: false,
            isEnable: true,
            isEmployeer: true,
        };

        if (credentialData.firstName == "") {
            showError("First Name can not be empty.");
            return false;
        }
        if (credentialData.lastName == "") {
            showError("Last Name can not be empty.");
            return false;
        }
        if (companyInfo.companyName == "") {
            showError("Company Name can not be empty.");
            return false;
        }
        if (credentialData.email == "") {
            showError("Email can not be empty.");
            return false;
        }
        if (credentialData.password == "") {
            showError("password can not be empty.");
            return false;
        }
        if (confirmPassword != credentialData.password) {
            showError("Confirm Password can not be empty.");
            return false;
        }
        authService
            .signup(singUpData)
            .then((res) => {
                localStorage.setItem("id", res.data?.id);
                saveCompanyInfo();
                setCredentialData({
                    firstName: "",
                    lastName: "",
                    email: "",
                    password: "",
                    isPremiumUser: false,
                    isEnable: true,
                    isEmployeer: false,
                });
                setCompanyInfo({
                    name: "",
                    employerDesignationId: "",
                    companyName: "",
                    companyWebsite: "",
                    email: "",
                    phoneNumber: "",
                });
                showSuccess(
                    " ",
                    "Please confirm your email to complete your registration."
                );
                //setConfirmationLink(res.data);
            })
            .catch((error) => {
                // if (error.data === undefined) {
                //     showInfo(" ", "Email is already taken.");
                // }
            });
    }
    return (
        <Fragment>
            <div className="flex flex-col justify-center items-center mt-16 pb-6">
                <h1 className="text-4xl font-bold text-fuchsia-800 mb-6 text-center">
                    Create a world of your own!
                </h1>
                <form
                    onSubmit={(event) => {
                        event.preventDefault();
                        onPaymentDone();
                    }}
                >
                    <div className="block p-4 ml-6">
                        <div className="grid grid-cols-1 mb-4 ">
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900 ">
                                    First Name
                                </label>
                                <input
                                    value={credentialData?.firstName}
                                    onChange={handleInputChange}
                                    type="text"
                                    name="firstName"
                                    maxLength="20"
                                    className="bg-gray-50 lg:w-96 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 mb-4 sm:w-full"
                                    placeholder="Enter your first name"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900 ">
                                    Last Name
                                </label>
                                <input
                                    value={credentialData?.lastName}
                                    onChange={handleInputChange}
                                    type="text"
                                    name="lastName"
                                    maxLength="20"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mb-4"
                                    placeholder="Enter your last name"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900 ">
                                    Company Name
                                </label>
                                <input
                                    value={companyInfo?.companyName}
                                    onChange={handleInputChangeForCompany}
                                    type="text"
                                    name="companyName"
                                    maxLength="20"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mb-4"
                                    placeholder="Enter your company name"
                                />
                            </div>
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900 ">
                                    Email
                                </label>
                                <input
                                    value={credentialData?.email}
                                    onChange={handleInputChange}
                                    type="email"
                                    name="email"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mb-4"
                                    placeholder="Enter your email"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900 ">
                                    Password
                                </label>
                                <input
                                    value={credentialData?.password}
                                    onChange={handleInputChange}
                                    type="password"
                                    name="password"
                                    placeholder="••••••••"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mb-4"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900 ">
                                    Confirm Password
                                </label>
                                <input
                                    type="password"
                                    name="confirmPassword"
                                    onChange={handleCofirmPassword}
                                    onBlur={checkIfNull}
                                    placeholder="••••••••"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                                    required
                                />
                                {errorMessage && (
                                    <span className="mt-2 inline-block text-red-500 text-sm">
                                        Password and confirm password do not
                                        match
                                    </span>
                                )}
                            </div>
                        </div>
                        <div>
                            <button
                                type="submit"
                                className="w-full mt-4 text-white bg-fuchsia-800 hover:bg-green-800 font-bold rounded-lg text-xl px-5 py-2.5 text-center "
                            >
                                Create Standard Account
                            </button>
                        </div>
                        <div className="text-center mt-4">
                            <h5 className="text-lg">Or</h5>
                        </div>
                        <div
                            type="submit"
                            onClick={onSignupForEmployeer}
                            className="w-full mt-4 text-white bg-fuchsia-800 hover:bg-green-800 font-bold rounded-lg text-xl px-5 py-2.5 text-center cursor-pointer"
                        >
                            {/* <Link
                                to={"/signUpPaymentForEmployeer"}
                                className="text-fuchsia-800 hover:underline "
                    > */}
                            Create your new account as Employer
                            {/* </Link> */}
                        </div>
                        {/* {confirmationLink !== "" ? (
                            <div className="text-sm mt-4 font-medium text-gray-500 ">
                                <Link
                                    to={confirmationLink}
                                    className="text-fuchsia-800 hover:underline "
                                >
                                    Click Here for confirming mail address.
                                </Link>
                            </div>
                        ) : (
                            <div></div>
                        )} */}

                        <div className="text-sm mt-4 font-medium text-gray-500 ">
                            Already have an account?{" "}
                            <Link
                                to={"/signIn"}
                                className="text-fuchsia-800 hover:underline "
                            >
                                Sign in
                            </Link>
                        </div>
                    </div>
                </form>
                {/* <div className="mt-2 text-center">
                    <h5 className="text-lg mb-2">Or</h5>
                    <button
                        type="button"
                        className="text-white bg-[#4285F4] hover:bg-[#4285F4]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2"
                    >
                        <svg
                            class="w-4 h-4 mr-2 -ml-1"
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fab"
                            data-icon="google"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 488 512"
                        >
                            <path
                                fill="currentColor"
                                d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
                            ></path>
                        </svg>
                        Sign in with Google
                    </button>
                    <button
                        type="button"
                        className="text-white bg-[#3b5998] hover:bg-[#3b5998]/90 focus:ring-4 focus:outline-none focus:ring-[#3b5998]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2"
                    >
                        <svg
                            class="w-4 h-4 mr-2 -ml-1"
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fab"
                            data-icon="facebook-f"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                        >
                            <path
                                fill="currentColor"
                                d="M279.1 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.4 0 225.4 0c-73.22 0-121.1 44.38-121.1 124.7v70.62H22.89V288h81.39v224h100.2V288z"
                            ></path>
                        </svg>
                        Sign in with Facebook
                    </button>
                </div> */}
            </div>
        </Fragment>
    );
}
