import EnrollTrainingCard from "../../components/Trining/enrollTrainingCard"
import { useParams } from "react-router";
import React from 'react'
import axios from "axios"
import TrainingDataService from '../../services/Training/training.service'
import TrainingContentService from '../../services/Training/trainingcontents.eervice'
import { useEffect } from "react"
const EnrollTraining = () => {
    const { id } = useParams()
    const [data, setData] = React.useState(null);
    const [training, setTraining] = React.useState({
        id: null,
        CourseName: null,
        instructorId: null,
        description: null,
        price: null,
        ImgUrl: null
    });

    const [trainingContentId, setTrainingContentId] = React.useState(null);


    useEffect(() => {
        if (id > 0) {
            getTrainings(id)
            getTainingContents(id)
        }

    }, []);

    const getTainingContents = (id) => {
        TrainingContentService.get("?trainingId=" + id)
            .then((response) => {
                //console.debug(response);
                setData(response.data);
                //console.log(response.data);
            });
    }

    const getTrainings = (id) => {
        TrainingDataService.get(id)
            .then((response) => {
               // console.debug(response);
                setTraining(response.data);
                //console.log(response.data);
            });
    }
    //----
    return (
        <>
            <div>
                <div className="flex flex-row ">

                    <div className="basis-1/4 mt-14">
                        
                    </div>

                    <div className="md:w-full sm:w-full basis-1/2 mt-20">
                        <div className="px-4 text-gray-900 mt-4">
                            <div className=" font-bold text-2xl mb-4">Enroll Training</div>
                        </div>

                        <EnrollTrainingCard training={training} />

                    </div>
                </div>

            </div>

        </>
    )

}
export default EnrollTraining