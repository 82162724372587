import { Fragment, useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import AuthVerify from "./components/Auth/authVerify";

import { AfterSignUp } from "./components/Auth/afterSignUp";
import { LostPassword } from "./components/Auth/lostPassword";
import { RecoverForgotPassword } from "./components/Auth/recoverForgotPassword";
import { ResetPassword } from "./components/Auth/resetpassword";
import { SignIn } from "./components/Auth/signIn";
import { Signup } from "./components/Auth/signUp";
import { SignUpPaymentForEmployeer } from "./components/Auth/signupPaymentForEmployeer";
import ResourceArticleView from "./components/Directory/resourceArticleView";
import ResourceView from "./components/Directory/resourceView";
import { AboutWone } from "./components/Landing/about-us";
import { AdPlacement } from "./components/Landing/adPlacement";
import { Hub } from "./components/Landing/hub";
import { PrivacyPolicy } from "./components/Landing/privacyPolicy";
import { Services } from "./components/Landing/services";
import { Talent } from "./components/Landing/talent";
import { TermsAndCondition } from "./components/Landing/termsAndCondition";
import NavBar from "./components/NavBar/navBar";
import { ProfileView } from "./components/Profile/profileView";
import { GoogleTranslate } from "./components/Translate/googleTranslate";
import TranslateWeb from "./components/translateWeb";
import Chat from "./pages/Chat/chat";
import Directory from "./pages/Directory/directory.jsx";
import { EventForm } from "./pages/Events/eventForm";
import EventIndexList from "./pages/Events/eventIndexList";
import EventParticipantList from "./pages/Events/eventParticipantList";
import Events from "./pages/Events/events";
import Host from "./pages/Host/host";
import AppliedJobList from "./pages/Job/appliedJobList";
import { EmployerJobPostForm } from "./pages/Job/employerJobPostForm";
import EmployerJobPostIndexList from "./pages/Job/employerJobPostIndexList";
import Job from "./pages/Job/job";
import JobApplicantList from "./pages/Job/jobApplicantList";
import { SeekerJobPostForm } from "./pages/Job/seekerJobPostForm";
import SeekerJobPostIndexList from "./pages/Job/seekerJobPostIndexList";
import SeekerSelectedList from "./pages/Job/seekerSelect";
import { Landing } from "./pages/Landing/landing";
import { Map } from "./pages/Map/map";
import NewsFeeds from "./pages/NewsFeed/newsFeed";
import BlogArticles from "./pages/ResourceBlog/blogArticles";
import BlogFiles from "./pages/ResourceBlog/blogFiles";
import BlogLinks from "./pages/ResourceBlog/blogLinks";
import SingleBlogArticle from "./pages/ResourceBlog/singleBlogArticle";
import SingleBlogLink from "./pages/ResourceBlog/singleBlogLink";
import { CourseContentVideo } from "./pages/Training/courseContentVideo";
import EnrollTraining from "./pages/Training/enrollTraining";
import Training from "./pages/Training/training.jsx";
import TrainingContents from "./pages/Training/trainingContent";
import TrainingDetails from "./pages/Training/trainingDetails";
import TrainingEditList from "./pages/Training/trainingEditList";
import TrainingIndexList from "./pages/Training/trainingIndexList";
import { authService } from "./services/Account/auth.service";
import { subscriptionService } from "./services/Account/subscription.service";

export function App() {
    const [authenticated, setauthenticated] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const loggedInUser = localStorage.getItem("authToken");
        const premium = localStorage.getItem("userType");
        if (loggedInUser) {
            setauthenticated(true);
            if (premium === "Premium") {
                setInterval(() => {
                    subscriptionService.updateAfterExpire().then((response) => {
                        if (response.data === "downgraded") {
                            authService.signout();
                            navigate("/");
                            window.location.reload();
                        }
                    });
                }, 60 * 60 * 1000);
            }
            //navigate("/newsFeeds");
        } else {
            setauthenticated(false);
        }

        setInterval(() => {
            //console.debug(new Date())
            authService.heartBeat().then((response) => {
                //console.debug(response?.data);
            });
        }, 30 * 1000);
    }, []);

    return (
        <Fragment>
               <TranslateWeb />
            {authenticated ? (
                <div>
                    <AuthVerify />
                    <NavBar />
                   
                    <Routes>
                        <Route path="/" element={<NewsFeeds />} />
                        <Route path="/training" element={<Training />} />
                        <Route path="/resources" element={<Directory />} />
                        {/* <Route path="/forumSeassion/:id" element={<ForumSeassion />} /> */}
                        <Route path="/events" element={<Events />} />
                        <Route
                            path="/enrollTraining/:id"
                            element={<EnrollTraining />}
                        />
                        <Route
                            path="/trainingDetails/:id"
                            element={<TrainingDetails />}
                        />
                        <Route path="/job" element={<Job />} />
                        <Route path="/host" element={<Host />} />
                        {/*<Route path="/translate" element={<Translate />} />*/}
                        <Route path="/map" element={<Map />} />
                        <Route path="/chat" element={<Chat />} />
                        <Route path="/profileView" element={<ProfileView />} />
                        <Route
                            path="/trainingContent"
                            element={<TrainingContents />}
                        />
                        <Route
                            path="/courseContentVideo/:id"
                            element={<CourseContentVideo />}
                        />
                        <Route
                            path="/employerJobPostIndexList"
                            element={<EmployerJobPostIndexList />}
                        />
                        <Route
                            path="/employerJobPost/create"
                            element={<EmployerJobPostForm />}
                        />
                        <Route
                            path="/employerJobPost/update/:id"
                            element={<EmployerJobPostForm />}
                        />
                        <Route
                            path="/seekerJobPostIndexList"
                            element={<SeekerJobPostIndexList />}
                        />
                        <Route
                            path="/seekerJobPost/create"
                            element={<SeekerJobPostForm />}
                        />
                        <Route
                            path="/seekerJobPost/update/:id"
                            element={<SeekerJobPostForm />}
                        />
                        <Route
                            path="/jobApplicantList"
                            element={<JobApplicantList />}
                        />
                        <Route
                            path="/appliedJobList"
                            element={<AppliedJobList />}
                        />
                        <Route
                            path="/seekerSelect"
                            element={<SeekerSelectedList />}
                        />
                        <Route
                            path="/trainingIndexList"
                            element={<TrainingIndexList />}
                        />
                        <Route
                            path="/trainingEditList/:id"
                            element={<TrainingEditList />}
                        />
                        <Route
                            path="/eventIndexList"
                            element={<EventIndexList />}
                        />
                        <Route
                            path="/eventParticipantList"
                            element={<EventParticipantList />}
                        />
                        <Route path="/event/create" element={<EventForm />} />
                        <Route
                            path="/event/update/:id"
                            element={<EventForm />}
                        />
                        <Route
                            path="/translate"
                            element={<GoogleTranslate />}
                        />
                        <Route
                            path="/resources/:id"
                            element={<ResourceView />}
                        />
                        <Route
                            path="/resources/article/:id"
                            element={<ResourceArticleView />}
                        />

                        {/* Resource blog pages */}
                        <Route
                            path="/resource/articles"
                            element={<BlogArticles />}
                        />
                        <Route
                            path="/resource/articles/:id"
                            element={<SingleBlogArticle />}
                        />
                        <Route path="/resource/files" element={<BlogFiles />} />
                        <Route path="/resource/links" element={<BlogLinks />} />
                        <Route
                            path="/resource/links/:id"
                            element={<SingleBlogLink />}
                        />
                    </Routes>
                </div>
            )  : (
                
                <div>
                 
                    <Routes>
                        <Route path="/" element={<Landing />} />
                        <Route path="/resources" element={<Directory />} />
                        <Route path="/events" element={<Events />} />
                        <Route path="/signUp" element={<Signup />} />
                        <Route path="/signIn" element={<SignIn />} />
                        <Route
                            path="/signUpSuccessful"
                            element={<AfterSignUp />}
                        />
                        <Route
                            path="/signUpPaymentForEmployeer"
                            element={<SignUpPaymentForEmployeer />}
                        />
                        <Route
                            path="/lostPassword"
                            element={<LostPassword />}
                        />
                        <Route
                            path="/resetPassword"
                            element={<ResetPassword />}
                        />
                        <Route
                            path="/recoverForgotPassword"
                            element={<RecoverForgotPassword />}
                        />
                        <Route path="/services" element={<Services />} />
                        <Route
                            path="/translate"
                            element={<GoogleTranslate />}
                        />
                        <Route path="/map" element={<Map />} />
                        <Route path="/job" element={<Job />} />
                        <Route
                            path="/privacyPolicy"
                            element={<PrivacyPolicy />}
                        />
                        <Route
                            path="/termsAndCondition"
                            element={<TermsAndCondition />}
                        />
                        <Route path="/hub" element={<Hub />} />
                        <Route path="/talent" element={<Talent />} />
                        <Route path="/adPlacement" element={<AdPlacement />} />
                        <Route path="/about-us" element={<AboutWone />} />
                    </Routes>
                </div>
            )}
        </Fragment>
    );
}
