import React, { Fragment, useEffect, useState } from "react";
import { showSuccess } from "../../global/alertService";
import TrainingContentDetService from "../../services/Training/trainingContentDet.service";
import { getFormData } from "../../utils/getFormData";

export function ContentDetUpdate(props) {
    const initialFormState = {
        name: "",
        isPreviewAvailable: "",
        video: null,
        trainingContentId: "",
    };

    const [data, setData] = useState(initialFormState);

    useEffect(() => {
        if (props.id > 0) {
            getContentDet(props.id);
        }
    }, [props]);

    const getContentDet = (id) => {
        TrainingContentDetService.get(id).then((res) => {
            console.debug(res);
            setData(res.data);
        });
    };

    const handleInputChange = (event) => {
        const { name, value } = event?.target;
        if (name === "video") {
            setData({
                ...data,
                video: event.target?.files[0],
            });
        } else {
            setData({
                ...data,
                [name]: value,
            });
        }
    };

    const updateContentDet = (id) => {
        console.log(data);
        const formData = getFormData(data);

        TrainingContentDetService.update(id, formData).then((response) => {
            // console.debug(response)
            showSuccess("Data updated successfully.");
            setData(response?.data);
            props.onClose();
            window.location.reload();
        });
    };
    //---------

    return (
        <Fragment>
            <form>
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-full my-6 mx-auto max-w-3xl">
                        {/*content*/}
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            {/*header*/}
                            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                <h3 className="text-3xl font-semibold">
                                    Update Training Content
                                </h3>

                                <button
                                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={props.onClose}
                                >
                                    <span className="bg-transparent text-red-500 background-transparent font-bold h-6 w-6 text-2xl block outline-none focus:outline-none">
                                        x
                                    </span>
                                </button>
                            </div>

                            {/*body*/}
                            <div className="py-6 px-6 lg:px-8">
                                <div className="grid gap-6 mb-6 md:grid-cols-3">
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            Chapter
                                        </label>
                                        <input
                                            name="name"
                                            value={data?.name}
                                            onChange={handleInputChange}
                                            type="text"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                            required
                                        />
                                    </div>

                                    <div>
                                        <input
                                            name="isPreviewAvailable"
                                            checked={data?.isPreviewAvailable}
                                            type="checkbox"
                                            onChange={(e) => {
                                                setData({
                                                    ...data,
                                                    isPreviewAvailable:
                                                        e.target.checked,
                                                });
                                            }}
                                            className="w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300"
                                        />
                                        <label className="ml-2 text-sm font-medium text-gray-900">
                                            Is Preview Available
                                        </label>
                                    </div>

                                    <div>
                                        <div>
                                            <label className="block mb-2 text-sm font-medium text-gray-900">
                                                Topics Video
                                            </label>
                                            <input
                                                name="video"
                                                type="file"
                                                onChange={handleInputChange}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                                required
                                            />
                                        </div>{" "}
                                        <input
                                            type="hidden"
                                            name="trainingContentId"
                                            value={props.trainingContentId}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*footer*/}
                            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                <button
                                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={props.onClose}
                                >
                                    Close
                                </button>
                                <button
                                    className="bg-fuchsia-800 text-white hover:bg-green-800 font-bold  text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={() => updateContentDet(data.id)}
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </Fragment>
    );
}
