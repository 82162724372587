import http from '../../global/http-common'

class JobApplicantDataService {
    getAll() {
        return http.get("/JobApplicants");
    }
    getAppliedJobs() {
        return http.get("/JobApplicants/AppliedJob");
    }
    get(id) {
        return http.get(`/JobApplicants/${id}`);
    }
    create(data) {
        return http.post("/JobApplicants", data);
    }

    update(id, data) {
        return http.put(`/JobApplicants/${id}`, data);
    }

    delete(id) {
        return http.delete(`/JobApplicants/${id}`);
    }

    // check if an applicant already applied for a job
    checkIfApplied(jobId){
        return http.get(`/JobApplicants/Check/${jobId}`);
    }

}

export default new JobApplicantDataService();