import jwtDecode from "jwt-decode";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { withRouter } from "../../hoc/withRouter";
import { authService } from "../../services/Account/auth.service";

const AuthVerify = (props) => {
    let location = props.router.location;
    const navigate = useNavigate();
    useEffect(() => {
        const token = localStorage.getItem("authToken");
        const decoded = jwtDecode(token);
        const expireTime = new Date(parseInt(decoded.exp, 10) * 1000);
        if (expireTime < Date.now()) {
            authService.signout();
            navigate("/");
            window.location.reload();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return <div></div>;
};

export default withRouter(AuthVerify);
