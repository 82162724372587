import http from "../../global/http-common";

const route = "/ResourceBlogs";

export const resourceBlogService = {
    getAll: function getAll(
        resourceType,
        categoryId,
        pageSize,
        currentPageNumber,
        countryId,
        stateId,
        cityId,
        search
    ) {
        return http.get(route, {
            params: {
                resourceType: resourceType,
                resourceCategory: categoryId,
                pageSize: pageSize,
                currentPage: currentPageNumber,
                country: countryId,
                state: stateId,
                city: cityId,
                searchTerm: search,
            },
        });
    },

    getById: function (id) {
        return http.get(route + `/${id}`);
    },
};
