import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { resourceBlogService } from "../../services/ResourceBlog/resourceBlog.service";

export default function SingleBlogLink() {
    const { id } = useParams();
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [imageUrl, setImgageUrl] = useState(null);

    const [links, setLinks] = useState([]);

    useEffect(() => {
        if (id !== null && id > 0) {
            getArticleById(id);
        }
    }, [id]);

    const getArticleById = (id) => {
        resourceBlogService.getById(id).then((res) => {
            setTitle(res.data.name);
            setDescription(res.data.description);
            //console.log(res.data);
            setLinks(res.data.resourceBlogLinks);
            setImgageUrl(res.data.imageUrl);
        });
    };
    return (
        <div className="mt-20 pt-8 mx-auto px-4 sm:w-full md:w-2/3">
            {imageUrl && (
                <img
                    src={imageUrl}
                    alt=""
                    className="w-full lg:h-72 md:h-60 sm:h-48 object-cover rounded-lg mb-4"
                />
            )}
            <h5 className="mb-4 text-2xl font-medium leading-tight text-neutral-800 w-full">
                {title}
            </h5>
            <p
                className="mb-4 text-sm text-gray-900"
                dangerouslySetInnerHTML={{
                    __html: description,
                }}
            />
            {links.map((item) => {
                return (
                    <div>
                        <a
                            className="text-blue-500 mb-2 block"
                            href={item.linkUrl}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {item.linkName}
                        </a>
                    </div>
                );
            })}
        </div>
    );
}
