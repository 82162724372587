export function toLocalEventDate(dateTime) {
    const dateoptions = {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric"
    }
    const date = new Date(dateTime)
    const localDateString = date.toLocaleDateString('en-CA',dateoptions)
    return localDateString
}