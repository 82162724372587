import { Fragment, useState, useEffect } from "react";
import { LandingNav } from "../../components/Landing/landingNav";
import { IntroVideoModal } from "../fileViewer/introVideoModal";
export function Hero() {
    const [showModal, setShowModal] = useState(false);
    useEffect(() => {
        let element = document.getElementById("google_translate_element");
        element.style.display = "block";
        return () => {
            element.style.display = "none";
        };
    }, []);
    return (
        <Fragment>
            <div className="bg-indigo-50">
                <section>
                    <LandingNav />
                </section>
                
                <section className="py-16">
                    <div className=" flex-row lg:flex md:hidden sm:hidden">
                        <div className="flex-col justify-start px-14  py-20">
                            <h1 className="text-6xl font-bold mb-14">
                                Welcome to Wone!
                            </h1>
                            <p className="mb-8 text-lg ">
                                {/*Are you visiting or moving into a new city? With
                                Thousands of members around the world joining
                                WONE every day, now is your chance to discover
                                what life in a new city or another country has
    to offer!*/}
    WONE is a unique platform that connects newcomers 
    with local job opportunities and employers with vetted 
    talent. Built with a professional and community mindset, 
    WONE offers ease in job searching while fostering meaningful, 
    long-term connections. Connect, immerse, and grow with WONE
                            </p>
                            <div>
                                <a href="/signUp">
                                    <div className=" inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-fuchsia-800 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-600 ">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 384 512"
                                            className="w-6 h-6 mr-2"
                                            fill="white"
                                        >
                                            <path d="M135.652 0c23.625 0 43.826 20.65 43.826 44.8v99.851c17.048-16.34 49.766-18.346 70.944 6.299 22.829-14.288 53.017-2.147 62.315 16.45C361.878 158.426 384 189.346 384 240c0 2.746-.203 13.276-.195 16 .168 61.971-31.065 76.894-38.315 123.731C343.683 391.404 333.599 400 321.786 400H150.261l-.001-.002c-18.366-.011-35.889-10.607-43.845-28.464C93.421 342.648 57.377 276.122 29.092 264 10.897 256.203.008 242.616 0 224c-.014-34.222 35.098-57.752 66.908-44.119 8.359 3.583 16.67 8.312 24.918 14.153V44.8c0-23.45 20.543-44.8 43.826-44.8zM136 416h192c13.255 0 24 10.745 24 24v48c0 13.255-10.745 24-24 24H136c-13.255 0-24-10.745-24-24v-48c0-13.255 10.745-24 24-24zm168 28c-11.046 0-20 8.954-20 20s8.954 20 20 20 20-8.954 20-20-8.954-20-20-20z" />
                                        </svg>
                                        <span>Sign up for free</span>
                                    </div>
                                </a>
                                <a
                                    href="https://www.youtube.com/watch?v=XNPUpmFd-BY"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <div className="ml-4 inline-flex items-center justify-center whitespace-nowrap rounded-md border  border-gray-400 px-4 py-2 text-base font-medium text-gray-800 shadow-sm hover:border-black ">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 576 512"
                                            className="w-6 h-6 mr-2"
                                        >
                                            <path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z" />
                                        </svg>
                                        <span>What's one?</span>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className="relative w-full">
                            <img src="./images_v1/header.png" alt="" />
                            <div
                                onClick={() => setShowModal(true)}
                                className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 cursor-pointer"
                            >
                                <svg
                                    className="w-24 h-24 text-gray-200"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                >
                                    <path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM188.3 147.1c7.6-4.2 16.8-4.1 24.3 .5l144 88c7.1 4.4 11.5 12.1 11.5 20.5s-4.4 16.1-11.5 20.5l-144 88c-7.4 4.5-16.7 4.7-24.3 .5s-12.3-12.2-12.3-20.9V168c0-8.7 4.7-16.7 12.3-20.9z" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className=" flex-col items-center lg:hidden md:flex sm:flex">
                        <div className="relative w-full mb-10">
                            <img src="./images_v1/header.png" alt="" />
                            <div
                                onClick={() => setShowModal(true)}
                                className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 cursor-pointer"
                            >
                                <svg
                                    className="w-24 h-24 text-gray-200"
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                >
                                    <path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM188.3 147.1c7.6-4.2 16.8-4.1 24.3 .5l144 88c7.1 4.4 11.5 12.1 11.5 20.5s-4.4 16.1-11.5 20.5l-144 88c-7.4 4.5-16.7 4.7-24.3 .5s-12.3-12.2-12.3-20.9V168c0-8.7 4.7-16.7 12.3-20.9z" />
                                </svg>
                            </div>
                        </div>
                        <div className="flex-col justify-start mx-6">
                            <h1 className="text-6xl font-bold mb-14">
                                Welcome to Wone!
                            </h1>
                            <p className="mb-8 text-lg ">
                               {/*} Are you visiting or moving into a new city? With
                                Thousands of members around the world joining
                                WONE every day, now is your chance to discover
                                what life in a new city or another country has
to offer!*/}
WONE is a unique platform that connects newcomers 
with local job opportunities and employers with vetted talent.
 Built with a professional and community mindset, 
 WONE offers ease in job searching while fostering meaningful, 
long-term connections. Connect, immerse, and grow with WONE.
                            </p>
                            <div>
                                <a href="/signUp">
                                    <div className=" inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-fuchsia-800 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-600 ">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 384 512"
                                            className="w-6 h-6 mr-2"
                                            fill="white"
                                        >
                                            <path d="M135.652 0c23.625 0 43.826 20.65 43.826 44.8v99.851c17.048-16.34 49.766-18.346 70.944 6.299 22.829-14.288 53.017-2.147 62.315 16.45C361.878 158.426 384 189.346 384 240c0 2.746-.203 13.276-.195 16 .168 61.971-31.065 76.894-38.315 123.731C343.683 391.404 333.599 400 321.786 400H150.261l-.001-.002c-18.366-.011-35.889-10.607-43.845-28.464C93.421 342.648 57.377 276.122 29.092 264 10.897 256.203.008 242.616 0 224c-.014-34.222 35.098-57.752 66.908-44.119 8.359 3.583 16.67 8.312 24.918 14.153V44.8c0-23.45 20.543-44.8 43.826-44.8zM136 416h192c13.255 0 24 10.745 24 24v48c0 13.255-10.745 24-24 24H136c-13.255 0-24-10.745-24-24v-48c0-13.255 10.745-24 24-24zm168 28c-11.046 0-20 8.954-20 20s8.954 20 20 20 20-8.954 20-20-8.954-20-20-20z" />
                                        </svg>
                                        <span>Sign up for free</span>
                                    </div>
                                </a>
                                <a
                                    href="https://www.youtube.com/watch?v=XNPUpmFd-BY"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <div className="ml-4 inline-flex items-center justify-center whitespace-nowrap rounded-md border  border-gray-400 px-4 py-2 text-base font-medium text-gray-800 shadow-sm hover:border-black ">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 576 512"
                                            className="w-6 h-6 mr-2"
                                        >
                                            <path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z" />
                                        </svg>
                                        <span>What's one?</span>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {showModal && (
                <IntroVideoModal
                    //src={"https://www.youtube.com/watch?v=XNPUpmFd-BY"}
                    onClose={() => setShowModal(false)}
                />
            )}
        </Fragment>
    );
}
