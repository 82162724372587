import http from "../../global/http-common";

const route = "/Resources";

export const resourceService = {
    getAll: function getAll(
        categoryId,
        countryId,
        stateId,
        cityId,
        search,
        currentPage,
        pageSize
    ) {
        return http.get(route, {
            params: {
                categoryId: categoryId,
                countryId: countryId,
                stateId: stateId,
                cityId: cityId,
                search: search,
                currentPage: currentPage,
                pageSize: pageSize,
            },
        });
    },

    getById: function (id) {
        return http.get(route + `/${id}`);
    },

    getArticleById: function (id) {
        return http.get(route + `/ResourceArticle/${id}`);
    },
};
