import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { useEffect, useState, useRef, useLayoutEffect } from "react";
import { conversationService } from "../../services/Chat/conversation.service";
import { toLocalEventTime } from "../../utils/event-time";
import { isImage } from "../../utils/url-utils";

export function ChatContainer({ conversation, onMessageSent }) {

    const initialFormState = {
        messageContent: "",
        files: [],
    };

    const currentUserId = localStorage.getItem("id");
    const [message, setMessage] = useState(initialFormState);
    const [chatMessages, setChatMessages] = useState(null);
    const [emojiPicker, setEmojiPicker] = useState(false);
    const [image, setImage] = useState(null);
    const [file, setFile] = useState(null);
   
    const chatContainerRef = useRef(null);
    useEffect(() => {
        const containerElement = chatContainerRef.current;
        containerElement.scrollTop = containerElement.scrollHeight;
    }, [chatMessages]);
   
    useEffect(() => {
        setChatMessages(conversation?.messages);
    }, [conversation]);

    const onEmojiClick = (e) => {
        //console.debug(e.native)
        let sym = e.unified.split("-");
        let codesArray = [];
        sym.forEach((el) => codesArray.push("0x" + el));
        let emoji = String.fromCodePoint(...codesArray);
        document.getElementById("emojiMessage").value =
            document.getElementById("emojiMessage").value + emoji;
        setMessage({
            ...message,
            messageContent: message.messageContent + emoji,
        });
        // console.debug(emoji)
    };
    const removeFile = () => {
        setFile(null);
        setMessage(initialFormState);
    };

    const removeImage = () => {
        setImage(null);
        setMessage(initialFormState);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (message.messageContent.length > 0 || message.files.length > 0) {
            const formData = new FormData();
            formData.append("messageContent", message.messageContent);
            message?.files?.forEach((file) => {
                formData.append("files", file);
            });
            conversationService
                .sendMessage(conversation?.id, formData)
                .then((response) => {
                    onMessageSent(message);
                    setMessage(initialFormState);
                    setImage(null);
                    setFile(null);
                    setEmojiPicker(false);
                });
        } else {
            return false;
        }
    };

    const onMessageUpdate = (event) => {
        const { name, value, files } = event.target;
        if (name === "files") {
            setMessage({
                ...message,
                files: [...message.files, files[0]],
            });
            if (event.currentTarget.id === "img") {
                setImage(event.target.files[0]);
            } else {
                setFile(event.target.files[0]);
            }
        } else {
            setMessage({
                ...message,
                [name]: value,
            });
        }
    };

    /*useEffect(() => {
        // console.debug(chatMessages)
        var element = document.getElementById("chat");
        element.scrollTop = 500;
    }, [chatMessages]);*/

    return (
        <div className=" border flex flex-col h-full">
            <div className="py-2 px-3 bg-fuchsia-800 flex flex-row justify-between items-center">
                {conversation !== null ? (
                    <div className="flex items-center">
                        <div>
                            {conversation?.profilePic != null ? (
                                <img
                                    className="h-12 w-12 rounded-full"
                                    src={conversation?.profilePic}
                                    alt=""
                                />
                            ) : (
                                <svg
                                    className=" bg-green-400 w-12 h-12 rounded-full text-gray-400"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                                        clipRule="evenodd"
                                    ></path>
                                </svg>
                            )}
                        </div>
                        <div className="ml-4">
                            <p className="text-white">{conversation?.name}</p>
                        </div>
                    </div>
                ) : (
                    <div className="ml-4">
                        <p className="text-white">+ New Message</p>
                    </div>
                )}
            </div>
            <div ref={chatContainerRef} style={{ height: "500px", overflowY: "scroll" }}
                id="chat"
                onScroll={(e) => {
                    if (e.target.scrollTop === 0) {
                        conversationService
                            .getMessages(
                                conversation?.id,
                                Math.min(...chatMessages.map((o) => o.id))
                            )
                            .then((response) => {
                                if (response.data.length !== 0) {
                                    setChatMessages((x) => [
                                        ...response.data,
                                        ...chatMessages,
                                    ]);
                                }
                            });
                    }
                }}
                className="flex-1 border overflow-auto flex-col-reverse "
            >
                {/*-- {chatMessages?.slice(0, 10).map((chatMessage, index) =>*/}
                {chatMessages?.map((chatMessage, index) =>
                    // eslint-disable-next-line eqeqeq
                    chatMessage?.senderId != currentUserId ? (
                        <div key={index} className="flex justify-start">
                            <div className="bg-green-300 float-right w-3/4 mx-4 my-2 p-2 rounded-lg clearfix">
                                {" "}
                                <p className="text-sm text-teal">
                                    {conversation?.name}
                                </p>
                                <p className="text-base mt-1">
                                    {chatMessage?.message}
                                    <a href="#!" className="mt-2">
                                        {chatMessage?.fileUrls.map(
                                            (fileUrl, index) => {
                                                return isImage(fileUrl) ? (
                                                    <img
                                                        key={index}
                                                        className="rounded-lg h-36 w-36"
                                                        src={fileUrl}
                                                        alt=""
                                                    />
                                                ) : (
                                                    <button
                                                        key={index}
                                                        onClick={() => {
                                                            window.open(
                                                                fileUrl
                                                            );
                                                        }}
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 15 15"
                                                            fill="none"
                                                        >
                                                            <path
                                                                d="M0 4.5V0H1V4.5C1 5.32843 1.67157 6 2.5 6C3.32843 6 4 5.32843 4 4.5V1.5C4 1.22386 3.77614 1 3.5 1C3.22386 1 3 1.22386 3 1.5V5H2V1.5C2 0.671573 2.67157 0 3.5 0C4.32843 0 5 0.671573 5 1.5V4.5C5 5.88071 3.88071 7 2.5 7C1.11929 7 0 5.88071 0 4.5Z"
                                                                fill="black"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M12.5 0H6V4.5C6 6.433 4.433 8 2.5 8H1V13.5C1 14.3284 1.67157 15 2.5 15H12.5C13.3284 15 14 14.3284 14 13.5V1.5C14 0.671573 13.3284 0 12.5 0ZM11 4H7V5H11V4ZM11 7H7V8H11V7ZM4 10H11V11H4V10Z"
                                                                fill="black"
                                                            />
                                                        </svg>
                                                    </button>
                                                );
                                            }
                                        )}
                                    </a>
                                </p>
                                <p className="text-right text-xs text-grey-dark mt-1">
                                    {new Date(
                                        chatMessage?.sentAt
                                    ).toLocaleDateString()}{" "}
                                    {toLocalEventTime(chatMessage?.sentAt)}
                                </p>
                            </div>
                        </div>
                    ) : (
                        <div key={index} className="flex justify-end">
                            <div className="bg-gray-300 w-3/4 mx-4 my-2 p-2 rounded-lg clearfix">
                                <p className="text-base mt-1">
                                    {chatMessage.message}
                                    <a href="#!" className="mt-2">
                                        {chatMessage?.fileUrls.map(
                                            (fileUrl, index) => {
                                                return isImage(fileUrl) ? (
                                                    <img
                                                        key={index}
                                                        className="rounded-lg h-36 w-36"
                                                        src={fileUrl}
                                                        alt=""
                                                    />
                                                ) : (
                                                    <button
                                                        key={index}
                                                        onClick={() => {
                                                            window.open(
                                                                fileUrl
                                                            );
                                                        }}
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 15 15"
                                                            fill="none"
                                                        >
                                                            <path
                                                                d="M0 4.5V0H1V4.5C1 5.32843 1.67157 6 2.5 6C3.32843 6 4 5.32843 4 4.5V1.5C4 1.22386 3.77614 1 3.5 1C3.22386 1 3 1.22386 3 1.5V5H2V1.5C2 0.671573 2.67157 0 3.5 0C4.32843 0 5 0.671573 5 1.5V4.5C5 5.88071 3.88071 7 2.5 7C1.11929 7 0 5.88071 0 4.5Z"
                                                                fill="black"
                                                            />
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M12.5 0H6V4.5C6 6.433 4.433 8 2.5 8H1V13.5C1 14.3284 1.67157 15 2.5 15H12.5C13.3284 15 14 14.3284 14 13.5V1.5C14 0.671573 13.3284 0 12.5 0ZM11 4H7V5H11V4ZM11 7H7V8H11V7ZM4 10H11V11H4V10Z"
                                                                fill="black"
                                                            />
                                                        </svg>
                                                    </button>
                                                );
                                            }
                                        )}
                                    </a>
                                </p>
                                <p className="text-right text-xs text-grey-dark mt-1">
                                    {new Date(
                                        chatMessage?.sentAt
                                    ).toLocaleDateString()}{" "}
                                    {toLocalEventTime(chatMessage?.sentAt)}
                                </p>
                            </div>
                        </div>
                    )
                )}
            </div>
            {image && (
                <div className="relative py-4">
                    <div className="flex justify-center">
                        <img
                            className=" h-40 w-40 rounded-lg "
                            src={URL.createObjectURL(image)}
                            alt=""
                        />
                    </div>
                    <button
                        onClick={removeImage}
                        className="absolute text-xl top-0 right-0 m-2 bg-white rounded-full p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100  "
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>
            )}
            {file ? (
                <div className="relative mb-4">
                    <p className="ml-4"> {file.name}</p>
                    <button
                        onClick={removeFile}
                        className="absolute text-xl top-0 right-0 m-1 bg-white rounded-full p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100  "
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>
            ) : null}
            <form onSubmit={onSubmit}>
                <div className="bg-grey-lighter px-4 py-4 flex items-center">
                    <label
                        htmlFor="file"
                        className="inline-flex justify-center p-2 text-fuchsia-800 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100"
                    >
                        <svg
                            aria-hidden="true"
                            className="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                        <span className="sr-only">Attach file</span>
                        <input
                            name="files"
                            type="file"
                            id="file"
                            style={{ display: "none" }}
                            accept=".pdf"
                            onChange={onMessageUpdate}
                        />
                    </label>
                    <label
                        htmlFor="img"
                        className="inline-flex justify-center p-2 text-fuchsia-800 rounded-lg cursor-pointer hover:text-gray-900 hover:bg-gray-100"
                    >
                        <svg
                            aria-hidden="true"
                            className="w-6 h-6"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                        <span className="sr-only">Upload image</span>
                        <input
                            name="files"
                            type="file"
                            id="img"
                            accept=".png,.jpeg,.jpg"
                            style={{ display: "none" }}
                            onChange={onMessageUpdate}
                        />
                    </label>
                    <button
                        type="button"
                        onClick={() => setEmojiPicker(!emojiPicker)}
                        className="p-2 text-fuchsia-800 rounded-lg cursor-pointer hover:text-gray-900 hover:bg-gray-100"
                    >
                        <svg
                            aria-hidden="true"
                            className="w-6 h-6"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 100-2 1 1 0 000 2zm7-1a1 1 0 11-2 0 1 1 0 012 0zm-.464 5.535a1 1 0 10-1.415-1.414 3 3 0 01-4.242 0 1 1 0 00-1.415 1.414 5 5 0 007.072 0z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                        <span className="sr-only">Add emoji</span>
                    </button>
                    <div className="flex-1 mx-4">
                        <input
                            id="emojiMessage"
                            name="messageContent"
                            value={message.messageContent}
                            onChange={onMessageUpdate}
                            className="block mx-4 p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                            placeholder="Write your message..."
                        />
                    </div>

                    {message.messageContent.length > 0 ||
                        message.files.length > 0 ? (
                        <button
                            type="submit"
                            className="inline-flex justify-center p-2 text-fuchsia-800 rounded-full cursor-pointer hover:bg-blue-100"
                        >
                            <svg
                                aria-hidden="true"
                                className="w-6 h-6 rotate-90"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                            </svg>
                        </button>
                    ) : (
                        <></>
                    )}
                </div>
            </form>
            {emojiPicker && (
                <div className="absolute z-10 sm:bottom-24">
                    <Picker data={data} onEmojiSelect={onEmojiClick} />
                </div>
            )}
        </div>
    );
}
