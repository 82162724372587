import React from "react";
import { Link } from "react-router-dom";

export function LinkItem({ article }) {
    return (
        <Link
            to={`/resource/links/${article.id}`}
            className="items-center bg-white rounded border shadow-md mb-4 h-72 relative"
        >
            <img
                className="object-cover w-full h-48 rounded-t absolute top-0"
                src={article.imageUrl}
                alt={`${article.name}`}
            />

            <div className="w-full absolute bottom-0 h-28 hover:h-52 transition-all duration-500 overflow-hidden z-10">
                <p
                    className="text-[10px] p-2 w-auto mx-2 flex items-center absolute top-0 rounded text-white font-bold uppercase"
                    style={{
                        backgroundColor: article.resourcesCategoryColor,
                    }}
                >
                    {article.resourcesCategory}
                </p>
                <div className="bg-white px-4 w-full mt-4">
                    <p className="pt-6 text-xl font-medium text-gray-900 only-2-lines">
                        {article.name}
                    </p>
                    <p
                        className="mt-2 text-sm text-gray-900 only-3-line h-16"
                        dangerouslySetInnerHTML={{
                            __html: article.description,
                        }}
                    />
                </div>
            </div>
        </Link>
    );
}
