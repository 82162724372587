import http from '../../global/http-common'

const route = '/InPersonServiceMessage';

export const InPersonMsgService = {
    getAll: function getAll() {
        return http.get(route);
    },
    get: function get(id) {
        return http.get(`${route}/${id}`);
    },
    create: function create(data) {
        return http.post(route, data);
    }
   
}