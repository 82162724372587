import React from "react";
import ReactPlayer from "react-player";
import DocsSkeleton from "../Skeletons/docsSkeleton";
import PdfSkeleton from "../Skeletons/pdfSkeleton";
import PptSkeleton from "../Skeletons/pptSkeleton";

export default function ResourseFile({
    setShowModal,
    setSource,
    setTopic,
    fileUrl,
    topic,
}) {
    return (
        <div className="block rounded-lg bg-white shadow-lg">
            <div
                className="flex items-center justify-center w-full h-40 bg-gray-300 rounded-t-lg cursor-pointer"
                onClick={() => {
                    setShowModal(true);
                    setSource(fileUrl);
                    setTopic(topic);
                }}
            >
                {fileUrl.includes(".mp4") && (
                    <ReactPlayer
                        url={fileUrl}
                        //light={true}
                        //light={"http://placekitten.com/g/300/200"}
                        height={"100%"}
                        width={"100%"}
                    />
                )}
                {fileUrl.includes(".pdf") && <PdfSkeleton />}
                {fileUrl.includes(".ppt") && <PptSkeleton />}
                {fileUrl.includes(".doc") && <DocsSkeleton />}
            </div>

            <div className="p-4">
                <h5 className="mb-2 text-base font-medium leading-tight text-neutral-800 w-full flex items-center">
                    {topic}
                </h5>
                <button
                    type="button"
                    className="w-auto mt-2 text-white font-medium bg-fuchsia-800 hover:bg-green-800 rounded text-sm px-5 py-2.5 text-center"
                    onClick={() => {
                        setShowModal(true);
                        setSource(fileUrl);
                        setTopic(topic);
                    }}
                >
                    <p>
                        {fileUrl.includes(".mp4") || fileUrl.includes(".pdf")
                            ? "View"
                            : "Download"}
                    </p>
                </button>
            </div>
        </div>
    );
}
