import { Fragment } from "react";

export function MainFeatures() {
    return (
        <Fragment>
            <div className="bg-gray-800 text-white">
                <h1
                    className="font-bold text-4xl pt-16  text-center "
                    id="mainFeatures"
                >
                    Main Features
                </h1>
                <p className="text-center pt-10 md:mx-4 sm:mx-4">
                    Gain Access to All the features you need to succeed in one
                    place..
                </p>
                <div className=" flex-row py-14 lg:flex md:hidden sm:hidden">
                    <div className="basis-1/3 mx-10 py-44 ">
                        <div className="mb-16 flex flex-col items-center text-center ">
                            <svg
                                className="w-8 h-8 text-white mb-4"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M96 0C43 0 0 43 0 96V416c0 53 43 96 96 96H384h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V384c17.7 0 32-14.3 32-32V32c0-17.7-14.3-32-32-32H384 96zm0 384H352v64H96c-17.7 0-32-14.3-32-32s14.3-32 32-32zm32-240c0-8.8 7.2-16 16-16H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16zm16 48H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
                            </svg>
                            <h1 className="font-bold text-2xl mb-2">
                                RESOURCES
                            </h1>

                            <p>
                                Visit, Relocate, and Embrace your new community
                                with ease, confidence, and armed with the right
                                information!
                            </p>
                        </div>
                        <div className="mb-10 flex flex-col items-center text-center">
                            <svg
                                className="w-10 h-10 text-white mb-4"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 640 512"
                            >
                                <path d="M160 64c0-35.3 28.7-64 64-64H576c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H336.8c-11.8-25.5-29.9-47.5-52.4-64H384V320c0-17.7 14.3-32 32-32h64c17.7 0 32 14.3 32 32v32h64V64L224 64v49.1C205.2 102.2 183.3 96 160 96V64zm0 64a96 96 0 1 1 0 192 96 96 0 1 1 0-192zM133.3 352h53.3C260.3 352 320 411.7 320 485.3c0 14.7-11.9 26.7-26.7 26.7H26.7C11.9 512 0 500.1 0 485.3C0 411.7 59.7 352 133.3 352z" />
                            </svg>
                            <h1 className="font-bold text-2xl mb-2">
                                {/*WONE ACADEMY*/}Learning
                            </h1>
                            <p>
                                Develop Soft skills and be ready to embrace your
                                best opportunities through WONE academy.
                            </p>
                        </div>
                    </div>
                    <div className="basis-1/3">
                        <img
                            style={{ height: "800px" }}
                            className="flex mx-2"
                            src="./images_v1/mobile-black.png"
                            alt=""
                        />
                    </div>
                    <div className="basis-1/3 mx-10 py-44">
                        <div className="mb-16 flex flex-col items-center text-center">
                            <svg
                                className="w-10 h-10 text-white mb-4"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 576 512"
                            >
                                <path d="M64 64C28.7 64 0 92.7 0 128v64c0 8.8 7.4 15.7 15.7 18.6C34.5 217.1 48 235 48 256s-13.5 38.9-32.3 45.4C7.4 304.3 0 311.2 0 320v64c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V320c0-8.8-7.4-15.7-15.7-18.6C541.5 294.9 528 277 528 256s13.5-38.9 32.3-45.4c8.3-2.9 15.7-9.8 15.7-18.6V128c0-35.3-28.7-64-64-64H64zm64 112l0 160c0 8.8 7.2 16 16 16H432c8.8 0 16-7.2 16-16V176c0-8.8-7.2-16-16-16H144c-8.8 0-16 7.2-16 16zM96 160c0-17.7 14.3-32 32-32H448c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H128c-17.7 0-32-14.3-32-32V160z" />
                            </svg>
                            <h1 className="font-bold text-2xl  mb-2">EVENTS</h1>

                            <p>
                                Share, post, and find out about events happening
                                near you.
                            </p>
                        </div>
                        <div className="mb-10 flex flex-col items-center text-center">
                            <svg
                                className="w-8 h-8 text-white mb-4"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                            >
                                <path d="M0 32C0 14.3 14.3 0 32 0H480c17.7 0 32 14.3 32 32s-14.3 32-32 32V448c17.7 0 32 14.3 32 32s-14.3 32-32 32H304V464c0-26.5-21.5-48-48-48s-48 21.5-48 48v48H32c-17.7 0-32-14.3-32-32s14.3-32 32-32V64C14.3 64 0 49.7 0 32zm96 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H112c-8.8 0-16 7.2-16 16zM240 96c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H240zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H368c-8.8 0-16 7.2-16 16zM112 192c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V208c0-8.8-7.2-16-16-16H112zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V208c0-8.8-7.2-16-16-16H240c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V208c0-8.8-7.2-16-16-16H368zM328 384c13.3 0 24.3-10.9 21-23.8c-10.6-41.5-48.2-72.2-93-72.2s-82.5 30.7-93 72.2c-3.3 12.8 7.8 23.8 21 23.8H328z" />
                            </svg>
                            <h1 className="font-bold text-2xl mb-2">{/*HOST*/}Connect</h1>
                            <p>
                                {/*Hospitality and Integration are made easier than
                                ever with Host. Connect with a family ready to
    give you a memorable experience.*/}
    Connect seamlessly with locals through immersion.
     Meet new people, build deeper connections, 
    and enjoy creating amazing experiences for yourself 
    and others.
                            </p>
                        </div>
                    </div>
                </div>

                {/* Mobile View */}
                <div className="mx-4 flex-col items-center mt-10 space-y-8  lg:hidden md:flex sm:flex">
                    <div className="space-y-8 ">
                        <div className="flex flex-col space-y-4 items-center text-center ">
                            <svg
                                className="w-8 h-8 text-white"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M96 0C43 0 0 43 0 96V416c0 53 43 96 96 96H384h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V384c17.7 0 32-14.3 32-32V32c0-17.7-14.3-32-32-32H384 96zm0 384H352v64H96c-17.7 0-32-14.3-32-32s14.3-32 32-32zm32-240c0-8.8 7.2-16 16-16H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16zm16 48H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
                            </svg>
                            <h1 className="font-bold text-2xl">RESOURCES</h1>

                            <p>
                                Visit, Relocate, and Embrace your new community
                                with ease, confidence, and armed with the right
                                information!
                            </p>
                        </div>
                        <div className="flex flex-col space-y-4 items-center text-center">
                            <svg
                                className="w-10 h-10 text-white"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 640 512"
                            >
                                <path d="M160 64c0-35.3 28.7-64 64-64H576c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H336.8c-11.8-25.5-29.9-47.5-52.4-64H384V320c0-17.7 14.3-32 32-32h64c17.7 0 32 14.3 32 32v32h64V64L224 64v49.1C205.2 102.2 183.3 96 160 96V64zm0 64a96 96 0 1 1 0 192 96 96 0 1 1 0-192zM133.3 352h53.3C260.3 352 320 411.7 320 485.3c0 14.7-11.9 26.7-26.7 26.7H26.7C11.9 512 0 500.1 0 485.3C0 411.7 59.7 352 133.3 352z" />
                            </svg>
                            <h1 className="font-bold text-2xl ">
                                {/*WONE ACADEMY*/}Learning
                            </h1>
                            <p>
                                Develop Soft skills and be ready to embrace your
                                best opportunities through WONE academy.
                            </p>
                        </div>
                    </div>
                    <div className="">
                        <img
                            style={{ width: "350px", height: "716px" }}
                            className="flex mb-10"
                            src="./images_v1/mobile-black.png"
                            alt=""
                        />
                    </div>
                    <div className="space-y-8 pb-12">
                        <div className="space-y-4 flex flex-col items-center text-center">
                            <svg
                                className="w-10 h-10 text-white "
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 576 512"
                            >
                                <path d="M64 64C28.7 64 0 92.7 0 128v64c0 8.8 7.4 15.7 15.7 18.6C34.5 217.1 48 235 48 256s-13.5 38.9-32.3 45.4C7.4 304.3 0 311.2 0 320v64c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V320c0-8.8-7.4-15.7-15.7-18.6C541.5 294.9 528 277 528 256s13.5-38.9 32.3-45.4c8.3-2.9 15.7-9.8 15.7-18.6V128c0-35.3-28.7-64-64-64H64zm64 112l0 160c0 8.8 7.2 16 16 16H432c8.8 0 16-7.2 16-16V176c0-8.8-7.2-16-16-16H144c-8.8 0-16 7.2-16 16zM96 160c0-17.7 14.3-32 32-32H448c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H128c-17.7 0-32-14.3-32-32V160z" />
                            </svg>
                            <h1 className="font-bold text-2xl">EVENTS</h1>

                            <p>
                                Share, post, and find out about events happening
                                near you.
                            </p>
                        </div>
                        <div className="space-y-4 flex flex-col items-center text-center">
                            <svg
                                className="w-8 h-8 text-white"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                            >
                                <path d="M0 32C0 14.3 14.3 0 32 0H480c17.7 0 32 14.3 32 32s-14.3 32-32 32V448c17.7 0 32 14.3 32 32s-14.3 32-32 32H304V464c0-26.5-21.5-48-48-48s-48 21.5-48 48v48H32c-17.7 0-32-14.3-32-32s14.3-32 32-32V64C14.3 64 0 49.7 0 32zm96 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H112c-8.8 0-16 7.2-16 16zM240 96c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H240zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H368c-8.8 0-16 7.2-16 16zM112 192c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V208c0-8.8-7.2-16-16-16H112zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V208c0-8.8-7.2-16-16-16H240c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V208c0-8.8-7.2-16-16-16H368zM328 384c13.3 0 24.3-10.9 21-23.8c-10.6-41.5-48.2-72.2-93-72.2s-82.5 30.7-93 72.2c-3.3 12.8 7.8 23.8 21 23.8H328z" />
                            </svg>
                            <h1 className="font-bold text-2xl ">{/*HOST*/}Connect</h1>
                            <p>
                                {/*Hospitality and Integration are made easier than
                                ever with Host. Connect with a family ready to
give you a memorable experience.*/}
Connect seamlessly with locals through immersion.
 Meet new people, build deeper connections, 
and enjoy creating amazing experiences for yourself and others.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
