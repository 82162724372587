import { Fragment, useEffect, useState } from "react";
import { showConfirmation, showSuccess } from "../../global/alertService";
import JobSeekerListService from "../../services/SeekerJob/jobSeekerList.service";
import { toLocalDate } from "../../utils/date-utils";
import { DownloadIcon } from "../Icons/downloadIcon";

export function SeekerSelectList() {
    const [seekers, setSeekers] = useState([]);
    const [seeker, setSeeker] = useState(null);
    const [cV, setCV] = useState(null);

    const [detailShowModal, setDetailShowModal] = useState(false);

    useEffect(() => {
        getJobSeekers();
    }, []);

    const getJobSeekers = () => {
        JobSeekerListService.getAll().then((response) => {
            // console.debug(response)
            setSeekers(response.data);
            //console.log(response.data)
        });
    };

    const onGet = (id) => {
        JobSeekerListService.get(id).then((response) => {
            setSeeker(response.data);
            //console.log(response.data)
            setCV(response.data?.fileUrl);
            //console.log(response.data?.fileUrl)
        });
    };

    const deleteSeeker = (id) => {
        showConfirmation().then((result) => {
            if (result.isConfirmed) {
                JobSeekerListService.delete(id).then((response) => {
                    // console.debug(response)
                    setSeekers();
                    showSuccess("Deleted successfully.");
                });
            } else {
                showSuccess("Not deleted", "Ok");
            }
        });
    };

    return (
        <Fragment>
            <h1 className="font-bold text-xl mb-6">Talent Selected List</h1>
            <div className="w-full ">
                <div className="bg-slate-50 shadow-md rounded-lg my-6 overflow-y-auto h-96">
                    <table className="min-w-max w-full table-auto ">
                        <thead className="sticky top-0">
                            <tr className="bg-fuchsia-800 text-white uppercase text-sm leading-normal">
                                <th className="py-3 px-6 text-left">Seeker</th>
                                <th className="py-3 px-6 text-left">Job</th>
                                <th className="py-3 px-6 text-left">Status</th>
                                <th className="py-3 px-6 text-center">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody className="text-gray-600 text-sm font-light">
                            {seekers.length > 0 ? (
                                seekers.map((item) => (
                                    <tr
                                        key={item.id}
                                        className="border-b border-gray-200 hover:bg-gray-100"
                                    >
                                        <td className="py-3 px-6 text-left whitespace-nowrap">
                                            <div className="flex items-center">
                                                <span className="font-medium">
                                                    {item.firstName}{" "}
                                                    {item.lastName}
                                                </span>
                                            </div>
                                        </td>
                                        <td className="py-3 px-6 text-left whitespace-nowrap">
                                            <div className="flex items-center">
                                                <span className="font-medium">
                                                    {item.jobSeekerPostTitle}
                                                </span>
                                            </div>
                                        </td>
                                        <td className="py-3 px-6 text-left whitespace-nowrap">
                                            <div className="flex items-center">
                                                <span className="font-medium">
                                                    {item.status}
                                                </span>
                                            </div>
                                        </td>
                                        <td className="py-3 px-6 text-center">
                                            <div className="flex item-center justify-center">
                                                <div
                                                    onClick={() => {
                                                        onGet(item?.id);
                                                        setDetailShowModal(
                                                            true
                                                        );
                                                    }}
                                                    className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="2"
                                                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                                        />
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="2"
                                                            d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                                        />
                                                    </svg>
                                                </div>
                                                <button
                                                    onClick={() =>
                                                        deleteSeeker(item.id)
                                                    }
                                                    className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="2"
                                                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                                        />
                                                    </svg>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td>No Data</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Details Modal */}
            {detailShowModal ? (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-full my-4 mx-auto max-w-3xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-3xl font-semibold">
                                        Seeker Details
                                    </h3>

                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() =>
                                            setDetailShowModal(false)
                                        }
                                    >
                                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            x
                                        </span>
                                    </button>
                                </div>

                                <div className="py-4 px-4 lg:px-8">
                                    {/* {getById?.map((item) => ( */}
                                    <div className="flex flex-col items-center md:flex-row ">
                                        {/*<img className="object-cover w-full h-100  md:h-auto md:w-60 ml-4 " src="" alt="" />*/}
                                        <div className="flex flex-col justify-between p-4 leading-normal">
                                            <div className="flex-1 min-w-0 ">
                                                <p className="text-sm font-bold text-gray-900 truncate">
                                                    Name:{" "}
                                                    <span className="text-sm text-gray-500 truncate">
                                                        {" "}
                                                        {seeker?.firstName}{" "}
                                                        {seeker?.lastName}
                                                    </span>
                                                </p>
                                                <p className="text-sm font-bold text-gray-900 truncate">
                                                    Email:{" "}
                                                    <span className="text-sm text-gray-500 truncate">
                                                        {seeker?.email}
                                                    </span>
                                                </p>
                                                {/*<p className="text-sm text-gray-900 truncate">
                                                    {seeker?.location} {seeker?.city} {seeker?.state} {seeker?.country}
                                                </p>*/}
                                                <p className="text-sm font-bold text-gray-900 truncate">
                                                    Contact No:{" "}
                                                    <span className="text-sm text-gray-500 truncate">
                                                        {seeker?.contactNo}
                                                    </span>
                                                </p>

                                                <p className="text-sm text-gray-900 truncate font-bold">
                                                    FaceBook:{" "}
                                                    <a
                                                        href={
                                                            seeker?.facebookUrl
                                                        }
                                                        className="inline-flex items-center font-medium text-fuchsia-600 hover:underline"
                                                    >
                                                        {seeker?.facebookUrl}
                                                    </a>
                                                </p>

                                                <p className="text-sm text-gray-900 truncate font-bold">
                                                    LinkedIn:{" "}
                                                    <a
                                                        href={
                                                            seeker?.linkedInUrl
                                                        }
                                                        className="inline-flex items-center font-medium text-fuchsia-600 hover:underline"
                                                    >
                                                        {seeker?.linkedInUrl}
                                                    </a>
                                                </p>

                                                <p className="text-sm text-gray-900 truncate font-bold">
                                                    Available From:{" "}
                                                    <span className="text-sm text-gray-500 truncate">
                                                        {toLocalDate(
                                                            seeker?.availableDate
                                                        )}{" "}
                                                        To {"  "}{" "}
                                                        {toLocalDate(
                                                            seeker?.expireDate
                                                        )}{" "}
                                                    </span>
                                                </p>
                                                <p className="text-sm font-bold text-gray-900 truncate">
                                                    Experience:{" "}
                                                    <span className="text-sm text-gray-500 truncate">
                                                        {seeker?.experience}
                                                    </span>
                                                </p>
                                                <p className="text-sm font-bold text-gray-900 truncate">
                                                    Skills:{" "}
                                                    <span className="text-sm text-gray-500 truncate">
                                                        {seeker?.skills}
                                                    </span>
                                                </p>
                                                <p className="text-sm font-bold text-gray-900 truncate">
                                                    Download CV:
                                                    {/*<button onClick={() => { 
                                                            window.open(seeker?.fileUrl)
                                                    }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 15 15" fill="none">
                                                            <path d="M0 4.5V0H1V4.5C1 5.32843 1.67157 6 2.5 6C3.32843 6 4 5.32843 4 4.5V1.5C4 1.22386 3.77614 1 3.5 1C3.22386 1 3 1.22386 3 1.5V5H2V1.5C2 0.671573 2.67157 0 3.5 0C4.32843 0 5 0.671573 5 1.5V4.5C5 5.88071 3.88071 7 2.5 7C1.11929 7 0 5.88071 0 4.5Z" fill="black" />
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M12.5 0H6V4.5C6 6.433 4.433 8 2.5 8H1V13.5C1 14.3284 1.67157 15 2.5 15H12.5C13.3284 15 14 14.3284 14 13.5V1.5C14 0.671573 13.3284 0 12.5 0ZM11 4H7V5H11V4ZM11 7H7V8H11V7ZM4 10H11V11H4V10Z" fill="black" />
                                                        </svg>
                                                </button>*/}
                                                    <a
                                                        //className=" rounded-full p-2"
                                                        href={cV}
                                                        download
                                                        rel="noreferrer"
                                                    >
                                                        <DownloadIcon />
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* ))} */}

                                    {/*</form>*/}
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end p-3 border-t border-solid border-slate-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() =>
                                            setDetailShowModal(false)
                                        }
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </Fragment>
    );
}
