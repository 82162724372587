import React, { Fragment } from "react";
import { CrossIcon } from "../Icons/crossIcon";

export function HostInfo(props) {

    return (
        <Fragment>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-full my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-3xl text-gray font-semibold">
                                Interested in Becoming a Connector !
                            </h3>

                            <button
                                className="absolute top-5 right-5 cursor-pointer"
                                onClick={props.onClose}
                            >
                                <CrossIcon />
                            </button>
                        </div>

                        {/*body*/}
                        <div className="py-6 px-6 lg:px-8">
                            <div
                                className="w-11/12 mx-auto mb-6"
                                style={{ maxHeight: "400px", overflowY: "auto" }}
                            >
                                <p style={{ fontSize: '1.25rem', fontWeight: 'semibold' }}>
                                    Welcome to the WONE Connect community! Connecting is not just about opening your home; it's about opening hearts and fostering a sense of belonging.
                                </p>
                                <p style={{ marginTop: '10px', fontSize: '1.25rem', color: 'blue' }}>
                                    Community Building :
                                </p>

                                <div style={{ margin: '0.5rem 0' }}>
                                    <p>
                                        Connecting on WONE is a free service that plays a crucial role in building an inclusive and diverse community. By becoming a connector, you contribute to making our community more welcoming and supportive.
                                    </p>
                                </div>
                                <p style={{ fontSize: '1.25rem', color: 'blue' }}>
                                    Connecting Role :
                                </p>

                                <div style={{ margin: '0.5rem 0' }}>
                                    <p>
                                        As a Connector, you are not merely providing space, you are offering support to newcomers as they adjust to their new environment. Your role is to assist them in settling in and integrating fully into the community.
                                    </p>
                                </div>
                                <p style={{ fontSize: '1.25rem', color: 'blue' }}
                                >
                                    Join Us :
                                </p>

                                <div style={{ margin: '0.5rem 0' }}>
                                    <p>
                                        If you're passionate about making a difference and want to contribute towards creating an all-inclusive community, we encourage you to join us as a connector.
                                    </p>
                                </div>
                                <p style={{ fontSize: '1.25rem' }}>
                                    To begin, simply enter your detailed information and start your journey as a WONE connector today. Remember, as a Connector, you're not just part of something great - you're helping create it.
                                </p>
                            </div>
                        </div>
                        {/*footer*/}
                        <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                            <button
                                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={props.onClose}
                            >
                                Close
                            </button>

                        </div>
                    </div>
                </div>
            </div>

            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </Fragment>
    );
}
