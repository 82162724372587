import {
    DirectionsRenderer,
    GoogleMap,
    InfoWindow,
    MarkerF,
} from "@react-google-maps/api";
import { Fragment, useEffect, useState } from "react";
import { mapService } from "../../services/Map/map.service";
import MapLeftIcon from "../Icons/mapLeftIcon";
import MapRightIcon from "../Icons/mapRightIcon";
import MapSearch from "../Icons/mapSearchIcon";
import PlaceDetail from "./PlaceDetail";

const containerStyle = {
    width: "100%",
    height: "88vh",
};

const yellowIcon = {
    url: "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png",
    scaledSize: { width: 40, height: 40 },
};

// main component of map
export function Mapcomponent() {
    const [center, setCenter] = useState({
        lat: 39.913518,
        lng: -75.27079599999999,
    });
    const [address, setAddress] = useState("");
    const [places, setPlaces] = useState(null);
    const [location, setLocation] = useState(null);
    const [nextPageToken, setNextPageToken] = useState("noPageToken");

    const [showInfo, setShowInfo] = useState("");
    const [directionsResponse, setDirectionsResponse] = useState(null);

    const [showSearch, setShowSearch] = useState(false);
    const [showMobileButton, setShowMobileButton] = useState(false);
    const [windowWidth, setWindowWidth] = useState(getWindowWidth());

    useEffect(() => {
        function handleWindowResize() {
            setWindowWidth(getWindowWidth());
        }

        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    useEffect(() => {
        if (windowWidth < 768) setShowMobileButton(true);
        else setShowMobileButton(false);
    }, [windowWidth]);

    function getWindowWidth() {
        const { innerWidth } = window;
        return innerWidth;
    }

    useEffect(() => {
        checkLocationAPIEnabled();
    }, []);

    // handle input change events
    const handleChange = (e) => {
        setAddress(e.target.value);
    };

    // handle form submit event
    const handleFormSubmit = (e) => {
        e.preventDefault();

        setLocation(null);
        getPlaces(address);
        setDirectionsResponse(null);
    };

    // handle mouseOver events on map-marker
    const handleMarkerMouseOver = (placeid) => {
        setShowInfo(placeid);
    };

    // handle mouseOut events in sidebar and map-marker
    const handleMarkerMouseOut = (e) => {
        setShowInfo("");
    };

    // get places from search results
    const getPlaces = async (search) => {
        await getPlacesFromAPI(search, center, "noPageToken");
    };

    // get more results from place search
    const handleMoreResult = async (e) => {
        await getPlacesFromAPI("noSearchQuery", center, nextPageToken);
    };

    // check if geolocation api enabled
    const checkLocationAPIEnabled = () => {
        navigator.permissions.query({ name: "geolocation" }).then((result) => {
            if (result.state === "granted") {
                navigator.geolocation.getCurrentPosition(function (position) {
                    setCenter({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    });
                });
            } else {
                alert(
                    "Location permission is not allowed. Map will show default location"
                );
            }
        });
    };

    // Update direction route
    const updateDirectionResponse = (response) => {
        setDirectionsResponse(response);
    };

    const getPlacesFromAPI = async (q, c, token) => {
        var response = await mapService.getPlaces(
            q,
            c.lat.toString(),
            c.lng.toString(),
            token
        );

        var result = response.data;
        if (result) {
            if (!result) {
                console.log("Error occured!");
            } else {
                if (result.next_page_token) {
                    setNextPageToken(result.next_page_token);
                } else {
                    setNextPageToken("");
                }
                setPlaces(result.results);
                setLocation(null);
                setDirectionsResponse(null);
            }
        } else {
            console.log("error");
        }
    };

    return (
        <Fragment>
            <div className="w-full flex flex-row flex-wrap pt-4 relative">
                <aside
                    className={`h-full md:block col-sm-4 md:w-1/3 sm:w-5/6 sm:mx-auto overflow-scroll ${
                        showSearch ? "block" : "hidden"
                    }`}
                >
                    <div className="top-0 p-4">
                        <form
                            className="my-2 flex flex-row flex-wrap relative"
                            onSubmit={handleFormSubmit}
                        >
                            <div className="w-full">
                                <input
                                    value={address}
                                    onChange={handleChange}
                                    placeholder="Search in map..."
                                    className="w-full p-4 text-md border rounded"
                                />
                            </div>
                            <div className="w-1/4 absolute right-2 top-2 flex justify-between items-center">
                                <button
                                    type="submit"
                                    className="w-full text-white bg-blue-700 text-lg rounded p-2"
                                >
                                    <MapSearch />
                                </button>
                            </div>
                        </form>

                        {places && (
                            <div>
                                {places.map((place) => {
                                    return (
                                        <div key={place.place_id}>
                                            <PlaceDetail
                                                place={place}
                                                center={center}
                                                updateDirectionResponse={
                                                    updateDirectionResponse
                                                }
                                                onIconClick={setShowSearch}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                        {nextPageToken !== "noPageToken" &&
                            nextPageToken !== "" && (
                                <div className="w-6/12 mt-2 mx-auto text-center">
                                    <button
                                        type="button"
                                        onClick={handleMoreResult}
                                        className="w-full text-white bg-blue-700 text-lg rounded p-2"
                                    >
                                        Get More result
                                    </button>
                                </div>
                            )}
                    </div>
                </aside>
                <main className="w-full md:w-2/3">
                    {showMobileButton && (
                        <p
                            className="absolute top-6 -left-2 bg-white text-black p-2 z-50 cursor-pointer border-2 border-gray-400 border-r-2 border-t-2 border-b-2 rounded-md"
                            onClick={() => {
                                setShowSearch((prev) => !prev);
                            }}
                        >
                            {showSearch ? <MapLeftIcon /> : <MapRightIcon />}
                        </p>
                    )}
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={15}
                    >
                        <MarkerF position={center} icon={yellowIcon} />
                        {directionsResponse && (
                            <DirectionsRenderer
                                directions={directionsResponse}
                            />
                        )}
                        {location && (
                            <MarkerF
                                position={{
                                    lat: location.lat,
                                    lng: location.lng,
                                }}
                            />
                        )}
                        {places &&
                            places.map((place) => {
                                return (
                                    <MarkerF
                                        onMouseOver={() =>
                                            handleMarkerMouseOver(
                                                place.place_id
                                            )
                                        }
                                        onMouseOut={handleMarkerMouseOut}
                                        //icon={hovered === place.place_id ? YellowIcon : ""}
                                        key={place.place_id}
                                        position={{
                                            lat: place.geometry.location.lat,
                                            lng: place.geometry.location.lng,
                                        }}
                                    >
                                        {showInfo === place.place_id && (
                                            <InfoWindow
                                                position={{
                                                    lat: place.geometry.location
                                                        .lat,
                                                    lng: place.geometry.location
                                                        .lng,
                                                }}
                                            >
                                                <h5>{place.name}</h5>
                                            </InfoWindow>
                                        )}
                                    </MarkerF>
                                );
                            })}
                    </GoogleMap>
                </main>
            </div>
        </Fragment>
    );
}
