import { Fragment } from "react";

export function Marketing() {
    return (
        <Fragment>
            <div className="flex-row  py-16 px-20 lg:flex md:hidden sm:hidden">
                <div>
                    <h1 className="text-3xl font-bold">
                        What are you waiting for? Join us today, it's completely
                        FREE!
                    </h1>
                </div>
                <a href="/signUp">
                    <div className="ml-16 inline-flex items-center whitespace-nowrap rounded-md border border-transparent bg-fuchsia-800 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-600 ">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            className="w-6 h-6 mr-2"
                            fill="white"
                        >
                            <path d="M135.652 0c23.625 0 43.826 20.65 43.826 44.8v99.851c17.048-16.34 49.766-18.346 70.944 6.299 22.829-14.288 53.017-2.147 62.315 16.45C361.878 158.426 384 189.346 384 240c0 2.746-.203 13.276-.195 16 .168 61.971-31.065 76.894-38.315 123.731C343.683 391.404 333.599 400 321.786 400H150.261l-.001-.002c-18.366-.011-35.889-10.607-43.845-28.464C93.421 342.648 57.377 276.122 29.092 264 10.897 256.203.008 242.616 0 224c-.014-34.222 35.098-57.752 66.908-44.119 8.359 3.583 16.67 8.312 24.918 14.153V44.8c0-23.45 20.543-44.8 43.826-44.8zM136 416h192c13.255 0 24 10.745 24 24v48c0 13.255-10.745 24-24 24H136c-13.255 0-24-10.745-24-24v-48c0-13.255 10.745-24 24-24zm168 28c-11.046 0-20 8.954-20 20s8.954 20 20 20 20-8.954 20-20-8.954-20-20-20z" />
                        </svg>
                        <span>Sign up NOW</span>
                    </div>
                </a>
            </div>

            {/* Mobile View */}
            <div className="flex-col items-center py-16 px-14 lg:hidden md:flex sm:flex">
                <div>
                    <h1 className="text-3xl font-bold text-center">
                        What are you waiting for? Join us today, it's completely
                        FREE!
                    </h1>
                </div>
                <a href="/signUp" className="pt-8">
                    <div className=" inline-flex items-center whitespace-nowrap rounded-md border border-transparent bg-fuchsia-800 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-600 ">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            className="w-6 h-6 mr-2"
                            fill="white"
                        >
                            <path d="M135.652 0c23.625 0 43.826 20.65 43.826 44.8v99.851c17.048-16.34 49.766-18.346 70.944 6.299 22.829-14.288 53.017-2.147 62.315 16.45C361.878 158.426 384 189.346 384 240c0 2.746-.203 13.276-.195 16 .168 61.971-31.065 76.894-38.315 123.731C343.683 391.404 333.599 400 321.786 400H150.261l-.001-.002c-18.366-.011-35.889-10.607-43.845-28.464C93.421 342.648 57.377 276.122 29.092 264 10.897 256.203.008 242.616 0 224c-.014-34.222 35.098-57.752 66.908-44.119 8.359 3.583 16.67 8.312 24.918 14.153V44.8c0-23.45 20.543-44.8 43.826-44.8zM136 416h192c13.255 0 24 10.745 24 24v48c0 13.255-10.745 24-24 24H136c-13.255 0-24-10.745-24-24v-48c0-13.255 10.745-24 24-24zm168 28c-11.046 0-20 8.954-20 20s8.954 20 20 20 20-8.954 20-20-8.954-20-20-20z" />
                        </svg>
                        <span>Sign up NOW</span>
                    </div>
                </a>
            </div>
        </Fragment>
    );
}
