import { Fragment, useEffect, useState } from "react";
import { subscriptionMembershipService } from "../../services/SubscriptionMembership/subscriptionMembership.service";
import { authService } from "../../services/Account/auth.service";
import { useSearchParams, useNavigate } from "react-router-dom";
import { subscriptionService } from "../../services/Account/subscription.service";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { SubscriptionUpgradeAndExtendPaypal } from "../Payment/subscriptionUpgradeAndExtendPaypal";
import { MembershipUpgradeContext } from "../Profile/upgradeAndExtendMembership";
import { CrossIcon } from "../Icons/crossIcon";
import { Elements } from "@stripe/react-stripe-js";
import { SubscriptionUpgradeAndExtendStripe } from "../Payment/subscriptionUpgradeAndExtendStripe";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_PUBLISHABLE_KEY } from "../../global/api-keys";
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

export function SignUpPaymentForEmployeer() {
    const mySwal = withReactContent(Swal);
    const navigate = useNavigate();

    const [subscriptions, setSubscriptions] = useState([]);
    const [searchParams] = useSearchParams();

    const [showStripeModal, setShowStripeModal] = useState(false);
    const [paypalOrderId, setPaypalOrderId] = useState("");
    const [stripeOrderId, setStripeOrderId] = useState("");

    const [isUpgraded, setIsUpgraded] = useState(false);
    const initialFormState = {
        membershipType: "",
        subscriptionCycle: "",
    };
    const [data, setData] = useState(initialFormState);
    const [memberships, setMemberships] = useState([]);

    const [subscriptionCycle, setSubscriptionCycle] = useState(0);
    const [price, setPrice] = useState(0);

    // calling the service while isUpgraded is true
    useEffect(() => {
        if (isUpgraded === true) {
            createPaidMember();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUpgraded]);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const emailConfirmationToken = params.get("emailConfirmationToken");
        if (emailConfirmationToken) {
            const email = params.get("email");
            authService.confirmEmail(email, emailConfirmationToken);
        }
    }, [searchParams]);

    useEffect(() => {
        getSubscriptions();
    }, []);
    const getSubscriptions = () => {
        subscriptionMembershipService.getForEmployeer().then((response) => {
            setSubscriptions(response.data);
        });
    };
    const createMemberFreeTrial = () => {
        const params = new URLSearchParams(window.location.search);
        const emailConfirmationToken = params.get("emailConfirmationToken");
        if (emailConfirmationToken) {
            subscriptionService
                .create({
                    userId: params.get("userId"),
                    paypalOrderId: " ",
                    stripeOrderId: " ",
                    subscriptionCycle: 0,
                    membershipType: 3,
                    isFreeTrial: true,
                })
                .then((res) => {
                    navigate("/signIn");
                    window.location.reload();
                });
        }
    };
    const createPaidMember = () => {
        const params = new URLSearchParams(window.location.search);
        const emailConfirmationToken = params.get("emailConfirmationToken");
        if (emailConfirmationToken) {
            subscriptionService
                .create({
                    userId: params.get("userId"),
                    paypalOrderId: paypalOrderId,
                    stripeOrderId: stripeOrderId,
                    subscriptionCycle: subscriptionCycle,
                    membershipType: 3,
                    isFreeTrial: false,
                })
                .then((res) => {
                    mySwal
                        .fire({
                            title: <strong>Success</strong>,
                            html: <i>{res.data}</i>,
                            icon: "success",
                        })
                        .then(() => {
                            setShowStripeModal(false);
                            navigate("/signIn");
                            //window.location.reload();
                        });
                });
        }
    };
    const stripeModal = (
        <div className="fixed inset-0 z-50 overflow-y-auto">
            <div
                className="fixed inset-0 w-full h-full bg-black opacity-40"
                onClick={() => setShowStripeModal(false)}
            ></div>
            <div className="flex items-center min-h-screen px-4 py-8">
                <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                    <div
                        onClick={() => setShowStripeModal(false)}
                        className="absolute top-4 right-6 cursor-pointer"
                    >
                        <CrossIcon />
                    </div>
                    <div className="mt-4 text-center sm:text-left border-dashed">
                        <Elements stripe={stripePromise}>
                            <SubscriptionUpgradeAndExtendStripe
                                price={price}
                                type={3}
                                setShowStripeModal={setShowStripeModal}
                            />
                        </Elements>
                    </div>
                </div>
            </div>
        </div>
    );
    return (
        <Fragment>
            <div className="lg:ml-24 lg:mr-24 mb-10 md:mx-6 sm:mx-6">
                <h1 className=" text-3xl text-center mt-16 font-bold text-gray-500 ">
                    Employer Subscription
                </h1>
                <div className="grid-cols-3  gap-4 mt-10  lg:grid md:hidden sm:hidden">
                    <div className="w-full max-w-sm p-4 bg-white border border-gray-300 rounded-lg shadow-lg ">
                        <div className="flex flex-col items-center px-6 pb-10">
                            <h5 className="mb-2 text-xl font-medium text-gray-500 ">
                                Free Trial
                            </h5>
                            <p>For 60 days.</p>
                            <div className="flex items-baseline text-gray-900 ">
                                <span className="text-3xl font-semibold">
                                    $
                                </span>
                                <span className="text-5xl font-extrabold tracking-tight">
                                    0
                                </span>
                            </div>
                            <button
                                type="button"
                                onClick={createMemberFreeTrial}
                                className="text-white bg-fuchsia-700 mt-4 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-200 font-medium rounded-lg text-lg px-5 py-2.5 inline-flex justify-center w-full text-center"
                            >
                                Let's Start
                            </button>
                        </div>
                    </div>
                    <MembershipUpgradeContext.Provider
                        value={{
                            setPaypalOrderId,
                            setStripeOrderId,
                            setIsUpgraded,
                        }}
                    >
                        {subscriptions.map((items) => (
                            <div className="w-full max-w-sm p-4 bg-white border border-gray-300 rounded-lg shadow-lg ">
                                <div
                                    key={items.id}
                                    className="flex flex-col items-center px-6 pb-10"
                                >
                                    <h5 className="mb-4 text-xl font-medium text-gray-500 ">
                                        {items.subscriptionCycle === 1
                                            ? "Monthly"
                                            : items.subscriptionCycle === 3
                                            ? "Quarter"
                                            : items.subscriptionCycle === 6
                                            ? "Semi Annually"
                                            : "Annually"}
                                    </h5>
                                    <div className="flex items-baseline text-gray-900 mb-10">
                                        <span className="text-3xl font-semibold">
                                            $
                                        </span>
                                        <span className="text-5xl font-extrabold tracking-tight">
                                            {items.amount}
                                        </span>
                                    </div>
                                    {/* <button
                                    type="button"
                                    className="text-white mt-4 bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-200  font-medium rounded-lg text-sm px-5 py-2.5 inline-flex justify-center w-full text-center"
                                >
                                    Choose plan
                                </button> */}
                                    {items.amount > 0 ? (
                                        <div className="flex flex-wrap">
                                            <div
                                                className="w-full"
                                                style={{
                                                    zIndex: showStripeModal
                                                        ? 0
                                                        : 1,
                                                }}
                                            >
                                                <SubscriptionUpgradeAndExtendPaypal
                                                    price={items.amount}
                                                />
                                            </div>
                                            <div className="w-full">
                                                <button
                                                    type="button"
                                                    className="focus:outline-none text-black font-semibold rounded px-5 py-2.5 mr-2 mb-2 h-12 w-full text-lg stripe-card-pay-button text-center"
                                                    onClick={() => {
                                                        setPrice(items.amount);
                                                        setSubscriptionCycle(
                                                            items.subscriptionCycle
                                                        );
                                                        setShowStripeModal(
                                                            true
                                                        );
                                                    }}
                                                >
                                                    Credit / Debit Card
                                                </button>
                                            </div>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                        ))}
                        {showStripeModal && stripeModal}
                    </MembershipUpgradeContext.Provider>
                </div>

                {/* Mobile View */}
                <div className="flex-col mt-10 space-y-8 items-center lg:hidden md:flex sm:flex">
                    <div className="w-full max-w-lg p-4 bg-white border border-gray-300 rounded-lg shadow-lg ">
                        <div className="flex flex-col items-center px-6 pb-10">
                            <h5 className="mb-4 text-xl font-medium text-gray-500 ">
                                Free
                            </h5>
                            <p>For 60 days.</p>
                            <div className="flex items-baseline text-gray-900">
                                <span className="text-3xl font-semibold">
                                    $
                                </span>
                                <span className="text-5xl font-extrabold tracking-tight">
                                    0
                                </span>
                            </div>
                            <button
                                type="button"
                                onClick={createMemberFreeTrial}
                                className="text-white bg-fuchsia-700 mt-4 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-200  font-medium rounded-lg text-lg px-5 py-2.5 inline-flex justify-center w-full text-center"
                            >
                                Let's Start
                            </button>
                        </div>
                    </div>
                    <MembershipUpgradeContext.Provider
                        value={{
                            setPaypalOrderId,
                            setStripeOrderId,
                            setIsUpgraded,
                        }}
                    >
                        {subscriptions.map((items) => (
                            <div className="w-full max-w-lg p-4 bg-white border border-gray-300 rounded-lg shadow-lg ">
                                <div
                                    key={items.id}
                                    className="flex flex-col items-center px-6 pb-10"
                                >
                                    <h5 className="mb-4 text-xl font-medium text-gray-500 ">
                                        {items.subscriptionCycle === 1
                                            ? "Monthly"
                                            : items.subscriptionCycle === 3
                                            ? "Quarterly"
                                            : items.subscriptionCycle === 6
                                            ? "Semi Annually"
                                            : "Annually"}
                                    </h5>
                                    <div className="flex items-baseline text-gray-900 ">
                                        <span className="text-3xl font-semibold">
                                            $
                                        </span>
                                        <span className="text-5xl mb-10 font-extrabold tracking-tight">
                                            {items.amount}
                                        </span>
                                    </div>
                                    {items.amount > 0 ? (
                                        <div className="flex flex-wrap">
                                            <div
                                                className="w-full"
                                                style={{
                                                    zIndex: showStripeModal
                                                        ? 0
                                                        : 1,
                                                }}
                                            >
                                                <SubscriptionUpgradeAndExtendPaypal
                                                    price={items.amount}
                                                />
                                            </div>
                                            <div className="w-full">
                                                <button
                                                    type="button"
                                                    className="focus:outline-none text-black font-semibold rounded px-5 py-2.5 mr-2 mb-2 h-12 w-full text-lg stripe-card-pay-button text-center"
                                                    onClick={() => {
                                                        setPrice(items.amount);
                                                        setSubscriptionCycle(
                                                            items.subscriptionCycle
                                                        );
                                                        setShowStripeModal(
                                                            true
                                                        );
                                                    }}
                                                >
                                                    Credit / Debit Card
                                                </button>
                                            </div>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                        ))}
                        {showStripeModal && stripeModal}
                    </MembershipUpgradeContext.Provider>
                </div>
            </div>
        </Fragment>
    );
}
