import React, { useEffect, useState } from "react";
import Paginate from "../../components/Pagination/paginate";
import VideoItem from "../../components/ResourceBlog/videoItem";
import { cityService } from "../../services/City/cities.service";
import { countryService } from "../../services/Country/countries.service";
import { resourceCategoriesService } from "../../services/Resource/resourceCategory.service";
import { resourceBlogService } from "../../services/ResourceBlog/resourceBlog.service";
import { stateService } from "../../services/State/states.service";

export default function BlogFiles() {
    const [blogs, setBlogs] = useState([]);
    const [categories, setCategories] = useState([]);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    const [categoryName, setCategoryName] = useState("All videos");
    const [categoryId, setCategoryId] = useState(0);
    const [countryId, setCountryId] = useState(0);
    const [stateId, setStateId] = useState(0);
    const [cityId, setCityId] = useState(0);

    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(12);
    const [totalItems, setTotalItems] = useState(0);
    const [currentItemsCount, setCurrentItemsCount] = useState(0);
    const [search, setSearch] = useState(null);

    const pageSizes = [12, 24, 48, 96];
    const resourceType = 2;

    useEffect(() => {
        getCategorires();
        getCountries();
    }, []);

    useEffect(() => {
        resourceBlogService
            .getAll(
                resourceType,
                categoryId,
                pageSize,
                currentPageNumber,
                countryId,
                stateId,
                cityId,
                search
            )
            .then((response) => {
                setBlogs(response.data.filteredData);
                setCurrentItemsCount(response.data.currentItemCount);
                setTotalItems(response.data.totalCount);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [
        search,
        resourceType,
        categoryId,
        pageSize,
        currentPageNumber,
        countryId,
        stateId,
        cityId,
    ]);

    const getCategorires = () => {
        resourceCategoriesService.getAll().then((response) => {
            //console.debug(response.data);
            setCategories(response.data);
        });
    };
    const getCountries = () => {
        countryService.getAll().then((response) => {
            setCountries(response.data);
        });
    };

    const getStates = (countryId) => {
        stateService.getByCountry(countryId).then((res) => {
            setStates(res.data);
        });
    };

    const getCities = (stateId) => {
        cityService.getByState(stateId).then((res) => {
            setCities(res.data);
        });
    };

    const changeCategoryIdAndName = (id) => {
        setCategoryId(id);
        setCurrentPageNumber(1);
        categories.forEach((item) => {
            if (item.id === id) setCategoryName(item.name);
        });
        if (id === 0) setCategoryName("All Videos");
    };

    return (
        <div className="mt-4 pt-24 mx-8">
            <div className="md:h-auto sm:h-72 sm:overflow-scroll p-2">
                <div className="grid gap-3 md:grid-cols-4 sm:grid-cols-2 mb-6">
                    <div>
                        <select
                            name="countryId"
                            onChange={(e) => {
                                setStates([]);
                                setCities([]);
                                getStates(e.target.value);
                                setStateId(0);
                                setCityId(0);
                                setCountryId(e.target.value);
                            }}
                            className="bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg block w-full p-2.5"
                        >
                            <option value={0}>Select Country</option>
                            {countries?.map((countries) => (
                                <option key={countries.id} value={countries.id}>
                                    {countries.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <select
                            name="stateId"
                            onChange={(e) => {
                                setCities([]);
                                getCities(e.target.value);
                                setCityId(0);
                                setStateId(e.target.value);
                            }}
                            className="bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg block w-full p-2.5"
                        >
                            <option value={0}>Select State</option>
                            {states?.map((state) => (
                                <option key={state.id} value={state.id}>
                                    {state.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <select
                            name="cityId"
                            onChange={(e) => {
                                setCityId(e.target.value);
                            }}
                            className="bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg block w-full p-2.5"
                        >
                            <option value={0}>Select City</option>
                            {cities?.map((city) => (
                                <option key={city.id} value={city.id}>
                                    {city.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="">
                        <input
                            type="text"
                            value={search}
                            onChange={(e) => {
                                setSearch(e.target.value);
                                setCurrentPageNumber(1);
                            }}
                            className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 float-right"
                            placeholder="Search articles"
                        />
                    </div>
                </div>
                <div className="grid lg:gap-4 md:gap-3 sm:gap-2 lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-3">
                    <div
                        onClick={() => {
                            changeCategoryIdAndName(0);
                            setCurrentPageNumber(1);
                        }}
                        className="md:mb-4 sm:mb-2 cursor-pointer rounded shadow"
                    >
                        {categoryId === 0 ? (
                            <div className="flex items-center justify-center md:h-24 sm:h-16 bg-fuchsia-800 rounded-t text-white md:text-xl sm:text-base font-bold">
                                All Videos
                            </div>
                        ) : (
                            <div className="flex items-center justify-center md:h-24 sm:h-16 bg-gray-800 rounded-t text-white md:text-xl sm:text-base font-bold">
                                All Videos
                            </div>
                        )}

                        {categoryId === 0 ? (
                            <div className="h-1 bg-fuchsia-800 rounded-b"></div>
                        ) : (
                            <div className="h-1 bg-gray-800 rounded-b"></div>
                        )}
                    </div>
                    {categories?.map((item) => {
                        return (
                            <div
                                key={item.id}
                                onClick={() => changeCategoryIdAndName(item.id)}
                                className="cursor-pointer md:mb-4 sm:mb-2 rounded"
                            >
                                <div
                                    className="md:h-24 sm:h-16 bg-cover rounded-t bg-center bg-no-repeat flex shadow justify-start items-end"
                                    style={{
                                        backgroundImage: `${
                                            categoryId === item.id
                                                ? "linear-gradient(to bottom, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75))"
                                                : "linear-gradient(to bottom, rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60))"
                                        }, url('${item.imgUrl}')`,
                                    }}
                                >
                                    <h1 className="text-white text-left ml-2 mb-1 md:text-sm sm:text-xs">
                                        {item.name}
                                    </h1>
                                </div>
                                <div
                                    className="h-1 rounded-b"
                                    style={{
                                        backgroundColor: item.colorCode,
                                    }}
                                ></div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="mt-4 mb-12">
                <div className="grid gpa-4 grid-cols-3">
                    <h3 className="col-span-2 mb-6 text-xl font-bold text-left">
                        {categoryId === null ? "All videos" : categoryName}
                    </h3>
                    <div className="flex justify-end items-center mb-6 text-xl font-bold text-right">
                        Size:
                        <select
                            className="ml-4 bg-gray-50 border border-gray-300 text-gray-700 text-base rounded block w-16 px-2 py-1"
                            onChange={(e) => {
                                setPageSize(e.target.value);
                                setCurrentPageNumber(1);
                            }}
                        >
                            {pageSizes.map((size) => {
                                return (
                                    <option key={size} value={size}>
                                        {size}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>
                <div className="grid gap-4 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-1">
                    {blogs?.map((item) => {
                        return (
                            <VideoItem
                                key={item.id}
                                src={item.fileUrl}
                                title={item.name}
                            />
                        );
                    })}
                </div>
                <div className="mb-12 mt-4">
                    <Paginate
                        totalItems={totalItems}
                        currentItemsCount={currentItemsCount}
                        currentPageNumber={currentPageNumber}
                        pageSize={pageSize}
                        setCurrentPageNumber={setCurrentPageNumber}
                    />
                </div>
            </div>
        </div>
    );
}
