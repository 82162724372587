import Ads from "../../components/Ads/ads"
import TrainingDetailsCard from "../../components/Trining/trainingDetailsCard"
import React from 'react'
import axios from "axios"
import { useEffect } from "react"
import TrainingContentService from '../../services/Training/trainingcontents.eervice'
import TrainingDataService from '../../services/Training/training.service'
import { useParams } from "react-router";
import TrainingContent from '../../components/Trining/trainingContent'
import ProfileCard from '../../components/Profile/profileCard'

const TrainingDetails = () => {
    const [data, setData] = React.useState(null);
    const [training, setTraining] = React.useState({
        id: null,
        CourseName: null,
        instructorId: null,
        description: null,
        price: null,
        ImgUrl: null,
        FirstName: "",
        LastName:""
    });
    const [trainingContentId, setTrainingContentId] = React.useState(null);
    const { id } = useParams()

    useEffect(() => {
        if (id > 0) {
            getTrainings(id)
            getTainingContents(id)
        }

    }, []);

    const getTainingContents = (id) => {
        TrainingContentService.get("?trainingId=" + id)
            .then((response) => {
                //console.debug(response);
                setData(response.data);
                //console.log(response.data);
            });
    }

    const getTrainings = (id) => {
        TrainingDataService.get(id)
            .then((response) => {
                //console.debug(response);
                setTraining(response.data);
                //console.log(response.data);
            });
    }
    //----


    return (
        <>
                <div className="flex flex-col mt-20 pt-4">

                    <TrainingContent training={training} />
                    
                </div>
        </>
    )
}
export default TrainingDetails