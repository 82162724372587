import { Fragment, useEffect, useState } from "react";
import TrainingContentDetService from "../../services/Training/trainingContentDet.service";
import { FileDownloader } from "../fileViewer/fileDownloader";
import { VideoPlayer } from "../Video/videoPlayer";

export function TrainingContentDetModal(props) {
    const [fileType, setFileType] = useState(null);
    const [trainingContentDetData, setTrainingContentDetData] = useState(null);

    useEffect(() => {
        if (props.trainingContentDetId > 0) {
            getTrainingContentDet(props.trainingContentDetId);
        }
    }, [props]);

    const getTrainingContentDet = (id) => {
        //console.debug(id);
        TrainingContentDetService.get(id).then((res) => {
            setTrainingContentDetData(res.data);
            setFileType(res.data.detailUrl.split(".").pop());
            //console.log(res.data.detailUrl.split('.').pop())
        });
    };

    return (
        <Fragment>
            <form>
                <div className=" justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-full my-6 mx-auto max-w-3xl">
                        {/*content*/}
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-black outline-none focus:outline-none">
                            {/*header*/}
                            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                <h3 className="text-white text-3xl font-semibold">
                                    {trainingContentDetData?.training}
                                </h3>

                                <button
                                    className="p-1 ml-auto bg-transparent border-0 text-red opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={props?.onClose}
                                >
                                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                        x
                                    </span>
                                </button>
                            </div>

                            {/*body*/}
                            <div className="py-3 px-6 lg:px-8">
                                <div className="items-center grid gap-6 mb-6 md:grid-cols-1">
                                    <div>
                                        {fileType === "mp4" ? (
                                            <VideoPlayer src={props.src} />
                                        ) : (
                                            <div
                                                style={{
                                                    color: "#ffffff",
                                                }}
                                            >
                                                <FileDownloader
                                                    source={props.src}
                                                    height={"h-32"}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className=" flex justify-between items-center">
                                        <span className="text-xl font-semibold text-white">
                                            {
                                                trainingContentDetData?.trainingChapter
                                            }
                                            : {trainingContentDetData?.name}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {/*footer*/}
                            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                <button
                                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={props?.onClose}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </Fragment>
    );
}
