import React, { Fragment } from "react";
import { CrossIcon } from "../Icons/crossIcon";

export function AboutInstructor(props) {

    return (
        <Fragment>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-full my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-3xl text-gray font-semibold">
                                WONE Instructors Terms and Conditions
                            </h3>

                            <button
                                className="absolute top-5 right-5 cursor-pointer"
                                onClick={props.onClose}
                            >
                                <CrossIcon />
                            </button>
                        </div>

                        {/*body*/}
                        <div className="py-6 px-6 lg:px-8">
                            <div
                                className="w-11/12 mx-auto mb-6"
                                style={{ maxHeight: "400px", overflowY: "auto" }}
                            >
                                <p style={{ fontSize: '1.25rem', fontWeight: 'semibold' }}>
                                    Welcome to the WONE instructors’ community. By choosing to promote your course on our
                                    platform, you agree to the following terms and conditions:
                                </p>
                                <p style={{ marginTop: '10px', fontSize: '1.25rem', color: 'blue' }}>
                                    Course Responsibility :
                                </p>

                                <div style={{ margin: '0.5rem 0' }}>
                                    <p>
                                        As an instructor, you are fully responsible for the content of any
                                        course that you create. WONE serves as a platform to connect you with interested
                                        learners but does not have control over or responsibility for your course content.

                                    </p>
                                </div>
                                <p style={{ fontSize: '1.25rem', color: 'blue' }}>
                                    Premium Users :
                                </p>

                                <div style={{ margin: '0.5rem 0' }}>
                                    <p>
                                        Only premium users are allowed to promote their courses on WONE.
                                        This promotion allows you to share your niche and expertise with a global audience and
                                        provides a potential source of passive income.
                                    </p>
                                </div>
                                <p style={{ fontSize: '1.25rem', color: 'blue' }}
                                >
                                    Course Promotion :
                                </p>

                                <div style={{ margin: '0.5rem 0' }}>
                                    <p>
                                        WONE will feature courses on the platform. Instructors are welcome
                                        to boost their course visibility for an additional cost.
                                    </p>
                                </div>
                                <p style={{ fontSize: '1.25rem', color: 'blue' }}
                                >
                                    Revenue Sharing :
                                </p>

                                <div style={{ margin: '0.5rem 0' }}>
                                    <p>
                                        Instructors retain 80% of the proceeds from each course sold. WONE
                                        retains 20% to cover processing fees, basic advertising, and promotion of the course.
                                    </p>
                                </div>
                                <p style={{ fontSize: '1.25rem', color: 'blue' }}
                                >
                                    Subscription Downgrade :
                                </p>

                                <div style={{ margin: '0.5rem 0' }}>
                                    <p>
                                        If you decide to downgrade from a premium subscription,
                                        you are responsible for removing all your course content from the platform. Once your
                                        subscription has been downgraded, you will no longer be able to offer your courses on
                                        WONE, and users will not be able to sign up for them.
                                    </p>
                                </div>
                                <p style={{ fontSize: '1.25rem' }}>
                                    By promoting your course on WONE, you confirm that you understand and agree to these terms
                                    and conditions. Please note: WONE reserves the right to change these terms and conditions at
                                    any time without notice. It is your responsibility to regularly check for any changes.
                                </p>
                            </div>
                        </div>
                        {/*footer*/}
                        <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                            <button
                                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={props.onClose}
                            >
                                Close
                            </button>

                        </div>
                    </div>
                </div>
            </div>

            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </Fragment>
    );
}
