import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import StarRatings from "react-star-ratings";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { showConfirmation, showSuccess } from "../../global/alertService";
import RegisteredTrainingService from "../../services/Training/registeredtraining.service";
import TrainingDataService from "../../services/Training/training.service";
import { RegisteredTrainingModal } from "./registeredTrainingModal";
import { RegisterInfo } from "./registerInfoModal";

export function SingleTraining({ training, updateTrainings }) {
    const mySwal = withReactContent(Swal);

    const trainingId = training.id;
    const currentUserEmail = localStorage.getItem("email");
    const currentUserType = localStorage.getItem("userType");
    const [checkRegistered, setCheckRegistered] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [showInfoModal, setShowInfoModal] = useState(false);

    // calculating ratings
    const trainingRating =
        training.totalRatingUsers == 0
            ? 0
            : (Math.round(training.rating) / training.totalRatingUsers).toFixed(
                1
            );

    const initialRating =
        training.totalRatingUsers == 0
            ? 0
            : Number.isNaN(training.rating / training.totalRatingUsers)
                ? 0
                : training.rating / training.totalRatingUsers;

    useEffect(() => {
        if (training.id > 0) {
            getRegistered(training.id);
        }
    }, []);

    const deleteTraining = (id) => {
        showConfirmation().then((result) => {
            if (result.isConfirmed) {
                TrainingDataService.delete(id).then((response) => {
                    mySwal
                        .fire({
                            title: <strong>Success</strong>,
                            html: <i>Deleted successfully</i>,
                            icon: "success",
                        })
                        .then(() => {
                            updateTrainings(id);
                        });
                });
            } else {
                showSuccess("Not deleted", "Ok");
            }
        });
    };

    const getRegistered = (trainingId) => {
        RegisteredTrainingService.getRegisteredUser(trainingId).then((res) => {
            setCheckRegistered(res.data);
        });
    };

    const unRegisterTraining = (trainingId) => {
        //console.log("trainingId:",trainingId);
        showConfirmation().then((result) => {
            if (result.isConfirmed) {
                RegisteredTrainingService.unRegisterTraining(trainingId,
                    currentUserEmail).then((response) => {
                        mySwal
                            .fire({
                                title: <strong>Success</strong>,
                                html: <i>Unregister successfully</i>,
                                icon: "success",
                            })
                            .then(() => {
                                updateTrainings(trainingId);
                            });
                    });
            } else {
                showSuccess("Not deleted", "Ok");
            }
        });
    };

    return (
        <Fragment>
            <div className="w-full bg-gray-50 shadow-md">
                <Link to={{ pathname: `/trainingDetails/${training.id}` }}>
                    <div
                        className="w-full h-52"
                        style={{
                            backgroundImage: `url("${training?.imgUrl}")`,
                            backgroundColor: "#cccccc",
                            backgroundPosition: "top top",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                        }}
                    ></div>
                </Link>
                <div className="h-2 ">
                    {currentUserEmail === training?.email ? (
                        <Menu
                            as="div"
                            className="flex items-center justify-end mt-2 mr-4 rounded-b"
                        >
                            <div>
                                <Menu.Button aria-hidden="true">
                                    <svg
                                        className="w-4 h-4"
                                        aria-hidden="true"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"></path>
                                    </svg>
                                </Menu.Button>
                            </div>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="absolute z-10 mt-24 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="py-1">
                                        <Menu.Item>
                                            {({ active }) => (
                                                <Link
                                                    to={`/trainingEditList/${training?.id}`}
                                                    className="px-4 py-2 text-sm w-full flex items-center space-x-3"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth={1.5}
                                                        stroke="currentColor"
                                                        className="w-6 h-6"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                                        />
                                                    </svg>
                                                    <span>Edit</span>
                                                </Link>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <button
                                                    onClick={() => {
                                                        deleteTraining(
                                                            training?.id
                                                        );
                                                    }}
                                                    className="px-4 py-2 text-sm w-full flex items-center space-x-3"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth={1.5}
                                                        stroke="currentColor"
                                                        className="w-6 h-6"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                                        />
                                                    </svg>
                                                    <span>Delete</span>
                                                </button>
                                            )}
                                        </Menu.Item>
                                    </div>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    ) : null}
                </div>
                <div className="px-5 pb-5">
                    <Link to={`/trainingDetails/${training.id}`}>
                        <h5 className="text-xl font-semibold tracking-tight mt-2 text-gray-900">
                            {training.courseName}
                        </h5>
                        <p>
                            <b>Instructor:</b> {training?.firstName + " "}
                            {training?.lastName}
                        </p>
                        <p>{training.totalEnrolled} People are Registered.</p>
                    </Link>
                    <div className="flex items-center mt-2.5 mb-5">
                        {/* <svg
                            aria-hidden="true"
                            className="w-5 h-5 text-yellow-300"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <title>First star</title>
                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                        </svg>
                        */}

                        <div
                            style={{
                                marginTop: "-2px",
                            }}
                        >
                            <StarRatings
                                rating={initialRating}
                                starDimension="18px"
                                starSpacing="0px"
                                starRatedColor="#FDE047"
                            />
                        </div>
                        <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded ml-2">
                            {trainingRating} ({training.totalRatingUsers + " "}
                            ratings)
                        </span>
                    </div>
                    <p className="text-xl font-bold">Description</p>
                    <p className="my-3 font-normal text-gray-700 only-4-lines">
                        {training.description}
                    </p>
                    <div className="flex justify-between items-center">
                        <span className="text-3xl font-bold text-gray-900">
                            {training?.price > 0 ? (
                                "$" + training?.price
                            ) : (
                                <span>Free</span>
                            )}
                        </span>
                        {/*---*/}
                        {checkRegistered > 0 ?
                            (
                                <button
                                    onClick={() => {
                                        unRegisterTraining(
                                            training?.id
                                        );
                                    }}
                                    //className="px-4 py-2 text-sm w-full flex items-center space-x-3"
                                >
                                    <span
                                        className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white rounded-lg bg-fuchsia-800 rounded-lg focus:ring-4 focus:ring-blue-200 hover:bg-green-800"
                                    >UnRegister</span>
                                </button>
                            ) : (
                                <div>
                                    <a
                                        onClick={() => {
                                            setShowInfoModal(true);
                                        }}
                                        href="#"
                                        className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white rounded-lg bg-fuchsia-800 rounded-lg focus:ring-4 focus:ring-blue-200 hover:bg-green-800"
                                    >
                                        Reg. Info
                                    </a>
                                </div>)
                        }
                        {showInfoModal ? (
                            <RegisterInfo
                                onClose={() => {
                                    setShowInfoModal(false);
                                }}
                            />
                        ) : null}
                        {/*--*/}
                        {currentUserEmail === training?.email ? (
                            <></>
                        ) : training.price > 0 ? (
                            checkRegistered > 0 ? (
                                <Link
                                    to={`/courseContentVideo/${trainingId}`}
                                    className="text-white bg-fuchsia-800  hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                >
                                    Continue
                                </Link>
                            ) : currentUserType === "Standard" ? (
                                <Link
                                    to={{
                                        pathname: `/enrollTraining/${training.id}`,
                                    }}
                                    className="text-white bg-fuchsia-800  hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                >
                                    Buy
                                </Link>
                            ) : (
                                <div
                                    onClick={() => {
                                        //setTrainingId(item.id);
                                        setShowModal(true);
                                    }}
                                    className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white rounded-lg bg-fuchsia-800 focus:ring-4 focus:ring-blue-200 hover:bg-green-800 cursor-pointer"
                                >
                                    Register
                                </div>
                            ) //}
                        ) : checkRegistered > 0 ? (
                            <Link
                                to={`/courseContentVideo/${trainingId}`}
                                className="text-white bg-fuchsia-800  hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                            >
                                Continue
                            </Link>
                        ) : (
                            <div
                                onClick={() => {
                                    //setTrainingId(item.id);
                                    setShowModal(true);
                                }}
                                className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white rounded-lg bg-fuchsia-800 focus:ring-4 focus:ring-blue-200 hover:bg-green-800 cursor-pointer"
                            >
                                Register
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {showModal ? (
                <RegisteredTrainingModal
                    trainingId={trainingId}
                    checkRegistered={checkRegistered}
                    onClose={() => {
                        setShowModal(false);
                    }}
                />
            ) : null}
        </Fragment>
    );
}
