import http from "../../global/http-common";

const route = "/Conversations";

export const conversationService = {
    get: function get(conversationId) {
        return http.get(route + "/" + conversationId);
    },
    getByUserId: function get(userId) {
        return http.get(route + "?userId=" + userId);
    },
    getRecent: function getRecent() {
        return http.get(route + "/Recent");
    },
    sendMessage: function (conversationId, data) {
        // data.forEach((value, key) => {
        //     console.debug(key, value)
        // });
        return http.post(route + "/" + conversationId + "/SendMessage", data);
    },
    getMessages: function (conversationId, cursor) {
        //console.log(conversationId, cursor);
        if (cursor === Infinity) cursor = 0;
        return http.get(
            route + "/" + conversationId + "/Messages?cursor=" + cursor
        );
    },
    getUnread: function getUnread() {
        return http.get(route + "/HasUnread");
    },
};
