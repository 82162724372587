//import { HubConnectionBuilder } from "@microsoft/signalr";
import { Fragment, useEffect, useRef, useState } from "react";
//import { UserOnline_HUB_URL } from "../../global/http-common";
import { authService } from "../../services/Account/auth.service";
import { cityService } from "../../services/City/cities.service";
import { countryService } from "../../services/Country/countries.service";
import { stateService } from "../../services/State/states.service";
import ActiveUserIcon from "../Icons/activeUserIcon";
import UserDummyImageIcon from "../Icons/userDummyImageIcon";

export function People({ onClick }) {
    const [users, setUsers] = useState([]);

    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    const [countryId, setCountryId] = useState(0);
    const [stateId, setStateId] = useState(0);
    const [cityId, setCityId] = useState(0);
    const [searchTerm, setSearchTerm] = useState(null);

    const countryRef = useRef(null);
    const stateRef = useRef(null);
    const cityRef = useRef(null);
    const searchRef = useRef(null);

    // useEffect(() => {
    //     authService
    //         .getFilteredUsers(countryId, stateId, cityId, searchTerm)
    //         .then((response) => {
    //             setUsers(response?.data);
    //         });
    //     getCountries();
    // }, []);

    useEffect(() => {
        getCountries();
    }, []);

    useEffect(() => {
        authService
            .getFilteredUsers(countryId, stateId, cityId, searchTerm)
            .then((response) => {
                setUsers(response?.data);
            });
    }, [countryId, stateId, cityId, searchTerm]);

    useEffect(() => {
        const peopleInterval = setInterval(() => {
            const country = countryRef.current.value;
            const state = stateRef.current.value;
            const city = cityRef.current.value;
            const search = searchRef.current.value;

            authService
                .getFilteredUsers(country, state, city, search)
                .then((response) => {
                    setUsers(response?.data);
                });
        }, 20 * 1000);

        return () => {
            clearInterval(peopleInterval);
        };
    }, []);

    const getCountries = () => {
        countryService.getAll().then((response) => {
            setCountries(response.data);
        });
    };

    const getStates = (countryId) => {
        stateService.getByCountry(countryId).then((res) => {
            setStates(res.data);
        });
    };

    const getCities = (stateId) => {
        cityService.getByState(stateId).then((res) => {
            setCities(res.data);
        });
    };

    // const searchItems = (searchValue) => {
    //     setSearchInput(searchValue);
    //     if (searchInput !== "") {
    //         const filteredData = users.filter((item) => {
    //             return Object.values(item)
    //                 .join("")
    //                 .toLowerCase()
    //                 .includes(searchInput.toLowerCase());
    //         });
    //         setFilteredResults(filteredData);
    //     } else {
    //         setFilteredResults(users);
    //     }
    // };

    return (
        <Fragment>
            <div className="border flex flex-col h-full">
                {/* <p>Active connections: {connectionCount}</p> */}

                <div className=" flex-1 w-full overflow-y-auto">
                    <div className="py-2 px-2 bg-grey-lightest">
                        <select
                            ref={countryRef}
                            name="countryId"
                            onChange={(e) => {
                                setStates([]);
                                setCities([]);
                                getStates(e.target.value);
                                setStateId(0);
                                setCityId(0);
                                setCountryId(e.target.value);
                            }}
                            className="bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg block w-full p-2.5"
                        >
                            <option value={0}>Select Country</option>
                            {countries?.map((country) => (
                                <option key={country.id} value={country.id}>
                                    {country.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="py-2 px-2 bg-grey-lightest">
                        <select
                            ref={stateRef}
                            name="stateId"
                            onChange={(e) => {
                                setCities([]);
                                getCities(e.target.value);
                                setCityId(0);
                                setStateId(e.target.value);
                            }}
                            className="bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg block w-full p-2.5"
                        >
                            <option value={0}>Select State</option>
                            {states?.map((state) => (
                                <option key={state.id} value={state.id}>
                                    {state.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="py-2 px-2 bg-grey-lightest">
                        <select
                            ref={cityRef}
                            name="cityId"
                            onChange={(e) => {
                                setCityId(e.target.value);
                            }}
                            className="bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg block w-full p-2.5"
                        >
                            <option value={0}>Select City</option>
                            {cities?.map((city) => (
                                <option key={city.id} value={city.id}>
                                    {city.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="py-2 px-2 bg-grey-lightest">
                        <input
                            ref={searchRef}
                            type="text"
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg block w-full p-2.5"
                            placeholder="Search or start new chat"
                        />
                    </div>
                    {/* <div className="py-2 px-2 bg-grey-lightest">
                        <input
                            type="text"
                            onChange={(e) => searchItems(e.target.value)}
                            className="bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg block w-full p-2.5"
                            placeholder="Search or start new chat"
                        />
                    </div> */}
                    <div className="divide-y divide-slate-200">
                        <h1 className="px-3 py-4 font-bold text-xl">Members</h1>
                        {users?.map((user, index) => (
                            <div
                                key={index}
                                onClick={() => {
                                    onClick(user);
                                }}
                                className="px-3 flex items-center bg-grey-light cursor-pointer"
                            >
                                <div>
                                    {user.imgUrl != null ? (
                                        <img
                                            className="h-12 w-12 rounded-full"
                                            src={user.imgUrl}
                                            alt=""
                                        />
                                    ) : (
                                        <UserDummyImageIcon />
                                    )}
                                </div>
                                <div className="ml-4 flex-1 py-4">
                                    <div className="flex items-bottom justify-between">
                                        <p className="text-grey-darkest">
                                            {user.name}
                                        </p>
                                    </div>
                                </div>
                                {user.isOnline ? (
                                    <div className="ml-10 flex-1 py-3">
                                        <div className="w-3 h-3 ">
                                            <ActiveUserIcon />
                                        </div>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
