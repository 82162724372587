export const featureService = {
    shouldShowContent: (featureName) => {
        const featuresJson = localStorage.getItem("features");
        const userType = localStorage.getItem("userType");
        const features = JSON.parse(featuresJson);
        const feature = features?.filter((x) => x.name === featureName)[0];
        if (!feature) return false;
        if (
            // (userType === "Premium" &&
            //     (feature.isAvaialableForEmployer,
            //     feature.isAvaialableForGeneral,
            //     feature.isAvaialableForStudent,
            //     feature.isAvaialableForWorker)) ||
            // (userType === "Standard" && feature.isAvailableForStandard)
            feature.isAvailable
        )
            return true;
        return false;
    },
};
