import http from "../../global/http-common";
const route = "/Posts";
const routeForScrolling = "/Posts/Scrolling";

export const PostService = {
    getAll: function getAll(categoryId) {
        if (categoryId) {
            return http.get(route + "?postCategoryId=" + categoryId);
        } else {
            return http.get(route);
        }
    },
    get: function get(id) {
        return http.get(`${route}/${id}`);
    },
    create: function create(data) {
        // data.forEach((value, key) => {
        //     console.debug(key, value);
        // });
        return http.post(route, data);
    },
    update: function update(id, data) {
        return http.put(`${route}/${id}`, data);
    },
    delete: function deleteOne(id) {
        return http.delete(`${route}/${id}`);
    },
    getPosts: function (categoryId, cursor) {
        if (cursor === Infinity) cursor = 0;
        if (categoryId) {
            // console.debug(categoryId);
            return http.get(routeForScrolling, {
                params: {
                    cursor: cursor,
                    id: categoryId,
                },
            });
        } else {
            return http.get(routeForScrolling + "?cursor=" + cursor);
        }
    },
};
