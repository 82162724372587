import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const swal = withReactContent(Swal)

export function showSuccess(message, title = null) {
        swal.fire(title === null || title === undefined || title === ''
            ? 'Success' : title, message, 'success')
}

export function showError(message, title = null) {
    swal.fire(title === null || title === undefined || title === ''
            ? 'Error' : title, message, 'error')
}

export function showConfirmation() {
    return swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'cancel!',
        reverseButtons: true
    });
}

export function showInfo(message, title = null) {
    swal.fire(title === null || title === undefined || title === ''
            ? '' : title, message, 'info')
};