export function toLocalEventTime(dateTime) {
    const timeoptions = {
        //timeZone: "US/Central",
        hour12: true,
        hour: "numeric",
        minute: "numeric", seconds: "numeric"
    }
    const date = new Date(dateTime)
    const LocaleTimeString = date.toLocaleTimeString('en-US',timeoptions)
    return LocaleTimeString
}
