import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Pagination } from "../../components/Pagination/pagination";
import { SingleTraining } from "../../components/Trining/singleTraining";
import { authService } from "../../services/Account/auth.service";
import { featureService } from "../../services/Feature/feature.service";
import TrainingDataService from "../../services/Training/training.service";

const Training = () => {
    const itemsPerPage = 12;
    const [allTrainings, setAllTrainings] = useState([]);
    const [itemOffset, setItemOffset] = useState(0);

    const [searchTerm, setSearchTerm] = useState("");
    const [filteredItems, setFilteredItems] = useState([]);

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = filteredItems.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(filteredItems.length / itemsPerPage);
    const [profile, setProfile] = useState([]);

    useEffect(() => {
        getAllTrainings();
        getProfile();
    }, []);
    const getProfile = () => {
        authService.profile().then((response) => {
            setProfile(response.data);
        });
    };

    useEffect(() => {
        setFilteredItems([...allTrainings]);
    }, [allTrainings]);

    // filtering trainings on search input
    useEffect(() => {
        if (searchTerm !== "") {
            const filteredData = allTrainings.filter((item) => {
                return Object.values(item)
                    .join("")
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase());
            });
            setFilteredItems(filteredData);
        } else {
            setFilteredItems([...allTrainings]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm]);

    // getting all trainings from database
    const getAllTrainings = async () => {
        await TrainingDataService.getAll().then((response) => {
            setAllTrainings(response.data);
        });
    };

    // update trainings if an event is deleted
    const updateTrainigs = useCallback((id) => {
        setAllTrainings((allTrainings) =>
            allTrainings.filter((training) => training.id !== id)
        );
    }, []);

    // handle plage change in pagination
    const handlePageChange = (e) => {
        const newOffset = (e.selected * itemsPerPage) % allTrainings.length;
        setItemOffset(newOffset);
    };

    // component to show items
    const Items = ({ currentItems }) => {
        return (
            <>
                {currentItems &&
                    currentItems.map((training) => (
                        <SingleTraining
                            training={training}
                            key={training.id}
                            updateTrainings={updateTrainigs}
                        />
                    ))}
            </>
        );
    };

    return (
        <div className="flex flex-col mt-20 pt-4 mx-4">
            <div className="px-4 text-gray-900">
                {featureService.shouldShowContent("Training Manage") ? (
                    profile?.isInstractor === true ? (
                        <div>
                            <Link
                                to={`/trainingContent`}
                                className="mb-4 inline-flex items-center  mt-6 px-5 py-2.5 text-sm font-medium text-center text-white bg-gradient-to-r from-purple-500 to-pink-500 rounded-lg  hover:bg-gradient-to-l"
                            >
                                Create {/*Training*/} Learning Course
                            </Link>
                        </div>
                    ) : (
                        <></>
                    )
                ) : (
                    <></>
                )}
                <div className="flex flex-wrap">
                    <div className="font-bold text-3xl mb-4 text-fuchsia-800 md:w-3/5 sm:w-full lg:w-2/3 xl:w-2/3 2xl:w-2/3 sm:text-center md:text-left">
                        {/*Development Course*/} Courses..
                    </div>
                    <div className="md:w-2/5 sm:w-full lg:w-1/3 xl:w-1/3 2xl:w-1/3 mb-6 text-base text-fuchsia-800">
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 float-right"
                            placeholder="Search trainings"
                        />
                    </div>
                </div>
                <div className="text-gray-500 font-medium text-xl mb-4">
                    {/*Together, we’re building a successful journey. Whether you
                    are looking to sharpen your financial literacy skills, learn
                    best practices before you buy your next house, or borrow
                    from your equity, our well-designed courses would help take
                    your knowledge to the next level. – Any of these self-study
            development courses would help you reach your success goal.*/}
            Together, we’re building a successful journey. 
            Whether you are looking to sharpen your skills, 
            learn best practices in various fields, 
            or explore new areas of interest, our well-designed courses 
            can help elevate your knowledge to the next level. 
            Any of these self-study development courses will assist 
            you in reaching your success goals. 
            Welcome to Learning, where personal development never stops.
                </div>
            </div>
            <div className="mx-auto w-full">
                <div className="grid gap-9 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 my-6">
                    <Items currentItems={currentItems} />
                </div>
                <Pagination
                    handlePageChange={handlePageChange}
                    pageCount={pageCount}
                />
            </div>
        </div>
    );
};

export default Training;
