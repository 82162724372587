import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { showError } from "../../global/alertService";
import RegisteredTrainingService from "../../services/Training/registeredtraining.service";
import TrainingDataService from "../../services/Training/training.service";
import { CrossIcon } from "../Icons/crossIcon";

export function RegisteredTrainingModal(props) {
    const mySwal = withReactContent(Swal);
    const navigate = useNavigate();
    const [data, setData] = useState(null);

    useEffect(() => {
        if (props.trainingId > 0) {
            getTraining(props.trainingId);
        }
    }, [props]);

    const getTraining = (id) => {
        console.debug(id);
        TrainingDataService.get(id).then((res) => {
            setData(res.data);
            //console.log(res.data)
        });
    };

    const saveRegisteredTraining = (event) => {
        event.preventDefault();
        console.debug(event);
        const registeredTrainingData = {
            trainingId: props.trainingId,
            paypalOrderId: "",
            paypalPayerID: "",
            stripePaymentId: "",
        };
        console.log(registeredTrainingData);
        RegisteredTrainingService.create(registeredTrainingData)

            .then((response) => {
                mySwal
                    .fire({
                        title: <strong>Success</strong>,
                        html: <i>Thank you for registered from Djigui.</i>,
                        icon: "success",
                    })
                    .then(() => {
                        navigate(`/courseContentVideo/${props.trainingId}`);
                    });
            })
            .catch((err) => {
                showError("Something went wrong");
                props.onClose();
            });
    };

    return (
        <Fragment>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-full my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-3xl font-semibold">
                                {data?.courseName}
                            </h3>

                            <button
                                className="absolute top-5 right-5 cursor-pointer"
                                onClick={props.onClose}
                            >
                                <CrossIcon />
                            </button>
                        </div>

                        {/*body*/}
                        <form onSubmit={saveRegisteredTraining}>
                            <div className="py-6 px-6 lg:px-8">
                                <input
                                    name="eventId"
                                    type="hidden"
                                    value={props.trainingId}
                                />
                                <input name="userId" type="hidden" value={6} />
                                <div style={{ marginTop: "10px" }}>
                                    <div className="px-5 pb-5 text-center">
                                        {props?.checkRegistered > 0 ? (
                                            <a href="#">
                                                <p className="text-2xl text-gray">
                                                    You have already registered
                                                    this course !
                                                </p>
                                            </a>
                                        ) : (
                                            <a href="#">
                                                <p className="text-2xl text-gray">
                                                    Are you interested to
                                                    register this course ?
                                                </p>
                                            </a>
                                        )}
                                    </div>
                                </div>

                                {/*</form>*/}
                            </div>
                            {/*footer*/}
                            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                {props?.checkRegistered.userId > 0 ? (
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={props?.onClose}
                                    >
                                        Close
                                    </button>
                                ) : (
                                    <>
                                        <button
                                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={props?.onClose}
                                        >
                                            No
                                        </button>
                                        <button
                                            className="bg-fuchsia-800 text-white hover:bg-green-800 font-bold  text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="submit"
                                        >
                                            Yes
                                        </button>
                                    </>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </Fragment>
    );
}
