import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { showConfirmation, showSuccess } from "../../global/alertService";
import HostDataService from "../../services/Host/host.service";

export function HostCreate(props) {
    const initialFormState = {
        description: "",
        facebookUrl: "",
        linkedInUrl: "",
        twitterUrl: "",
        instagramUrl: "",
        hobbies: "",
    };
    const navigate = useNavigate();
    const [host, setHost] = useState(initialFormState);

    useEffect(() => {
        getHostInfo();
    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        // console.debug(name, value, files);
        setHost({
            ...host,
            [name]: value,
        });
    };

    const getHostInfo = () => {
        HostDataService.get().then((res) => {
            setHost(res.data);
        });
    };

    const saveHostInfo = () => {
        HostDataService.create(host).then((res) => {
            getHostInfo();
        });
        navigate(`/host`);
    };

    const updateHostInfo = (id) => {
        HostDataService.update(id, host).then((res) => {
            setHost(res.data);
            getHostInfo();
        });
        navigate(`/host`);
    };
    const deleteHostInfo = (id) => {
        showConfirmation().then((result) => {
            if (result.isConfirmed) {
                HostDataService.delete(id).then((response) => {
                    setHost(initialFormState);
                    showSuccess("Deleted");
                });
            } else {
                showSuccess("Not deleted", "Ok");
            }
        });
    };
    const closeModal = () => {
        window.location.reload();
    };
    return (
        <Fragment>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    if (host.id > 0) {
                        updateHostInfo(host?.id);
                    } else {
                        saveHostInfo();
                    }
                }}
            >
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-full my-6 mx-auto max-w-3xl">
                        {/*content*/}
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            {/*header*/}
                            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                <h3 className="text-3xl font-semibold">
                                    Connector Information
                                </h3>

                                <button
                                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={props.onClose}
                                >
                                    <span className="bg-transparent text-red-500 background-transparent font-bold h-6 w-6 text-2xl block outline-none focus:outline-none">
                                        x
                                    </span>
                                </button>
                            </div>

                            {/*body*/}
                            <div className="py-6 px-6 lg:px-8">
                                <div className="grid gap-3 mb-4 md:grid-cols-2">
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            About You
                                        </label>
                                        <textarea
                                            name="description"
                                            value={host?.description}
                                            onChange={handleInputChange}
                                            rows={4}
                                            type="text"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                            placeholder="Write about you..."
                                            defaultValue={""}
                                        />
                                    </div>
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            Facebook URL
                                        </label>
                                        <div className="m-0 p-0 flex">
                                            <span class="inline-flex items-center px-2.5 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l-md">
                                                https://facebook.com/
                                            </span>
                                            <input
                                                name="facebookUrl"
                                                value={host?.facebookUrl}
                                                onChange={handleInputChange}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-r-md block w-full p-2.5"
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            LinkedIn URL
                                        </label>
                                        <div className="m-0 p-0 flex">
                                            <span class="inline-flex items-center px-2.5 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l-md">
                                                https://linkedin.com/in/
                                            </span>
                                            <input
                                                name="linkedInUrl"
                                                value={host?.linkedInUrl}
                                                onChange={handleInputChange}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-r-md block w-full p-2.5"
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            Twitter URL
                                        </label>
                                        <div className="m-0 p-0 flex">
                                            <span class="inline-flex items-center px-2.5 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l-md">
                                                https://twitter.com/
                                            </span>
                                            <input
                                                name="twitterUrl"
                                                value={host?.twitterUrl}
                                                onChange={handleInputChange}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-r-md block w-full p-2.5"
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            Instagram URL
                                        </label>
                                        <div className="m-0 p-0 flex">
                                            <span class="inline-flex items-center px-2.5 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l-md">
                                                https://instagram.com/
                                            </span>
                                            <input
                                                name="instagramUrl"
                                                value={host?.instagramUrl}
                                                onChange={handleInputChange}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-r-md block w-full p-2.5"
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            Hobbies (Seperated by comma ",")
                                        </label>
                                        <div className="m-0 p-0 flex">
                                            <input
                                                name="hobbies"
                                                value={host?.hobbies}
                                                onChange={handleInputChange}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md block w-full p-2.5"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*footer*/}
                            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                <button
                                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={() => closeModal()}
                                >
                                    Close
                                </button>
                                {host.id > 0 ? (
                                    <button
                                        type="button"
                                        onClick={() => deleteHostInfo(host.id)}
                                        className="text-white mr-4 bg-red-700 hover:bg-red-400  font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                    >
                                        Delete
                                    </button>
                                ) : (
                                    <> </>
                                )}
                                <button
                                    className="bg-fuchsia-800 text-white hover:bg-green-800 font-bold  text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="submit"
                                >
                                    {host.id > 0 ? "Update" : "Save"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </Fragment>
    );
}
