import React, { Fragment, useEffect, useState } from "react";
import { FileDownloader } from "../fileViewer/fileDownloader";
import { PdfViewer } from "../fileViewer/pdfViewer";
import { VideoPlayer } from "../Video/videoPlayer";

export default function ShowFileModal(props) {
    const [fileType, setFileType] = useState("");

    useEffect(() => {
        setFileType(props.src.split(".").pop());
    }, [props]);

    return (
        <Fragment>
            <form>
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-full my-6 mx-auto max-w-3xl">
                        {/*content*/}
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-black outline-none focus:outline-none">
                            {/*header*/}
                            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                <h3 className="text-white text-3xl font-semibold">
                                    {props?.topic}
                                </h3>

                                <button
                                    className="p-1 ml-auto bg-transparent border-0 text-red opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={props?.onClose}
                                >
                                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                        x
                                    </span>
                                </button>
                            </div>

                            {/*body*/}
                            <div className="py-3 px-6 lg:px-8">
                                <div className="items-center grid gap-6 mb-6 md:grid-cols-1">
                                    {fileType === "mp4" ? (
                                        <VideoPlayer src={props.src} />
                                    ) : fileType === "pdf" ? (
                                        <PdfViewer source={props.src} />
                                    ) : fileType === "ppt" ||
                                      fileType === "pptx" ||
                                      fileType === "doc" ||
                                      fileType === "docx" ? (
                                        <div
                                            style={{
                                                color: "#ffffff",
                                            }}
                                        >
                                            <FileDownloader
                                                source={props.src}
                                                height={"h-32"}
                                            />
                                        </div>
                                    ) : (
                                        <>unknown file</>
                                    )}
                                </div>
                            </div>
                            {/*footer*/}
                            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                <button
                                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={props?.onClose}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </Fragment>
    );
}
