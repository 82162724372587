import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { showError } from "../../global/alertService";
import { authService } from "../../services/Account/auth.service";

export function RecoverForgotPassword() {
    const mySwal = withReactContent(Swal);
    const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState(false);
    const [password, setPassword] = useState("");
    const [token, setToken] = useState("");

    useEffect(() => {
        let queryToken = new URLSearchParams(window.location.search).get(
            "token"
        );
        setToken(queryToken);
    }, []);

    // check is confirmPassword is not matched with password
    const handleCofirmPassword = (e) => {
        if (e.target.value !== password) {
            setErrorMessage(true);
        } else {
            setErrorMessage(false);
        }
    };

    // handle confirmPassword blur events
    const checkIfNull = (e) => {
        let text = e.target.value;
        if (text === "") {
            setErrorMessage(false);
        }
    };

    // handle form submit events
    const handleFormSubmit = (e) => {
        e.preventDefault();

        if (errorMessage) {
            showError("<i>Password and confirm password do not match!</i>");
            return false;
        }

        if (password.length < 5) {
            showError("<i>Password must be at least 5 characters!</i>");
            return false;
        }

        const data = {
            token: token,
            password: password,
        };

        authService
            .recoverForgotPassword(data)
            .then((res) => {
                mySwal
                    .fire({
                        title: <strong>Success</strong>,
                        html: <i>{res.data}</i>,
                        icon: "success",
                    })
                    .then(() => {
                        navigate("/signIn");
                    });
            })
            .catch((err) => {
                console.log(err);
            });
        //console.log(data);
    };

    return (
        <Fragment>
            <div className="flex flex-col items-center mt-24">
                <h1 className="text-4xl font-bold mb-6 text-fuchsia-800">
                    Reset New Password!
                </h1>
                <form onSubmit={handleFormSubmit}>
                    <div>
                        <label className="block mb-4 text-sm font-medium text-gray-900">
                            New Password
                        </label>
                        <input
                            type="password"
                            name="new-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="bg-gray-50 w-96 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                            placeholder="••••••••"
                            required
                        />
                    </div>

                    <div>
                        <label className="block my-4 text-sm font-medium text-gray-900">
                            Confirm password
                        </label>
                        <input
                            type="password"
                            name="confirm-password"
                            onChange={handleCofirmPassword}
                            onBlur={checkIfNull}
                            className="bg-gray-50 w-96 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                            placeholder="••••••••"
                            required
                        />
                        {errorMessage && (
                            <span className="mt-2 inline-block text-red-500 text-sm">
                                Password and confirm password do not match
                            </span>
                        )}
                    </div>

                    <button
                        type="submit"
                        className="w-full mt-6 text-white bg-fuchsia-800 hover:bg-green-800 font-bold rounded-lg text-xl px-5 py-2.5 text-center "
                    >
                        Reset Password
                    </button>
                </form>
            </div>
        </Fragment>
    );
}
