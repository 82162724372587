import http from "../../global/http-common";

const route = "/ApplicationUsersEnable";

export const userEnableService = {
    get: function get(id) {
        return http.get(`${route}/${id}`);
    },
    update: function update(id) {
        console.debug(id);
        return http.put(`${route}/${id}`);
    },
};
