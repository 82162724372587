import { Fragment } from "react";
import { AboutUs } from "../../components/Landing/aboutUs";
//import { HowWeHelp } from "../../components/Landing/howWeHelp";
//import { ContactUs } from "../../components/Landing/contactUs";
import { BackButton } from "../../components/Landing/backBtn";
import { Footer } from "../../components/Landing/footer";
import { Hero } from "../../components/Landing/hero";
import { MainFeatures } from "../../components/Landing/mainFeatures";
import { Marketing } from "../../components/Landing/marketing";
import { Services } from "../../components/Landing/services";
import { Team } from "../../components/Landing/team";

export function Landing() {
    return (
        <Fragment>
            <div>
                <div>
                    <Hero />
                </div>
                <div
                    id="aboutUs"
                    className="lg:ml-24 lg:mr-24 mb-10 md:mx-6 sm:mx-6"
                >
                    <AboutUs />
                </div>
                <div id="mainFeatures" className=" lg:mb-24 md:mb-16 sm:mb-16 ">
                    <MainFeatures />
                </div>
                <div
                    id="team"
                    className="lg:ml-24 lg:mr-24 mb-10 md:mx-6 sm:mx-6"
                >
                    <Team />
                </div>
                <div id="services" className="bg-white mt-20 ">
                    <div className="lg:ml-24 lg:mr-24 md:mx-6 sm:mx-6 ">
                        <Services />
                    </div>
                </div>
                <div
                    id="terms&Conditions"
                    className=" bg-indigo-50 lg:h-40 mt-10"
                >
                    <Marketing />
                </div>
                <div>
                    <Footer />
                </div>
            </div>
            <div>
                <BackButton />
            </div>
        </Fragment>
    );
}
