import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router";
import { DocsIcon } from "../../components/Icons/docsIcon";
import { PdfIcon } from "../../components/Icons/pdfIcon";
import { PptIcon } from "../../components/Icons/pptIcon";
import { VideoIcon } from "../../components/Icons/videoIcon";
import { VideoPlayer } from "../../components/Video/videoPlayer";
import { FileDownloader } from "../../components/fileViewer/fileDownloader";
import { PdfViewer } from "../../components/fileViewer/pdfViewer";
import TrainingDataService from "../../services/Training/training.service";
import TrainingContentDetService from "../../services/Training/trainingContentDet.service";
import TrainingContentService from "../../services/Training/trainingcontents.eervice";

export function CourseContentVideo() {
    const { id } = useParams();
    const [contentDet, setContentDet] = useState(null);
    const [data, setData] = useState(null);
    const [trainingContentDetId, setTrainingContentDetId] = useState(null);
    const [videoSrc, setVideoSrc] = useState(null);
    const [training, setTraining] = useState(null);

    const [fileType, setFileType] = useState(null);
    const [mime, setMime] = useState(null);

    useEffect(() => {
        if (id > 0) {
            getTrainingContents(id);
            getTraining(id);
        }
    }, []);

    // useEffect(() => {
    //   fileType && console.log(fileType)
    // }, [fileType])

    //--
    const getTraining = (id) => {
        //console.debug(id);
        TrainingDataService.get(id).then((res) => {
            setTraining(res.data);
            //console.log(res.data)
        });
    };
    //--

    const getTrainingContents = (id) => {
        TrainingContentService.get("?trainingId=" + id).then((response) => {
            setData(response.data);
            //console.log(response.data)
        });
    };

    const getTrainingContentDet = (id) => {
        //TrainingContentDetService.get(id)
        TrainingContentDetService.get("?trainingContentId=" + id).then(
            (response) => {
                setContentDet(response.data);
                //console.log(response.data)
            }
        );
    };

    const [toggle, setToggle] = useState(null);
    const handleToggle = (id) => {
        if (toggle === id) {
            setToggle(null);
            return false;
        }
        setToggle(id);
    };

    return (
        <Fragment>
            <div className="flex flex-col mt-20 pt-4">
                <div className="flex flex-wrap justify-between px-6">
                    <div className="md:w-full sm:w-full lg:w-2/3 xl:w-2/3 2xl:w-2/3 mb-6 lg:pr-6">
                        <div className="  block rounded-lg shadow-lg bg-gray-50">
                            {videoSrc != null ? (
                                fileType === "mp4" ? (
                                    <VideoPlayer
                                        src={videoSrc}
                                        id={trainingContentDetId}
                                    />
                                ) : fileType === "pdf" ? (
                                    <PdfViewer source={videoSrc} />
                                ) : fileType === "ppt" ||
                                  fileType === "pptx" ||
                                  fileType === "doc" ||
                                  fileType === "docx" ? (
                                    <FileDownloader source={videoSrc} />
                                ) : (
                                    <>unknown file</>
                                )
                            ) : (
                                <>
                                    <div
                                        style={{ minHeight: "350px" }}
                                        className="flex flex-col items-center bg-white rounded-lg border shadow-md md:flex-row mb-18 "
                                    >
                                        <img
                                            style={{ minWidth: "300px" }}
                                            className="object-cove w-full h-60 md:w-full lg:w-60 box-border p-4"
                                            src={training?.imgUrl}
                                            alt=""
                                        />
                                        <div className="flex flex-col justify-between p-4 leading-normal ">
                                            <div className="flex-1 min-w-0 ">
                                                <p className="text-xl font-bold text-gray-900 truncate mb-2">
                                                    {training?.courseName}
                                                </p>
                                                <p>
                                                    <span className="text-xl text-gray-900 truncate font-bold mt-2">
                                                        Instructor :
                                                    </span>
                                                    <span className="text-sm text-gray-500">
                                                        {" "}
                                                        {
                                                            training?.firstName
                                                        }{" "}
                                                        {training?.lastName}
                                                    </span>
                                                </p>

                                                <p className="text-xl text-gray-900 truncate font-bold mt-2">
                                                    Description :
                                                </p>
                                                <p className="text-xm text-gray-500 overflow-clip">
                                                    {training?.description}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="md:w-full sm:w-full lg:w-1/3 xl:w-1/3 2xl:w-1/3 mb-6">
                        <div className="block p-4 rounded-lg shadow-lg bg-gray-50 mb-4">
                            <h5 className="text-gray-900 text-xl leading-tight font-medium mb-2">
                                {training?.courseName}
                            </h5>

                            <button
                                onClick={() => {
                                    setVideoSrc(null);
                                }}
                                type="button"
                                className="flex items-center justify-end cursor-pointer p-2 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-6 h-6 "
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                                    />
                                </svg>
                                Back
                            </button>
                        </div>
                        {data?.map((item, i) => {
                            return (
                                <div
                                    key={i}
                                    className="block p-4 rounded-lg shadow-lg bg-gray-50"
                                >
                                    {/*<h5 className="text-gray-900 text-xl leading-tight font-medium mb-2">{item?.training}</h5>*/}
                                    <p>
                                        <strong>{item?.name}</strong>
                                    </p>
                                    <button
                                        onClick={() => {
                                            getTrainingContentDet(item.id);
                                            handleToggle(item.id);
                                        }}
                                        type="button"
                                        className="flex items-center p-2 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100"
                                    >
                                        <span
                                            className="flex-1 ml-3 text-left whitespace-nowrap"
                                            sidebar-toggle-item
                                        >
                                            {item?.chapterName}-{}
                                            {item?.content}
                                        </span>

                                        <svg
                                            sidebar-toggle-item
                                            className="w-6 h-6"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                clipRule="evenodd"
                                            ></path>
                                        </svg>
                                    </button>

                                    {item.id === toggle ? (
                                        <>
                                            {/* {isOpen && (-- */}
                                            <ul className="py-2 space-y-2">
                                                {contentDet?.map(
                                                    (item, index) =>
                                                        (item?.detailUrl
                                                            .split(".")
                                                            .pop() === "mp4" ||
                                                            item?.detailUrl
                                                                .split(".")
                                                                .pop() ===
                                                                "pdf" ||
                                                            item?.detailUrl
                                                                .split(".")
                                                                .pop() ===
                                                                "ppt" ||
                                                            item?.detailUrl
                                                                .split(".")
                                                                .pop() ===
                                                                "pptx" ||
                                                            item?.detailUrl
                                                                .split(".")
                                                                .pop() ===
                                                                "doc" ||
                                                            item?.detailUrl
                                                                .split(".")
                                                                .pop() ===
                                                                "docx") && (
                                                            <li
                                                                key={index}
                                                                onClick={() => {
                                                                    setTrainingContentDetId(
                                                                        item.id
                                                                    );
                                                                    setVideoSrc(
                                                                        item?.detailUrl
                                                                    );
                                                                    setFileType(
                                                                        item?.detailUrl
                                                                            .split(
                                                                                "."
                                                                            )
                                                                            .pop()
                                                                    );
                                                                }}
                                                            >
                                                                <div className="flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 cursor-pointer">
                                                                    {item?.detailUrl
                                                                        .split(
                                                                            "."
                                                                        )
                                                                        .pop() ===
                                                                        "pdf" && (
                                                                        <>
                                                                            <PdfIcon />{" "}
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </>
                                                                    )}
                                                                    {(item?.detailUrl
                                                                        .split(
                                                                            "."
                                                                        )
                                                                        .pop() ===
                                                                        "ppt" ||
                                                                        item?.detailUrl
                                                                            .split(
                                                                                "."
                                                                            )
                                                                            .pop() ===
                                                                            "pptx") && (
                                                                        <>
                                                                            <PptIcon />{" "}
                                                                            {
                                                                                item.name
                                                                            }{" "}
                                                                        </>
                                                                    )}
                                                                    {(item?.detailUrl
                                                                        .split(
                                                                            "."
                                                                        )
                                                                        .pop() ===
                                                                        "doc" ||
                                                                        item?.detailUrl
                                                                            .split(
                                                                                "."
                                                                            )
                                                                            .pop() ===
                                                                            "docx") && (
                                                                        <>
                                                                            <DocsIcon />{" "}
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </>
                                                                    )}
                                                                    {item?.detailUrl
                                                                        .split(
                                                                            "."
                                                                        )
                                                                        .pop() ===
                                                                        "mp4" && (
                                                                        <>
                                                                            <VideoIcon />{" "}
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </li>
                                                        )
                                                )}
                                            </ul>
                                            {/*-- )}-- */}
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            );
                        })}

                        {/*<Ads trainingContentId={contentDet?.trainingContentId}/>*/}
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
