import React from "react";

export default function UserDummyImageIcon() {
    return (
        <svg
            className=" bg-green-400 w-12 h-12 rounded-full text-gray-400"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}
