import { useEffect, useState } from "react";

import SingleResource from "../../components/Directory/singleResource";
import Paginate from "../../components/Pagination/paginate";
import { cityService } from "../../services/City/cities.service";
import { countryService } from "../../services/Country/countries.service";
import { resourceService } from "../../services/Resource/resource.service";
import { resourceCategoriesService } from "../../services/Resource/resourceCategory.service";
import { stateService } from "../../services/State/states.service";

const Directory = () => {
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [categories, setCategories] = useState([]);

    const [allResources, setAllResources] = useState([]);

    const [categoryName, setCategoryName] = useState("");
    const [categoryId, setCategoryId] = useState(null);
    const [countryId, setCountryId] = useState(null);
    const [stateId, setStateId] = useState(null);
    const [cityId, setCityId] = useState(null);

    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [currentItemsCount, setCurrentItemsCount] = useState(0);
    const [pageSize, setPageSize] = useState(12);

    const [searchTerm, setSearchTerm] = useState("");

    const pageSizes = [12, 24, 48, 96];

    useEffect(() => {
        getCountries();
        getCategorires();
    }, []);

    useEffect(() => {
        resourceService
            .getAll(
                categoryId,
                countryId,
                stateId,
                cityId,
                searchTerm,
                currentPageNumber,
                pageSize
            )
            .then((response) => {
                //console.log(response.data);
                setTotalItems(response?.data.totalCount);
                setCurrentItemsCount(response?.data.currentItemCount);
                setAllResources(response?.data.filteredData);
            });
    }, [
        categoryId,
        countryId,
        stateId,
        cityId,
        searchTerm,
        currentPageNumber,
        pageSize,
    ]);

    const getCategorires = () => {
        resourceCategoriesService.getAll().then((response) => {
            //console.debug(response.data);
            setCategories(response.data);
        });
    };
    const getCountries = () => {
        countryService.getAll().then((response) => {
            setCountries(response.data);
        });
    };

    const getStates = (countryId) => {
        stateService.getByCountry(countryId).then((res) => {
            setStates(res.data);
        });
    };

    const getCities = (stateId) => {
        cityService.getByState(stateId).then((res) => {
            setCities(res.data);
        });
    };

    // update categoryId and category name
    const changeCategoryIdAndName = (id) => {
        setCategoryId(id);
        setCurrentPageNumber(1);
        categories.forEach((item) => {
            if (item.id === id) setCategoryName(item.name);
        });
    };

    return (
        <div>
            <div className="mt-4 pt-24 mx-8">
                <div className="grid gap-3 md:grid-cols-4 sm:grid-cols-1 mb-6">
                    <div>
                        <select
                            name="countryId"
                            onChange={(e) => {
                                setStates([]);
                                setCities([]);
                                getStates(e.target.value);
                                setStateId(null);
                                setCityId(null);
                                setCountryId(
                                    e.target.value > 0 ? e.target.value : null
                                );
                            }}
                            className="bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg block w-full p-2.5"
                        >
                            <option value="0">Select Country</option>
                            {countries?.map((countries) => (
                                <option key={countries.id} value={countries.id}>
                                    {countries.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <select
                            name="stateId"
                            onChange={(e) => {
                                setCities([]);
                                getCities(e.target.value);
                                setCityId(null);
                                setStateId(
                                    e.target.value > 0 ? e.target.value : null
                                );
                            }}
                            className="bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg block w-full p-2.5"
                        >
                            <option value="0">Select State</option>
                            {states?.map((state) => (
                                <option key={state.id} value={state.id}>
                                    {state.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <select
                            name="cityId"
                            onChange={(e) => {
                                setCityId(
                                    e.target.value > 0 ? e.target.value : null
                                );
                            }}
                            className="bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg block w-full p-2.5"
                        >
                            <option value="0">Select City</option>
                            {cities?.map((city) => (
                                <option key={city.id} value={city.id}>
                                    {city.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="">
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={(e) => {
                                setSearchTerm(e.target.value);
                                setCurrentPageNumber(1);
                            }}
                            className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 float-right"
                            placeholder="Search resources"
                        />
                    </div>
                </div>
                <div className="grid gap-4 lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-2">
                    <div
                        onClick={() => {
                            setCategoryId(null);
                            setCurrentPageNumber(1);
                        }}
                        className="mb-4 cursor-pointer rounded shadow"
                    >
                        {categoryId === null ? (
                            <div className="flex items-center justify-center h-24 bg-fuchsia-800 rounded-t text-white text-xl font-bold">
                                All Resources
                            </div>
                        ) : (
                            <div className="flex items-center justify-center h-24 bg-gray-800 rounded-t text-white text-xl font-bold">
                                All Resources
                            </div>
                        )}

                        {categoryId === null ? (
                            <div className="h-1 bg-fuchsia-800 rounded-b"></div>
                        ) : (
                            <div className="h-1 bg-gray-800 rounded-b"></div>
                        )}
                    </div>
                    {categories?.map((item) => {
                        return (
                            <div
                                key={item.id}
                                onClick={() => changeCategoryIdAndName(item.id)}
                                className="cursor-pointer mb-4 rounded"
                            >
                                <div
                                    className="h-24 bg-cover rounded-t bg-center bg-no-repeat flex shadow"
                                    style={{
                                        backgroundImage: `${
                                            categoryId === item.id
                                                ? "linear-gradient(to bottom, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75))"
                                                : "linear-gradient(to bottom, rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60))"
                                        }, url('${item.imgUrl}')`,
                                    }}
                                >
                                    <h1 className="text-white text-left ml-2 mt-14 text-sm">
                                        {item.name}
                                    </h1>
                                </div>
                                <div
                                    className="h-1 rounded-b"
                                    style={{
                                        backgroundColor: item.colorCode,
                                    }}
                                ></div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="mt-4 mx-8">
                <div className="grid gpa-4 grid-cols-3">
                    <h3 className="col-span-2 mb-6 text-xl font-bold text-left">
                        {categoryId === null ? "All resources" : categoryName}
                    </h3>
                    <div className="flex justify-end items-center mb-6 text-xl font-bold text-right">
                        Size:
                        <select
                            className="ml-4 bg-gray-50 border border-gray-300 text-gray-700 text-base rounded block w-16 px-2 py-1"
                            onChange={(e) => {
                                setPageSize(e.target.value);
                                setCurrentPageNumber(1);
                            }}
                        >
                            {pageSizes.map((size) => {
                                return (
                                    <option key={size} value={size}>
                                        {size}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>
                <div className="grid gap-4 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-1">
                    {allResources?.map((item) => {
                        return <SingleResource key={item.id} resource={item} />;
                    })}
                </div>
            </div>
            <div className="mx-8 mb-12 mt-4">
                <Paginate
                    totalItems={totalItems}
                    currentItemsCount={currentItemsCount}
                    currentPageNumber={currentPageNumber}
                    pageSize={pageSize}
                    setCurrentPageNumber={setCurrentPageNumber}
                />
            </div>
        </div>
    );
};

export default Directory;
