import http from '../../global/http-common'
const routeLike = '/Posts/Likes';
const routeUnlike = '/Posts/Unlike';

export const LikeService = {
    create: function create(data) {
       console.debug(data)
        return http.post(routeLike, data);
    },
    delete: function deleteOne(id) {
        return http.delete(`${routeUnlike}/${id}`);
    }
}