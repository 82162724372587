import http from "../../global/http-common";

const route = "/Subscriptions";

export const subscriptionService = {
    get: function get() {
        return http.get(route);
    },
    create: function create(data) {
        //console.debug(data);
        return http.post(route, data);
    },

    update: function update(data) {
        //console.debug(data);
        return http.patch(route, data);
    },

    updateAfterExpire: function updateAfterExpire() {
        //console.debug(data);
        return http.patch(route + "/ExpairSubscriptionDate");
    },
};
