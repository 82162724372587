export const getDistanceAndDuration = async (center, location, travelMode) => {
  // eslint-disable-next-line no-undef
  const directionService = new google.maps.DirectionsService();
  const results = await directionService.route({
    origin: center,
    destination: location,
    travelMode: travelMode,
  });
  let result = {
    distance: results.routes[0].legs[0].distance.text,
    duration: results.routes[0].legs[0].duration.text,
    results: results,
  };
  return result;
};
