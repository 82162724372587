import { useEffect, useState } from "react";
import { showSuccess } from "../../global/alertService";
import { authService } from "../../services/Account/auth.service";

export function UserImage({ profile }) {
    const [image, setImage] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);

    useEffect(() => {
        setImageUrl(profile?.imgUrl);
    }, [profile]);

    return (
        <form>
            <div className="p-6 rounded-lg shadow-lg bg-gray-50 mt-6 ">
                <div className="flex justify-center items-center mb-4 h-48 bg-gray-300 rounded">
                    {image ? (
                        <img
                            className=" h-40 w-40 rounded-lg "
                            src={URL.createObjectURL(image)}
                            alt=""
                        />
                    ) : imageUrl ? (
                        <img
                            className=" h-40 w-40 rounded-lg "
                            src={imageUrl}
                            alt=""
                        />
                    ) : (
                        <svg
                            className="w-12 h-12 text-gray-200"
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                            fill="currentColor"
                            viewBox="0 0 640 512"
                        >
                            <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                        </svg>
                    )}
                </div>
                {profile.firstName === null ? (
                    <></>
                ) : (
                    <h5 className="text-gray-900 text-xl leading-tight font-medium mb-4 text-center">
                        {profile?.firstName + " "}
                        {profile?.lastName}
                    </h5>
                )}

                <div className="text-center">
                    <label
                        htmlFor="img"
                        className="text-white bg-fuchsia-800  hover:bg-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-4"
                    >
                        Change Picture
                        <input
                            name="image"
                            type="file"
                            id="img"
                            accept=".png,.jpeg,.jpg"
                            style={{ display: "none" }}
                            onChange={(event) => {
                                setImage(event.target.files[0]);
                            }}
                        />
                    </label>
                    <button
                        type="button"
                        onClick={() => {
                            authService.updateProfilePic(image).then((res) => {
                                showSuccess("Updated successfully.");
                            });
                        }}
                        className="text-white bg-fuchsia-800  hover:bg-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    >
                        Update
                    </button>
                </div>
            </div>
        </form>
    );
}
