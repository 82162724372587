import { Menu, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import EventDetail from "../../components/Events/eventDetail";
import { showConfirmation, showSuccess } from "../../global/alertService";
import EventDataService from "../../services/Event/event.service";
import { classNames } from "../../utils/className-utils";
import { toLocalEventDate } from "../../utils/evant-date";
import { toLocalEventTime } from "../../utils/event-time";

export default function SingleEvent({ event, updateEvents }) {
    const mySwal = withReactContent(Swal);

    const eventId = event.id;
    const currentUserEmail = localStorage.getItem("email");

    const [showModal, setShowModal] = useState(false);

    const deleteEvent = (id) => {
        showConfirmation().then((result) => {
            if (result.isConfirmed) {
                EventDataService.delete(id).then((response) => {
                    mySwal
                        .fire({
                            title: <strong>Success</strong>,
                            html: <i>Deleted successfully</i>,
                            icon: "success",
                        })
                        .then(() => {
                            updateEvents(id);
                        });
                });
            } else {
                showSuccess("Not deleted", "Ok");
            }
        });
    };

    return (
        <Fragment>
            <div className="max-w-sm w-full mt-4 bg-gray-50 mb-6 shadow-md">
                <div
                    className="w-full h-52 cursor-pointer"
                    onClick={() => {
                        setShowModal(true);
                    }}
                    style={{
                        backgroundImage: `url("${event?.imgUrl}")`,
                        backgroundColor: "#cccccc",
                        backgroundPosition: "top top",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                    }}
                ></div>
                {/* <img
                        className=" w-full h-50 "
                        src={event?.imgUrl}
                        alt="product click"
                    /> */}

                <div className="h-2 mt-2">
                    {currentUserEmail === event?.email ? (
                        <Menu
                            as="div"
                            className="flex items-center justify-end mr-4 rounded-b"
                        >
                            <div>
                                <Menu.Button aria-hidden="true">
                                    <svg
                                        className="w-4 h-4"
                                        aria-hidden="true"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"></path>
                                    </svg>
                                </Menu.Button>
                            </div>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="absolute z-10 mt-24 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="py-1">
                                        <Menu.Item>
                                            {({ active }) => (
                                                <Link
                                                    to={{
                                                        pathname: `/event/update/${event?.id}`,
                                                    }}
                                                    className={classNames(
                                                        active
                                                            ? "bg-gray-100 text-gray-900"
                                                            : "text-gray-700",
                                                        " px-4 py-2 text-sm w-full flex items-center space-x-3"
                                                    )}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth={1.5}
                                                        stroke="currentColor"
                                                        className="w-6 h-6"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                                        />
                                                    </svg>
                                                    <span>Edit</span>
                                                </Link>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <button
                                                    onClick={() => {
                                                        deleteEvent(event?.id);
                                                    }}
                                                    className="px-4 py-2 text-sm w-full flex items-center space-x-3"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth={1.5}
                                                        stroke="currentColor"
                                                        className="w-6 h-6"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                                        />
                                                    </svg>
                                                    <span>Delete</span>
                                                </button>
                                            )}
                                        </Menu.Item>
                                    </div>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    ) : null}
                </div>
                <div className="px-5 pb-5">
                    <h5 className="text-xl font-semibold tracking-tight mt-2 text-gray-900">
                        {event.name}
                    </h5>

                    <div className="flex justify-between items-center">
                        <span>{toLocalEventDate(event?.eventDate)}</span>
                        <span>{toLocalEventTime(event?.eventDate)}</span>
                    </div>

                    <p>
                        {event?.location}
                        {","} {event?.city}
                        {","} {event?.state}
                        {","} {event?.country}
                    </p>

                    <div className="flex justify-between items-center mt-4 mb-2">
                        <span className="text-xl font-bold text-gray-900">
                            Description
                        </span>
                    </div>
                    <p className="text-md text-justify mb-3 font-normal text-gray-700"
                        style={{
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 3,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {event?.description}
                    </p>
                    <div className="flex justify-between items-center">
                        <div
                            onClick={() => {
                                setShowModal(true);
                            }}
                            className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-fuchsia-800 rounded-lg focus:ring-4 focus:ring-blue-200 hover:bg-green-800 cursor-pointer"
                        >
                            Read more
                            <svg
                                aria-hidden="true"
                                className="ml-2 -mr-1 w-4 h-4"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            {showModal ? (
                <EventDetail
                    detailEventId={eventId}
                    onClose={() => {
                        setShowModal(false);
                    }}
                />
            ) : null}
        </Fragment>
    );
}
