import { useEffect, useState } from "react";
import { HostCreate } from "../../components/HostServices/hostModal";
import { SingleHost } from "../../components/HostServices/singleHost";
import { Pagination } from "../../components/Pagination/pagination";
import { cityService } from "../../services/City/cities.service";
import { countryService } from "../../services/Country/countries.service";
import { featureService } from "../../services/Feature/feature.service";
import HostDataService from "../../services/Host/host.service";
import { stateService } from "../../services/State/states.service";

function Host() {
    const itemsPerPage = 12;
    const [allHosts, setAllHosts] = useState([]);
    const [itemOffset, setItemOffset] = useState(0);

    const [searchTerm, setSearchTerm] = useState("");
    const [filteredItems, setFilteredItems] = useState([]);

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = filteredItems.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(filteredItems.length / itemsPerPage);
    const [showModal, setShowModal] = useState(false);

    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [cityId, setCityId] = useState(null);
    const [stateId, setStateId] = useState(null);
    const [countryId, setCountryId] = useState(null);
    const [isHost, setIsHost] = useState(false);

    useEffect(() => {
        getAllHosts(countryId, stateId, cityId);
        getCountries();
        checkIfHost();
    }, [countryId, stateId, cityId]);
    //----
    const checkIfHost = () => {
        HostDataService.get().then((res) => {
            setIsHost(res.data);
        });
    };
    const getCountries = () => {
        countryService.getAll().then((response) => {
            setCountries(response.data);
        });
    };

    const getStates = (countryId) => {
        stateService.getByCountry(countryId).then((res) => {
            setStates(res.data);
        });
    };

    const getCities = (stateId) => {
        cityService.getByState(stateId).then((res) => {
            setCities(res.data);
        });
    };

    //--------

    useEffect(() => {
        setFilteredItems([...allHosts]);
    }, [allHosts]);

    // filtering hosts on search input
    useEffect(() => {
        if (searchTerm !== "") {
            const filteredData = allHosts.filter((item) => {
                return Object.values(item)
                    .join("")
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase());
            });
            setFilteredItems(filteredData);
        } else {
            setFilteredItems([...allHosts]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm]);

    // getting all hosts from database
    const getAllHosts = async (countryId, stateId, cityId) => {
        await HostDataService.getAll(countryId, stateId, cityId).then(
            (response) => {
                setAllHosts(response.data);
            }
        );
    };

    // handle plage change in pagination
    const handlePageChange = (e) => {
        const newOffset = (e.selected * itemsPerPage) % allHosts.length;
        setItemOffset(newOffset);
    };

    // component to show items
    const Items = ({ currentItems }) => {
        return (
            <>
                {currentItems &&
                    currentItems.map((host) => (
                        <SingleHost host={host} key={host.id} />
                    ))}
            </>
        );
    };

    return (
        <div>
            <div>
                <div
                    className="flex relative flex-row justify-center text-center w-full h-80"
                    style={{
                        //backgroundImage: `url("https://lacity.gov/sites/g/files/wph1996/files/styles/medium_hero_image_jumbotron_floating_1600x432_/public/2022-05/LACity22_Residents_Secondary_V9.jpg.webp?itok=YQXDclvX")`,
                        backgroundImage: `url("./images_v1/host3.jpeg")`,
                        backgroundColor: "#cccccc",
                        backgroundPosition: "center top",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                    }}
                    
                >
                    {/*<img src="./images_v1/host.jpg"  alt="" />*/}
                    <h1 className="absolute text-4xl text-white  font-bold top-3/4 left-1/2 -translate-x-1/2 -translate-y-1/2">
                        Get Connected
                    </h1>
                </div>
                <div className="px-8 mt-6">
                    <div className="mb-2 mx-auto text-center">
                        <p className="font-bold text-3xl text-fuchsia-800">
                            Welcome To Your City ....
                        </p>
                    </div>
                    <div>
                        <p className="text-xl font-medium leading-relaxed mb-2 text-gray-500">
                            {/*Moving into a new city, state or Country presents
                            its own sets of challenges and can be overwhelming.
                            Think about someone, whether a friend, an
                            acquaintance or better yet, someone willing to teach
                            you things you need to know and learn from you;
                            that’s what Host is all about. Making new
                            connections and friendships as you settle into your
                            new city and navigate your busy schedule. Please
                choose from the list of prospective hosts.*/}
                Moving into a new city, state, or country 
                presents its own set of challenges and can be overwhelming. 
                Imagine having someone, whether a friend, an acquaintance,
                or someone eager to teach you what you need to know 
                and learn from you; that's what Connect is all about. 
                Building new connections and friendships as you settle into your new city 
                and navigate your busy schedule. 
                Please choose from the list of prospective connections.
                        </p>
                    </div>
                    {featureService.shouldShowContent("Be Host") ? (
                        <button
                            onClick={() => {
                                setShowModal(true);
                            }}
                            type="submit"
                            className="mb-2 inline-flex items-center mt-6 px-5 py-2.5 text-sm font-medium text-center text-white bg-gradient-to-r from-purple-500 to-pink-500 rounded-lg  hover:bg-gradient-to-l"
                        >
                            {isHost ? "Update Connector Information" : "Be a Connector"}
                        </button>
                    ) : (
                        <></>
                    )}
                    {/*--: <></>}--*/}
                    {showModal ? (
                        <HostCreate
                            onClose={() => {
                                setShowModal(false);
                            }}
                        />
                    ) : null}

                    <div className="mt-2 mb-4 grid gap-3 mr-6 md:grid-cols-3 sm:grid-cols-1">
                        <div>
                            <select
                                name="countryId"
                                onChange={(e) => {
                                    getStates(e.target.value);
                                    setCountryId(e.target.value);
                                }}
                                className="bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg block w-full p-2.5"
                            >
                                <option value="">Select Country</option>
                                {countries?.map((countries) => (
                                    <option
                                        key={countries.id}
                                        value={countries.id}
                                    >
                                        {countries.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <select
                                name="stateId"
                                onChange={(e) => {
                                    getCities(e.target.value);
                                    setStateId(e.target.value);
                                }}
                                className="bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg block w-full p-2.5"
                            >
                                <option value="">Select State</option>
                                {states?.map((state) => (
                                    <option key={state.id} value={state.id}>
                                        {state.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <select
                                name="cityId"
                                onChange={(e) => {
                                    setCityId(e.target.value);
                                }}
                                className="bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg block w-full p-2.5"
                            >
                                <option value="">Select City</option>
                                {cities?.map((city) => (
                                    <option key={city.id} value={city.id}>
                                        {city.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>

                <hr />

                <div className="px-8 mt-4 bg-white">
                    <div>
                        <div className="flex flex-wrap">
                            <div className="font-bold text-2xl mb-4 text-fuchsia-800 md:w-3/5 sm:w-full lg:w-2/3 xl:w-2/3 2xl:w-2/3 sm:text-center md:text-left">
                                We are Here To Serve
                            </div>
                            <div className="md:w-2/5 sm:w-full lg:w-1/3 xl:w-1/3 2xl:w-1/3 mb-4 text-base text-fuchsia-800">
                                <input
                                    type="text"
                                    value={searchTerm}
                                    onChange={(e) =>
                                        setSearchTerm(e.target.value)
                                    }
                                    className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 float-right"
                                    placeholder="Search hosts"
                                />
                            </div>
                        </div>
                        <div>
                            <p className="text-xl font-medium leading-relaxed mb-4 text-gray-500">
                                {/*You are not just part of something great, you
                                are helping create an all-inclusive and diverse
                                community. As a host, you will provide support
                                to newcomers as they adjust to their new
                                environment, settle, and fully integrate into
                                the community.*/}
                                Participating in the connector community 
                                is vital in fostering a diverse and inclusive environment. 
                                Whether as a connector or a friend, your actions 
                                go beyond simply welcoming others as 
                                they adjust to their surroundings; 
                                they also pave the way for newcomers 
                                to fully integrate and thrive within the local community.{" "}
                            </p>
                        </div>
                    </div>

                    <div className="mb-4">
                        <div className="m-4 flex flex-wrap justify-around">
                            <Items currentItems={currentItems} />
                        </div>
                        <Pagination
                            handlePageChange={handlePageChange}
                            pageCount={pageCount}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Host;
