import { Link, NavLink } from "react-router-dom";

import { React, useEffect, useState } from "react";
import { authService } from "../../services/Account/auth.service";
import { PostCategoryService } from "../../services/Post/postCategory.service";
//import { ProfileView } from '../../components/Profile/profileView'
import EventParticipantService from "../../services/Event/eventParticipants.service";
import { featureService } from "../../services/Feature/feature.service";
import RegisteredTrainingService from "../../services/Training/registeredtraining.service";
import { classNames } from "../../utils/className-utils";
import Event from "../Icons/Navbar/event";
import Career from "../Icons/Navbar/career";
import BottomArrowIcon from "../Icons/bottomArrowIcon";
import Training from "../Icons/Navbar/training";
import { ListIcon } from "../Icons/listIcon";

const ProfileCard = ({ setPostCategoryId }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [postCategory, setPostCategory] = useState(null);
    const [registeredTraining, setRegisteredTraining] = useState(null);
    const [eventParticipant, setEventParticipant] = useState(null);
    const [isActive, setIsActive] = useState(null);

    const [isParticipantOpen, setIsParticipantOpen] = useState(false);
    const Participanttoggling = () => setIsParticipantOpen(!isParticipantOpen);

    const [isTrainingOpen, setIsTrainingOpen] = useState(false);
    const Trainingtoggling = () => setIsTrainingOpen(!isTrainingOpen);

    const [isTrainingFormOpen, setIsTrainingFormOpen] = useState(false);
    const TrainingFormtoggling = () =>
        setIsTrainingFormOpen(!isTrainingFormOpen);

    const [isEmployerOpen, setIsEmployerOpen] = useState(false);
    const Employertoggling = () => {
        setIsEmployerOpen(!isEmployerOpen);
    };

    const [isSeekerOpen, setIsSeekerOpen] = useState(false);
    const Seekertoggling = () => {
        setIsSeekerOpen(!isSeekerOpen);
    };

    const [isEventOpen, setIsEventOpen] = useState(false);
    const [isActiveEvent, setIsActiveEvent] = useState(null);
    const Eventtoggling = () => {
        setIsEventOpen(!isEventOpen);
        setIsActiveEvent(null);
    };

    const [profile, setProfile] = useState([]);

    const usremail = localStorage.getItem("email");

    const toggling = () => {
        setIsOpen(!isOpen);
        setPostCategoryId(null);
        setIsActive(null);
    };

    const handleClick = (id) => {
        setIsActive(id);
    };

    useEffect(() => {
        getPostCategories();
        getRegisteredTraining();
        getEventParticipant();
        getProfile();
    }, []);

    const getProfile = () => {
        authService.profile().then((response) => {
            setProfile(response.data);
            localStorage.setItem("profilePicUrl", response.data?.imgUrl);
        });
    };

    const getRegisteredTraining = () => {
        RegisteredTrainingService.getAll().then((response) => {
            setRegisteredTraining(response.data);
        });
    };

    const getEventParticipant = () => {
        EventParticipantService.getAll().then((response) => {
            setEventParticipant(response.data);
        });
    };

    const getPostCategories = () => {
        PostCategoryService.getAll().then((response) => {
            setPostCategory(response.data);
        });
    };

    function logOutPage() {
        authService.signout();
    }

    return (
        <>
            <div className="overflow-hidden relative w-10 h-10 bg-gray-100 rounded-full ">
                {profile.imgUrl === null ? (
                    <svg
                        className="absolute -left-1 bg-green-400 w-12 h-12 text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                            clipRule="evenodd"
                        ></path>
                    </svg>
                ) : (
                    <img
                        className="h-12 w-12 rounded-full"
                        src={profile.imgUrl}
                        alt=""
                    />
                )}
            </div>

            <div className="py-3 px-4 text-sm text-black">
                <div>
                    {profile.firstName} {profile.lastName}
                </div>
                <div className="font-medium truncate">{usremail}</div>
            </div>
            <ul
                className="py-1 text-sm text-black"
                aria-labelledby="avatarButton"
            >
                <li>
                    <Link
                        to={`/profileView`}
                        className="items-center py-2 px-4 hover:bg-gray-100 flex space-x-3"
                    >
                        {/*--*/}
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="w-4 h-5"
                        >
                            <path
                                fillRule="evenodd"
                                d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                                clipRule="evenodd"
                            />
                        </svg>
                        {/*--*/}

                        <span>Profile</span>
                    </Link>
                </li>

                {featureService.shouldShowContent("Event Manage") ? (
                    <li>
                        <button
                            onClick={Eventtoggling}
                            //className="items-center py-2 px-4 hover:bg-gray-100  flex space-x-3"
                            className="items-center py-2 px-4 hover:bg-gray-100 flex space-x-3"

                        >
                            {/*<Event/>*/}
                            <svg xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 576 512"
                                fill="currentColor"
                                className="w-4 h-4"
                            >
                                <path d="M64 64C28.7 64 0 92.7 0 128v64c0 8.8 7.4 15.7 15.7 18.6C34.5 217.1 48 235 48 256s-13.5 38.9-32.3 45.4C7.4 304.3 0 311.2 0 320v64c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V320c0-8.8-7.4-15.7-15.7-18.6C541.5 294.9 528 277 528 256s13.5-38.9 32.3-45.4c8.3-2.9 15.7-9.8 15.7-18.6V128c0-35.3-28.7-64-64-64H64zm64 112l0 160c0 8.8 7.2 16 16 16H432c8.8 0 16-7.2 16-16V176c0-8.8-7.2-16-16-16H144c-8.8 0-16 7.2-16 16zM96 160c0-17.7 14.3-32 32-32H448c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H128c-17.7 0-32-14.3-32-32V160z" />
                            </svg>
                            <span>Event</span>
                            <svg
                                className="w-4 h-4"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                        </button>

                        {isEventOpen && (
                            <ul>
                                <li className="flex items-center p-1 pl-11 w-full  text-black rounded-lg transition duration-75 group hover:text-fuchsia-800 ">
                                    <NavLink
                                        to={`/eventIndexList`}
                                        /*className={classNames(
                                            isActiveEvent
                                                ? "text-fuchsia-800  w-full"
                                                : "text-gray-900",
                                            "block py-2 px-4 hover:bg-gray-100 "
                                        )}*/
                                        className="items-center block py-2 px-4 hover:bg-gray-100  flex space-x-3"

                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            className="w-4 h-4 mr-2">
                                            <path fill-rule="evenodd" d="M6 4.75A.75.75 0 016.75 4h10.5a.75.75 0 010 1.5H6.75A.75.75 0 016 4.75zM6 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H6.75A.75.75 0 016 10zm0 5.25a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H6.75a.75.75 0 01-.75-.75zM1.99 4.75a1 1 0 011-1H3a1 1 0 011 1v.01a1 1 0 01-1 1h-.01a1 1 0 01-1-1v-.01zM1.99 15.25a1 1 0 011-1H3a1 1 0 011 1v.01a1 1 0 01-1 1h-.01a1 1 0 01-1-1v-.01zM1.99 10a1 1 0 011-1H3a1 1 0 011 1v.01a1 1 0 01-1 1h-.01a1 1 0 01-1-1V10z" clip-rule="evenodd" />
                                        </svg>
                                        Event List
                                    </NavLink>
                                </li>
                                <li className="flex items-center p-1 pl-11 w-full  text-black rounded-lg transition duration-75 group hover:text-fuchsia-800 ">
                                    <Link
                                        to={`/eventParticipantList`}
                                        //className="block py-2 px-4 hover:bg-gray-100 "
                                        className="items-center block py-2 px-4 hover:bg-gray-100  flex space-x-3"

                                    >

                                        <svg xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            className="w-4 h-4 mr-2">
                                            <path fill-rule="evenodd" d="M6 4.75A.75.75 0 016.75 4h10.5a.75.75 0 010 1.5H6.75A.75.75 0 016 4.75zM6 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H6.75A.75.75 0 016 10zm0 5.25a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H6.75a.75.75 0 01-.75-.75zM1.99 4.75a1 1 0 011-1H3a1 1 0 011 1v.01a1 1 0 01-1 1h-.01a1 1 0 01-1-1v-.01zM1.99 15.25a1 1 0 011-1H3a1 1 0 011 1v.01a1 1 0 01-1 1h-.01a1 1 0 01-1-1v-.01zM1.99 10a1 1 0 011-1H3a1 1 0 011 1v.01a1 1 0 01-1 1h-.01a1 1 0 01-1-1V10z" clip-rule="evenodd" />
                                        </svg>
                                        Participant Event List
                                    </Link>
                                </li>
                            </ul>
                        )}
                    </li>
                ) : (
                    <></>
                )}

                {featureService.shouldShowContent("Employer Manage") ? (
                    <li>
                        <button
                            onClick={Employertoggling}
                            className="items-center py-2 px-4 hover:bg-gray-100  flex space-x-3"
                        >
                            <span>Employer</span>
                            <svg
                                className="w-4 h-4"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                        </button>

                        {isEmployerOpen && (
                            <ul>
                                <li className="flex items-center p-1 pl-11 w-full  text-black rounded-lg transition duration-75 group hover:text-fuchsia-800 ">
                                    <Link
                                        to={`/employerJobPostIndexList`}
                                        className="block py-2 px-4 hover:bg-gray-100 "
                                    >
                                        Job Post List
                                    </Link>
                                </li>
                                <li className="flex items-center p-1 pl-11 w-full  text-black rounded-lg transition duration-75 group hover:text-fuchsia-800 ">
                                    <Link
                                        to={`/jobApplicantList`}
                                        className="block py-2 px-4 hover:bg-gray-100 "
                                    >
                                        Job Applicant List
                                    </Link>
                                </li>
                                <li className="flex items-center p-1 pl-11 w-full  text-black rounded-lg transition duration-75 group hover:text-fuchsia-800 ">
                                    <Link
                                        to={`/seekerSelect`}
                                        className="block py-2 px-4 hover:bg-gray-100 "
                                    >
                                        {/*Seeker*/}Applicant Selected List
                                    </Link>
                                </li>
                            </ul>
                        )}
                    </li>
                ) : (
                    <></>
                )}

                {featureService.shouldShowContent("Seeker manage") ? (
                    <li>
                        <button
                            onClick={Seekertoggling}
                            //className="items-center py-2 px-4 hover:bg-gray-100  flex space-x-3"
                            className="items-center py-2 px-4 hover:bg-gray-100 flex flex-row space-x-3"

                        >
                            {/*<Career/>*/}
                            <svg xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                                className="w-4 h-4"
                            >
                                <path d="M219.3 .5c3.1-.6 6.3-.6 9.4 0l200 40C439.9 42.7 448 52.6 448 64s-8.1 21.3-19.3 23.5L352 102.9V160c0 70.7-57.3 128-128 128s-128-57.3-128-128V102.9L48 93.3v65.1l15.7 78.4c.9 4.7-.3 9.6-3.3 13.3s-7.6 5.9-12.4 5.9H16c-4.8 0-9.3-2.1-12.4-5.9s-4.3-8.6-3.3-13.3L16 158.4V86.6C6.5 83.3 0 74.3 0 64C0 52.6 8.1 42.7 19.3 40.5l200-40zM111.9 327.7c10.5-3.4 21.8 .4 29.4 8.5l71 75.5c6.3 6.7 17 6.7 23.3 0l71-75.5c7.6-8.1 18.9-11.9 29.4-8.5C401 348.6 448 409.4 448 481.3c0 17-13.8 30.7-30.7 30.7H30.7C13.8 512 0 498.2 0 481.3c0-71.9 47-132.7 111.9-153.6z" />
                            </svg>
                            <span className="flex-grow items-end">Talent </span>

                            <BottomArrowIcon fill={'currentColor'} className="w-4 h-4" />

                            {/*<svg
                                //className="w-4 h-4"
                                className="mr-1  w-4 h-4"
       
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                ></path>
                </svg>*/}
                        </button>

                        {isSeekerOpen && (
                            <ul>
                                <li className="flex items-center p-1 pl-11 w-full  text-black rounded-lg transition duration-75 group hover:text-fuchsia-800 ">
                                    <Link
                                        to={`/seekerJobPostIndexList`}
                                        className="block py-2 px-4 hover:bg-gray-100 "
                                    >
                                        Talent portfolio
                                    </Link>
                                </li>
                                <li className="flex items-center p-1 pl-11 w-full  text-black rounded-lg transition duration-75 group hover:text-fuchsia-800 ">
                                    <Link
                                        to={`/appliedJobList`}
                                        className="block py-2 px-4 hover:bg-gray-100 "
                                    >
                                        Applied Jobs
                                    </Link>
                                </li>
                            </ul>
                        )}
                    </li>
                ) : (
                    <></>
                )}

                {featureService.shouldShowContent("Training Manage") ? (
                    <li>
                        <button
                            onClick={TrainingFormtoggling}
                            className="items-center py-2 px-4 hover:bg-gray-100  flex space-x-3"
                        >
                            <Training />
                            <span>Wone Academy</span>
                            <svg
                                className="w-4 h-4"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                        </button>

                        {isTrainingFormOpen && (
                            <ul>
                                <li className="flex items-center p-1 pl-11 w-full  text-black rounded-lg transition duration-75 group hover:text-fuchsia-800">
                                    <Link
                                        to={`/trainingIndexList`}
                                        className="block py-2 px-4 hover:bg-gray-100 "
                                    >
                                        Training List
                                    </Link>
                                </li>
                            </ul>
                        )}
                    </li>
                ) : (
                    <></>
                )}
                {!featureService.shouldShowContent("Employer Manage") ? (
                    <li>
                        <button
                            onClick={Trainingtoggling}
                            className="items-center py-2 px-4 hover:bg-gray-100  flex space-x-3"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="black"
                                className="w-4 h-4 ">
                                <path fill-rule="evenodd" d="M6 4.75A.75.75 0 016.75 4h10.5a.75.75 0 010 1.5H6.75A.75.75 0 016 4.75zM6 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H6.75A.75.75 0 016 10zm0 5.25a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H6.75a.75.75 0 01-.75-.75zM1.99 4.75a1 1 0 011-1H3a1 1 0 011 1v.01a1 1 0 01-1 1h-.01a1 1 0 01-1-1v-.01zM1.99 15.25a1 1 0 011-1H3a1 1 0 011 1v.01a1 1 0 01-1 1h-.01a1 1 0 01-1-1v-.01zM1.99 10a1 1 0 011-1H3a1 1 0 011 1v.01a1 1 0 01-1 1h-.01a1 1 0 01-1-1V10z" clip-rule="evenodd" />
                            </svg>
                            <span>{/*Registered Training*/}Learning</span>
                            <svg
                                className="w-4 h-4"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                        </button>

                        {isTrainingOpen && (
                            <ul>
                                <span className="pl-11 w-full  text-black ">--Registered Training--</span>
                                {registeredTraining?.map((item) => {
                                    return (
                                        <li>
                                            <Link
                                                to={`/courseContentVideo/${item.trainingId}`}
                                                className="flex items-center p-1 pl-11 w-full  text-black rounded-lg transition duration-75 group hover:text-fuchsia-800 "
                                            >
                                                <svg
                                                    className="w-4 h-4 mr-2 text-gray-600 group-hover:text-fuchsia-800"
                                                    fill="currentColor"
                                                    viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M13 7H7v6h6V7z" />
                                                    <path fillRule="evenodd" d="M5 4a2 2 0 00-2 2v8a2 2 0 002 2h10a2 2 0 002-2V6a2 2 0 00-2-2H5zm2 1h6a1 1 0 011 1v8a1 1 0 01-1 1H7a1 1 0 01-1-1V6a1 1 0 011-1z" clipRule="evenodd" />
                                                </svg>
                                                {item?.training}
                                            </Link>
                                        </li>
                                    );
                                })}
                            </ul>
                        )}
                    </li>
                ) : (<></>)}

                {/*<li>
                    <Link
                        to={`/eventParticipantList`}
                        //className="block py-2 px-4 hover:bg-gray-100 "
                        className="items-center block py-2 px-4 hover:bg-gray-100  flex space-x-3"

                    >

                        <svg xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            className="w-4 h-4 mr-2">
                            <path fill-rule="evenodd" d="M6 4.75A.75.75 0 016.75 4h10.5a.75.75 0 010 1.5H6.75A.75.75 0 016 4.75zM6 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H6.75A.75.75 0 016 10zm0 5.25a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H6.75a.75.75 0 01-.75-.75zM1.99 4.75a1 1 0 011-1H3a1 1 0 011 1v.01a1 1 0 01-1 1h-.01a1 1 0 01-1-1v-.01zM1.99 15.25a1 1 0 011-1H3a1 1 0 011 1v.01a1 1 0 01-1 1h-.01a1 1 0 01-1-1v-.01zM1.99 10a1 1 0 011-1H3a1 1 0 011 1v.01a1 1 0 01-1 1h-.01a1 1 0 01-1-1V10z" clip-rule="evenodd" />
                        </svg>
                        Participant Event List
                    </Link>
            </li>*/}

                <li>
                    <Link to="/">
                        <button
                            onClick={toggling}
                            className="items-center py-2 px-4 hover:bg-gray-100  flex space-x-3"
                        >
                            {/*<span>Forum</span>*/}
                            <svg class="w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                fill="none" viewBox="0 0 24 24">
                                <path stroke="black" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 19V4c0-.6.4-1 1-1h12c.6 0 1 .4 1 1v13H7a2 2 0 0 0-2 2Zm0 0c0 1.1.9 2 2 2h12M9 3v14m7 0v4" />
                            </svg>

                            <span>Feature</span>
                            <svg
                                className="w-4 h-4"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                        </button>

                    </Link>

                    {isOpen && (
                        <ul>
                            {postCategory?.map((item) => {
                                return (
                                    <li>
                                        <button
                                            onClick={() => {
                                                setPostCategoryId(item.id);
                                                handleClick(item.id);
                                            }}
                                            className={classNames(
                                                isActive === item.id
                                                    ? "bg-fuchsia-800 text-white w-full"
                                                    : "text-gray-900",
                                                "flex items-center p-1 pl-11 w-full   rounded-lg transition duration-75 group hover:bg-fuchsia-600 "
                                            )}
                                        >
                                            {item.name}
                                        </button>
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                </li>
            </ul>
            <div className="py-1">
                <a
                    href="/"
                    onClick={logOutPage}
                    //className="block py-2 px-4 text-sm text-black hover:bg-gray-100 "
                    className="items-center block py-2 px-4 hover:bg-gray-100 flex space-x-3"

                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-4 h-4 mr-2"
                    >
                        <path d="M9.97.97a.75.75 0 011.06 0l3 3a.75.75 0 01-1.06 1.06l-1.72-1.72v3.44h-1.5V3.31L8.03 5.03a.75.75 0 01-1.06-1.06l3-3zM9.75 6.75v6a.75.75 0 001.5 0v-6h3a3 3 0 013 3v7.5a3 3 0 01-3 3h-7.5a3 3 0 01-3-3v-7.5a3 3 0 013-3h3z" />
                        <path d="M7.151 21.75a2.999 2.999 0 002.599 1.5h7.5a3 3 0 003-3v-7.5c0-1.11-.603-2.08-1.5-2.599v7.099a4.5 4.5 0 01-4.5 4.5H7.151z" />
                    </svg>
                    Sign out
                </a>
            </div>
        </>
    );
};
export default ProfileCard;
