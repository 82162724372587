import { Link } from "react-router-dom";

export function SingleHost({ host }) {
    return (
        <div key={host.id} className="w-80 mb-6 max-w-sm bg-gray-50  shadow-md">
            <div className="overflow-hidden relative w-10 h-10 bg-gray-100 rounded-full m-8">
                {host?.imgUrl != null ? (
                    <img
                        className="w-12 h-12 justify-center hosts-center rounded-full"
                        src={host?.imgUrl}
                        alt=""
                    />
                ) : (
                    <svg
                        className="absolute  bg-blue-400 w-12 h-12 text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                            clipRule="evenodd"
                        ></path>
                    </svg>
                )}
            </div>

            <div className="px-5 pb-5">
                <h5 className="text-xl font-semibold tracking-tight mt-4 text-gray-900">
                    {host?.firstName + " "} {host?.lastName}
                </h5>
                <span className=" text-gray-700 ">{host?.description}</span>
                <div className="mt-2">
                    <div>
                        <span className="text-m font-semibold tracking-tight mt-4 text-gray-900">
                            Address:{" "}
                        </span>
                        <span className=" text-gray-700 ">
                            {host?.location} {host?.city}
                            {","} {host?.state}
                            {","} {host?.country}
                        </span>
                    </div>
                    <div>
                        <span className="text-m font-semibold tracking-tight mt-4 text-gray-900">
                            Contact No:{" "}
                        </span>
                        <span className=" text-gray-700 ">
                            {host?.phoneNumber}
                        </span>
                    </div>
                    <div>
                        <span className="text-m font-semibold tracking-tight mt-4 text-gray-900">
                            Email:{" "}
                        </span>
                        <span className=" text-gray-700 ">{host?.email}</span>
                    </div>
                    <div>
                        <span className="text-m font-semibold tracking-tight mt-4 text-gray-900">
                            Relationship:{" "}
                        </span>
                        <span className=" text-gray-700 ">
                            {host?.relationship}
                        </span>
                    </div>
                    <div>
                        <span className="text-m font-semibold tracking-tight mt-4 text-gray-900">
                            Number of children:{" "}
                        </span>
                        <span className=" text-gray-700 ">
                            {host?.numberOfChildren}
                        </span>
                    </div>
                    <div>
                        <span className="text-m font-semibold tracking-tight mt-4 text-gray-900">
                            Facebook:{" "}
                        </span>
                        <a
                            href={`https://www.faceboook.com/${host?.facebookUrl}`}
                            target="_blank"
                            className="inline-flex hosts-center font-medium text-fuchsia-600 hover:underline"
                            rel="noreferrer"
                        >
                            faceboook.com/{host?.facebookUrl}
                        </a>
                    </div>
                    <div>
                        <span className="text-m font-semibold tracking-tight mt-4 text-gray-900">
                            LinkdIn:{" "}
                        </span>
                        <a
                            href={`https://www.linkedIn.com/in/${host?.linkedInUrl}`}
                            target="_blank"
                            className="inline-flex hosts-center font-medium text-fuchsia-600 hover:underline"
                            rel="noreferrer"
                        >
                            linkedIn.com/in/{host?.linkedInUrl}
                        </a>
                    </div>
                    <div>
                        <span className="text-m font-semibold tracking-tight mt-4 text-gray-900">
                            Instragram:{" "}
                        </span>
                        <a
                            href={`https://www.instagram.com/${host?.instagramUrl}`}
                            target="_blank"
                            className="inline-flex hosts-center font-medium text-fuchsia-600 hover:underline"
                            rel="noreferrer"
                        >
                            instagram.com/{host?.instagramUrl}
                        </a>
                    </div>
                    <div>
                        <span className="text-m font-semibold tracking-tight mt-4 text-gray-900">
                            Twitter:{" "}
                        </span>
                        <a
                            href={`https://www.twitter.com/${host?.twitterUrl}`}
                            target="_blank"
                            className="inline-flex hosts-center font-medium text-fuchsia-600 hover:underline"
                            rel="noreferrer"
                        >
                            twitter.com/{host?.twitterUrl}
                        </a>
                    </div>
                    <div>
                        <span className="text-m font-semibold tracking-tight mt-4 text-gray-900">
                            Hobbies:{" "}
                        </span>
                        <span className=" text-gray-700">{host?.hobbies}</span>
                    </div>
                    <div className="mt-6">
                        <Link
                            to={`/chat`}
                            className=" w-full py-2 px-3 text-sm font-medium text-center text-white  rounded-lg bg-fuchsia-800 hover:bg-green-800"
                        >
                            Send Message
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
