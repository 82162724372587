import { useLoadScript } from "@react-google-maps/api";
import { Mapcomponent } from "../../components/Map/mapComponent";
import { GOOGLE_MAP_API } from "../../global/api-keys";

// exporting this function to other components
export function Map() {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: GOOGLE_MAP_API,
        libraries: ["places"],
    });

    if (!isLoaded) return <div>Loading...</div>;
    return (
        <div
            style={{ height: "88vh" }}
            className="mt-20 ml-6 mr-6 flex overflow-hidden "
        >
            <Mapcomponent />
        </div>
    );
}
