import { Menu, Popover, Transition } from "@headlessui/react";
import {
    Bars3Icon,
    HomeIcon,
    LanguageIcon,
    MapIcon,
    XMarkIcon,
} from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { authService } from "../../services/Account/auth.service";
import { featureService } from "../../services/Feature/feature.service";
import { conversationService } from "../../services/Chat/conversation.service";
import Home from "../Icons/Navbar/home";
import Resource from "../Icons/Navbar/resource";
import Event from "../Icons/Navbar/event";
import Host from "../Icons/Navbar/host";
import Career from "../Icons/Navbar/career";
import Training from "../Icons/Navbar/training";
import Chat from "../Icons/Navbar/chat";
import Map from "../Icons/Navbar/map";
import Translate from "../Icons/Navbar/translate";
import Profile from "../Icons/Navbar/profile";
import LogOut from "../Icons/Navbar/logout";
const resources = [
    {
        name: "Translate",
        description:
            "A few of the many ways Translate helps you understand and communicate in another languages.",
        href: "/translate",
        icon: LanguageIcon,
    },
    {
        name: "Map",
        description:
            "Find local businesses, view maps and get driving directions in Maps.",
        href: "/map",
        icon: MapIcon,
    },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

const NavBar = () => {
    const [unread, setUnread] = useState(null);

    const activeLink = "text-lg font-bold text-fuchsia-800 ";
    const normalLink =
        "text-lg font-medium text-gray-500 hover:text-fuchsia-600";
    const mobileLink =
        "w-1/2 relative grid gap-2 rounded-lg bg-white px-5 sm:gap-2 sm:p-2 hover:bg-gray-50 text-lg font-medium text-gray-500 hover:text-fuchsia-600";

    const usremail = localStorage.getItem("email");
    const usrname = localStorage.getItem("name");
    const profilePicUrl = localStorage.getItem("profilePicUrl");
    console.log("profilePicUrl::", profilePicUrl);
    useEffect(() => {
        setInterval(() => {
            conversationService.getUnread().then((res) => {
                //console.debug(res.data);
                setUnread(res.data);
            });
        }, 500);
    }, []);

    function logOutPage() {
        authService.signout();
    }



    return (
        <Popover className=" mb-20 fixed w-full z-20 top-0 left-0 bg-slate-50">
            <div className="mx-auto max-w-7xl px-4 sm:px-4 ">
                <div className="flex items-center justify-between border-b-2 border-gray-100 py-2 lg:justify-start lg:space-x-10">
                    <div className="flex justify-start lg:w-0 lg:flex-1">
                        <NavLink to="/" className="">
                            <img
                                className="lg:-ml-4 -mt-2 -mb-2 lg:w-full h-10 sm:w-24 sm:m-4"
                                src="./wone_logo.png"
                                alt="Your Company"
                            />
                        </NavLink>
                    </div>

                    <div className="-my-2 -mr-2 lg:hidden">
                        {/* Mobile Menu */}
                        <Menu as="div">
                            <Menu.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                <span className="sr-only">Open menu</span>
                                <Bars3Icon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                />
                            </Menu.Button>
                            <Popover.Panel
                                focus
                                className="absolute z-10 inset-x-0 top-0 origin-top-right transform p-2 transition lg:hidden"
                            >
                                <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                                    <div className="px-5 py-2">
                                        <div className="flex items-center justify-end">
                                            <div className="-mr-2">
                                                <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                                    <span className="sr-only">
                                                        Close menu
                                                    </span>
                                                    <XMarkIcon
                                                        className="h-6 w-6"
                                                        aria-hidden="true"
                                                    />
                                                </Popover.Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="space-y-6 py-2 px-5">
                                        <div className="grid grid-cols-1 gap-y-4 gap-x-8">
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items className="flex flex-wrap">
                                                    <Menu.Item
                                                        className={mobileLink}
                                                    >
                                                        <NavLink
                                                            to={`/`}
                                                            end
                                                            className={
                                                                normalLink
                                                            }
                                                        >
                                                            <div className="flex items-center">
                                                                <Home />
                                                                <span className="ml-2">Home</span>
                                                            </div>
                                                        </NavLink>
                                                    </Menu.Item>
                                                    {featureService.shouldShowContent(
                                                        "Resource View"
                                                    ) && (
                                                            <div className="relative">
                                                                <button className="peer text-lg font-medium text-gray-500 hover:text-fuchsia- ml-2 pt-2">
                                                                    <div className="flex items-center">
                                                                        <Resource />
                                                                        <span className="ml-2">Resources</span>
                                                                    </div>
                                                                </button>

                                                                <div className="hidden peer-hover:flex hover:flex z-50 flex-col bg-white drop-shadow-lg overflow-clip absolute">
                                                                    <Menu.Item>
                                                                        <NavLink
                                                                            className="px-5 py-3 text-base font-medium text-gray-500 hover:text-fuchsia-600 cursor-pointer"
                                                                            to={`/resource/articles`}
                                                                        >
                                                                            Articles
                                                                        </NavLink>
                                                                    </Menu.Item>
                                                                    <Menu.Item>
                                                                        <NavLink
                                                                            className="px-5 py-3 text-base font-medium text-gray-500 hover:text-fuchsia-600 cursor-pointer"
                                                                            to={`/resource/files`}
                                                                        >
                                                                            Videos
                                                                        </NavLink>
                                                                    </Menu.Item>
                                                                    <Menu.Item>
                                                                        <NavLink
                                                                            className="px-5 py-3 text-base font-medium text-gray-500 hover:text-fuchsia-600 cursor-pointer"
                                                                            to={`/resource/links`}
                                                                        >
                                                                            Links
                                                                        </NavLink>
                                                                    </Menu.Item>
                                                                </div>
                                                            </div>
                                                        )}
                                                    {featureService.shouldShowContent(
                                                        "Event View"
                                                    ) && (
                                                            <Menu.Item
                                                                className={
                                                                    mobileLink
                                                                }
                                                            >
                                                                <NavLink
                                                                    to={`/events`}
                                                                >
                                                                    <div className="flex items-center">
                                                                        <Event /><span className="ml-2"> Events </span>
                                                                    </div>
                                                                </NavLink>
                                                            </Menu.Item>
                                                        )}
                                                    {featureService.shouldShowContent(
                                                        "Training View"
                                                    ) && (
                                                            <Menu.Item
                                                                className={
                                                                    mobileLink
                                                                }
                                                            >
                                                                <NavLink
                                                                    to={`/training`}
                                                                >
                                                                    {/*Training*/}
                                                                    <div className="flex items-center">
                                                                        <Training /><span className="ml-2"> Learning </span>
                                                                    </div>
                                                                    {/*Wone Academy*/}
                                                                </NavLink>
                                                            </Menu.Item>
                                                        )}
                                                    <Menu.Item
                                                        className={mobileLink}
                                                    >
                                                        <NavLink to={`/job`}>
                                                            <div className="flex items-center">
                                                                <Career /><span className="ml-2"> Career </span>
                                                            </div>
                                                            {/*Career*/}
                                                        </NavLink>
                                                    </Menu.Item>
                                                    {featureService.shouldShowContent(
                                                        "Host View"
                                                    ) && (
                                                            <Menu.Item
                                                                className={
                                                                    mobileLink
                                                                }
                                                            >
                                                                <NavLink
                                                                    to={`/host`}
                                                                >
                                                                    <div className="flex items-center">
                                                                        <Host /><span className="ml-2"> Connect </span>
                                                                    </div>
                                                                    {/*Host*/}
                                                                </NavLink>
                                                            </Menu.Item>
                                                        )}

                                                    <Menu.Item
                                                        className={mobileLink}
                                                    >
                                                        <NavLink to={`/chat`}>
                                                            {unread > 0 ? (
                                                                <span>
                                                                    {/*Chat{" "}--*/}
                                                                    <div className="flex items-center">
                                                                        <Chat /><span className="ml-2"> Chat{" "} </span>
                                                                    </div>
                                                                    <span className="inline-flex items-center px-1.5 py-1 text-sm font-medium text-center text-white bg-fuchsia-800 rounded-lg  ">
                                                                        {unread}
                                                                    </span>
                                                                </span>
                                                            ) : (
                                                                <span>
                                                                    {/*Chat*/}
                                                                    <div className="flex items-center">
                                                                        <Chat /><span className="ml-2"> Chat </span>
                                                                    </div>
                                                                </span>

                                                            )}
                                                        </NavLink>
                                                    </Menu.Item>

                                                    <Menu.Item
                                                        className={mobileLink}
                                                    >
                                                        <NavLink
                                                            to={`/translate`}
                                                        >
                                                            {/*Translate*/}
                                                            <div className="flex items-center">
                                                                <Translate /><span className="ml-2"> Translate </span>
                                                            </div>
                                                        </NavLink>
                                                    </Menu.Item>
                                                    <Menu.Item
                                                        className={mobileLink}
                                                    >
                                                        <NavLink to={`/map`}>
                                                            {/*Geolocation*/}
                                                            <div className="flex items-center">
                                                                <Map /><span className="ml-2"> Geolocation </span>
                                                            </div>
                                                        </NavLink>
                                                    </Menu.Item>
                                                    <div className="w-full flex flex-wrap">
                                                        <Menu.Item
                                                            className={
                                                                mobileLink
                                                            }
                                                        >
                                                            <NavLink
                                                                to={`/profileView`}
                                                            >
                                                                <div className="flex items-center">
                                                                    <Profile /><span className="ml-2"> Profile </span>
                                                                </div>
                                                                {/*Profile*/}
                                                            </NavLink>
                                                        </Menu.Item>
                                                        <Menu.Item
                                                            className={
                                                                mobileLink
                                                            }
                                                            onClick={() => {
                                                                logOutPage();
                                                                window.location.reload();
                                                            }}
                                                        >
                                                            <a href="/">
                                                                <div className="flex items-center">
                                                                    <LogOut /><span className="ml-2"> Logout </span>
                                                                </div>
                                                                {/*Logout*/}
                                                            </a>
                                                        </Menu.Item>
                                                    </div>
                                                </Menu.Items>
                                            </Transition>
                                        </div>
                                    </div>
                                </div>
                            </Popover.Panel>
                        </Menu>
                    </div>

                    <Popover.Group
                        as="nav"
                        className="hidden space-x-8 lg:flex"
                    >
                        <NavLink
                            to={`/`}
                            end
                            className={({ isActive }) =>
                                isActive ? activeLink : normalLink
                            }
                        >
                            <div className="flex flex-col items-center">
                                <Home />
                                <span className="mt-1">Home</span>
                            </div>
                        </NavLink>


                        <div className="relative">
                            <button className="peer text-lg font-medium text-gray-500 hover:text-fuchsia-600">
                                <div className="flex flex-col items-center">
                                    <Resource />
                                    <span className="mt-1">Resources</span>
                                </div>
                            </button>

                            <div className="hidden peer-hover:flex hover:flex w-40 flex-col bg-white drop-shadow-lg overflow-clip absolute">
                                <Link
                                    className="px-5 py-3 text-base font-medium text-gray-500 hover:text-fuchsia-600 cursor-pointer"
                                    to={`/resource/articles`}
                                >
                                    Articles
                                </Link>
                                <Link
                                    className="px-5 py-3 text-base font-medium text-gray-500 hover:text-fuchsia-600 cursor-pointer"
                                    to={`/resource/files`}
                                >
                                    Videos
                                </Link>
                                <Link
                                    className="px-5 py-3 text-base font-medium text-gray-500 hover:text-fuchsia-600 cursor-pointer"
                                    to={`/resource/links`}
                                >
                                    Links
                                </Link>
                            </div>
                        </div>

                        {featureService.shouldShowContent("Event View") ? (
                            <NavLink
                                to={`/events`}
                                className={({ isActive }) =>
                                    isActive ? activeLink : normalLink
                                }
                            >
                                <div className="flex flex-col items-center">
                                    <Event /><span className="mt-1"> Events </span>
                                </div>
                            </NavLink>
                        ) : (
                            <></>
                        )}
                        {featureService.shouldShowContent("Training View") ? (
                            <NavLink
                                to={`/training`}
                                className={({ isActive }) =>
                                    isActive ? activeLink : normalLink
                                }
                            >

                                <div className="flex flex-col items-center">
                                    <Training /><span className="mt-1"> Learning </span>
                                </div>
                            </NavLink>
                        ) : (
                            <></>
                        )}
                        <NavLink
                            to={`/job`}
                            className={({ isActive }) =>
                                isActive ? activeLink : normalLink
                            }
                        >

                            <div className="flex flex-col items-center">
                                <Career /><span className="mt-1"> Career </span>
                            </div>
                        </NavLink>
                        {featureService.shouldShowContent("Host View") ? (
                            <NavLink
                                to={`/host`}
                                className={({ isActive }) =>
                                    isActive ? activeLink : normalLink
                                }
                            >

                                <div className="flex flex-col items-center">
                                    <Host /><span className="mt-1"> Connect </span>
                                </div>
                            </NavLink>
                        ) : (
                            <></>
                        )}

                        <NavLink
                            to={`/chat`}
                            className={({ isActive }) =>
                                isActive ? activeLink : normalLink
                            }
                        >
                            {unread > 0 ? (
                                <span>
                                    <div className="flex flex-col items-center">
                                        <Chat /><span className="mt-1"> Chat{"  "} </span>
                                    </div>
                                    <span className="inline-flex items-center px-1.5 py-1 text-sm font-medium text-center text-white bg-fuchsia-800 rounded-lg ">
                                        {" "}
                                        {unread}
                                    </span>
                                </span>
                            ) : (
                                <span>
                                    <div className="flex flex-col items-center">
                                        <Chat /><span className="mt-1"> Chat </span>
                                    </div>
                                </span>

                            )}
                        </NavLink>

                        <NavLink
                            to={`/translate`}
                            className={({ isActive }) =>
                                isActive ? activeLink : normalLink
                            }
                        >

                            <div className="flex flex-col items-center">
                                <Translate /><span className="mt-1"> Translate </span>
                            </div>
                        </NavLink>
                        <NavLink
                            to={`/map`}
                            className={({ isActive }) =>
                                isActive ? activeLink : normalLink
                            }
                        >

                            <div className="flex flex-col items-center">
                                <Map /><span className="mt-1"> Geolocation </span>
                            </div>
                        </NavLink>
                    </Popover.Group>
                    <div className="hidden items-center justify-end lg:flex lg:flex-1 lg:w-0">
                        <Menu
                            as="div"
                            className="items-center justify-end rounded-b"
                        >
                            <div>
                                <Menu.Button
                                    className="ml-6 mt-1"
                                    aria-hidden="true"
                                >
                                    {profilePicUrl === "No Profile Picture" || profilePicUrl === null ? (
                                        <svg
                                            className="h-12 w-12 rounded-full bg-green-400 text-gray-400"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                                                clipRule="evenodd"
                                            ></path>
                                        </svg>
                                    ) : (
                                        <img
                                            className="h-12 w-12 rounded-full"
                                            src={profilePicUrl}
                                            alt=""
                                        />
                                    )}

                                    <span
                                        style={{ marginLeft: "-15px" }}
                                        className="mt-0.5 block text-sm text-gray-900"
                                    >
                                        {usrname}
                                    </span>
                                </Menu.Button>
                            </div>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="absolute z-10 top-15 right-5 items-center w-44 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="px-4 py-3">
                                        <span className="block text-sm text-gray-900">
                                            {usrname}
                                        </span>
                                        <span className="block text-sm font-medium text-gray-500 truncate">
                                            {usremail}
                                        </span>
                                    </div>
                                    <div className="py-1">
                                        <Menu.Item>
                                            {({ active }) => (
                                                <Link
                                                    to={`/profileView`}
                                                    className={classNames(
                                                        active
                                                            ? "bg-gray-100 text-gray-900"
                                                            : "text-gray-700",
                                                        " px-4 py-2 text-sm w-full flex items-center space-x-3"
                                                    )}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 24 24"
                                                        fill="currentColor"
                                                        className="w-6 h-6"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                                                            clipRule="evenodd"
                                                        />
                                                    </svg>

                                                    <span>Profile</span>
                                                </Link>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <a
                                                    href="/"
                                                    onClick={logOutPage}
                                                    className={classNames(
                                                        active
                                                            ? "bg-gray-100 text-gray-900 w-full"
                                                            : "text-gray-700",
                                                        "px-4 py-2 text-sm w-full flex items-center space-x-3"
                                                    )}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 24 24"
                                                        fill="currentColor"
                                                        className="w-6 h-6"
                                                    >
                                                        <path d="M9.97.97a.75.75 0 011.06 0l3 3a.75.75 0 01-1.06 1.06l-1.72-1.72v3.44h-1.5V3.31L8.03 5.03a.75.75 0 01-1.06-1.06l3-3zM9.75 6.75v6a.75.75 0 001.5 0v-6h3a3 3 0 013 3v7.5a3 3 0 01-3 3h-7.5a3 3 0 01-3-3v-7.5a3 3 0 013-3h3z" />
                                                        <path d="M7.151 21.75a2.999 2.999 0 002.599 1.5h7.5a3 3 0 003-3v-7.5c0-1.11-.603-2.08-1.5-2.599v7.099a4.5 4.5 0 01-4.5 4.5H7.151z" />
                                                    </svg>

                                                    <span>Sign Out</span>
                                                </a>
                                            )}
                                        </Menu.Item>
                                    </div>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </div>
                </div>
            </div>

            <Transition
                as={Fragment}
                enter="duration-200 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <Popover.Panel
                    focus
                    className="absolute z-10 inset-x-0 top-0 origin-top-right transform p-2 transition lg:hidden"
                >
                    <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="px-5 py-2">
                            <div className="flex items-center justify-end">
                                <div className="-mr-2">
                                    <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                        <span className="sr-only">
                                            Close menu
                                        </span>
                                        <XMarkIcon
                                            className="h-6 w-6"
                                            aria-hidden="true"
                                        />
                                    </Popover.Button>
                                </div>
                            </div>
                        </div>
                        <div className="space-y-6 py-6 px-5">
                            <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                                <NavLink
                                    to={`/`}
                                    className={({ isActive }) =>
                                        isActive ? activeLink : normalLink
                                    }
                                >
                                    <div className="flex items-center">
                                        <Home />
                                        <span className="ml-2">Home</span>
                                    </div>
                                </NavLink>


                                <div className="relative">
                                    <button className="peer text-lg font-medium text-gray-500 hover:text-fuchsia-600">
                                        <div className="flex items-center">
                                            <Resource />
                                            <span className="ml-2">Resources</span>
                                        </div>
                                    </button>

                                    <div className="hidden peer-hover:flex hover:flex w-40 flex-col bg-white drop-shadow-lg overflow-clip absolute">
                                        <Link
                                            className="px-5 py-3 text-base font-medium text-gray-500 hover:text-fuchsia-600 cursor-pointer"
                                            to={`/resource/articles`}
                                        >
                                            Articles
                                        </Link>
                                        <Link
                                            className="px-5 py-3 text-base font-medium text-gray-500 hover:text-fuchsia-600 cursor-pointer"
                                            to={`/resource/files`}
                                        >
                                            Videos
                                        </Link>
                                        <Link
                                            className="px-5 py-3 text-base font-medium text-gray-500 hover:text-fuchsia-600 cursor-pointer"
                                            to={`/resource/links`}
                                        >
                                            Links
                                        </Link>
                                    </div>
                                </div>
                                {featureService.shouldShowContent(
                                    "Event View"
                                ) ? (
                                    <NavLink
                                        to={`/events`}
                                        className={({ isActive }) =>
                                            isActive ? activeLink : normalLink
                                        }
                                    >
                                        <div className="flex items-center">
                                            <Event /><span className="ml-2"> Events </span>
                                        </div>
                                    </NavLink>
                                ) : (
                                    <></>
                                )}
                                {featureService.shouldShowContent(
                                    "Training View"
                                ) ? (
                                    <NavLink
                                        to={`/training`}
                                        className={({ isActive }) =>
                                            isActive ? activeLink : normalLink
                                        }
                                    >
                                        {/*Training*/}
                                        {/*Wone Academy*/}
                                        <div className="flex items-center">
                                            <Training />
                                            <span className="ml-2">Learning</span>
                                        </div>
                                    </NavLink>
                                ) : (
                                    <></>
                                )}
                                <NavLink
                                    to={`/job`}
                                    className={({ isActive }) =>
                                        isActive ? activeLink : normalLink
                                    }
                                >
                                    {/*Career*/}
                                    <div className="flex items-center">
                                        <Career />
                                        <span className="ml-2">Career</span>
                                    </div>
                                </NavLink>
                                {featureService.shouldShowContent(
                                    "Host View"
                                ) ? (
                                    <NavLink
                                        to={`/host`}
                                        className={({ isActive }) =>
                                            isActive ? activeLink : normalLink
                                        }
                                    >
                                        {/*Host*/}
                                        <div className="flex items-center">
                                            <Host />
                                            <span className="ml-2">Connect</span>
                                        </div>
                                    </NavLink>
                                ) : (
                                    <></>
                                )}
                                <NavLink
                                    to={`/chat`}
                                    className={({ isActive }) =>
                                        isActive ? activeLink : normalLink
                                    }
                                >
                                    {/*Chat*/}
                                    <div className="flex items-center">
                                        <Chat />
                                        <span className="ml-2">Chat</span>
                                    </div>
                                </NavLink>
                                {resources.map((item) => (
                                    <a
                                        key={item.name}
                                        href={item.href}
                                        className="text-base font-medium text-gray-900 hover:text-gray-700"
                                    >
                                        {item.name}
                                    </a>
                                ))}
                            </div>
                            <div>
                                <Menu
                                    as="div"
                                    className="items-center justify-end rounded-b"
                                >
                                    <div>
                                        <Menu.Button
                                            className="ml-6"
                                            aria-hidden="true"
                                        >
                                            {profilePicUrl ===
                                                "No Profile Picture" || profilePicUrl === null ? (
                                                <svg
                                                    className="h-12 w-12 rounded-full bg-green-400 text-gray-400"
                                                    fill="currentColor"
                                                    viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                                                        clipRule="evenodd"
                                                    ></path>
                                                </svg>
                                            ) : (
                                                <img
                                                    className="h-12 w-12 rounded-full"
                                                    src={profilePicUrl}
                                                    alt=""
                                                />
                                            )}
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className=" z-10 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <div className="px-4 py-3">
                                                <span className="block text-sm text-gray-900">
                                                    {usrname}
                                                </span>
                                                <span className="block text-sm font-medium text-gray-500 truncate">
                                                    {usremail}
                                                </span>
                                            </div>
                                            <div className="py-1">
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <Link
                                                            to={`/profileView`}
                                                            className={classNames(
                                                                active
                                                                    ? "bg-gray-100 text-gray-900"
                                                                    : "text-gray-700",
                                                                " px-4 py-2 text-sm w-full flex items-center space-x-3"
                                                            )}
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 24 24"
                                                                fill="currentColor"
                                                                className="w-6 h-6"
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                                                                    clipRule="evenodd"
                                                                />
                                                            </svg>

                                                            <span>Profile</span>
                                                        </Link>
                                                    )}
                                                </Menu.Item>
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <a
                                                            href="/"
                                                            onClick={logOutPage}
                                                            className={classNames(
                                                                active
                                                                    ? "bg-gray-100 text-gray-900 w-full"
                                                                    : "text-gray-700",
                                                                "px-4 py-2 text-sm w-full flex items-center space-x-3"
                                                            )}
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 24 24"
                                                                fill="currentColor"
                                                                className="w-6 h-6"
                                                            >
                                                                <path d="M9.97.97a.75.75 0 011.06 0l3 3a.75.75 0 01-1.06 1.06l-1.72-1.72v3.44h-1.5V3.31L8.03 5.03a.75.75 0 01-1.06-1.06l3-3zM9.75 6.75v6a.75.75 0 001.5 0v-6h3a3 3 0 013 3v7.5a3 3 0 01-3 3h-7.5a3 3 0 01-3-3v-7.5a3 3 0 013-3h3z" />
                                                                <path d="M7.151 21.75a2.999 2.999 0 002.599 1.5h7.5a3 3 0 003-3v-7.5c0-1.11-.603-2.08-1.5-2.599v7.099a4.5 4.5 0 01-4.5 4.5H7.151z" />
                                                            </svg>

                                                            <span>
                                                                Sign Out
                                                            </span>
                                                        </a>
                                                    )}
                                                </Menu.Item>
                                            </div>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                        </div>
                    </div>
                </Popover.Panel>
            </Transition>
        </Popover>
    );
};
export default NavBar;
