import { GoogleLogin } from "@leecheuk/react-google-login";
import { gapi } from "gapi-script";
import { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { showError, showInfo } from "../../global/alertService";
import { authService } from "../../services/Account/auth.service";

export function SignIn(props) {
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    // const [isRememberMe,setlsRememberMe] = useState(false);
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const emailConfirmationToken = params.get("emailConfirmationToken");
        if (emailConfirmationToken) {
            const email = params.get("email");
            authService.confirmEmail(email, emailConfirmationToken);
        }
    }, [searchParams]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setData({ ...data, [name]: value });
    };

    function onSubmitSignInForm() {
        const signInData = {
            email: data.email,
            password: data.password,
        };
        authService
            .signin(signInData)
            .then((res) => {
                //console.debug(res.data);
                authService.signout();
                localStorage.setItem("id", res.data?.userId);
                localStorage.setItem("authToken", res.data?.token);
                localStorage.setItem("name", res.data?.name);
                localStorage.setItem("email", res.data?.email);
                localStorage.setItem("userType", res.data?.userType);
                if (res.data?.profilePicUrl === null) {
                    localStorage.setItem("profilePicUrl", "No Profile Picture");
                } else {
                    localStorage.setItem(
                        "profilePicUrl",
                        res.data?.profilePicUrl
                    );
                }
                localStorage.setItem(
                    "features",
                    JSON.stringify(res.data?.userAvailableFeatures)
                );
                setData(null);
                navigate("/");
                window.location.reload();
            })
            .catch((error) => {
                // showInfo(error.message);
                // if (error.data === undefined) {
                //     showError("Email or Password is not correct!");
                // }
            });
    }

    const clientId =
        "454589988547-0c92pp0eu9c8nrhccist0rdhqr1hr7g7.apps.googleusercontent.com";

    useEffect(() => {
        const initClient = () => {
            gapi.client.init({
                clientId: clientId,
                scope: "https://www.googleapis.com/auth/cloud-platform",
            });
        };
        gapi.load("client:auth2", initClient);
    });

    const onSuccess = (res) => {
        console.debug("success:", res);

        authService
            .signinExt({
                provider: "Google",
                idToken: res?.tokenId,
            })
            .then((res) => {
                localStorage.setItem("id", res.data?.userId);
                localStorage.setItem("authToken", res.data?.token);
                localStorage.setItem("name", res.data?.name);
                localStorage.setItem("email", res.data?.email);
                localStorage.setItem("userType", res.data?.userType);
                if (res.data?.profilePicUrl === null) {
                    localStorage.setItem("profilePicUrl", "No Profile Picture");
                } else {
                    localStorage.setItem(
                        "profilePicUrl",
                        res.data?.profilePicUrl
                    );
                }
                localStorage.setItem(
                    "features",
                    JSON.stringify(res.data?.userAvailableFeatures)
                );
                localStorage.setItem("extUser", "External");

                setData(null);
                navigate("/");
                window.location.reload();
            })
            .catch((error) => {
                console.error(error);
            });
    };
    const onFailure = (err) => {
        console.error("failed:", err);
    };

    return (
        <Fragment>
            <div className="flex flex-col justify-center items-center mt-24 overflow-hidden">
                <h1 className="text-4xl font-bold text-fuchsia-800 mb-6">
                    Welcome Back!
                </h1>
                {/*<h2 className="text-2xl font-bold mt-4 mb-6">Enjoying our services.</h2>*/}
                <form
                    onSubmit={(event) => {
                        event.preventDefault();
                        onSubmitSignInForm();
                    }}
                >
                    <div className="block p-4 ml-6">
                        <div className="grid grid-cols-1 mb-4 ">
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900 ">
                                    Email
                                </label>
                                <input
                                    id="email"
                                    value={data?.email}
                                    onChange={handleInputChange}
                                    type="email"
                                    name="email"
                                    className="bg-gray-50 lg:w-96 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 mb-4 sm:w-full"
                                    placeholder="Enter your email"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900 ">
                                    Password
                                </label>
                                <input
                                    id="password"
                                    value={data?.password}
                                    onChange={handleInputChange}
                                    type="password"
                                    name="password"
                                    placeholder="••••••••"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                    required
                                />
                            </div>
                        </div>

                        <div className="flex justify-between mt-4">
                            <Link
                                to={"/lostPassword"}
                                onClick={props.onClose}
                                className="text-sm text-fuchsia-800 hover:underline "
                            >
                                Lost Password?
                            </Link>
                        </div>
                        <button
                            type="submit"
                            className="w-full mt-4 text-white bg-fuchsia-800 hover:bg-green-800 font-bold rounded-lg text-xl px-5 py-2.5 text-center "
                        >
                            Login to your account
                        </button>
                        <div className="text-sm mt-4 font-medium text-gray-500 ">
                            Not registered?{" "}
                            <Link
                                to={"/signUp"}
                                onClick={props.onClose}
                                className="text-fuchsia-800 hover:underline "
                            >
                                Create account
                            </Link>
                        </div>
                    </div>
                </form>

                <div className="mt-2 text-center">
                    <h5 className="text-lg mb-2">Or</h5>
                    <GoogleLogin
                        clientId={clientId}
                        buttonText="Sign in with Google"
                        onSuccess={onSuccess}
                        onFailure={onFailure}
                        cookiePolicy={"single_host_origin"}
                        isSignedIn={true}
                    />

                    {/* <form method='POST' action={`https://api.domain.com/identity/v1/account/external-login?provider=Google&returnUrl=/home`} >
                        <button type="submit"
                            name='provider'
                            value='Google' className="text-white bg-[#4285F4] hover:bg-[#4285F4]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2">
                            <svg class="w-4 h-4 mr-2 -ml-1" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="google" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512"><path fill="currentColor" d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"></path></svg>
                            Sign in with Google
                        </button>
                    </form>
                    {/* <button type="button" className="text-white bg-[#3b5998] hover:bg-[#3b5998]/90 focus:ring-4 focus:outline-none focus:ring-[#3b5998]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2">
                        <svg class="w-4 h-4 mr-2 -ml-1" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M279.1 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.4 0 225.4 0c-73.22 0-121.1 44.38-121.1 124.7v70.62H22.89V288h81.39v224h100.2V288z"></path></svg>
                        Sign in with Facebook
                    </button> */}
                </div>
            </div>
        </Fragment>
    );
}
