import http from '../../global/http-common'
const route = '/TrainingContents';
class TrainingContentService {
    getAll() {
        return http.get("/TrainingContents");
    }
    get(id) {
        return http.get(`/TrainingContents/${id}`);
    }
    create(data) {
        return http.post("/TrainingContents", data);
    }

    update(id, data) {
        return http.put(`/TrainingContents/${id}`, data);
    }

    delete(id) {
        return http.delete(`/TrainingContents/${id}`);
    }
    getByCourse(trainingId) {
        return http.get(route + "?trainingId=" + trainingId);
    }

}

export default new TrainingContentService();