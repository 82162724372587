import { Fragment, useEffect, useState } from "react";
import { showConfirmation, showSuccess } from "../../global/alertService";
import { Link } from 'react-router-dom';
import SeekerJobPostService from "../../services/SeekerJob/seekerJob.service";
import { authService } from "../../services/Account/auth.service";


export function SeekerJobPostIndex() {

    const [jobs, setJobs] = useState([])
    const [profile, setProfile] = useState([])
    
    useEffect(() => {
        getJobs()
        getProfile()
    }, [])

    const getJobs = () => {
        SeekerJobPostService.GetJobsByUser()
            .then((response) => {
                // console.debug(response)
                setJobs(response.data)
                //console.log(response.data)
            })
    }

    const getProfile = () => {
        authService.profile()
            .then((response) => {
                // console.debug(response)
                setProfile(response.data)
                //console.log(response.data)
            })
    }


    const deleteJob = (id) => {
        showConfirmation()
            .then((result) => {
                if (result.isConfirmed) {
                    SeekerJobPostService.delete(id)
                        .then((response) => {
                            // console.debug(response)
                            getJobs()
                            showSuccess('Deleted successfully.')
                        })
                } else {
                    showSuccess('Not deleted', 'Ok')
                }
            })
    }

    return (
        <Fragment>
            <div className="mb-4">
                
                <Link to={`/seekerJobPost/create`}>
                    <button
                        type="submit"
                        className="inline-flex items-center mt-6 px-5 py-2.5 text-sm font-medium text-center text-white bg-gradient-to-r from-purple-500 to-pink-500 rounded-lg  hover:bg-gradient-to-l"
                    >
                        Create New
                    </button>
                </Link>
            </div>
            <h1 className="font-bold text-xl mb-2">Talent Portfolio List</h1>
            <p className="font-bold text-xl mb-2">User : <span className="text-gray-600 text-sm font-medium">{profile?.firstName + " "}{profile?.lastName}</span></p>
            <div className="w-full ">
                <div className="bg-slate-50 shadow-md rounded-lg my-6 overflow-y-auto h-96">
                    <table className="min-w-max w-full table-auto ">
                        <thead className="sticky top-0">
                            <tr className="bg-fuchsia-800 text-white uppercase text-sm leading-normal">
                                <th className="py-3 px-6 text-left">Job Title</th>
                                <th className="py-3 px-6 text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody className="text-gray-600 text-sm font-light">
                            {
                                jobs.length > 0 ?
                                    (jobs.map(item => (

                                        <tr key={item?.id} className="border-b border-gray-200 hover:bg-gray-100">

                                            <td className="py-3 px-6 text-left whitespace-nowrap">
                                                <div className="flex items-center">
                                                    <span className="font-medium">{item?.jobTitle}</span>
                                                </div>
                                            </td>
                                            <td className="py-3 px-6 text-center">
                                                <div className="flex item-center justify-center">

                                                    <Link to={{ pathname: `/seekerJobPost/update/${item?.id}` }} className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                                        </svg>
                                                    </Link>

                                                    <button onClick={() => deleteJob(item?.id)} className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))) :
                                    (<tr>
                                        <td>No Data</td>
                                    </tr>)
                            }
                        </tbody>
                    </table>
                </div>
            </div>


        </Fragment>
    )
}