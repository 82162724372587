import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ProfileCard from "../../components/Profile/profileCard";
import { showError } from "../../global/alertService";
import jobCategoriesService from "../../services/Category/jobCategories.service";
import SeekerJobPostService from "../../services/SeekerJob/seekerJob.service";
import { toLocalDate } from "../../utils/date-utils";
import { getFormData } from "../../utils/getFormData";

export function SeekerJobPostForm(props) {
    const mySwal = withReactContent(Swal);
    const navigate = useNavigate();

    const initialFormState = {
        jobTitle: "",
        experience: "",
        skills: "",
        //seekerUserId: "",
        jobCategoryId: "",
        availableDate: "",
        expireDate: "",
        facebookUrl: "",
        linkedInUrl: "",
        contactNo: "",
    };

    const [data, setData] = useState(initialFormState);
    const [categories, setCategories] = useState(null);
    const [isUpdate, setIsUpdate] = useState(false);

    const { id } = useParams();

    useEffect(() => {
        getCategories();
        if (id > 0) {
            setIsUpdate(true);
            getSeekerJobPost(id);
        } else {
            setIsUpdate(false);
            setData(initialFormState);
        }
    }, [id, props]);

    const getCategories = () => {
        jobCategoriesService.getAll().then((response) => {
            //console.debug(response);
            setCategories(response.data);
            //console.log(response.data);
        });
    };

    const getSeekerJobPost = (id) => {
        SeekerJobPostService.get(id).then((res) => {
            //console.debug(res)
            setData(res.data);
            //console.log(res.data)
        });
    };

    const handleInputChange = (event) => {
        const { name, value } = event?.target;
        if (name === "image") {
            setData({
                ...data,
                image: event.target?.files[0],
            });
        } else {
            setData({
                ...data,
                [name]: value,
            });
        }
    };
    const saveSeekerJobPost = () => {
        const formData = getFormData(data);
        SeekerJobPostService.create(formData)
            .then((response) => {
                mySwal
                    .fire({
                        title: <strong>Success</strong>,
                        html: <i>Seeker Job Post saved successfully.</i>,
                        icon: "success",
                    })
                    .then(() => {
                        setData(initialFormState);
                        navigate(`/seekerJobPostIndexList`);
                    });
            })
            .catch((err) => {
                showError("Something went wrong");
                console.log(err);
            });
    };

    const updateSeekerJobPost = (id) => {
        const formData = getFormData(data);
        SeekerJobPostService.update(id, formData)
            .then((response) => {
                mySwal
                    .fire({
                        title: <strong>Success</strong>,
                        html: <i>Record updated successfully.</i>,
                        icon: "success",
                    })
                    .then(() => {
                        setData(initialFormState);
                        setIsUpdate(false);
                        navigate(`/seekerJobPostIndexList`);
                    });
            })
            .catch((err) => {
                showError("Something went wrong");
                console.log(err);
            });
    };

    return (
        <Fragment>
            <div className="flex flex-row flex-wrap mt-20 ">
                <div className="md:w-full sm:w-full lg:w-1/4 xl:w-1/4 2xl:w-1/4 ">
                    <div className="block p-6 rounded-lg shadow-lg bg-gray-50 m-6">
                        <ProfileCard />
                    </div>
                </div>
                <div className="mt-6 md:w-full sm:w-full lg:w-3/4 xl:w-3/4 2xl:w-3/4 mb-6 lg:pr-6">
                    <div className="p-6 rounded-lg shadow-lg bg-gray-50 ">
                        {/*<div className="flex flex-col m-4">*/}
                        <h1 className="font-bold text-xl mb-6">
                            Talent portfolio
                        </h1>
                        <div className="mb-4 mr-4 w-full bg-gray-50 rounded-lg border border-gray-200">
                            <form
                                onSubmit={(event) => {
                                    event.preventDefault();
                                    if (!isUpdate) {
                                        saveSeekerJobPost();
                                    } else {
                                        updateSeekerJobPost(id);
                                    }
                                }}
                                className="m-4"
                            >
                                <div className="grid gap-3 mb-4 md:grid-cols-4">
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            Job Title
                                        </label>
                                        <input
                                            name="jobTitle"
                                            value={data?.jobTitle}
                                            onChange={handleInputChange}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            Job Category
                                        </label>
                                        <select
                                            name="jobCategoryId"
                                            value={data?.jobCategoryId}
                                            onChange={handleInputChange}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                            required
                                        >
                                            <option value="">
                                                -- Select Category --
                                            </option>
                                            {categories?.map((item) => (
                                                <option
                                                    key={item.id}
                                                    value={item.id}
                                                >
                                                    {" "}
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            Experience
                                        </label>
                                        <textarea
                                            name="experience"
                                            value={data?.experience}
                                            onChange={handleInputChange}
                                            rows={2}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                            required
                                        />
                                    </div>

                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            Skills
                                        </label>
                                        <textarea
                                            name="skills"
                                            value={data?.skills}
                                            onChange={handleInputChange}
                                            rows={2}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                        />
                                    </div>

                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            Available From
                                        </label>
                                        <input
                                            name="availableDate"
                                            value={toLocalDate(
                                                data?.availableDate
                                            )}
                                            onChange={handleInputChange}
                                            type="date"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            Expire Date
                                        </label>
                                        <input
                                            name="expireDate"
                                            value={toLocalDate(
                                                data?.expireDate
                                            )}
                                            onChange={handleInputChange}
                                            type="date"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            Facebook Url
                                        </label>
                                        <input
                                            name="facebookUrl"
                                            value={data?.facebookUrl}
                                            onChange={handleInputChange}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                        />
                                    </div>
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            LinkedIn Url
                                        </label>
                                        <input
                                            name="linkedInUrl"
                                            value={data?.linkedInUrl}
                                            onChange={handleInputChange}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                        />
                                    </div>
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            Contact No
                                        </label>
                                        <input
                                            name="contactNo"
                                            value={data?.contactNo}
                                            onChange={handleInputChange}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            Upload CV
                                        </label>
                                        <input
                                            name="image"
                                            type="file"
                                            onChange={handleInputChange}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-row justify-end items-center">
                                    <div>
                                        <Link
                                            to="/seekerJobPostIndexList"
                                            className="px-10 mr-4 hover:text-green-800 "
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth={1.5}
                                                stroke="currentColor"
                                                className="w-6 h-6 "
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                                                />
                                            </svg>
                                        </Link>
                                    </div>
                                    <div>
                                        <button
                                            className="text-right bg-fuchsia-800 text-white hover:bg-green-800 font-bold  text-sm px-6 py-2.5 mb-4 rounded shadow hover:shadow-lg outline-none focus:outline-none inline-block"
                                            type="submit"
                                        >
                                            {isUpdate ? "Update" : "Save"}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
