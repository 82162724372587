import { Fragment, useEffect, useState } from "react";
import { InstractorInfo } from "../../components/Profile/instractorInfo";
import { showSuccess } from "../../global/alertService";
import { Enums } from "../../global/enums";
import { authService } from "../../services/Account/auth.service";
import { cityService } from "../../services/City/cities.service";
import { enumService } from "../../services/Common/enum.service";
import { countryService } from "../../services/Country/countries.service";
import { degreesService } from "../../services/Degree/degrees.service";
import { featureService } from "../../services/Feature/feature.service";
import { relationshipsService } from "../../services/Relationship/relationships.service";
import { stateService } from "../../services/State/states.service";
import { Host } from "./host";
import { HostInfo } from "../../components/Profile/hostInfoModal";
import { AboutInstructor } from "../../components/Profile/aboutInstrutorModal";

export function GeneralInformation({ profile }) {
    const [profileGeneralInfo, setProfileGeneralInfo] = useState({});
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [genders, setGenders] = useState([]);
    const [showHost, setShowHost] = useState(false);
    const [showInstractor, setShowInstractor] = useState(false);

    const [relationships, setRelationships] = useState([]);
    const [degrees, setDegrees] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [showInsModal, setShowInsModal] = useState(false);

    useEffect(() => {
        getCountries();
        getGender();
        getRelationships();
        getDegrees();
        if (profile?.email) {
            setProfileGeneralInfo({
                firstName: profile?.firstName,
                lastName: profile?.lastName,
                email: profile?.email,
                phoneNumber: profile?.phoneNumber,
                dateOfBirth: profile?.dateOfBirth,
                gender: profile?.gender,
                countryId: profile?.countryId,
                stateId: profile?.stateId,
                cityId: profile?.cityId,
                location: profile?.location,
                isHost: profile?.isHost,
                isInstractor: profile?.isInstractor,
                relationshipId: profile?.relationshipId,
                degreeId: profile?.degreeId,
                numberOfChildren: profile?.numberOfChildren,
            });
            if (profile.countryId != null) {
                getStates(profile?.countryId);
            }
            if (profile.stateId != null) {
                getCities(profile?.stateId);
            }
            setShowHost(profile?.isHost);
            setShowInstractor(profile?.isInstractor);
        }
    }, [profile]);

    const handleInputChange = (event) => {
        const { name, value } = event?.target;
        setProfileGeneralInfo({ ...profileGeneralInfo, [name]: value });
    };

    const getCountries = () => {
        countryService.getAll().then((response) => {
            setCountries(response.data);
        });
    };

    const getGender = () => {
        enumService.get(Enums.GENDER).then((response) => {
            setGenders(response.data);
        });
    };

    const getStates = (countryId) => {
        stateService.getByCountry(countryId).then((res) => {
            setStates(res.data);
        });
    };

    const getCities = (stateId) => {
        cityService.getByState(stateId).then((res) => {
            setCities(res.data);
        });
    };

    const getRelationships = () => {
        relationshipsService.getAll().then((response) => {
            setRelationships(response.data);
        });
    };

    const getDegrees = () => {
        degreesService.getAll().then((response) => {
            setDegrees(response.data);
        });
    };

    const updateProfile = () => {
        if (profileGeneralInfo.relationshipId === "") {
            profileGeneralInfo.relationshipId = null;
        }
        if (profileGeneralInfo.degreeId === "") {
            profileGeneralInfo.degreeId = null;
        }
        if (profileGeneralInfo.countryId === "0") {
            profileGeneralInfo.countryId = null;
        }
        if (profileGeneralInfo.stateId === "0") {
            profileGeneralInfo.stateId = null;
        }
        if (profileGeneralInfo.cityId === "") {
            profileGeneralInfo.cityId = null;
        }
        authService.updateProfile(profileGeneralInfo).then((res) => {
            showSuccess("Updated successfully.");
        });
    };

    return (
        <Fragment>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    updateProfile();
                }}
            >
                <div className="block p-6 rounded-lg shadow-lg bg-gray-50 mt-6 ml-6">
                    <h5 className="text-gray-900 text-xl leading-tight font-medium mb-4">
                        General Information
                    </h5>
                    <div className="grid gap-3 mb-4 md:grid-cols-2">
                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 ">
                                First Name
                            </label>
                            <input
                                name="firstName"
                                maxLength="20"
                                value={profileGeneralInfo?.firstName}
                                onChange={handleInputChange}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                required
                            />
                        </div>
                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 ">
                                Last Name
                            </label>
                            <input
                                name="lastName"
                                maxLength="20"
                                value={profileGeneralInfo?.lastName}
                                onChange={handleInputChange}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                required
                            />
                        </div>
                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 ">
                                Email
                            </label>
                            <input
                                name="email"
                                value={profileGeneralInfo?.email}
                                onChange={handleInputChange}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                disabled
                            />
                        </div>
                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 ">
                                Phone No
                            </label>
                            <input
                                name="PhoneNumber"
                                value={profileGeneralInfo?.phoneNumber}
                                onChange={handleInputChange}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                            />
                        </div>
                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 ">
                                Date of Birth
                            </label>
                            <input
                                name="dateOfBirth"
                                value={profileGeneralInfo?.dateOfBirth}
                                onChange={handleInputChange}
                                type="datetime-local"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                            />
                        </div>
                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 ">
                                Gender
                            </label>
                            <select
                                name="gender"
                                value={profileGeneralInfo?.gender}
                                onChange={handleInputChange}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                            >
                                <option value="0">-- Select Gender --</option>
                                {genders?.map((gender) => (
                                    <option value={gender.key} key={gender.key}>
                                        {gender.value}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 ">
                                Relationship Status
                            </label>
                            <select
                                name="relationshipId"
                                value={profileGeneralInfo?.relationshipId}
                                onChange={handleInputChange}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                            >
                                <option value="">
                                    -- Select Relationship --
                                </option>
                                {relationships?.map((relationship) => (
                                    <option
                                        key={relationship.id}
                                        value={relationship.id}
                                    >
                                        {relationship.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 ">
                                Number of children
                            </label>
                            <input
                                name="numberOfChildren"
                                value={profileGeneralInfo?.numberOfChildren}
                                onChange={handleInputChange}
                                type="number"
                                min={0}
                                max={255}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                            />
                        </div>
                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 ">
                                Degree
                            </label>
                            <select
                                name="degreeId"
                                value={profileGeneralInfo?.degreeId}
                                onChange={handleInputChange}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                            >
                                <option value="">-- Select Degree --</option>
                                {degrees?.map((degree) => (
                                    <option key={degree.id} value={degree.id}>
                                        {degree.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 ">
                                Country of Residence
                            </label>
                            <select
                                name="countryId"
                                value={profileGeneralInfo?.countryId}
                                onChange={(e) => {
                                    if (e.target.value === "0") {
                                        profileGeneralInfo.stateId = null;
                                        profileGeneralInfo.cityId = null;
                                    }
                                    getStates(e.target.value);
                                    getCities(e.target.value);

                                    handleInputChange(e);
                                }}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                            >
                                <option value="0">-- Select Country --</option>
                                {countries?.map((countries) => (
                                    <option
                                        key={countries.id}
                                        value={countries.id}
                                    >
                                        {countries.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 ">
                                State{" "}
                            </label>
                            <select
                                name="stateId"
                                value={profileGeneralInfo?.stateId}
                                onChange={(e) => {
                                    if (e.target.value === "0") {
                                        profileGeneralInfo.cityId = null;
                                    }
                                    getCities(e.target.value);
                                    handleInputChange(e);
                                }}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                            >
                                <option value="0">-- Select State --</option>
                                {states?.map((state) => (
                                    <option key={state.id} value={state.id}>
                                        {state.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 ">
                                City{" "}
                            </label>
                            <select
                                name="cityId"
                                value={profileGeneralInfo?.cityId}
                                onChange={(e) => {
                                    handleInputChange(e);
                                }}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                            >
                                <option value="">-- Select City --</option>
                                {cities?.map((city) => (
                                    <option key={city.id} value={city.id}>
                                        {city.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 ">
                                House/Apt No.
                            </label>
                            <input
                                name="location"
                                value={profileGeneralInfo?.location}
                                onChange={handleInputChange}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                            />
                        </div>
                    </div>
                    <div className="grid gap-3 mb-4 md:grid-cols-2">
                        {featureService.shouldShowContent("Be Host") ? (
                            <div className="flex justify-between items-center">
                                <span>
                                    Become a Connector
                                    <input
                                        name="isHost"
                                        onChange={(e) => {
                                            //e.preventDefault();
                                            setProfileGeneralInfo({
                                                ...profileGeneralInfo,
                                                isHost: e.target.checked,
                                            });
                                            setShowHost(e.target.checked);
                                        }}
                                        checked={profileGeneralInfo?.isHost}
                                        type="checkbox"
                                        className="ml-4"
                                    />
                                </span>
                                {/*--*/}
                                <div>
                                     <a
                                        onClick={() => {
                                            setShowModal(true);
                                        }}
                                        href="#"
                                        className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white rounded-lg bg-fuchsia-800 rounded-lg focus:ring-4 focus:ring-blue-200 hover:bg-green-800"
                                    >
                                         Info
                                    </a>
                                </div>
                                {showModal ? (
                                    <HostInfo
                                        onClose={() => {
                                            setShowModal(false);
                                        }}
                                    />
                                ) : null}

                                {/*--*/}
                            </div>
                        ) : (
                            <></>
                        )}
                        {featureService.shouldShowContent("Be Instructor") ? (
                            <div className="flex justify-between items-center">
                                <span>
                                Become an Instructor
                                    <input
                                        name="isInstractor"
                                        onChange={(e) => {
                                            //e.preventDefault();
                                            setProfileGeneralInfo({
                                                ...profileGeneralInfo,
                                                isInstractor: e.target.checked,
                                            });
                                            setShowInstractor(e.target.checked);
                                        }}
                                        checked={
                                            profileGeneralInfo?.isInstractor
                                        }
                                        type="checkbox"
                                        className="ml-4"
                                    />
                                </span>
                                 {/*--*/}
                                 <div>
                                     <a
                                        onClick={() => {
                                            setShowInsModal(true);
                                        }}
                                        href="#"
                                        className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white rounded-lg bg-fuchsia-800 rounded-lg focus:ring-4 focus:ring-blue-200 hover:bg-green-800"
                                    >
                                        Instructor Info
                                    </a>
                                </div>
                                {showInsModal ? (
                                    <AboutInstructor
                                        onClose={() => {
                                            setShowInsModal(false);
                                        }}
                                    />
                                ) : null}

                                {/*--*/}
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className="text-right">
                        <button
                            type="submit"
                            className="text-white bg-fuchsia-800  hover:bg-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                        >
                            Update
                        </button>
                    </div>
                </div>
            </form>
            {showHost && <Host />}
            {showInstractor && <InstractorInfo />}
        </Fragment>
    );
}
