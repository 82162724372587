import { useEffect, useState } from "react";
import { InstractorService } from "../../services/Instractor/instractor.service";

export function InstractorInfo() {
    const [instractor, setInstractor] = useState({
        institute: "",
        designation: "",
        experience: "",
    });

    useEffect(() => {
        get();
    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        // console.debug(name, value, files);
        setInstractor({
            ...instractor,
            [name]: value,
        });
    };

    const get = () => {
        InstractorService.get().then((res) => {
            setInstractor(res.data);
        });
    };

    const save = () => {
        InstractorService.create(instractor).then((res) => {
            get();
        });
    };

    const update = (id) => {
        console.debug(id);
        InstractorService.update(id, instractor).then((res) => {
            setInstractor(res.data);
        });
        window.location.reload();
    };
    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                if (instractor.id > 0) {
                    update(instractor.id);
                } else {
                    save();
                }
            }}
        >
            <div className="block p-6 rounded-lg shadow-lg bg-gray-50 mt-6 ml-6">
                <h5 className="text-gray-900 text-xl leading-tight font-medium mb-4">
                    Instructor Information
                </h5>
                <div className="grid gap-3 mb-4 md:grid-cols-2">
                    <div>
                        <label className="block mb-2 text-sm font-medium text-gray-900 ">
                            Institute
                        </label>
                        <input
                            name="institute"
                            value={instractor?.institute}
                            onChange={handleInputChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                            required
                        />
                    </div>
                    <div>
                        <label className="block mb-2 text-sm font-medium text-gray-900 ">
                            Designation
                        </label>
                        <input
                            name="designation"
                            value={instractor?.designation}
                            onChange={handleInputChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                            required
                        />
                    </div>
                    <div>
                        <label className="block mb-2 text-sm font-medium text-gray-900 ">
                            Experience
                        </label>
                        <input
                            name="experience"
                            value={instractor?.experience}
                            onChange={handleInputChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                            required
                        />
                    </div>
                </div>
                <div className="text-right">
                    <button
                        type="submit"
                        className="text-white bg-fuchsia-800  hover:bg-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    >
                        {instractor.id > 0 ? "Update" : "Save"}
                    </button>
                </div>
            </div>
        </form>
    );
}
