import { React, Fragment} from 'react';
import {TrainingIndex} from "../../components/Trining/trainingIndex"

import ProfileCard from '../../components/Profile/profileCard'


function TrainingIndexList() {

  return (
    <Fragment>

      <div className="flex flex-row flex-wrap mt-20">
        {/*<div className="basis-1/4 mt-14">--*/}
        <div className=" md:w-full sm:w-full lg:w-1/4 xl:w-1/4 2xl:w-1/4 ">
          <div className="block p-6 rounded-lg shadow-lg bg-gray-50 m-6">
            <ProfileCard />
          </div>
        </div>

        {/*<div className="basis-3/4 mt-20 mr-6">*/}
        <div className="mt-6 md:w-full sm:w-full lg:w-3/4 xl:w-3/4 2xl:w-3/4 mb-6 lg:pr-6">

          <div className="p-6 rounded-lg shadow-lg bg-gray-50 ">
            <TrainingIndex />
          </div>

        </div>
        
      </div>
     
    </Fragment>
  )

}
export default TrainingIndexList