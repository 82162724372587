import React, { Fragment, useState } from "react";
import ReactPlayer from "react-player";
import { VideoModal } from "../Video/videoModal";

export default function VideoItem({ src, title }) {
    const [showModal, setShowModal] = useState(false);
    return (
        <Fragment>
            <div
                onClick={() => setShowModal(true)}
                className="cursor-pointer bg-black rounded-md mb-8"
            >
                <ReactPlayer
                    width={"100%"}
                    height={"100%"}
                    url={src}
                    controls={false}
                    playing={false}
                    muted={true}
                />
                <p className="text-base mt-2">{title}</p>
            </div>
            {showModal && (
                <VideoModal src={src} onClose={() => setShowModal(false)} />
            )}
        </Fragment>
    );
}
