import http from '../../global/http-common'
const route = '/JobPosts';
class EmployerJobPostService {
    /*getAll() {
        return http.get("/JobPosts");
    }*/
    getAll(categoryId, countryId, stateId, cityId) {
    
        if (categoryId) {
            if(countryId)
            {
                if(stateId){
                    if(cityId){
                        return http.get(route ,{
                            params: {
                                categoryId: categoryId,
                                cityId: cityId
                            },
                          });
                    }
                    else
                    {
                        return http.get(route ,{
                            params: {
                                categoryId: categoryId,
                                stateId: stateId
                            },
                          });
                    }
                }
                return http.get(route ,{
                    params: {
                        categoryId: categoryId,
                        countryId: countryId
                    },
                  });
            }
            return http.get(route + "?categoryId=" + categoryId);
        }
        else {
            if (countryId) {
                if(stateId){
                    if(cityId){
                        return http.get(route + "?cityId=" + cityId);
                    }
                    else
                    {
                        return http.get(route + "?stateId=" + stateId);
                    }
                }
                else{
                    return http.get(route + "?countryId=" + countryId);
                }
                
            }
            return http.get(route);
        }
    }
 
   // },



    GetJobsByUser () {
        return http.get('JobPosts/GetJobsByUser');
    }
    GetJobsByCity (id) {
        return http.get(`/JobPosts/GetJobsByCity/${id}`);
    }
    /*GetJobsByCityandCategory (cityId,categoryId) {
        return http.get(`/JobPosts/GetJobsByCityandCategory/${cityId},${categoryId}`);
    }*/
    get(id) {
        return http.get(`/JobPosts/${id}`);
    }
    create(data) {
        return http.post("/JobPosts", data);
    }

    update(id, data) {
        return http.put(`/JobPosts/${id}`, data);
    }

    delete(id) {
        return http.delete(`/JobPosts/${id}`);
    }

}

export default new EmployerJobPostService();