import http from "../../global/http-common";
const route = "/GoogleMap";

export const mapService = {
    getPlaces: function create(query, lat, lng, token) {
        //console.log(query, lat, lng, token);
        return http.get(route, {
            params: {
                query: query,
                lat: lat,
                lng: lng,
                token: token,
            },
        });
    },
};
