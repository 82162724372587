import { Menu, Popover, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment} from "react";
import { NavLink } from "react-router-dom";

export function LandingNav() {
    const activeLinkSingUp = "font-bold text-fuchsia-800";
    const normalLinkSingUp =
        "whitespace-nowrap text-base font-medium text-gray-500 hover:text-fuchsia-600";
    const activeLinkSingIn =
        "ml-8 inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-green-800 px-4 py-2 text-base font-medium text-white shadow-sm";
    const normalLinkSingIn =
        "ml-8 inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-fuchsia-800 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-600";
    
    return (
        <Popover className=" w-full z-20 top-0 left-0">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 ">
                <div className="flex items-center justify-between py-2 lg:justify-start lg:space-x-10">
                    <div className="flex justify-start lg:w-0 lg:flex-1">
                        <NavLink to="/" className="">
                            <img
                                className="w-40 py-2 -mt-2 -mb-2 "
                                src="./wone_logo.png"
                                alt="Your Company"
                            />
                        </NavLink>
                    </div>
                    <div className="-my-2 -mr-2 lg:hidden">
                        {/* Mobile Menu */}
                        <Menu as="div">
                            <Menu.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                <span className="sr-only">Open menu</span>
                                <Bars3Icon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                />
                            </Menu.Button>
                            <Popover.Panel
                                focus
                                className="absolute z-10 inset-x-0 top-0 origin-top-right transform p-2 transition lg:hidden"
                            >
                                <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                                    <div className="px-5 py-2">
                                        <div className="flex items-center justify-end">
                                            <div className="-mr-2">
                                                <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                                    <span className="sr-only">
                                                        Close menu
                                                    </span>
                                                    <XMarkIcon
                                                        className="h-6 w-6"
                                                        aria-hidden="true"
                                                    />
                                                </Popover.Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="space-y-6 py-2 px-5">
                                        <div className="grid grid-cols-1 gap-y-4 gap-x-8">
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items className="flex flex-wrap">
                                                    <Menu.Item className="w-1/2 relative grid gap-2 rounded-lg bg-white px-5 sm:gap-2 sm:p-2 hover:bg-gray-50 text-gray-500 hover:text-fuchsia-600">
                                                        <a href="/#aboutUs">
                                                            <div className="flex flex-wrap">
                                                                <span className="text-base font-medium">
                                                                    Focus Area
                                                                </span>
                                                            </div>
                                                        </a>
                                                    </Menu.Item>
                                                    <Menu.Item className="w-1/2 relative grid gap-2 rounded-lg bg-white px-5 sm:gap-2 sm:p-2 hover:bg-gray-50 text-gray-500 hover:text-fuchsia-600">
                                                        <a href="/#mainFeatures">
                                                            <div className="flex flex-wrap">
                                                                <span className="text-base font-medium">
                                                                    Feature
                                                                </span>
                                                            </div>
                                                        </a>
                                                    </Menu.Item>
                                                    <Menu.Item className="w-1/2 relative grid gap-2 rounded-lg bg-white px-5 sm:gap-2 sm:p-2 hover:bg-gray-50 text-gray-500 hover:text-fuchsia-600">
                                                        <a href="/#services">
                                                            <div className="flex flex-wrap">
                                                                <span className="text-base font-medium">
                                                                    How it work
                                                                </span>
                                                            </div>
                                                        </a>
                                                    </Menu.Item>
                                                    <div className="w-full flex flex-wrap">
                                                        <Menu.Item className="w-1/2 relative grid gap-2 rounded-lg bg-white px-5 sm:gap-2 sm:p-2 hover:bg-gray-50 text-gray-500 hover:text-fuchsia-600">
                                                            <NavLink
                                                                to={"/signUp"}
                                                            >
                                                                <div className="flex flex-wrap">
                                                                    <span className="text-base font-medium">
                                                                        Sign Up
                                                                    </span>
                                                                </div>
                                                            </NavLink>
                                                        </Menu.Item>
                                                        <Menu.Item className="w-1/2 relative grid gap-2 rounded-lg bg-white px-5 sm:gap-2 sm:p-2 hover:bg-gray-50 text-gray-500 hover:text-fuchsia-600">
                                                            <NavLink
                                                                to={"/signIn"}
                                                            >
                                                                <div className="flex flex-wrap">
                                                                    <span className="text-base font-medium">
                                                                        Sign In
                                                                    </span>
                                                                </div>
                                                            </NavLink>
                                                        </Menu.Item>
                                                    </div>
                                                </Menu.Items>
                                            </Transition>
                                        </div>
                                    </div>
                                </div>
                            </Popover.Panel>
                        </Menu>
                    </div>

                    <Popover.Group
                        as="nav"
                        className="hidden space-x-10 lg:flex"
                    >
                        <a
                            href="/#aboutUs"
                            className="text-base font-medium text-black hover:text-fuchsia-600"
                        >
                            Focus Area
                        </a>

                        <a
                            href="/#mainFeatures"
                            className="text-base font-medium text-black hover:text-fuchsia-600"
                        >
                            Feature
                        </a>
                        <a
                            href="/#services"
                            className="text-base font-medium text-black hover:text-fuchsia-600"
                        >
                            How it work
                        </a>
                    </Popover.Group>
                    <div className="hidden items-center justify-end lg:flex lg:flex-1 xl:w-0">
                        <NavLink
                            to={"/signUp"}
                            className={({ isActive }) =>
                                isActive ? activeLinkSingUp : normalLinkSingUp
                            }
                        >
                            Sign Up
                        </NavLink>
                        <NavLink
                            to={"/signIn"}
                            className={({ isActive }) =>
                                isActive ? activeLinkSingIn : normalLinkSingIn
                            }
                        >
                            Sign In
                        </NavLink>
                    </div>
                </div>
            </div>
        </Popover>
    );
}
