import { Fragment, useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import jobCategoriesService from "../../services/Category/jobCategories.service";
import { cityService } from "../../services/City/cities.service";
import { countryService } from "../../services/Country/countries.service";
import { featureService } from "../../services/Feature/feature.service";
import SeekerJobPostService from "../../services/SeekerJob/seekerJob.service";
import { stateService } from "../../services/State/states.service";
import { Pagination } from "../Pagination/pagination";
import { SingleSeekerJobPost } from "./singleSeekerJobPost";

export function Seeker() {
    const itemsPerPage = 12;
    const [allJobPosts, setAllJobPosts] = useState([]);
    const [itemOffset, setItemOffset] = useState(0);

    const [searchTerm, setSearchTerm] = useState("");
    const [filteredItems, setFilteredItems] = useState([]);

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = filteredItems.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(filteredItems.length / itemsPerPage);
    //--
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [countryId, setCountryId] = useState(null);
    const [stateId, setStateId] = useState(null);
    const [cityId, setCityId] = useState(null);
    const [categories, setCategories] = useState(null);
    const [categoryId, setCategoryId] = useState(null);

    useEffect(() => {
        getCountries();
        getCategories();
        getSeekerJobPosts(categoryId, countryId, stateId, cityId);
    }, [categoryId, countryId, stateId, cityId]);

    const getCountries = () => {
        countryService.getAll().then((response) => {
            // console.debug(response)
            setCountries(response.data);
        });
    };

    const getStates = (countryId) => {
        stateService.getByCountry(countryId).then((res) => {
            console.debug(res);
            setStates(res.data);
        });
    };

    const getCities = (stateId) => {
        cityService.getByState(stateId).then((res) => {
            console.debug(res);
            setCities(res.data);
        });
    };

    const getCategories = () => {
        jobCategoriesService.getAll().then((response) => {
            //console.debug(response);
            setCategories(response.data);
            //console.log(response.data);
        });
    };

    useEffect(() => {
        setFilteredItems([...allJobPosts]);
    }, [allJobPosts]);

    // filtering job posts on search input
    useEffect(() => {
        if (searchTerm !== "") {
            const filteredData = allJobPosts.filter((item) => {
                return Object.values(item)
                    .join("")
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase());
            });
            setFilteredItems(filteredData);
        } else {
            setFilteredItems([...allJobPosts]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm]);

    // get all jobs from seekers
    const getSeekerJobPosts = (categoryId, countryId, stateId, cityId) => {
        SeekerJobPostService.getAll(
            categoryId,
            countryId,
            stateId,
            cityId
        ).then((response) => {
            setAllJobPosts(response.data);
        });
    };

    // update trainings if an event is deleted
    const updateJobPosts = useCallback((id) => {
        /*setAllJobPosts((allTrainings) =>
            allTrainings.filter((training) => training.id !== id)
        );*/
        setAllJobPosts((allJobPosts) =>
            allJobPosts.filter((jobpost) => jobpost.id !== id)
        );
    }, []);

    // handle plage change in pagination
    const handlePageChange = (e) => {
        const newOffset = (e.selected * itemsPerPage) % allJobPosts.length;
        setItemOffset(newOffset);
    };

    // component to show items
    const Items = ({ currentItems }) => {
        return (
            <>
                {currentItems &&
                    currentItems.map((jobpost) => (
                        <SingleSeekerJobPost
                            jobpost={jobpost}
                            key={jobpost.id}
                            updateJobPosts={updateJobPosts}
                        />
                    ))}
            </>
        );
    };

    return (
        <Fragment>
            <div className="px-4 bg-white w-full">
                {featureService.shouldShowContent("Employer Manage") ? (
                    <div>
                        <Link
                            to={`/employerJobPost/create`}
                            className="mb-2 inline-flex items-center px-5 py-2.5 text-sm font-medium text-center text-white bg-gradient-to-r from-purple-500 to-pink-500 rounded-lg  hover:bg-gradient-to-l"
                        >
                            Create New Job
                        </Link>
                    </div>
                ) : (
                    <></>
                )}
                <div className="mb-4 grid gap-3 mr-6 md:grid-cols-4 sm:grid-cols-1">
                    <div>
                        <select
                            name="countryId"
                            onChange={(e) => {
                                getStates(e.target.value);
                                setCountryId(e.target.value);
                            }}
                            className="bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg block w-full p-2.5"
                        >
                            <option value="">Select Country</option>
                            {countries?.map((countries) => (
                                <option key={countries.id} value={countries.id}>
                                    {countries.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <select
                            name="stateId"
                            onChange={(e) => {
                                getCities(e.target.value);
                                setStateId(e.target.value);
                            }}
                            className="bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg block w-full p-2.5"
                        >
                            <option value="">Select State</option>
                            {states?.map((state) => (
                                <option key={state.id} value={state.id}>
                                    {state.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <select
                            name="cityId"
                            onChange={(e) => {
                                setCityId(e.target.value);
                            }}
                            className="bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg block w-full p-2.5"
                        >
                            <option value="">Select City</option>
                            {cities?.map((city) => (
                                <option key={city.id} value={city.id}>
                                    {city.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <select
                            name="jobCategoryId"
                            onChange={(e) => {
                                setCategoryId(e.target.value);
                            }}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                        >
                            <option value="">-- Select Category --</option>
                            {categories?.map((item) => (
                                <option key={item.id} value={item.id}>
                                    {" "}
                                    {item.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="flex flex-wrap">
                    <div className="font-bold text-3xl mb-4 text-fuchsia-800 md:w-3/5 sm:w-full lg:w-2/3 xl:w-2/3 2xl:w-2/3 sm:text-center md:text-left">
                        {/*Welcome Job Seekers!*/}
                        Welcome Employers!
                    </div>
                    <div className="md:w-2/5 sm:w-full lg:w-1/3 xl:w-1/3 2xl:w-1/3 mb-4 text-base text-fuchsia-800">
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 float-right"
                            placeholder="Search talent jobs"
                        />
                    </div>
                </div>
                <div>
                    <p className="text-xl font-medium leading-relaxed mb-4 text-gray-500">
                        We’re here to help you find your next talent.
                    </p>
                </div>
            </div>
            <div className="mt-4 mx-auto w-full">
                <div className="flex flex-wrap mx-4 justify-around">
                    <Items currentItems={currentItems} />
                </div>
                <Pagination
                    handlePageChange={handlePageChange}
                    pageCount={pageCount}
                />
            </div>
        </Fragment>
    );
}
