import React, { useEffect, useState } from "react";
import { Employer } from "../../components/Job/employerJobPost";
import { Message } from "../../components/Job/inPersonMsgService";
import { Seeker } from "../../components/Job/seekerJobPosts";
import { authService } from "../../services/Account/auth.service";
import { featureService } from "../../services/Feature/feature.service";

function Job() {
    const [activeTab, setActiveTab] = React.useState("");

    const [membershipType, setMembershipType] = useState(0);

    const checkEmployerVisibility = () => {
        if (featureService.shouldShowContent("Employer Manage")) {
            setActiveTab("seeker");
        } else {
            setActiveTab("employer");
        }
    }

    const handleEmployer = () => {
        setActiveTab("seeker");
    };

    const handleSeeker = () => {
        setActiveTab("employer");
    };

    const handleMessage = () => {
        setActiveTab("message");
    };

    const getProfile = React.useCallback(() => {
        authService.profile().then((response) => {
            setMembershipType(response?.data.membershipType);
            checkEmployerVisibility();
        });
    }, []);

    useEffect(() => {
        getProfile();
    }, [getProfile]);


    return (
        <>
            <div className="pt-4">
                <div
                    className="flex relative flex-row justify-center text-center w-full h-80"
                    style={{
                        //backgroundImage: `url("https://lacity.gov/sites/g/files/wph1996/files/styles/medium_hero_image_jumbotron_floating_1600x432_/public/2022-04/LACity22-Jobs_less_dark_V7c.jpg.webp?itok=yLGzKark")`,
                        backgroundImage: `url("./images_v1/job.png")`,
                        backgroundColor: "#cccccc",
                        backgroundPosition: "center top",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                    }}
                >
                    {/* <img src="https://lacity.gov/sites/g/files/wph1996/files/styles/medium_hero_image_jumbotron_floating_1600x432_/public/2022-04/LACity22-Jobs_less_dark_V7c.jpg.webp?itok=yLGzKark" alt=".." />
                     */}
                    <h1 className="absolute text-4xl text-white  font-bold top-3/4 left-1/2 -translate-x-1/2 -translate-y-1/2">
                        Develop your career with us...
                    </h1>
                </div>
            </div>

            <div className="px-4 mb-4">
                <div className="border-b border-gray-200">
                    <ul className="flex flex-wrap -mb-px text-sm font-medium text-center">
                        {/*{featureService.shouldShowContent('Employer View')*/}
                        {featureService.shouldShowContent("Seeker View") ? (
                            <li
                                className={
                                    activeTab === "employer"
                                        ? "text-fuchsia-600 border-b-2 border-transparent border-fuchsia-300"
                                        : "text-gray-500 border-b-2 border-transparent hover:text-fuchsia-600 hover:border-fuchsia-300"
                                }
                                onClick={handleSeeker}
                            >
                                <button className=" inline-block p-4 rounded-t-lg ">
                                    Job Opening{/*Employer*/}
                                </button>
                            </li>
                        ) : (
                            <></>
                        )}
                        {/* {featureService.shouldShowContent('Seeker View')*/}
                        {featureService.shouldShowContent("Employer View") &&
                            membershipType === 3 ? (
                            <li
                                className={
                                    activeTab === "seeker"
                                        ? "text-fuchsia-600 border-b-2 border-transparent border-fuchsia-300"
                                        : "text-gray-500 border-b-2 border-transparent hover:text-fuchsia-600"
                                }
                                onClick={handleEmployer}
                            >
                                <button className=" inline-block p-4 rounded-t-lg ">
                                    Employer{/*Job Seeker*/}
                                </button>
                            </li>
                        ) : (
                            <></>
                        )}

                        <li
                            className={
                                activeTab === "message"
                                    ? "text-fuchsia-600 border-b-2 border-transparent border-fuchsia-300"
                                    : "text-gray-500 border-b-2 border-transparent hover:text-fuchsia-600"
                            }
                            onClick={handleMessage}
                        >
                            <button className=" inline-block p-4 rounded-t-lg ">
                                Contact For In Person Service
                            </button>
                        </li>
                    </ul>
                </div>
            </div>

            {/*--{activeTab === "employer" ?
                <>
                    
                    <div className="ml-4 mr-4 flex flex-wrap justify-between mb-4">
                    <Employer /> 
                    </div>
                </>
                :
                <>
                    
                    <div className="ml-4 mr-4 flex flex-wrap justify-between mb-4">
                    <Seeker />
                    </div>
                </>
            }*/}
            {activeTab === "employer" ? (
                <>
                    <div className="ml-4 mr-4 flex flex-wrap justify-between mb-4">
                        <Employer />
                    </div>
                </>
            ) : (
                <></>
            )}
            {activeTab === "seeker" ? (
                <>
                    <div className="ml-4 mr-4 flex flex-wrap justify-between mb-4">
                        <Seeker />
                    </div>
                </>
            ) : (
                <></>
            )}
            {activeTab === "message" ? (
                <>
                    <div className="ml-4 mr-4 flex flex-wrap justify-center mb-4">
                        <Message />
                    </div>
                </>
            ) : (
                <></>
            )}

            <hr />
        </>
    );
}
export default Job;
