import React, { Fragment } from "react";
import { CrossIcon } from "../Icons/crossIcon";

export function RegisterInfo(props) {

    return (
        <Fragment>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-full my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-3xl text-gray font-semibold">
                                Interested in Registering For Courses Online !
                            </h3>

                            <button
                                className="absolute top-5 right-5 cursor-pointer"
                                onClick={props.onClose}
                            >
                                <CrossIcon />
                            </button>
                        </div>

                        {/*body*/}
                        <div className="py-6 px-6 lg:px-8">
                            <div
                                className="w-11/12 mx-auto mb-6"
                                style={{ maxHeight: "400px", overflowY: "auto" }}
                            >
                                <p style={{ fontSize: '1.25rem', fontWeight: 'semibold' }}>
                                    Welcome to the WONE platform, where we&#39;re passionate about connecting knowledge seekers
                                    with experts from around the world!
                                </p>
                                
                                <p style={{ marginTop: '10px', fontSize: '1.25rem', color: 'blue' }}>
                                    Diverse Range of Courses :
                                </p>

                                <div style={{ margin: '0.5rem 0' }}>
                                    <p>
                                        We offer a vast array of courses to choose from, across various
                                        domains. Whether you&#39;re looking to pick up a new hobby, enhance your professional skills, or
                                        simply satisfy your curiosity, we have something for everyone.
                                    </p>
                                </div>
                                <p style={{ fontSize: '1.25rem', color: 'blue' }}>
                                    Skill-Building Opportunities :
                                </p>

                                <div style={{ margin: '0.5rem 0' }}>
                                    <p>
                                        Our courses are specifically designed to provide valuable skill-
                                        building opportunities. They are a fantastic way to broaden your horizons and enhance your skill
                                        set.
                                    </p>
                                </div>
                                <p style={{ fontSize: '1.25rem', color: 'blue' }}
                                >
                                    Course Credits :
                                </p>

                                <div style={{ margin: '0.5rem 0' }}>
                                    <p>
                                        Please note that our courses can&#39;t be used as college credits. They are, however,
                                        a stepping stone towards personal and professional growth.
                                    </p>
                                </div>
                                <p style={{ fontSize: '1.25rem' }}>
                                    Join us on this journey of learning and growing together. Sign up for a course today and start
                                    exploring!
                                </p>
                            </div>
                        </div>
                        {/*footer*/}
                        <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                            <button
                                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={props.onClose}
                            >
                                Close
                            </button>

                        </div>
                    </div>
                </div>
            </div>

            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </Fragment>
    );
}
