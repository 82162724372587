import { useEffect, useState } from "react";
import { showConfirmation, showSuccess } from "../../global/alertService";
import HostDataService from "../../services/Host/host.service";

export function Host() {
    const initialFormState = {
        description: "",
        facebookUrl: "",
        linkedInUrl: "",
        twitterUrl: "",
        instagramUrl: "",
        hobbies: "",
    };
    const [host, setHost] = useState(initialFormState);

    useEffect(() => {
        getHostInfo();
    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setHost({
            ...host,
            [name]: value,
        });
    };

    const getHostInfo = () => {
        HostDataService.get().then((res) => {
            setHost(res.data);
        });
    };

    const saveHostInfo = () => {
        HostDataService.create(host).then((res) => {
            getHostInfo();
        });
    };

    const updateHostInfo = (id) => {
        HostDataService.update(id, host).then((res) => {
            setHost(res.data);
            getHostInfo();
        });
    };
    const deleteHostInfo = (id) => {
        showConfirmation().then((result) => {
            if (result.isConfirmed) {
                HostDataService.delete(id).then((response) => {
                    window.location.reload();
                });
            } else {
                showSuccess("Not deleted", "Ok");
            }
        });
    };

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                if (host.id > 0) {
                    updateHostInfo(host?.id);
                } else {
                    saveHostInfo();
                }
            }}
        >
            <div className="block p-6 rounded-lg shadow-lg bg-gray-50 mt-6 ml-6">
                <h5 className="text-gray-900 text-xl leading-tight font-medium mb-4">
                    Host Information
                </h5>
                <div className="grid gap-3 mb-4 md:grid-cols-2">
                    <div>
                        <label className="block mb-2 text-sm font-medium text-gray-900 ">
                            About Host
                        </label>
                        <textarea
                            name="description"
                            value={host?.description}
                            onChange={handleInputChange}
                            rows={4}
                            type="text"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                            placeholder="Write about you..."
                            defaultValue={""}
                        />
                    </div>
                    <div>
                        <label className="block mb-2 text-sm font-medium text-gray-900 ">
                            Facebook URL
                        </label>
                        <div className="m-0 p-0 flex">
                            <span className="inline-flex items-center px-2.5 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l-md">
                                https://facebook.com/
                            </span>
                            <input
                                name="facebookUrl"
                                value={host?.facebookUrl}
                                onChange={handleInputChange}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-r-md block w-full p-2.5"
                            />
                        </div>
                    </div>
                    <div>
                        <label className="block mb-2 text-sm font-medium text-gray-900 ">
                            LinkedIn URL
                        </label>
                        <div className="m-0 p-0 flex">
                            <span className="inline-flex items-center px-2.5 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l-md">
                                https://linkedin.com/in/
                            </span>
                            <input
                                name="linkedInUrl"
                                value={host?.linkedInUrl}
                                onChange={handleInputChange}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-r-md block w-full p-2.5"
                            />
                        </div>
                    </div>
                    <div>
                        <label className="block mb-2 text-sm font-medium text-gray-900 ">
                            Twitter URL
                        </label>
                        <div className="m-0 p-0 flex">
                            <span className="inline-flex items-center px-2.5 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l-md">
                                https://twitter.com/
                            </span>
                            <input
                                name="twitterUrl"
                                value={host?.twitterUrl}
                                onChange={handleInputChange}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-r-md block w-full p-2.5"
                            />
                        </div>
                    </div>
                    <div>
                        <label className="block mb-2 text-sm font-medium text-gray-900 ">
                            Instagram URL
                        </label>
                        <div className="m-0 p-0 flex">
                            <span className="inline-flex items-center px-2.5 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l-md">
                                https://instagram.com/
                            </span>
                            <input
                                name="instagramUrl"
                                value={host?.instagramUrl}
                                onChange={handleInputChange}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-r-md block w-full p-2.5"
                            />
                        </div>
                    </div>
                    <div>
                        <label className="block mb-2 text-sm font-medium text-gray-900 ">
                            Hobbies (Seperated by comma ",")
                        </label>
                        <div className="m-0 p-0 flex">
                            <input
                                name="hobbies"
                                value={host?.hobbies}
                                onChange={handleInputChange}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md block w-full p-2.5"
                            />
                        </div>
                    </div>
                </div>
                <div className="text-right">
                    {host.id > 0 ? (
                        <button
                            type="button"
                            onClick={() => deleteHostInfo(host.id)}
                            className="text-white mr-4 bg-red-700 hover:bg-red-400  font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                        >
                            Delete
                        </button>
                    ) : (
                        <> </>
                    )}
                    <button
                        type="submit"
                        className="text-white bg-fuchsia-800  hover:bg-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    >
                        {host.id > 0 ? "Update" : "Save"}
                    </button>
                </div>
            </div>
        </form>
    );
}
