import { useState } from "react";
import {
    FaBusAlt,
    FaCar,
    FaDirections,
    FaRegClock,
    FaRoad,
    FaWalking,
} from "react-icons/fa";
import { getDistanceAndDuration } from "./GetDistanceAndDuration";

const PlaceDetail = ({
    place,
    center,
    updateDirectionResponse,
    onIconClick,
}) => {
    const [distance, setDistance] = useState("");
    const [duration, setDuration] = useState("");

    const location = place?.geometry?.location;

    // updating travelMode and showing distance and duration
    const updateTravelMode = async (travelMode) => {
        const result = await getDistanceAndDuration(
            center,
            location,
            travelMode
        );
        setDistance(result.distance);
        setDuration(result.duration);
        updateDirectionResponse(result.results);
    };
    //console.log(place);

    return (
        <div className="w-full p-3 text-left bg-white border border-gray-200 rounded-lg shadow-md hover:bg-gray-100 mb-1">
            <div className="flex flex-col mb-2">
                <div className="flex items-start">
                    <img
                        src={place?.icon}
                        alt={place?.name}
                        className="w-4 h-4 mr-2 mt-1"
                    />
                    <h5 className="text-base font-bold tracking-tight text-gray-900">
                        {place?.name}
                    </h5>
                </div>

                <p className="text-[14px]">{place?.formatted_address}</p>
            </div>
            <div className="flex flex-row items-center justify-start border-indigo-600 mb-2">
                <button
                    className="mr-2"
                    type="button"
                    onClick={() => {
                        updateTravelMode("DRIVING");
                        onIconClick((prev) => !prev);
                    }}
                >
                    <FaDirections />
                </button>
                <button
                    className="mr-2"
                    type="button"
                    onClick={() => {
                        updateTravelMode("DRIVING");
                        onIconClick((prev) => !prev);
                    }}
                >
                    <FaCar />
                </button>
                <button
                    className="mr-2"
                    type="button"
                    onClick={() => {
                        updateTravelMode("TRANSIT");
                        onIconClick((prev) => !prev);
                    }}
                >
                    <FaBusAlt />
                </button>
                <button
                    className="mr-2"
                    type="button"
                    onClick={() => {
                        updateTravelMode("WALKING");
                        onIconClick((prev) => !prev);
                    }}
                >
                    <FaWalking />
                </button>
            </div>
            <div>
                {distance && (
                    <div className="text-sm flex">
                        <div className="flex items-center mr-3">
                            <FaRoad className="mr-1" /> : {distance}
                        </div>
                        <div className="flex items-center">
                            <FaRegClock className="mr-1" />: {duration}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PlaceDetail;
