import http from "../../global/http-common";

class HostDataService {
    getAll(countryId, stateId, cityId) {
        if (countryId) {
            if (stateId) {
                if (cityId) {
                    return http.get("/HostLists/All?cityId=" + cityId);
                } else {
                    return http.get("/HostLists/All?stateId=" + stateId);
                }
            } else {
                return http.get("/HostLists/All?countryId=" + countryId);
            }
        }
        return http.get("/HostLists/All");
    }
    get() {
        return http.get("/HostLists");
    }
    // GetHostByCity (id) {
    //     return http.get(`/HostLists/GetHostByCity/${id}`);
    // }
    create(data) {
        //console.debug(data)
        return http.post("/HostLists", data);
    }

    update(id, data) {
        //console.debug(id,data)
        return http.put(`/HostLists/${id}`, data);
    }

    delete(id) {
        return http.delete(`/HostLists/${id}`);
    }
}

export default new HostDataService();
