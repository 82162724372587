import data from "@emoji-mart/data";
import EmojiPicker from "@emoji-mart/react";
import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { Comments } from "../../components/NewsFeed/comments";
import { showConfirmation, showSuccess } from "../../global/alertService";
import { LikeService } from "../../services/Post/like.service";
import { PostService } from "../../services/Post/post.service";
import { PostCommentService } from "../../services/Post/postComment.service";
import { classNames } from "../../utils/className-utils";
import { toLocalEventTime } from "../../utils/event-time";
import { isImage } from "../../utils/url-utils";

export function StatusCard({ post, setFetchingPosts }) {
    const currentUserEmail = localStorage.getItem("email");

    const [image, setImage] = useState(null);
    const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
    const [comment, setComment] = useState({
        comment: "",
        image: null,
        postId: 0,
    });
    const [showCommentSection, setShowCommentSection] = useState(false);
    const [comments, setComments] = useState([]);
    const [viewComment, setViewComment] = useState(false);
    const [like, setLike] = useState({
        postId: post?.id,
    });
    const [isLiked, setIsLiked] = useState(false);
    const [likeCount, setLikeCount] = useState(null);
    const [fetchingComment, setFetchingComment] = useState(false);

    useEffect(() => {
        setLikeCount(post?.likeCount);
        setIsLiked(post?.isLikedByCurrentUser);
    }, [post]);

    const onEmojiClick = (e) => {
        //console.debug(e.native)
        let sym = e.unified.split("-");
        let codesArray = [];
        sym.forEach((el) => codesArray.push("0x" + el));
        let emoji = String.fromCodePoint(...codesArray);
        document.getElementById("comment").value =
            document.getElementById("comment").value + emoji;
        setComment({
            ...comment,
            comment: comment.comment + emoji,
        });
        // console.debug(emoji)
    };

    useEffect(() => {
        if (showCommentSection) {
            PostCommentService.get(
                post?.id > 0 ? "?postId=" + post?.id : ""
            ).then((res) => {
                setComments(res.data);
                setFetchingComment(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [post, showCommentSection]);

    useEffect(() => {
        setFetchingPosts(true);
        setFetchingComment(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchingComment]);

    const onLikeClick = () => {
        if (isLiked) {
            // console.debug(post?.id);
            unlikePost(post?.id);
            // setLikes(likes - 1);
        } else {
            setLike({
                ...like,
                postId: post?.id,
            });
            likePost();
        }
        setIsLiked((isLiked) => !isLiked);
    };

    const likePost = () => {
        //console.debug(like)
        LikeService.create(like).then((res) => {
            if (res.status === 200) {
                setLikeCount(likeCount + 1);
            }
        });
    };

    const unlikePost = (id) => {
        LikeService.delete(id).then((res) => {
            if (res.status === 200) {
                setLikeCount(likeCount - 1);
            }
        });
    };

    const deletePost = (id) => {
        showConfirmation().then((result) => {
            if (result.isConfirmed) {
                if (likeCount !== 0) {
                    LikeService.delete(id);
                }
                if (post.commentCount !== 0) {
                    PostCommentService.deleteAll();
                }
                PostService.delete(id).then((response) => {
                    showSuccess("Deleted successfully.");
                    setFetchingPosts(true);
                });
            } else {
                showSuccess("Not deleted", "Ok");
            }
        });
    };
    const saveComment = (e) => {
        e.preventDefault();
        //console.debug(postId)
        //const formData = getFormData(comment);
        const formData = new FormData();
        formData.append("comment", comment.comment);
        formData.append("image", comment.image);
        formData.append("postId", post?.id);
        PostCommentService.create(formData).then((res) => {
            setComment({
                comment: "",
                image: null,
                postId: 0,
            });
            setComments((current) => [...current, res.data]);
            setFetchingPosts(true);
        });
        // window.location.reload(true);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        //console.debug(name, value, files);
        if (name === "image") {
            setComment({
                ...comment,
                image: event.target?.files[0],
            });
            setImage(event.target.files[0]);
        } else {
            setComment({
                ...comment,
                [name]: value,
            });
        }
    };

    return (
        <div className="p-6 rounded-lg shadow-lg bg-gray-50 mt-6">
            <div className="flex justify-between">
                <div className=" flex items-center md:flex-row ">
                    {post?.profilePic != null ? (
                        <img
                            className="w-12 h-12 justify-center items-center rounded-full"
                            src={post?.profilePic}
                            alt=""
                        />
                    ) : (
                        <svg
                            className="bg-green-400 w-12 h-12 text-gray-400 rounded-full"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                    )}
                    {/* <img className="w-12 h-12 justify-center items-center rounded-full" src="https://mdbootstrap.com/img/new/standard/nature/010.webp" alt="" /> */}
                    <div className="flex-col justify-between p-4 leading-normal">
                        <div className="flex-1 min-w-0 max-w-lg">
                            <p className="text-sm font-medium text-gray-900 truncate ">
                                {post?.firstName + " "}
                                {post?.lastName}
                            </p>
                            <p className="text-sm font-medium text-gray-500 truncate ">
                                {new Date(
                                    post?.createdAtUtc
                                ).toLocaleDateString()}{" "}
                                {toLocalEventTime(post?.createdAtUtc)}
                            </p>
                        </div>
                    </div>
                </div>

                {currentUserEmail === post?.email ? (
                    <Menu
                        as="div"
                        className="items-center justify-end rounded-b"
                    >
                        <div>
                            <Menu.Button className="ml-6" aria-hidden="true">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    height="24"
                                >
                                    <path
                                        fill="#263238"
                                        fillOpacity=".6"
                                        d="M12 7a2 2 0 1 0-.001-4.001A2 2 0 0 0 12 7zm0 2a2 2 0 1 0-.001 3.999A2 2 0 0 0 12 9zm0 6a2 2 0 1 0-.001 3.999A2 2 0 0 0 12 15z"
                                    ></path>
                                </svg>
                            </Menu.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="absolute z-10 mt-2 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1">
                                    {/* <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                className={classNames(
                                                    active
                                                        ? "bg-gray-100 text-gray-900"
                                                        : "text-gray-700",
                                                    " px-4 py-2 text-sm w-full flex items-center space-x-3"
                                                )}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1.5}
                                                    stroke="currentColor"
                                                    className="w-6 h-6"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                                    />
                                                </svg>
                                                <span>Edit</span>
                                            </button>
                                        )}
                                    </Menu.Item> */}
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                onClick={() => {
                                                    deletePost(post?.id);
                                                }}
                                                className={classNames(
                                                    active
                                                        ? "bg-gray-100 text-gray-900 w-full"
                                                        : "text-gray-700",
                                                    "px-4 py-2 text-sm w-full flex items-center space-x-3"
                                                )}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1.5}
                                                    stroke="currentColor"
                                                    className="w-6 h-6"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                                    />
                                                </svg>
                                                <span>Delete</span>
                                            </button>
                                        )}
                                    </Menu.Item>
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                ) : null}
            </div>
            <hr className="m-2" />
            <p className="text-gray-700 text-base ml-4 mt-4 mb-4">
                {post?.textContent}
            </p>
            <a href="#!">
                {post?.fileUrls.map((fileUrl, index) => {
                    return isImage(fileUrl) ? (
                        <div className="flex justify-center items-center h-72">
                            <img
                                key={index}
                                className="rounded-lg h-full w-full md:w-3/4"
                                src={fileUrl}
                                alt=""
                            />
                        </div>
                    ) : (
                        <button
                            key={index}
                            onClick={() => {
                                window.open(fileUrl);
                            }}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 15 15"
                                fill="none"
                            >
                                <path
                                    d="M0 4.5V0H1V4.5C1 5.32843 1.67157 6 2.5 6C3.32843 6 4 5.32843 4 4.5V1.5C4 1.22386 3.77614 1 3.5 1C3.22386 1 3 1.22386 3 1.5V5H2V1.5C2 0.671573 2.67157 0 3.5 0C4.32843 0 5 0.671573 5 1.5V4.5C5 5.88071 3.88071 7 2.5 7C1.11929 7 0 5.88071 0 4.5Z"
                                    fill="black"
                                />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M12.5 0H6V4.5C6 6.433 4.433 8 2.5 8H1V13.5C1 14.3284 1.67157 15 2.5 15H12.5C13.3284 15 14 14.3284 14 13.5V1.5C14 0.671573 13.3284 0 12.5 0ZM11 4H7V5H11V4ZM11 7H7V8H11V7ZM4 10H11V11H4V10Z"
                                    fill="black"
                                />
                            </svg>
                        </button>
                    );
                })}
            </a>
            {/*</div>*/}

            <div className="flex justify-between mt-4">
                <div className=" flex items-center ml-4 md:flex-row ">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-5 h-5"
                    >
                        <path d="M7.493 18.75c-.425 0-.82-.236-.975-.632A7.48 7.48 0 016 15.375c0-1.75.599-3.358 1.602-4.634.151-.192.373-.309.6-.397.473-.183.89-.514 1.212-.924a9.042 9.042 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75 2.25 2.25 0 012.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H14.23c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23h-.777zM2.331 10.977a11.969 11.969 0 00-.831 4.398 12 12 0 00.52 3.507c.26.85 1.084 1.368 1.973 1.368H4.9c.445 0 .72-.498.523-.898a8.963 8.963 0 01-.924-3.977c0-1.708.476-3.305 1.302-4.666.245-.403-.028-.959-.5-.959H4.25c-.832 0-1.612.453-1.918 1.227z" />
                    </svg>
                    <span className="text-sm ml-2 font-semibold">
                        {likeCount}
                    </span>
                </div>

                <div
                    onClick={() => {
                        setShowCommentSection(!showCommentSection);
                    }}
                    className="items-center justify-end cursor-pointer "
                >
                    <span className="text-sm mr-4 font-semibold">
                        {post?.commentCount} comments
                    </span>
                </div>
            </div>

            <hr className="m-2" />

            <div className="flax justify-between">
                <div className=" flex items-center md:flex-row ">
                    <button
                        type="button"
                        onClick={onLikeClick}
                        className={`px-4 py-2 text-sm w-full flex items-center space-x-2 hover:text-fuchsia-600 ${isLiked ? "text-fuchsia-900" : "text-gray-900"
                            }`}
                    >
                        {isLiked ? (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 h-5"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z"
                                />
                            </svg>
                        ) : (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 h-5"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z"
                                />
                            </svg>
                        )}

                        <span className="text-base font-bold">Like</span>
                    </button>
                    <button
                        onClick={() => {
                            setShowCommentSection(!showCommentSection);
                        }}
                        className="px-4 py-2 text-sm w-full flex items-center justify-end space-x-2 text-gray-700 cursor-pointer"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.076-4.076a1.526 1.526 0 011.037-.443 48.282 48.282 0 005.68-.494c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
                            />
                        </svg>
                        <span className="text-base font-bold">Comment</span>
                    </button>
                </div>
            </div>
            {showCommentSection && (
                <div>
                    <hr className="m-2" />
                    {post?.commentCount === 0 ? (
                        <></>
                    ) : (
                        <button
                            type="button"
                            onClick={() => {
                                setViewComment(!viewComment);
                            }}
                            className="flex ml-9 justify-right rounded-lg text-lg font-semibold "
                        >
                            {viewComment === false
                                ? "View all comments"
                                : "Hide all comments"}
                        </button>
                    )}

                    {viewComment && (
                        <div className="ml-10">
                            {comments.map((comment, index) => {
                                return (
                                    <Comments
                                        key={index}
                                        comment={comment}
                                        setFetchingComment={(
                                            fetchingComment
                                        ) => {
                                            setFetchingComment(fetchingComment);
                                        }}
                                    />
                                );
                            })}
                        </div>
                    )}
                    <form onSubmit={saveComment}>
                        <div className="flex items-center py-2 px-3 rounded-lg  mt-4">
                            <label
                                htmlFor="img"
                                className="inline-flex justify-center p-2 text-gray-500 rounded-lg cursor-pointer hover:text-gray-900 hover:bg-gray-100 "
                            >
                                <svg
                                    aria-hidden="true"
                                    className="w-6 h-6"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                        clipRule="evenodd"
                                    ></path>
                                </svg>
                                <span className="sr-only">Upload image</span>
                                <input
                                    name="image"
                                    type="file"
                                    id="img"
                                    accept=".png,.jpeg,.jpg"
                                    style={{ display: "none" }}
                                    onChange={handleInputChange}
                                />
                            </label>
                            <button
                                onClick={() => {
                                    setIsEmojiPickerOpen(!isEmojiPickerOpen);
                                }}
                                type="button"
                                className="p-2 text-gray-500 rounded-lg cursor-pointer hover:text-gray-900 hover:bg-gray-100 "
                            >
                                <svg
                                    aria-hidden="true"
                                    className="w-6 h-6"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 100-2 1 1 0 000 2zm7-1a1 1 0 11-2 0 1 1 0 012 0zm-.464 5.535a1 1 0 10-1.415-1.414 3 3 0 01-4.242 0 1 1 0 00-1.415 1.414 5 5 0 007.072 0z"
                                        clipRule="evenodd"
                                    ></path>
                                </svg>
                                <span className="sr-only">Add emoji</span>
                            </button>
                            <textarea
                                rows="1"
                                name="comment"
                                id="comment"
                                type="text"
                                value={comment?.comment}
                                onChange={handleInputChange}
                                className="block mx-4 p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 "
                                placeholder="Your comment..."
                            ></textarea>

                            <button
                                type="submit"
                                className="inline-flex justify-center p-2 text-fuchsia-800 rounded-full cursor-pointer hover:bg-blue-100 "
                            >
                                <svg
                                    aria-hidden="true"
                                    className="w-6 h-6 rotate-90"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                                </svg>
                            </button>
                        </div>

                        {image && (
                            <div className="relative px-4">
                                <div className="flex justify-center">
                                    <img
                                        className=" h-40 w-40 rounded-lg "
                                        src={URL.createObjectURL(image)}
                                        alt=""
                                    />
                                </div>

                                <button
                                    onClick={() => {
                                        setImage(null);
                                    }}
                                    className="absolute text-xl top-0 right-0 m-2 bg-white rounded-full p-2 inline-flex items-center justify-center text-gray-500 hover:text-gray-500 hover:bg-gray-100  "
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-6 h-6"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    </svg>
                                </button>
                            </div>
                        )}
                        {isEmojiPickerOpen && (
                            <div className="absolute z-10 h-96 w-96">
                                <EmojiPicker
                                    data={data}
                                    onEmojiSelect={onEmojiClick}
                                />
                            </div>
                        )}
                    </form>
                </div>
            )}
        </div>
    );
}
