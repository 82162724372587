import http from "../../global/http-common";

const route = "/CompanyInfos";

export const CompanyInfosService = {
    get: function get() {
        return http.get(route);
    },
    create: function create(data) {
        console.debug(data);
        return http.post(route, data);
    },
    update: function update(data) {
        //console.debug(data);
        return http.put(route, data);
    },
    delete: function deleteOne(id) {
        return http.delete(`${route}/${id}`);
    },
};
