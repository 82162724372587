import React, { Fragment, useEffect, useState } from "react";
import TrainingDataService from "../../services/Training/training.service";
import TrainingContentDetService from "../../services/Training/trainingContentDet.service";
import TrainingContentService from "../../services/Training/trainingcontents.eervice";
//import ContentDetTable from '../../components/Trining/contentDetTable'
//import ContentTable from '../../components/Trining/contentTable'
import { showSuccess } from "../../global/alertService";

export default function TrainingContentDetCreateForm() {
    const [name, setName] = useState(null);
    const [isPreviewAvailable, setIsPreviewAvailable] = useState(false);
    const [detailUrl, setDetailUrl] = useState(null);
    const [trainingContentId, setTrainingContentId] = useState(null);

    const [courses, setCourses] = useState([]);
    const [courseWiseChapter, setCourseWiseChapter] = useState([]);

    useEffect(() => {
        getCourses();
    }, []);

    const getCourses = () => {
        TrainingDataService.getAll().then((response) => {
            //console.debug(response);
            setCourses(response.data);
            //console.log(response.data);
        });
    };
    const getChapters = (trainingId) => {
        TrainingContentService.getByCourse(trainingId).then((res) => {
            //console.debug(res)
            setCourseWiseChapter(res.data);
        });
    };

    const saveContentDet = (event) => {
        event.preventDefault();
        console.debug(event);
        //console.log(isPreviewAvailable)
        let formData = new FormData();
        formData.append("name", name);
        formData.append("isPreviewAvailable", isPreviewAvailable);
        formData.append("trainingContentId", trainingContentId);
        formData.append("video", detailUrl);

        //console.log(formData);
        TrainingContentDetService.create(formData).then((response) => {
            console.debug(response);
            showSuccess(`Record saved successfully`);
            //window.location.reload(true);
        });
    };

    //---

    return (
        <Fragment>
            <form onSubmit={saveContentDet}>
                {/*body*/}
                <div className="flex flex-col  ">
                    <h1 className="text-2xl font-bold mb-8">
                        Add Training Content Detail
                    </h1>

                    <div className="grid gap-6 mb-6 md:grid-cols-3">
                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900">
                                Course{" "}
                            </label>
                            <select
                                name="trainingId"
                                onChange={(e) => {
                                    getChapters(e.target.value);
                                }}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                required
                            >
                                <option value="">-- Select Course --</option>
                                {courses?.map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item.courseName}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900">
                                Content{" "}
                            </label>
                            <select
                                name="trainingContentId"
                                onChange={(e) => {
                                    setTrainingContentId(e.target.value);
                                }}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                required
                            >
                                <option value="">-- Select Content --</option>
                                {courseWiseChapter?.map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item.chapterName}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900">
                                Topic
                            </label>
                            <input
                                onChange={(e) => setName(e.target.value)}
                                type="text"
                                placeholder="Introduction"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                required
                            />
                        </div>

                        <div>
                            <input
                                type="checkbox"
                                onChange={(e) =>
                                    setIsPreviewAvailable(e.target.checked)
                                }
                                className="w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300"
                            />
                            <label className="ml-2 text-sm font-medium text-gray-900">
                                Is Preview Available
                            </label>
                        </div>

                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900">
                                Topics Video
                            </label>
                            <input
                                type="file"
                                accept=".doc, .docx, .mp4, .pdf, .ppt, .pptx"
                                onChange={(e) =>
                                    setDetailUrl(e.target.files[0])
                                }
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                required
                            />
                        </div>
                    </div>
                </div>

                <div className="text-right">
                    <button
                        type="submit"
                        className="inline-flex mt-6 mb-4 items-center px-5 py-2.5 text-sm font-medium text-center text-white bg-fuchsia-800 rounded-lg focus:ring-4 focus:ring-blue-200 hover:bg-green-800"
                    >
                        Save
                    </button>
                </div>
            </form>
        </Fragment>
    );
}
