import { Fragment } from "react";
import { Footer } from "./footer";
import { LandingNav } from "./landingNav";

export function PrivacyPolicy() {
    return (
        <Fragment>
            <div>
                <div className="bg-indigo-50">
                    <LandingNav />
                </div>
                <div className="py-16">
                    <h1 className="text-5xl font-bold text-center">
                        Privacy Policy
                    </h1>
                    <div className="px-14">
                        <p className=" py-6">
                            Protecting your private information is our priority.
                            This Statement of Privacy applies to WONE, and
                            Djigui “WONE” and governs data collection and usage.
                            For the purposes of this Privacy Policy, unless
                            otherwise noted, all references to Djigui “WONE”
                            include https://woneapp.com and WONE. The WONE
                            application is a Social networking and learning
                            application. By using the WONE application, you
                            consent to the data practices described in this
                            statement.
                        </p>
                        <h2 className="text-2xl font-bold">
                            Collection of your Personal Information
                        </h2>
                        <p className="py-6">
                            WONE may collect anonymous demographic information,
                            which is not unique to you, such as your:
                            <ul>
                                <li>- Age</li>
                                <li>- Gender</li>
                            </ul>
                        </p>
                        <p className="py-6">
                            We do not collect any personal information about you
                            unless you voluntarily provide it to us. However,
                            you may be required to provide certain personal
                            information to us when you elect to use certain
                            products or services. These may include: (a)
                            registering for an account; (b) entering a
                            sweepstakes or contest sponsored by us or one of our
                            partners; (c) signing up for special offers from
                            selected third parties; (d) sending us an email
                            message; (e) submitting your credit card or other
                            payment information when ordering and purchasing
                            products and services. To wit, we will use your
                            information for, but not limited to, communicating
                            with you in relation to services and/or products you
                            have requested from us. We also may gather
                            additional personal or non-personal information in
                            the future.
                        </p>
                        <h2 className="text-2xl font-bold">
                            Sharing Information with Third Parties
                        </h2>
                        <p className="py-6">
                            WONE does not sell, rent or lease its customer lists
                            to third parties.
                        </p>
                        <p>
                            WONE may, from time to time, contact you on behalf
                            of external business partners about a particular
                            offering that may be of interest to you. In those
                            cases, your unique personally identifiable
                            information (e-mail, name, address, telephone
                            number) is transferred to the third party. WONE may
                            share data with trusted partners to help perform
                            statistical analysis, send you email or postal mail,
                            provide customer support, or arrange for deliveries.
                            All such third parties are prohibited from using
                            your personal information except to provide these
                            services to WONE, and they are required to maintain
                            the confidentiality of your information.
                        </p>
                        <p className="py-6">
                            WONE may disclose your personal information, without
                            notice, if required to do so by law or in the good
                            faith belief that such action is necessary to: (a)
                            conform to the edicts of the law or comply with
                            legal process served on WONE or the site; (b)
                            protect and defend the rights or property of WONE;
                            and/or (c) act under exigent circumstances to
                            protect the personal safety of users of WONE, or the
                            public.
                        </p>
                        <h2 className="text-2xl font-bold">
                            Opt-Out of Disclosure of Personal Information to
                            Third Parties
                        </h2>
                        <p className="py-6">
                            In connection with any personal information we may
                            disclose to a third party for a business purpose,
                            you have the right to know:
                        </p>
                        <ul className="list-disc">
                            <li className="ml-8">
                                The categories of personal information that we
                                disclosed about you for a business purpose.
                            </li>
                        </ul>
                        <p className="py-6">
                            You have the right under the California Consumer
                            Privacy Act of 2018 (CCPA) and certain other privacy
                            and data protection laws, as applicable, to opt-out
                            of the disclosure of your personal information. If
                            you exercise your right to opt-out of the disclosure
                            of your personal information, we will refrain from
                            disclosing your personal information, unless you
                            subsequently provide express authorization for the
                            disclosure of your personal information. To opt-out
                            of the disclosure of your personal information,
                            visit this Web page _________________.
                        </p>

                        <h2 className="text-2xl font-bold">
                            Right to Deletion
                        </h2>
                        <p className="py-6">
                            Subject to certain exceptions set out below, on
                            receipt of a verifiable request from you, we will:
                        </p>
                        <ul className="list-disc">
                            <li className="ml-8">
                                Delete your personal information from our
                                records; and
                            </li>
                            <li className="ml-8">
                                Direct any service providers to delete your
                                personal information from their records.
                            </li>
                        </ul>
                        <p className="py-6">
                            Please note that we may not be able to comply with
                            requests to delete your personal information if it
                            is necessary to:
                        </p>
                        <ul className="list-disc">
                            <li className="ml-8">
                                Complete the transaction for which the personal
                                information was collected, fulfill the terms of
                                a written warranty or product recall conducted
                                in accordance with federal law, provide a good
                                or service requested by you, or reasonably
                                anticipated within the context of our ongoing
                                business relationship with you, or otherwise
                                perform a contract between you and us;
                            </li>
                            <li className="ml-8">
                                Detect security incidents, protect against
                                malicious, deceptive, fraudulent, or illegal
                                activity; or prosecute those responsible for
                                that activity;
                            </li>
                            <li className="ml-8">
                                Debug to identify and repair errors that impair
                                existing intended functionality;
                            </li>
                            <li className="ml-8">
                                Exercise free speech, ensure the right of
                                another consumer to exercise his or her right of
                                free speech, or exercise another right provided
                                for by law;
                            </li>
                            <li className="ml-8">
                                Comply with the California Electronic
                                Communications Privacy Act;
                            </li>
                            <li className="ml-8">
                                Engage in public or peer-reviewed scientific,
                                historical, or statistical research in the
                                public interest that adheres to all other
                                applicable ethics and privacy laws, when our
                                deletion of the information is likely to render
                                impossible or seriously impair the achievement
                                of such research, provided we have obtained your
                                informed consent;
                            </li>
                            <li className="ml-8">
                                Enable solely internal uses that are reasonably
                                aligned with your expectations based on your
                                relationship with us;
                            </li>
                            <li className="ml-8">
                                Comply with an existing legal obligation; or
                            </li>
                            <li className="ml-8">
                                Otherwise use your personal information,
                                internally, in a lawful manner that is
                                compatible with the context in which you
                                provided the information.
                            </li>
                        </ul>

                        <h2 className="text-2xl font-bold py-6">
                            Children Under Thirteen
                        </h2>
                        <p className="py-4">
                            WONE does not knowingly collect personally
                            identifiable information from children under the age
                            of thirteen. If you are under the age of thirteen,
                            you must ask your parent or guardian for permission
                            to use this application.
                        </p>

                        <h2 className="text-2xl font-bold">
                            Opt-Out & Unsubscribe from Third Party
                            Communications
                        </h2>
                        <p className="py-6">
                            We respect your privacy and give you an opportunity
                            to opt-out of receiving announcements of certain
                            information. Users may opt-out of receiving any or
                            all communications from third-party partners of WONE
                            by contacting us here:
                            <ul>
                                <li>– Web page: _________________</li>
                                <li>– Email: support@woneapp.com</li>
                                <li>– Phone: _________________</li>
                            </ul>
                        </p>

                        <h2 className="text-2xl font-bold">
                            E-mail Communications
                        </h2>
                        <p className="py-6">
                            From time to time, WONE may contact you via email
                            for the purpose of providing announcements,
                            promotional offers, alerts, confirmations, surveys,
                            and/or other general communication. In order to
                            improve our Services, we may receive a notification
                            when you open an email from WONE or click on a link
                            therein.
                        </p>
                        <p>
                            If you would like to stop receiving marketing or
                            promotional communications via email from WONE, you
                            may opt out of such communications by Customers may
                            unsubscribe from email by clicking on the
                            “UNSUBSCRIBE” button.
                        </p>

                        <h2 className="text-2xl font-bold py-6">
                            External Data Storage Sites
                        </h2>
                        <p>
                            We may store your data on servers provided by third
                            party hosting vendors with whom we have contracted.
                        </p>

                        <h2 className="text-2xl font-bold py-6">
                            Changes to this Statement
                        </h2>
                        <p>
                            WONE reserves the right to change this Privacy
                            Policy from time to time. We will notify you about
                            significant changes in the way we treat personal
                            information by sending a notice to the primary email
                            address specified in your account, by placing a
                            prominent notice on our application, and/or by
                            updating any privacy information. Your continued use
                            of the application and/or Services available after
                            such modifications will constitute your: (a)
                            acknowledgment of the modified Privacy Policy; and
                            (b) agreement to abide and be bound by that Policy.
                        </p>

                        <h2 className="text-2xl font-bold py-6">
                            Contact Information
                        </h2>
                        <p>
                            WONE welcomes your questions or comments regarding
                            this Statement of Privacy. If you believe that WONE
                            has not adhered to this Statement, please contact
                            WONE at:
                        </p>
                        <ul className="py-6">
                            <li>Djigui “WONE”</li>
                            <li>421 Pusey Ave</li>
                            <li>Collingdale, Pennsylvania 19023</li>
                        </ul>
                        <ul>
                            <li>Email Address: support@woneapp.com</li>
                            <li>Telephone number: 2673880927</li>
                        </ul>
                        <p className="py-6">
                            Effective as of February 06, 2023
                        </p>
                    </div>
                </div>
                <div>
                    <Footer />
                </div>
            </div>
        </Fragment>
    );
}
