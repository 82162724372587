import { Fragment } from "react";
import ReactPlayer from "react-player";

import { useEffect, useState } from "react";
import TrainingContentDetService from "../../services/Training/trainingContentDet.service";

export function VideoPlayer(props) {
    const [contentDet, setContentDet] = useState(null);

    useEffect(() => {
        if (props.id > 0) {
            getTrainingContentDet(props.id);
        }
    }, [props]);

    const getTrainingContentDet = (id) => {
        TrainingContentDetService.get(id).then((response) => {
            setContentDet(response.data);
        });
    };

    return (
        <Fragment>
            <ReactPlayer
                width={"100%"}
                height={"100%"}
                url={props.src}
                volume={0.1}
                controls={true}
                playing={true}
                muted={false}
            />

            {props.id > 0 ? (
                <div>
                    <p className="mt-2">
                        <strong>{contentDet?.name} </strong>
                    </p>
                    <p className="mt-1">
                        {contentDet?.trainingChapter}-{" "}
                        {contentDet?.trainingContent}
                    </p>
                    <p className="mt-1">{contentDet?.training}</p>
                </div>
            ) : (
                ""
            )}
        </Fragment>
    );
}
