import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Fragment, useEffect, useState } from "react";
import { showError } from "../../global/alertService";
import { StripePaymentService } from "../../services/Payment/stripePayment.service";
export function EventParticipateStripe({
    eventId,
    setStripePaymentId,
    handleApprope,
    setShowStripeModal,
}) {
    const elements = useElements();
    const stripe = useStripe();

    const [clientSecret, setClientSecret] = useState("");

    useEffect(() => {
        getEventIntent(eventId);
    }, [eventId]);

    const getEventIntent = (eventId) => {
        if (eventId !== undefined) {
            const data = {
                eventId: eventId,
            };
            StripePaymentService.createEventIntent(data).then((res) => {
                const value = res.data?.clientSecret;
                setClientSecret(value);
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            return;
        }
        const { error: stripeError, paymentIntent } =
            await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                },
            });

        if (stripeError) {
            showError(stripeError.message);
            setShowStripeModal(false);
        } else {
            setStripePaymentId(paymentIntent?.id);
            handleApprope(paymentIntent?.id);
        }
    };

    return (
        <Fragment>
            <form onSubmit={handleSubmit} className="p-4">
                <div className="bg-blue-400 text-white rounded mb-2">
                    <p className="py-1 px-2">Pay with card</p>
                </div>
                <div className="text-center">
                    <hr className="mb-4 text-left font-medium text-lg" />
                    {clientSecret && <CardElement />}
                    <hr className="mt-4" />
                    <button
                        type="submit"
                        className="focus:outline-none text-black font-semibold rounded h-10 w-1/2 text-base stripe-card-pay-button text mt-4 inline-block"
                    >
                        Pay
                    </button>
                </div>
            </form>
        </Fragment>
    );
}

