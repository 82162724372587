import React, { Fragment, useEffect, useState } from "react";
import { showError, showSuccess } from "../../global/alertService";
import { CrossIcon } from "../Icons/crossIcon";
import EmployerJobPostService from "../../services/EmployerJob/employerjob.service";
import JobApplicantDataService from "../../services/EmployerJob/jobApplicants.service";
import { featureService } from "../../services/Feature/feature.service";
import { toLocalDate } from "../../utils/date-utils";

export function EmployerJobPostDetails(props) {
    const [appliedShowModal, setAppliedShowModal] = React.useState(false);
    const [employerJobPost, setEmployerJobPost] = useState([]);
    const [jobPostId, setJobPostId] = useState(null);
    const [file, setFile] = useState(null);
    const currentUserEmail = localStorage.getItem("email");

    const [applied, setApplied] = useState(false);
    const userType = localStorage.getItem("userType");

    useEffect(() => {
        if (props.detailEmployerJobPostId > 0) {
            getEmployerJobPost(props.detailEmployerJobPostId);
            ckeckIfApplied(props.detailEmployerJobPostId);
        }
        //console.debug(props)
    }, [props]);

    const getEmployerJobPost = (id) => {
        //console.debug(id);
        EmployerJobPostService.get(id).then((res) => {
            setEmployerJobPost(res.data);
            //console.log(res.data);
        });
    };
    //--
    const removeFile = () => {
        setFile(null);
    };
    const handleInputChange = (event) => {
        setFile(event.target.files[0]);
        //console.log(file);
    };
    //----
    const saveJobApplicant = (event) => {
        event.preventDefault();
        //console.debug(event);
        /*const jobApplicantData = {
            jobPostId: employerJobPost.id,
            status: "NEW"
        }
        console.log(jobApplicantData);*/
        if (file === null) {
            showError("Please upload your CV!");
            return false;
        }

        let formData = new FormData();
        formData.append("jobPostId", employerJobPost.id);
        formData.append("status", "APPLIED");
        formData.append("image", file);

        JobApplicantDataService.create(formData).then((response) => {
            //console.debug(response);
            showSuccess(`Your request is received successfully`);
            props.onClose();
            //window.location.reload(true);
        });
    };

    // check if user has already applied for this job
    const ckeckIfApplied = async (jobId) => {
        JobApplicantDataService.checkIfApplied(jobId).then((res) => {
            //console.log(res?.data);
            setApplied(res?.data);
        });
    };

    return (
        <Fragment>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-full my-4 mx-auto max-w-3xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-3xl font-semibold">
                                Job Detail
                            </h3>

                            <button
                                className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={props.onClose}
                            >
                                <CrossIcon />
                            </button>
                        </div>

                        <div className="px-5 pb-5 overflow-y-auto max-h-96">
                            <h5 className="text-xl font-bold tracking-tight mt-4 text-gray-900">
                                {employerJobPost?.jobTitle}
                            </h5>
                            <p>{employerJobPost?.employerName}</p>
                            <div className="flex justify-between items-center mt-2">
                                <span>
                                    <b>Published :</b>{" "}
                                    {toLocalDate(
                                        employerJobPost?.publishedDate
                                    )}{" "}
                                </span>
                                {employerJobPost.applicationDeadline === "0001-01-01T00:00:00" || employerJobPost?.applicationDeadline === null ? (
                                    <></>
                                ) : (
                                    <span>
                                        <b>Deadline :</b>{" "}
                                        {toLocalDate(employerJobPost.applicationDeadline)}
                                    </span>
                                )}

                            </div>
                            <p className="text-base font-bold text-gray-900 truncate">
                                Vacancy :{" "}
                                <span className="font-normal text-gray-500 truncate">
                                    {employerJobPost?.vacancy}
                                </span>
                            </p>

                            <div className="flex justify-between items-center mt-1 mb-1">
                                <span className="text-base font-bold text-gray-900">
                                    Job Responsibility
                                </span>
                            </div>
                            <p className="mb-2 font-normal text-gray-700">
                                {employerJobPost?.jobResponsibility}{" "}
                            </p>
                            <div className="flex justify-between items-center mt-1 mb-1">
                                <span className="text-base font-bold text-gray-900">
                                    Experience Requirements
                                </span>
                            </div>
                            <p className="mb-2 font-normal text-gray-700">
                                {employerJobPost?.experience}
                            </p>

                            <p className="text-base font-bold text-gray-900 truncate">
                                Employment Status :{" "}
                                <span className="font-normal text-gray-500 truncate">
                                    {employerJobPost?.status}
                                </span>
                            </p>
                            <div className="flex justify-between items-center mt-1 mb-1">
                                <span className="text-base font-bold text-gray-900">
                                    Salary
                                </span>
                            </div>
                            <p className="mb-2 font-normal text-gray-700">
                                {employerJobPost?.salary}
                            </p>
                            <div className="flex justify-between items-center mt-1 mb-1">
                                <span className="text-base font-bold text-gray-900">
                                    Compensation & Other Benefits
                                </span>
                            </div>
                            <p className="mb-2 font-normal text-gray-700">
                                {employerJobPost?.benefits}
                            </p>
                            <p className="text-base font-bold text-gray-900 truncate">
                                Location :{" "}
                                <span className="font-normal text-gray-500 truncate">
                                    {employerJobPost?.jobLocation}{" "}
                                    {employerJobPost?.city}{" "}
                                    {employerJobPost?.state}{" "}
                                    {employerJobPost?.country}{" "}
                                </span>
                            </p>

                            <label className="text-base font-bold text-gray-800 mt-1">
                                Upload CV
                            </label>
                            <input
                                name="fileUrl"
                                type="file"
                                accept=".pdf"
                                onChange={handleInputChange}
                                className="shadow border m-4 rounded w-72 py-2 px-1 text-black"
                            />

                            <p
                                style={{ color: "red" }}
                                className="mt-2 font-normal"
                            >
                                {/* Please ensure your CV has already been submitted before apply. */}
                                Please upload your resume or CV before applying
                            </p>

                            {/*</div>*/}
                        </div>
                        {/*footer*/}

                        <div className="flex items-center justify-end p-3 border-t border-solid border-slate-200 rounded-b">
                            {/*{userType === "Standard" &&
                                <p className='mr-auto'>Please upgrade your membershp to apply</p>
                            }--*/}
                            <button
                                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={props.onClose}
                            >
                                Close
                            </button>
                            {featureService.shouldShowContent("Applicant") ? (
                                currentUserEmail === employerJobPost?.email ? (
                                    <></>
                                ) : (
                                    employerJobPost?.employerLink && (
                                        <a href={employerJobPost.employerLink} target="_blank" rel="noopener noreferrer">
                                            <button
                                                className="bg-fuchsia-800 text-white hover:bg-green-800 font-bold text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            >
                                                Apply From Employer Website
                                            </button>
                                        </a>
                                    )
                                )
                            ) : (
                                <></>
                            )}

                            {featureService.shouldShowContent("Applicant") ? (
                                currentUserEmail === employerJobPost?.email ? (
                                    <></>
                                ) : applied === true ? (
                                    <button
                                        className="bg-green-800 text-white hover:bg-green-800 font-bold  text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 pointer-events-none"
                                        disabled
                                    >
                                        Already applied
                                    </button>
                                ) : (
                                    <button
                                        className="bg-fuchsia-800 text-white hover:bg-green-800 font-bold  text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        //onClick={() => setShowModal(false)}
                                        onClick={() => {
                                            setAppliedShowModal(true);
                                        }}
                                    >
                                        Apply
                                    </button>
                                )
                            ) : (
                                <></>
                            )}

                            {/* Apply Modal */}
                            {appliedShowModal ? (
                                <>
                                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                                        <div className="relative w-full my-6 mx-auto max-w-3xl">
                                            {/*content*/}
                                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                                {/*header*/}
                                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                                    <h3 className="text-3xl font-semibold">
                                                        {
                                                            employerJobPost?.jobTitle
                                                        }
                                                    </h3>

                                                    <button
                                                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                                        onClick={props.onClose}
                                                    >
                                                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                                            ×
                                                        </span>
                                                    </button>
                                                </div>

                                                {/*body*/}
                                                <form
                                                    onSubmit={saveJobApplicant}
                                                >
                                                    <div className="py-6 px-6 lg:px-8">
                                                        <input
                                                            type="hidden"
                                                            value={jobPostId}
                                                        />
                                                        <input
                                                            type="hidden"
                                                            value="6"
                                                        />
                                                        <input
                                                            name="Status"
                                                            type="hidden"
                                                            value="New"
                                                        />
                                                        <input
                                                            name="FileUrl"
                                                            type="hidden"
                                                            value={file}
                                                        />
                                                        <div
                                                            style={{
                                                                marginTop:
                                                                    "10px",
                                                            }}
                                                        >
                                                            <div className="px-5 pb-5 text-center">
                                                                <a href="#">
                                                                    {/*<h5 className="text-xl font-semibold tracking-tight mt-4 text-gray-900">Event 2</h5>*/}

                                                                    <p className="text-2xl text-gray">
                                                                        Interested in applying?
                                                                    </p>
                                                                </a>
                                                            </div>
                                                        </div>

                                                        {/*</form>*/}
                                                    </div>
                                                    {/*footer*/}
                                                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                                        <button
                                                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                            type="button"
                                                            onClick={
                                                                props.onClose
                                                            }
                                                        >
                                                            No
                                                        </button>
                                                        <button
                                                            className="bg-fuchsia-800 text-white hover:bg-green-800 font-bold  text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                            type="submit"
                                                        //onClick={() => setInterestModal(false)}
                                                        >
                                                            Yes
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                                </>
                            ) : null}

                            {/* -------- */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </Fragment>
    );
}
