import { Fragment } from "react";
import { Footer } from "./footer";
import { LandingNav } from "./landingNav";

export function Talent() {
    return (
        <Fragment>
            <div>
                <div className="bg-indigo-50">
                    <LandingNav />
                </div>
                <div className="py-16">
                    <h1 className="text-5xl font-bold text-center">
                        Talent
                    </h1>
                    <div className="px-14">
                        <p className=" py-5">
                            Our platform provides talented
                            job seekers the perfect opportunity
                            to get connected with potential
                            employers quickly and easily.
                            With this platform,
                            job seekers can quickly
                            and effortlessly build a polished resume
                            that effectively showcases their talent and skill set,
                            then reach out to employers with confidence.
                            This straightforward connection
                            gives job seekers an invaluable
                            chance to position themselves
                            as the ideal asset for any company.
                            Utilizing our platform allows job seekers
                            to receive a much-needed interview and potentially get hired!
                            
                        </p>
                        <p>
                        <b>This is suitable for users and job seekers.</b>
                        </p>
                    </div>
                </div>
                <div>
                    <Footer />
                </div>
            </div>
        </Fragment>
    );
}
