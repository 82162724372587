import http from '../../global/http-common'

class TrainingDataService {
    getAll() {
        return http.get("/Training");
    }
    GetTrainingsByUser () {
        return http.get('Training/GetTrainingsByUser');
    }
    get(id) {
        return http.get(`/Training/${id}`);
    }
    create(data) {
        return http.post("/Training", data);
    }

    update(id, data) {
        return http.put(`/Training/${id}`, data);
    }

    delete(id) {
        return http.delete(`/Training/${id}`);
    }

}

export default new TrainingDataService();