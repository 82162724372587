import axios from "axios";
import React, { Fragment, useState } from "react";
import { LANGUAGE_TRANSLATE_API } from "../../global/api-keys";

export function GoogleTranslate() {
    const [inputText, setInputText] = useState("");
    const [detectLanguageKey, setdetectedLanguageKey] = useState("");
    const [selectedLanguageKey, setLanguageKey] = useState("");
    const [languagesList, setLanguagesList] = useState([
        { code: "en", name: "English" },
        { code: "ar", name: "Arabic" },
        { code: "az", name: "Azerbaijani" },
        { code: "af", name: "Afrikaans" },
        { code: "sq", name: "Albanian" },
        { code: "am", name: "Amharic" },
        { code: "hy", name: "Armenian" },
        { code: "eu", name: "Basque" },
        { code: "be", name: "Belarusian" },
        { code: "bn", name: "Bengali" },
        { code: "bs", name: "Bosnian" },
        { code: "bg", name: "Bulgarian" },
        { code: "co", name: "Corsican" },
        { code: "hr", name: "Croatian" },
        { code: "ca", name: "Catalan" },
        { code: "zh", name: "Chinese" },
        { code: "cs", name: "Czech" },
        { code: "da", name: "Danish" },
        { code: "nl", name: "Dutch" },
        { code: "eo", name: "Esperanto" },
        { code: "et", name: "Estonian" },
        { code: "tl", name: "Filipino" },
        { code: "fi", name: "Finnish" },
        { code: "fr", name: "French" },
        { code: "fy", name: "Frisian" },
        { code: "de", name: "German" },
        { code: "el", name: "Greek" },
        { code: "gl", name: "Galician" },
        { code: "ka", name: "Georgian" },
        { code: "gu", name: "Gujarati" },
        { code: "he", name: "Hebrew" },
        { code: "hi", name: "Hindi" },
        { code: "hu", name: "Hungarian" },
        { code: "id", name: "Indonesian" },
        { code: "ga", name: "Irish" },
        { code: "it", name: "Italian" },
        { code: "ja", name: "Japanese" },
        { code: "ko", name: "Korean" },
        { code: "jw", name: "Javanese" },
        { code: "kn", name: "Kannada" },
        { code: "kk", name: "Kazakh" },
        { code: "km", name: "Khmer" },
        { code: "ku", name: "Kurdish" },
        { code: "ky", name: "Kyrgyz" },
        { code: "lo", name: "Lao" },
        { code: "la", name: "Latin" },
        { code: "lv", name: "Latvian" },
        { code: "lt", name: "Lithuanian" },
        { code: "lb", name: "Luxembourgish" },
        { code: "mk", name: "Macedonian" },
        { code: "mg", name: "Malagasy" },
        { code: "ms", name: "Malay" },
        { code: "ml", name: "Malayalam" },
        { code: "mt", name: "Maltese" },
        { code: "mi", name: "Maori" },
        { code: "mr", name: "Marathi" },
        { code: "mn", name: "Mongolian" },
        { code: "my", name: "Myanmar (Burmese)" },
        { code: "ne", name: "Nepali" },
        { code: "no", name: "Norwegian" },
        { code: "ps", name: "Pashto" },
        { code: "fa", name: "Persian" },
        { code: "pl", name: "Polish" },
        { code: "pt", name: "Portuguese" },
        { code: "pa", name: "Punjabi" },
        { code: "ro", name: "Romanian" },
        { code: "ru", name: "Russian" },
        { code: "sk", name: "Slovak" },
        { code: "es", name: "Spanish" },
        { code: "sv", name: "Swedish" },
        { code: "tr", name: "Turkish" },
        { code: "th", name: "Thai" },
        { code: "tg", name: "Tajik" },
        { code: "ta", name: "Tamil" },
        { code: "te", name: "Telugu" },
        { code: "uk", name: "Ukrainian" },
        { code: "ur", name: "Urdu" },
        { code: "uz", name: "Uzbek" },
        { code: "vi", name: "Vietnamese" },
        { code: "cy", name: "Welsh" },
        { code: "xh", name: "Xhosa" },
        { code: "yi", name: "Yiddish" },
        { code: "yo", name: "Yoruba" },
        { code: "zu", name: "Zulu" },
    ]);
    const [resultText, setResultText] = useState("");

    const translateText = () => {
        let data = {
            q: inputText,
            source: detectLanguageKey,
            target: selectedLanguageKey,
        };
        //console.log(data)
        // axios.post(`https://translation.googleapis.com/language/translate/v2?key=AIzaSyACT7H0S5EASZZF9iM3nrSC_s2IKQXWlDQ`, data)
        axios
            .post(
                `https://translation.googleapis.com/language/translate/v2?key=` +
                    LANGUAGE_TRANSLATE_API,
                data
            )

            .then((response) => {
                //console.log(response.data.data.translations[0].translatedText);
                setResultText(
                    response.data.data.translations[0].translatedText
                );
            });
    };

    return (
        <Fragment>
            <div className="flex flex-col items-center mt-24">
                <h1 className="mb-6 mt-6 text-4xl font-bold text-fuchsia-800">
                    Translate language here!
                </h1>
                <div className="p-4 flex flex-wrap justify-around">
                    <div className="mb-4">
                        <span className="font-bold text-2xl p-2">From:</span>
                        <select
                            className="p-2.5 bg-gray-50 border border-gray-300 text-gray-900"
                            onChange={(e) => {
                                setdetectedLanguageKey(e.target.value);
                            }}
                        >
                            <option>--- Select Language ---</option>
                            {languagesList.map((language) => {
                                return (
                                    <option value={language.code}>
                                        {language.name}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    <div className="mb-4">
                        <span className="font-bold text-2xl p-2">To:</span>
                        <select
                            className="p-2.5 bg-gray-50 border border-gray-300 text-gray-900"
                            onChange={(e) => {
                                setLanguageKey(e.target.value);
                            }}
                        >
                            <option>--- Select Language ---</option>
                            {languagesList.map((language) => {
                                return (
                                    <option
                                        value={language.code}
                                        key={language.code}
                                    >
                                        {language.name}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>
                <div className="flex items-center justify-center border-t border-solid border-slate-200 rounded-b">
                    <textarea
                        cols="72"
                        placeholder="Enter Text..."
                        onChange={(e) => setInputText(e.target.value)}
                        className="mr-2 bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg block w-full p-2.5 h-72"
                    ></textarea>
                    <p
                        dangerouslySetInnerHTML={{ __html: resultText }}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg block w-full p-2.5 h-72"
                    />
                </div>
                <div>
                    <button
                        onClick={translateText}
                        className="w-full mt-6 text-white bg-fuchsia-800 hover:bg-green-800 font-bold rounded-lg text-xl px-5 py-2.5 text-center "
                    >
                        Translate
                    </button>
                </div>
            </div>
        </Fragment>
    );
}
