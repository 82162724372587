import React from 'react';
import { Fragment, useEffect, useState } from 'react';
import TrainingDataService from '../../services/Training/training.service'
import TrainingContentDetService from '../../services/Training/trainingContentDet.service'
import TrainingContentService from '../../services/Training/trainingcontents.eervice'
import { showConfirmation, showSuccess } from "../../global/alertService";
import { getFormData } from "../../utils/getFormData";
import { ContentUpdate } from '../../components/Trining/contentUpdate';
import { ContentDetUpdate } from '../../components/Trining/contentDetUpdate';
import { CourseUpdate } from '../../components/Trining/courseUpdate'

export default function TrainingEdit(props) {

    const [training, setTraining] = useState([])
    const [content, setContent] = useState([])
    const [contentDet, setContentDet] = useState([])
    const [showDetail, setShowDetail] = useState(false)
    const [editShowModal, setEditShowModal] = useState(false)
    const [contentId, setContentId] = useState(null)

    const [contentDetEditShowModal, setContentDetEditShowModal] = useState(false)
    const [contentDetId, setContentDetId] = useState(null)
    const [courseEditShowModal, setCourseEditShowModal] = useState(false);

    useEffect(() => {
        if (props.id > 0) {
            getTrainings(props.id)
            getTainingContents(props.id)
        }
    }, [props]);

    const getTrainings = (id) => {

        TrainingDataService.get(id)
            .then((response) => {
                //console.debug(response);
                setTraining(response.data);
                //console.log(response.data);
            });
    }

    const getTainingContents = (id) => {
        TrainingContentService.get("?trainingId=" + id)
            .then((response) => {
                //console.debug(response);
                setContent(response.data);
                //console.log(response.data);
            });
    }

    const getContentDet = (id) => {
        TrainingContentDetService.get("?trainingContentId=" + id)
            .then((res) => {
                setContentDet(res.data)
                console.log(res.data)
            })
    }
    
    //------
    const deleteContent = (id) => {
        showConfirmation()
            .then((result) => {
                if (result.isConfirmed) {
                    TrainingContentService.delete(id)
                        .then((response) => {
                            // console.debug(response)
                            //getTainingContents()
                            showSuccess('Deleted successfully.')
                            window.location.reload()
                        })
                } else {
                    showSuccess('Not deleted', 'Ok')
                }
            })
    }

    const deleteContentDet = (id) => {
        showConfirmation()
            .then((result) => {
                if (result.isConfirmed) {
                    TrainingContentDetService.delete(id)
                        .then((response) => {
                            // console.debug(response)
                            showSuccess('Deleted successfully.')
                            window.location.reload()
                        })
                } else {
                    showSuccess('Not deleted', 'Ok')
                }
            })
    }
    //----

    return (
        <Fragment>
            <form>

                <div className="flex flex-col  ">
                    <h1 className="text-2xl font-bold mb-4">Update Course </h1>
                    <div className="bg-slate-50 shadow-md rounded-lg my-6 overflow-y-auto h-24">
                        <table className="min-w-max w-full  table-auto ">
                            <thead className="sticky top-0">
                                <tr className="bg-fuchsia-800 text-white uppercase text-sm leading-normal">
                                    <th className="py-3 px-6 text-left">Course Name</th>
                                    <th className="py-3 px-6 text-left">Description</th>
                                    <th className="py-3 px-6 text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody className="text-gray-600 text-sm font-light">
                                <tr key={training?.id} className="border-b border-gray-200 hover:bg-gray-100">
                                    <td className="py-3 px-6 text-left whitespace-nowrap">
                                        <div className="flex items-center">
                                            <span className="font-medium">{training?.courseName} </span>
                                        </div>
                                    </td>
                                    <td className="py-3 px-6 text-left whitespace-nowrap">
                                        <div className="flex items-center">
                                            <span className="font-medium">{training?.description}</span>
                                        </div>
                                    </td>

                                    <td className="py-3 px-6 text-center">
                                        <div className="flex item-center justify-center">

                                            <div style={{cursor:'pointer'}} onClick={() => { setCourseEditShowModal(true); }}
                                                className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                                </svg>
                                            </div>

                                        </div>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>

                </div>


            </form>


            <form>

                <div className="flex flex-col  ">
                    <h1 className="text-2xl font-bold mb-4">Update Course Content</h1>
                    <div className="bg-slate-50 shadow-md rounded-lg my-6 overflow-y-auto h-56">
                        <table className="min-w-max w-full table-auto ">
                            <thead className="sticky top-0">
                                <tr className="bg-fuchsia-800 text-white uppercase text-sm leading-normal">
                                    <th className="py-3 px-6 text-left">Chapter Name</th>
                                    <th className="py-3 px-6 text-left">Content</th>
                                    <th className="py-3 px-6 text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody className="text-gray-600 text-sm font-light">
                                {
                                    content?.length > 0 ?
                                        (content.map(item => (
                                            <tr key={item?.id} className="border-b border-gray-200 hover:bg-gray-100">
                                                <td className="py-3 px-6 text-left whitespace-nowrap">
                                                    <div className="flex items-center">
                                                        <span className="font-medium">{item?.chapterName} </span>
                                                    </div>
                                                </td>
                                                <td className="py-3 px-6 text-left whitespace-nowrap">
                                                    <div className="flex items-center">
                                                        <span className="font-medium">{item?.content}</span>
                                                    </div>
                                                </td>

                                                <td className="py-3 px-6 text-center">
                                                    <div className="flex item-center justify-center">

                                                        <div style={{cursor:'pointer'}} onClick={() => { setEditShowModal(true); setContentId(item?.id) }}
                                                            className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110">
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                                            </svg>
                                                        </div>

                                                        <div style={{cursor:'pointer'}} onClick={() => { getContentDet(item?.id); setShowDetail(true) }} className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110">
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                                            </svg>
                                                        </div>

                                                        <div style={{cursor:'pointer'}} onClick={() => deleteContent(item?.id)} className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110">
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))) :
                                        (<tr>
                                            <td>No Data</td>
                                        </tr>)
                                }
                            </tbody>
                        </table>
                    </div>

                </div>


            </form>


            {showDetail ? (
                <>
                    <form >

                        <div className="flex flex-col  ">
                            <h1 className="text-2xl font-bold mb-4">Update Course Content Detail</h1>

                            <div className="bg-slate-50 shadow-md rounded-lg my-6 overflow-y-auto h-56">
                                <table className="min-w-max w-full table-auto ">
                                    <thead className="sticky top-0">
                                        <tr className="bg-fuchsia-800 text-white uppercase text-sm leading-normal">
                                            <th className="py-3 px-6 text-left">Topic</th>
                                            <th className="py-3 px-6 text-left">Video</th>
                                            <th className="py-3 px-6 text-left">Is Preview Available</th>
                                            <th className="py-3 px-6 text-center">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-gray-600 text-sm font-light">
                                        {
                                            contentDet?.length > 0 ?
                                                (contentDet.map(item => (
                                                    <tr key={item?.id} className="border-b border-gray-200 hover:bg-gray-100">
                                                        <td className="py-3 px-6 text-left whitespace-nowrap">
                                                            <div className="flex items-center">
                                                                <span className="font-medium">{item?.name} </span>
                                                            </div>
                                                        </td>
                                                        <td className="py-3 px-6 text-left whitespace-nowrap">
                                                            <div className="flex items-center">
                                                                <span className="font-medium">{/*{item?.detailUrl}*/}</span>
                                                            </div>
                                                        </td>

                                                        <td className="py-3 px-6 text-left whitespace-nowrap">
                                                            <div className="flex items-center justify-center">
                                                                <span className="font-medium">
                                                                    <input

                                                                        checked={item?.isPreviewAvailable} type="checkbox" readOnly />
                                                                </span>
                                                            </div>

                                                        </td>

                                                        <td className="py-3 px-6 text-center">
                                                            <div className="flex item-center justify-center">

                                                                <div style={{cursor:'pointer'}} onClick={() => { setContentDetEditShowModal(true); setContentDetId(item?.id) }}
                                                                    className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                                                    </svg>
                                                                </div>


                                                                <div style={{cursor:'pointer'}} onClick={() => deleteContentDet(item.id)} className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))) :
                                                (<tr>
                                                    <td>No Data</td>
                                                </tr>)
                                        }
                                    </tbody>
                                </table>
                            </div>

                        </div>


                    </form>

                </>
            ) : null}

            {/*- Course Edit Modal-*/}
            {
                courseEditShowModal ? (

                    <CourseUpdate id={props.id} onClose={() => {
                        setCourseEditShowModal(false)
                    }} />

                ) : null
            }

            {/*-End Content Edit Modal-*/}

            {/*- Content Edit Modal-*/}
            {
                editShowModal ? (

                    <ContentUpdate id={contentId} trainingId={props.id} onClose={() => {
                        setEditShowModal(false)
                    }} />

                ) : null
            }

            {/*-End Content Edit Modal-*/}

            {/*- Content Detaol Edit Modal-*/}
            {
                contentDetEditShowModal ? (

                    <ContentDetUpdate id={contentDetId} trainingContentId={contentId} onClose={() => {
                        setContentDetEditShowModal(false)
                    }} />

                ) : null
            }

            {/*-End Content Detail Edit Modal-*/}

        </Fragment>
    )
}