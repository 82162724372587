import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { authService } from "../../services/Account/auth.service";

export function LostPassword() {
    const mySwal = withReactContent(Swal);
    const navigate = useNavigate();

    const [sending, setSending] = useState(false);
    const [data, setData] = useState({
        email: "",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value,
        });
    };

    const submitForgotPasswordForm = (e) => {
        setSending(true);
        e.preventDefault();

        authService.forgotPassword(data).then((res) => {
            setSending(false);
            console.log(res);
            mySwal
                .fire({
                    title:
                        res.data ===
                        "Please check you email inbox, an email is sent."
                            ? "<strong>Success</strong>"
                            : "<strong>Error</strong>",
                    html: <i>{res.data}</i>,
                    icon:
                        res.data ===
                        "Please check you email inbox, an email is sent."
                            ? "success"
                            : "error",
                })
                .then(() => {
                    navigate("/signIn");
                });
        });
    };

    return (
        <Fragment>
            <div className="flex flex-col items-center mt-24">
                <h1 className="text-4xl font-bold text-fuchsia-800">
                    {" "}
                    Forgot Password!
                </h1>
                <h2 className="text-2xl font-bold mt-4 mb-1">
                    Please enter your email address.
                </h2>
                <h2 className="text-2xl font-bold  mb-8">
                    We will send you an email to reset your password.
                </h2>
                <form onSubmit={submitForgotPasswordForm}>
                    <div>
                        <label className="block mb-4 text-sm font-medium text-gray-900">
                            Email
                        </label>
                        <input
                            value={data.email}
                            onChange={(e) => handleInputChange(e)}
                            type="email"
                            name="email"
                            className="bg-gray-50 w-96 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                            placeholder="Enter your email"
                            required
                        />
                    </div>

                    <button
                        type="submit"
                        className="w-full mt-6 text-white bg-fuchsia-800 hover:bg-green-800 font-bold rounded-lg text-xl px-5 py-2.5 text-center"
                        style={{
                            pointerEvents: sending && "none",
                            opacity: sending && "0.75",
                        }}
                    >
                        {sending ? "Sending email..." : "Send Reset Link"}
                    </button>
                </form>
            </div>
        </Fragment>
    );
}
