import http from '../../global/http-common'

export const InstractorService = {

    get: function get() {
        return http.get("/Instractors");
    },
    create: function create(data) {
        console.debug(data)
        return http.post("/Instractors", data);
    },
    update: function update(id, data) {
        console.debug(id,data)
        return http.put(`/Instractors/${id}`, data);
    },

}
