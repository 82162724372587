import { Fragment, useEffect, useState } from "react";
import EventParticipantService from '../../services/Event/eventParticipants.service'
import { authService } from "../../services/Account/auth.service";
import { toLocalEventDate } from '../../utils/evant-date';
import { toLocalEventTime } from '../../utils/event-time';

export function EventParticipant() {

    const [events, setEvents] = useState([])
    const [profile, setProfile] = useState([])
    
    useEffect(() => {
        getEventParticipant()
        getProfile()
    }, [])

    const getEventParticipant = () => {
        EventParticipantService.getAll()
            .then((response) => {
                //console.debug(response);
                setEvents(response.data);
                //console.log(response.data);
            });
    }

    const getProfile = () => {
        authService.profile()
            .then((response) => {
                // console.debug(response)
                setProfile(response.data)
                //console.log(response.data)
            })
    }


    
    return (
        <Fragment>
            
            <h1 className="font-bold text-xl mb-2">Participant Event List</h1>
            <p className="font-bold text-xl mb-2">User : <span className="text-gray-600 text-sm font-medium">{profile?.firstName + " "}{profile?.lastName}</span></p>
            <div className="w-full ">
                <div className="bg-slate-50 shadow-md rounded-lg my-6 overflow-y-auto h-96">
                    <table className="min-w-max w-full table-auto ">
                        <thead className="sticky top-0">
                            <tr className="bg-fuchsia-800 text-white uppercase text-sm leading-normal">
                                <th className="py-3 px-6 text-left">Event Name</th>
                                {/*<th className="py-3 px-6 text-left">Description</th>*/}
                                <th className="py-3 px-6 text-left">Date/Time</th>
                            </tr>
                        </thead>
                        <tbody className="text-gray-600 text-sm font-light">
                            {
                                events.length > 0 ?
                                    (events.map(item => (

                                        <tr key={item?.id} className="border-b border-gray-200 hover:bg-gray-100">

                                            <td className="py-3 px-6 text-left whitespace-nowrap">
                                                <div className="flex items-center">
                                                    <span className="font-medium">{item?.event}</span>
                                                </div>
                                            </td>
                                            {/*<td className="py-3 px-6 text-left whitespace-nowrap">
                                                <div className="flex items-center">
                                                    <span className="font-medium">{item?.description}</span>
                                                </div>
                                            </td>*/}
                                            <td className="py-3 px-6 text-left whitespace-nowrap">
                                                <div className="flex items-center">
                                                    <span className="font-medium">{toLocalEventDate(item?.eventDate)}{" -- "}</span>
                                                    <span className="font-medium">{toLocalEventTime(item?.eventDate)}</span>
                                                </div>
                                            </td>
                                            
                                        </tr>
                                    ))) :
                                    (<tr>
                                        <td>No Data</td>
                                    </tr>)
                            }
                        </tbody> 
                    </table>
                </div>
            </div>

            
        </Fragment>
    )
}