import { Fragment } from "react";
import { Footer } from "./footer";
import { LandingNav } from "./landingNav";
import { Marketing } from "../../components/Landing/marketing";


export function AdPlacement() {
    return (
        <Fragment>
            <div>
                <div className="bg-indigo-50">
                    <LandingNav />
                </div>
                <div className="py-16">
                    <h1 className="text-5xl font-bold text-center">
                    Ad Placement
                    </h1>
                    <div className="px-14">

                        <p className="py-6">
                        Our platform provides fast, 
                        easy, and affordable ad placement 
                        for local businesses. 
                        With different types of memberships to suit your needs, 
                        including premium options 
                        that offer even greater savings, 
                        you can start reaching out to potential customers quickly 
                        and cost-effectively. 
                        Whether you're a small business looking 
                        to expand your reach 
                        or an established employer seeking 
                        to optimize your recruitment efforts, 
                        our ad placement services are designed 
                        to make it simple for you. 
                        So why wait? Sign up today and 
                        start connecting with your target audience in no time!</p>
                        
                        
                       </div> 
              
                   
                </div>
                <div
                    
                    className=" bg-indigo-50 lg:h-40 "
                >
                    <Marketing />
                </div>
                <div>
                    <Footer />
                </div>
            </div>
        </Fragment>
    );
}
