import http from "../../global/http-common";

const route = "/Accounts";

export const authService = {
    signin: function (data) {
        return http.post(route + "/Signin", data);
    },

    signinExt: function (data) {
        return http.post(route + "/ExternalSignin", data);
    },

    signup: function (data) {
        return http.post(route + "/Signup", data);
    },

    signout: function () {
        localStorage.removeItem("authToken");
        localStorage.removeItem("name");
        localStorage.removeItem("email");
        localStorage.removeItem("profilePicUrl");
        localStorage.removeItem("features");
        localStorage.removeItem("userType");
        localStorage.removeItem("id");
        localStorage.removeItem("extUser");
    },

    profile: function () {
        return http.get(route + "/Profile");
    },

    updateProfile: function (data) {
        return http.put(route + "/UpdateProfile", data);
    },
    updateOrigin: function (data) {
        return http.put(route + "/UpdateOrigin", data);
    },
    getUsers: function () {
        return http.get(route + "/Users");
    },
    getFilteredUsers: function (countryId, stateId, cityId, search) {
        //console.log(countryId, stateId, cityId, search);
        return http.get(route + "/FilteredUsers", {
            params: {
                country: countryId,
                state: stateId,
                city: cityId,
                searchTerm: search,
            },
        });
    },
    getAllUsers: function () {
        return http.get(route + "/AllUsers");
    },

    resetPassword: function (data) {
        return http.patch(route + "/ResetPassword", data);
    },

    forgotPassword: function (data) {
        return http.patch(route + "/ForgotPassword", data);
    },

    recoverForgotPassword: function (data) {
        return http.patch(route + "/recoverForgotPassword", data);
    },

    updateProfilePic: function (pic) {
        const formData = new FormData();
        formData.append("profilePic", pic);
        return http.put(route + "/UpdateProfilePic", formData);
    },

    heartBeat: function () {
        return http.post(route + "/heartBeat");
    },

    confirmEmail: function (email, token) {
        return http.get(
            route + "/ConfirmEmail?token=" + token + "&email=" + email
        );
    },
};
