import http from '../../global/http-common'

class jobCategoriesService {
    getAll() {
        return http.get("/JobCategories");
    }
    get(id) {
        return http.get(`/JobCategories/${id}`);
    }
    create(data) {
        return http.post("/JobCategories", data);
    }

    update(id, data) {
        return http.put(`/JobCategories/${id}`, data);
    }

    delete(id) {
        return http.delete(`/JobCategories/${id}`);
    }

}

export default new jobCategoriesService();