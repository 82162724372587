import { Fragment, useEffect, useState } from "react";
import { showSuccess } from "../../global/alertService";
import { featureService } from "../../services/Feature/feature.service";
import {
    default as JobSeekerListService,
    default as jobSeekerListService,
} from "../../services/SeekerJob/jobSeekerList.service";
import SeekerJobPostService from "../../services/SeekerJob/seekerJob.service";
import { toLocalDate } from "../../utils/date-utils";

export function SeekerJobPostDetails(props) {
    const [selectShowModal, setSelectShowModal] = useState(false);
    const [seekerJobPost, setSeekerJobPost] = useState([]);
    const [jobSeekerPostId, setJobSeekerPostId] = useState(null);

    const [selected, setSeleted] = useState(false);

    useEffect(() => {
        if (props.detailSeekerJobPostId > 0) {
            getSeekerJobPost(props.detailSeekerJobPostId);
            checkIfSelected(props.detailSeekerJobPostId);
        }
        //console.debug(props)
    }, [props]);

    const getSeekerJobPost = (id) => {
        //console.debug(id);
        SeekerJobPostService.get(id).then((res) => {
            setSeekerJobPost(res.data);
        });
    };

    const saveSeekerList = (event) => {
        event.preventDefault();
        //console.debug(event);
        const jobSeekerListData = {
            //employerUserId: 1,
            jobSeekerPostId: seekerJobPost.id,
            status: "SELECTED",
        };
        //console.log(jobSeekerListData);
        JobSeekerListService.create(jobSeekerListData).then((response) => {
            //console.debug(response);
            showSuccess(`Your request is received successfully`);
        });
    };

    // check if this applicant is already selected
    const checkIfSelected = async (postId) => {
        jobSeekerListService.checkIfSelected(postId).then((res) => {
            //console.log(res?.data);
            setSeleted(res?.data);
        });
    };

    return (
        <Fragment>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-full my-4 mx-auto max-w-3xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3 className="text-3xl font-semibold">
                                {seekerJobPost?.jobTitle}
                            </h3>

                            <button
                                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                // onClick={() => setShowModal(false)}
                            >
                                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                    x
                                </span>
                            </button>
                        </div>

                        <div className="py-4 px-4 lg:px-8">
                            <div className="mt-4">
                                <div>
                                    <span className="text-m font-semibold tracking-tight mt-4 text-gray-900">
                                        Name:{" "}
                                    </span>
                                    <span className=" text-gray-700 ">
                                        {seekerJobPost?.firstName + " "}
                                        {seekerJobPost?.lastName}
                                    </span>
                                </div>
                                <div>
                                    <span className="text-m font-semibold tracking-tight mt-4 text-gray-900">
                                        Address:{" "}
                                    </span>
                                    <span className=" text-gray-700 ">
                                        {seekerJobPost?.location}{" "}
                                        {seekerJobPost?.city}{" "}
                                        {seekerJobPost?.state}{" "}
                                        {seekerJobPost?.country}
                                    </span>
                                </div>
                                <div>
                                    <span className="text-m font-semibold tracking-tight mt-4 text-gray-900">
                                        Contact No:{" "}
                                    </span>
                                    <span className=" text-gray-700 ">
                                        {seekerJobPost?.contactNo}
                                    </span>
                                </div>
                                <div>
                                    <span className="text-m font-semibold tracking-tight mt-4 text-gray-900">
                                        Email:{" "}
                                    </span>
                                    <span className=" text-gray-700 ">
                                        {seekerJobPost?.email}
                                    </span>
                                </div>
                                <div>
                                    <span className="text-m font-semibold tracking-tight mt-4 text-gray-900">
                                        Facebook:{" "}
                                    </span>
                                    <a
                                        href="#"
                                        className="inline-flex items-center font-medium text-fuchsia-600 hover:underline"
                                    >
                                        {seekerJobPost?.facebookUrl}
                                    </a>
                                </div>
                                <div>
                                    <span className="text-m font-semibold tracking-tight mt-4 text-gray-900">
                                        LinkdIn:{" "}
                                    </span>
                                    <a
                                        href="#"
                                        className="inline-flex items-center font-medium text-fuchsia-600 hover:underline"
                                    >
                                        {seekerJobPost?.linkedInUrl}
                                    </a>
                                </div>
                            </div>
                            <div className="flex justify-between items-center mt-4">
                                <span className="text-sm text-gray-900 truncate font-bold">
                                    Experience:
                                    <p className="text-sm font-medium">
                                        {seekerJobPost?.experience}
                                    </p>
                                </span>
                                <span className="text-sm text-gray-900 truncate font-bold">
                                    Expected Responsibility:
                                    <p className="text-sm font-medium">
                                        {seekerJobPost?.skills}
                                    </p>
                                </span>
                            </div>
                            <div className="flex justify-between items-center mt-4">
                                <span className="text-sm text-gray-900 truncate font-bold">
                                    Available From:
                                    <p className="text-sm font-medium">
                                        {toLocalDate(
                                            seekerJobPost?.availableDate
                                        )}
                                    </p>
                                </span>
                                {/*<span className="text-sm text-gray-900 truncate font-bold">Expire Date:
                                    <p className="text-sm font-medium">
                                    {toLocalDate(seekerJobPost?.expireDate)}
                                    </p>
                                </span>*/}
                            </div>
                        </div>
                        {/*footer*/}

                        <div className="flex items-center justify-end p-3 border-t border-solid border-slate-200 rounded-b">
                            <button
                                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={props.onClose}
                            >
                                Close
                            </button>
                            {featureService.shouldShowContent(
                                "Seeker Selection"
                            ) ? (
                                selected === true ? (
                                    <button className="bg-green-800 text-white hover:bg-fuchsia-800 font-bold  text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 pointer-events-none">
                                        Talent already selected
                                    </button>
                                ) : (
                                    <button
                                        className="bg-fuchsia-800 text-white hover:bg-green-800 font-bold  text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        //onClick={() => setShowModal(false)}
                                        onClick={() => {
                                            setSelectShowModal(true);
                                        }}
                                    >
                                        Want To Select ?
                                    </button>
                                )
                            ) : (
                                <></>
                            )}

                            {/* Select Modal */}
                            {selectShowModal ? (
                                <>
                                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                                        <div className="relative w-full my-6 mx-auto max-w-3xl">
                                            {/*content*/}
                                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                                {/*header*/}
                                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                                    <h3 className="text-3xl font-semibold">
                                                        {
                                                            seekerJobPost?.jobTitle
                                                        }
                                                    </h3>

                                                    <button
                                                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                                        onClick={() =>
                                                            setSelectShowModal(
                                                                false
                                                            )
                                                        }
                                                    >
                                                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                                            ×
                                                        </span>
                                                    </button>
                                                </div>

                                                {/*body*/}
                                                <form onSubmit={saveSeekerList}>
                                                    <div className="py-6 px-6 lg:px-8">
                                                        <input
                                                            type="hidden"
                                                            value={
                                                                jobSeekerPostId
                                                            }
                                                        />
                                                        <input
                                                            type="hidden"
                                                            name="EmployerUserId"
                                                            value="1"
                                                        />
                                                        <input
                                                            name="Status"
                                                            type="hidden"
                                                            value="New"
                                                        />
                                                        <div>
                                                            <div className="px-5 pb-5 text-center">
                                                                <a href="#">
                                                                    <p className="text-2xl text-gray">
                                                                        Do you
                                                                        like to
                                                                        select ?
                                                                    </p>
                                                                </a>
                                                            </div>
                                                        </div>

                                                        {/*</form>*/}
                                                    </div>
                                                    {/*footer*/}
                                                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                                        <button
                                                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                            type="button"
                                                            onClick={() =>
                                                                setSelectShowModal(
                                                                    false
                                                                )
                                                            }
                                                        >
                                                            No
                                                        </button>
                                                        <button
                                                            className="bg-fuchsia-800 text-white hover:bg-green-800 font-bold  text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                            type="submit"
                                                            //onClick={() => setInterestModal(false)}
                                                        >
                                                            Yes
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                                </>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </Fragment>
    );
}
