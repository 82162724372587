import { Fragment } from "react";
// import { WhoAreWe } from "../../components/Landing/whoAreWe";

export function AboutUs() {
    return (
        <Fragment>
            <div className="pb-8">
                <h1
                    className="font-bold text-4xl pt-16 text-black text-center "
                    id="focusSection"
                >
                    Our Focus Areas
                </h1>
                <p className="text-center pt-10 mb-10">
                    Discover what being part of a great community is about.
                </p>
                <div className="flex-row space-x-8 lg:flex md:hidden sm:hidden">
                    <div className="w-full max-w-sm bg-white border border-gray-300 rounded-lg shadow-lg ">
                        <div className="flex flex-col items-center px-6 pb-10">
                            <svg
                                className="w-16 h-16 text-fuchsia-800 mt-8 mb-4"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M144 64c0-8.8 7.2-16 16-16s16 7.2 16 16c0 9.1 5.1 17.4 13.3 21.5s17.9 3.2 25.1-2.3c2.7-2 6-3.2 9.6-3.2c8.8 0 16 7.2 16 16c0 9.1 5.1 17.4 13.3 21.5s17.9 3.2 25.1-2.3c2.7-2 6-3.2 9.6-3.2c8.8 0 16 7.2 16 16c0 9.1 5.1 17.4 13.3 21.5s17.9 3.2 25.1-2.3c2.7-2 6-3.2 9.6-3.2c8.8 0 16 7.2 16 16V264c0 31.3-20 58-48 67.9c-9.6 3.4-16 12.5-16 22.6V488c0 13.3 10.7 24 24 24s24-10.7 24-24V370.2c38-20.1 64-60.1 64-106.2V160c0-35.3-28.7-64-64-64c-2.8 0-5.6 .2-8.3 .5C332.8 77.1 311.9 64 288 64c-2.8 0-5.6 .2-8.3 .5C268.8 45.1 247.9 32 224 32c-2.8 0-5.6 .2-8.3 .5C204.8 13.1 183.9 0 160 0C124.7 0 96 28.7 96 64v64.3c-11.7 7.4-22.5 16.4-32 26.9l17.8 16.1L64 155.2l-9.4 10.5C40 181.8 32 202.8 32 224.6v12.8c0 49.6 24.2 96.1 64.8 124.5l13.8-19.7L96.8 361.9l8.9 6.2c6.9 4.8 14.4 8.6 22.3 11.3V488c0 13.3 10.7 24 24 24s24-10.7 24-24V359.9c0-12.6-9.8-23.1-22.4-23.9c-7.3-.5-14.3-2.9-20.3-7.1l-13.1 18.7 13.1-18.7-8.9-6.2C96.6 303.1 80 271.3 80 237.4V224.6c0-9.9 3.7-19.4 10.3-26.8l9.4-10.5c3.8-4.2 7.9-8.1 12.3-11.6V208c0 8.8 7.2 16 16 16s16-7.2 16-16V142.3 128 64z" />
                            </svg>
                            <h5 className="mb-1 text-3xl font-bold text-gray-900">
                                {/*Empower*/}Networking
                            </h5>
                            <p className=" text-gray-900 mt-4 text-center">
                                {/*Create result-driven tools, resources, and
                                strategies that drive financial self-sufficiency
    while promoting economic growth.*/}
    Facilitate your job search with our proven matching 
    and vetting process. Access tools, resources, 
    and strategies that 
    drive financial self-sufficiency and promote economic growth.
                            </p>
                        </div>
                    </div>
                    <div className="w-full max-w-sm bg-white border border-gray-300 rounded-lg shadow-lg ">
                        <div className="flex flex-col items-center px-6 pb-10">
                            <svg
                                className="w-16 h-16 text-fuchsia-800 mt-8"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 640 512"
                            >
                                <path d="M320 32c-8.1 0-16.1 1.4-23.7 4.1L15.8 137.4C6.3 140.9 0 149.9 0 160s6.3 19.1 15.8 22.6l57.9 20.9C57.3 229.3 48 259.8 48 291.9v28.1c0 28.4-10.8 57.7-22.3 80.8c-6.5 13-13.9 25.8-22.5 37.6C0 442.7-.9 448.3 .9 453.4s6 8.9 11.2 10.2l64 16c4.2 1.1 8.7 .3 12.4-2s6.3-6.1 7.1-10.4c8.6-42.8 4.3-81.2-2.1-108.7C90.3 344.3 86 329.8 80 316.5V291.9c0-30.2 10.2-58.7 27.9-81.5c12.9-15.5 29.6-28 49.2-35.7l157-61.7c8.2-3.2 17.5 .8 20.7 9s-.8 17.5-9 20.7l-157 61.7c-12.4 4.9-23.3 12.4-32.2 21.6l159.6 57.6c7.6 2.7 15.6 4.1 23.7 4.1s16.1-1.4 23.7-4.1L624.2 182.6c9.5-3.4 15.8-12.5 15.8-22.6s-6.3-19.1-15.8-22.6L343.7 36.1C336.1 33.4 328.1 32 320 32zM128 408c0 35.3 86 72 192 72s192-36.7 192-72L496.7 262.6 354.5 314c-11.1 4-22.8 6-34.5 6s-23.5-2-34.5-6L143.3 262.6 128 408z" />
                            </svg>
                            <h5 className="mb-1 text-3xl font-bold text-gray-900">
                                Educate
                            </h5>
                            <p className=" text-gray-900 mt-4 text-center">
                               {/*} Educational support and programs that help build
                                opportunities, economic mobility, and personal
development.*/}
Build or enroll in soft skills training with WONE 
to enhance your personal development and 
contribute to your growth journey.
                            </p>
                        </div>
                    </div>
                    <div className="w-full max-w-sm bg-white border border-gray-300 rounded-lg shadow-lg ">
                        <div className="flex flex-col items-center px-6 pb-10">
                            <svg
                                className="w-16 h-16 text-fuchsia-800 mt-8"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 640 512"
                            >
                                <path d="M144 0a80 80 0 1 1 0 160A80 80 0 1 1 144 0zM512 0a80 80 0 1 1 0 160A80 80 0 1 1 512 0zM0 298.7C0 239.8 47.8 192 106.7 192h42.7c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0H21.3C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7h42.7C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3H405.3zM224 224a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zM128 485.3C128 411.7 187.7 352 261.3 352H378.7C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7H154.7c-14.7 0-26.7-11.9-26.7-26.7z" />
                            </svg>
                            <h5 className="mb-1 text-3xl font-bold text-gray-900">
                                Engage
                            </h5>
                            <p className=" text-gray-900 mt-4 text-center">
                                {/*Creating a diverse and Inclusive approach that
                                serve as a driving force to facilitate
integration.*/}
Immerse yourself with WONE: Elevate your personal 
and professional growth through our vibrant community 
dedicated to your development and success.
                            </p>
                        </div>
                    </div>
                </div>

                {/* Mobile View */}
                <div className="flex-col space-y-8 items-center lg:hidden md:flex sm:flex">
                    <div className="w-full max-w-lg bg-white border border-gray-300 rounded-lg shadow-lg ">
                        <div className="flex flex-col items-center px-6 pb-10">
                            <svg
                                className="w-16 h-16 text-fuchsia-800 mt-8 mb-4"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                            >
                                <path d="M144 64c0-8.8 7.2-16 16-16s16 7.2 16 16c0 9.1 5.1 17.4 13.3 21.5s17.9 3.2 25.1-2.3c2.7-2 6-3.2 9.6-3.2c8.8 0 16 7.2 16 16c0 9.1 5.1 17.4 13.3 21.5s17.9 3.2 25.1-2.3c2.7-2 6-3.2 9.6-3.2c8.8 0 16 7.2 16 16c0 9.1 5.1 17.4 13.3 21.5s17.9 3.2 25.1-2.3c2.7-2 6-3.2 9.6-3.2c8.8 0 16 7.2 16 16V264c0 31.3-20 58-48 67.9c-9.6 3.4-16 12.5-16 22.6V488c0 13.3 10.7 24 24 24s24-10.7 24-24V370.2c38-20.1 64-60.1 64-106.2V160c0-35.3-28.7-64-64-64c-2.8 0-5.6 .2-8.3 .5C332.8 77.1 311.9 64 288 64c-2.8 0-5.6 .2-8.3 .5C268.8 45.1 247.9 32 224 32c-2.8 0-5.6 .2-8.3 .5C204.8 13.1 183.9 0 160 0C124.7 0 96 28.7 96 64v64.3c-11.7 7.4-22.5 16.4-32 26.9l17.8 16.1L64 155.2l-9.4 10.5C40 181.8 32 202.8 32 224.6v12.8c0 49.6 24.2 96.1 64.8 124.5l13.8-19.7L96.8 361.9l8.9 6.2c6.9 4.8 14.4 8.6 22.3 11.3V488c0 13.3 10.7 24 24 24s24-10.7 24-24V359.9c0-12.6-9.8-23.1-22.4-23.9c-7.3-.5-14.3-2.9-20.3-7.1l-13.1 18.7 13.1-18.7-8.9-6.2C96.6 303.1 80 271.3 80 237.4V224.6c0-9.9 3.7-19.4 10.3-26.8l9.4-10.5c3.8-4.2 7.9-8.1 12.3-11.6V208c0 8.8 7.2 16 16 16s16-7.2 16-16V142.3 128 64z" />
                            </svg>
                            <h5 className="mb-1 text-3xl font-bold text-gray-900">
                                {/*Empower*/}Networking
                            </h5>
                            <p className=" text-gray-900 mt-4 text-center">
                                {/*Create result-driven tools, resources, and
                                strategies that drive financial self-sufficiency
while promoting economic growth.*/}
Facilitate your job search with our proven matching 
    and vetting process. Access tools, resources, 
    and strategies that 
    drive financial self-sufficiency and promote economic growth.
    
                            </p>
                        </div>
                    </div>
                    <div className="w-full max-w-lg bg-white border border-gray-300 rounded-lg shadow-lg ">
                        <div className="flex flex-col items-center px-6 pb-10">
                            <svg
                                className="w-16 h-16 text-fuchsia-800 mt-8"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 640 512"
                            >
                                <path d="M320 32c-8.1 0-16.1 1.4-23.7 4.1L15.8 137.4C6.3 140.9 0 149.9 0 160s6.3 19.1 15.8 22.6l57.9 20.9C57.3 229.3 48 259.8 48 291.9v28.1c0 28.4-10.8 57.7-22.3 80.8c-6.5 13-13.9 25.8-22.5 37.6C0 442.7-.9 448.3 .9 453.4s6 8.9 11.2 10.2l64 16c4.2 1.1 8.7 .3 12.4-2s6.3-6.1 7.1-10.4c8.6-42.8 4.3-81.2-2.1-108.7C90.3 344.3 86 329.8 80 316.5V291.9c0-30.2 10.2-58.7 27.9-81.5c12.9-15.5 29.6-28 49.2-35.7l157-61.7c8.2-3.2 17.5 .8 20.7 9s-.8 17.5-9 20.7l-157 61.7c-12.4 4.9-23.3 12.4-32.2 21.6l159.6 57.6c7.6 2.7 15.6 4.1 23.7 4.1s16.1-1.4 23.7-4.1L624.2 182.6c9.5-3.4 15.8-12.5 15.8-22.6s-6.3-19.1-15.8-22.6L343.7 36.1C336.1 33.4 328.1 32 320 32zM128 408c0 35.3 86 72 192 72s192-36.7 192-72L496.7 262.6 354.5 314c-11.1 4-22.8 6-34.5 6s-23.5-2-34.5-6L143.3 262.6 128 408z" />
                            </svg>
                            <h5 className="mb-1 text-3xl font-bold text-gray-900">
                                Educate
                            </h5>
                            <p className=" text-gray-900 mt-4 text-center">
                                {/*Educational support and programs that help build
                                opportunities, economic mobility, and personal
development.*/}
Build or enroll in soft skills training 
with WONE to enhance your personal development and 
contribute to your growth journey.
                            </p>
                        </div>
                    </div>
                    <div className="w-full max-w-lg bg-white border border-gray-300 rounded-lg shadow-lg ">
                        <div className="flex flex-col items-center px-6 pb-10">
                            <svg
                                className="w-16 h-16 text-fuchsia-800 mt-8"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 640 512"
                            >
                                <path d="M144 0a80 80 0 1 1 0 160A80 80 0 1 1 144 0zM512 0a80 80 0 1 1 0 160A80 80 0 1 1 512 0zM0 298.7C0 239.8 47.8 192 106.7 192h42.7c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0H21.3C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7h42.7C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3H405.3zM224 224a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zM128 485.3C128 411.7 187.7 352 261.3 352H378.7C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7H154.7c-14.7 0-26.7-11.9-26.7-26.7z" />
                            </svg>
                            <h5 className="mb-1 text-3xl font-bold text-gray-900">
                                Engage
                            </h5>
                            <p className=" text-gray-900 mt-4 text-center">
                                {/*Creating a diverse and Inclusive approach that
                                serve as a driving force to facilitate
integration.*/}
Immerse yourself with WONE: Elevate your personal 
and professional growth through our vibrant community 
dedicated to your development and success.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
