import { Fragment, useState, useEffect } from "react";
import { authService } from "../../services/Account/auth.service";
import { UserImage } from "../../components/Profile/userImage";
import { OriginLanguageAndCountry } from "../../components/Profile/originLanguageAndCountry";
import { ResetPassword } from "./resetPassword";
import { GeneralInformation } from "../../components/Profile/generalInformation";
import { UserType } from "./userType";
import { createContext } from "react";
import { useNavigate } from "react-router-dom";
import { userEnableService } from "../../services/Account/userEnable.service";
import { showConfirmation, showInfo } from "../../global/alertService";
import { CompanyInformation } from "./companyInfo";
import { featureService } from "../../services/Feature/feature.service";

export const ProfileReloadContext = createContext();

export function ProfileView() {
    const navigate = useNavigate();

    const [profile, setProfile] = useState([]);
    const [reloadView, setReloadView] = useState(false);

    const ext = localStorage.getItem("extUser");
    const id = localStorage.getItem("id");

    useEffect(() => {
        getProfile();
    }, []);

    useEffect(() => {
        if (reloadView === true) getProfile();
    }, [reloadView]);

    const getProfile = () => {
        authService.profile().then((response) => {
            //console.log(response.data);
            setProfile(response.data);
        });
    };

    const userEnableDisable = (id) => {
        showConfirmation().then((result) => {
            if (result.isConfirmed) {
                userEnableService.update(id).then((res) => {
                    authService.signout();
                    navigate("/");
                    window.location.reload();
                });
            } else {
                showInfo("Account Disable canceled.");
            }
        });
    };

    {/* return (
        <Fragment>
            <ProfileReloadContext.Provider
                value={{
                    setReloadView,
                }}
            >
                <h1 className="mt-20 pt-4 ml-20 text-4xl font-bold">Profile</h1>
                <div className="md:flex flex-row mt-4 ml-20 hidden ">
                    <div className="flex-col basis-1/4">
                        <UserImage profile={profile} />
                        <OriginLanguageAndCountry profile={profile} />
                        <UserType profile={profile} />
                        <button
                            type="button"
                            onClick={() => userEnableDisable(id)}
                            className="text-white bg-fuchsia-800 mt-4 mb-4 hover:bg-green-800 font-medium rounded-lg text-lg px-5 py-2.5 inline-flex justify-center w-full text-center"
                        >
                            Disable Your Account
                        </button>
                    </div>
                    <div className="flex-col basis-3/4 mr-20">
                        {featureService.shouldShowContent(
                            "General Information"
                        ) && <GeneralInformation profile={profile} />}
                        {featureService.shouldShowContent(
                            "Employer Profile Set Up"
                        ) && <CompanyInformation profile={profile} />}
                        {ext !== "External" ? <ResetPassword /> : null}
                    </div>
                </div>
                <div className="flex  mt-4 md:hidden sm:block ">
                    <div className="flex-col mr-6">
                        <div className="ml-6">
                            <UserImage profile={profile} />
                        </div>
                        <div className="ml-6">
                            <OriginLanguageAndCountry profile={profile} />
                        </div>
                        {featureService.shouldShowContent(
                            "General Information"
                        ) && <GeneralInformation profile={profile} />}
                        {featureService.shouldShowContent(
                            "Employer Profile Set Up"
                        ) && <CompanyInformation profile={profile} />}
                        <div className="ml-6">
                            <UserType profile={profile} />
                        </div>
                        <div className="mb-4">
                            {ext !== "External" ? <ResetPassword /> : null}
                        </div>
                        <div className="text-right">
                            <button
                                type="button"
                                onClick={() => userEnableDisable(id)}
                                className="text-white mt-6 mb-6 bg-fuchsia-800  hover:bg-green-800 font-medium rounded-lg text-lg px-5 py-2.5 justify-center w-auto text-center"
                            >
                                Disable Your Account
                            </button>
                        </div>
                    </div>
                </div>

                
            </ProfileReloadContext.Provider>
        </Fragment>
    );
}--*/}

    return (
        <Fragment>
            <ProfileReloadContext.Provider
                value={{
                    setReloadView,
                }}
            >
                <h1 className="mt-20 pt-4 ml-20 text-4xl font-bold">Profile</h1>

                {/* Desktop View */}
                <div className="hidden md:flex md:flex-row mt-4 ml-20">
                    <div className="flex-col basis-1/4">
                        <UserImage profile={profile} />
                        <OriginLanguageAndCountry profile={profile} />
                        <UserType profile={profile} />
                        <button
                            type="button"
                            onClick={() => userEnableDisable(id)}
                            className="text-white bg-fuchsia-800 mt-4 mb-4 hover:bg-green-800 font-medium rounded-lg text-lg px-5 py-2.5 inline-flex justify-center w-full text-center"
                        >
                            Disable Your Account
                        </button>
                    </div>
                    <div className="flex-col basis-3/4 mr-20">
                        {featureService.shouldShowContent(
                            "General Information"
                        ) && <GeneralInformation profile={profile} />}

                        {featureService.shouldShowContent(
                            "Employer Profile Set Up"
                        ) && <CompanyInformation profile={profile} />}

                        {ext !== "External" && <ResetPassword />}
                    </div>
                </div>

                {/* Tablet and Mobile View */}
                <div className="flex mt-4 md:hidden">
                    <div className="flex-col mr-6">
                        <div className="ml-6">
                            <UserImage profile={profile} />
                        </div>
                        <div className="ml-6">
                            <OriginLanguageAndCountry profile={profile} />
                        </div>
                        {featureService.shouldShowContent(
                            "General Information"
                        ) && <GeneralInformation profile={profile} />}
                        {featureService.shouldShowContent(
                            "Employer Profile Set Up"
                        ) && <CompanyInformation profile={profile} />}
                        <div className="ml-6">
                            <UserType profile={profile} />
                        </div>
                        <div className="mb-4">
                            {ext !== "External" && <ResetPassword />}
                        </div>
                        <div className="text-right">
                            <button
                                type="button"
                                onClick={() => userEnableDisable(id)}
                                className="text-white mt-6 mb-6 bg-fuchsia-800 hover:bg-green-800 font-medium rounded-lg text-lg px-5 py-2.5 justify-center w-auto text-center"
                            >
                                Disable Your Account
                            </button>
                        </div>
                        
                    </div>
                </div>
            </ProfileReloadContext.Provider>
        </Fragment>
    );
}

