export function toLocalDate(dateTime) {
    const dateoptions = {
        //weekday: "short",
        year: "numeric",
        month: "short",
        day: "2-digit"
    }
    const date = new Date(dateTime)
    const localDateString = date.toLocaleDateString('en-CA')
    return localDateString
}