import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { showError } from "../../global/alertService";
import { PAYPAL_CLIENT_ID } from "../../global/api-keys";

export default function TrainingBuyPaypal({
    courseName,
    price,
    setOrderId,
    setPayerID,
    handleApprope,
}) {
    return (
        <div className="w-full">
            <PayPalScriptProvider
                options={{
                    "client-id": PAYPAL_CLIENT_ID,
                }}
            >
                <PayPalButtons
                    fundingSource="paypal"
                    style={{
                        height: 48,
                    }}
                    createOrder={(data, actions) => {
                        return actions.order.create({
                            purchase_units: [
                                {
                                    description: courseName,
                                    amount: {
                                        value: price,
                                    },
                                },
                            ],
                        });
                    }}
                    onApprove={function (data, actions) {
                        setOrderId(data.orderID);
                        setPayerID(data.payerID);
                        handleApprope(data.orderID);
                        //console.log(data);
                    }}
                    onError={(err) => {
                        console.log(err);
                        showError(`Transaction Error.`);
                    }}
                />
            </PayPalScriptProvider>
        </div>
    );
}
