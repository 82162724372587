import { useEffect, useState } from "react";
import { subscriptionService } from "../../services/Account/subscription.service";
import { UpgradeAndExtendMembership } from "./upgradeAndExtendMembership";

export function SubscriptionEnd() {
    const [subscriptionEndTime, setSubscriptionEndTime] = useState("");
    const [showExtend, setShowExtend] = useState(false);

    useEffect(() => {
        getProfile();
    }, []);

    const getProfile = () => {
        subscriptionService.get().then((res) => {
            let subscriptionExpired = new Date(
                res.data?.subscriptionExpired.toString().slice(0, 10)
            );
            let expireDate =
                subscriptionExpired.getDate().toString().padStart(2, "0") +
                "/" +
                (subscriptionExpired.getMonth() + 1)
                    .toString()
                    .padStart(2, "0") +
                "/" +
                subscriptionExpired.getFullYear().toString();
            setSubscriptionEndTime(expireDate);
        });
    };

    return (
        <>
            <div>
                Your premium membership is valid till {subscriptionEndTime}
            </div>
            <div className="text-right mb-4 mt-2">
                <button
                    onClick={() => setShowExtend((prev) => !prev)}
                    type="button"
                    className="text-white bg-fuchsia-800  hover:bg-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                    Extend Membership
                </button>
            </div>
            {showExtend && <UpgradeAndExtendMembership type={"Premium"} />}
        </>
    );
}
