//import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import { showConfirmation, showSuccess } from "../../global/alertService";

import JobApplicantDataService from '../../services/EmployerJob/jobApplicants.service'


export function JobApplicant() {

    const [applicants, setApplicants] = useState([])
    
    useEffect(() => {
        getJobApplicants()
    }, [])

    const getJobApplicants = () => {
        JobApplicantDataService.getAll()
            .then((response) => {
                // console.debug(response)
                setApplicants(response.data)
                //console.log(response.data)
            })
    }

    const deleteApplicant = (id) => {
        showConfirmation()
            .then((result) => {
                if (result.isConfirmed) {
                    JobApplicantDataService.delete(id)
                        .then((response) => {
                            // console.debug(response)
                            getJobApplicants()
                            showSuccess('Deleted successfully.')
                        })
                } else {
                    showSuccess('Not deleted', 'Ok')
                }
            })
    }
    
    return (
        <Fragment>
            <h1 className="font-bold text-xl mb-6">Job Applicant List</h1>
            <div className="w-full ">
                <div className="bg-slate-50 shadow-md rounded-lg my-6 overflow-y-auto h-96">
                    <table className="min-w-max w-full table-auto ">
                        <thead className="sticky top-0">
                            <tr className="bg-fuchsia-800 text-white uppercase text-sm leading-normal">
                                <th className="py-3 px-6 text-left">Applicant</th>
                                <th className="py-3 px-6 text-left">Job</th>
                                <th className="py-3 px-6 text-left">Status</th>
                                <th className="py-3 px-6 text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody className="text-gray-600 text-sm font-light">
                            {
                                applicants.length > 0 ?
                                    (applicants.map(item => (
                                        <tr key={item.id} className="border-b border-gray-200 hover:bg-gray-100">
                                            <td className="py-3 px-6 text-left whitespace-nowrap">
                                                <div className="flex items-center">
                                                    <span className="font-medium">{item.firstName} {" "} {item.lastName}</span>
                                                </div>
                                            </td>
                                            <td className="py-3 px-6 text-left whitespace-nowrap">
                                                <div className="flex items-center">
                                                    <span className="font-medium">{item.jobPost}</span>
                                                </div>
                                            </td>
                                            <td className="py-3 px-6 text-left whitespace-nowrap">
                                                <div className="flex items-center">
                                                    <span className="font-medium">{item.status}</span>
                                                </div>
                                            </td>
                                            <td className="py-3 px-6 text-center">
                                                <div className="flex item-center justify-center">
                                                    
                                                    <button onClick={() => { 
                                                            window.open(item?.fileUrl)
                                                    }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 15 15" fill="none">
                                                            <path d="M0 4.5V0H1V4.5C1 5.32843 1.67157 6 2.5 6C3.32843 6 4 5.32843 4 4.5V1.5C4 1.22386 3.77614 1 3.5 1C3.22386 1 3 1.22386 3 1.5V5H2V1.5C2 0.671573 2.67157 0 3.5 0C4.32843 0 5 0.671573 5 1.5V4.5C5 5.88071 3.88071 7 2.5 7C1.11929 7 0 5.88071 0 4.5Z" fill="black" />
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M12.5 0H6V4.5C6 6.433 4.433 8 2.5 8H1V13.5C1 14.3284 1.67157 15 2.5 15H12.5C13.3284 15 14 14.3284 14 13.5V1.5C14 0.671573 13.3284 0 12.5 0ZM11 4H7V5H11V4ZM11 7H7V8H11V7ZM4 10H11V11H4V10Z" fill="black" />
                                                        </svg>
                                                    </button>
                                                    <button onClick={() => deleteApplicant(item.id)} className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))) :
                                    (<tr>
                                        <td>No Data</td>
                                    </tr>)
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </Fragment>
    )
}