import { React, Fragment } from 'react';
import { EventParticipant } from "../../components/Events/eventParticipant"

import ProfileCard from '../../components/Profile/profileCard'


function EventParticipantList() {

  return (
    <Fragment>

      <div className="flex flex-row flex-wrap mt-20 ">

        <div className="md:w-full sm:w-full lg:w-1/4 xl:w-1/4 2xl:w-1/4 ">
          <div className="block p-6 rounded-lg shadow-lg bg-gray-50 m-6">
            <ProfileCard />
          </div>
        </div>

        <div className="mt-6 md:w-full sm:w-full lg:w-3/4 xl:w-3/4 2xl:w-3/4 mb-6 lg:pr-6">
          <div className="p-6 rounded-lg shadow-lg bg-gray-50 ">
            <EventParticipant />
          </div>
        </div>
      </div>

    </Fragment>
  )

}
export default EventParticipantList