import React, { Fragment, useEffect, useState } from "react";
import TrainingDataService from "../../services/Training/training.service";
import { getFormData } from "../../utils/getFormData";

export function CourseUpdate(props) {
    const initialFormState = {
        courseName: "",
        image: null,
        description: "",
        instructorId: "",
        price: "",
    };
    const [data, setData] = useState(initialFormState);

    useEffect(() => {
        if (props.id > 0) {
            getTrainings(props.id);
        }
    }, [props]);

    const getTrainings = (id) => {
        TrainingDataService.get(id).then((response) => {
            //console.debug(response);
            setData(response.data);
            //console.log(response.data);
        });
    };

    //-----UPDATE COURSE-----

    const handleInputChange = (event) => {
        const { name, value } = event?.target;
        if (name === "image") {
            setData({
                ...data,
                image: event.target?.files[0],
            });
        } else {
            setData({
                ...data,
                [name]: value,
            });
        }
    };

    const updateCourse = (id) => {
        const formData = getFormData(data);
        //console.log(formData)

        TrainingDataService.update(id, formData);
        window.location.reload(true);
        /*.then(response => {
            // console.debug(response)
            showSuccess('Record updated successfully.')
            props.onClose()
            window.location.reload()
        })*/
    };

    //---------

    return (
        <Fragment>
            <form>
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-full my-6 mx-auto max-w-3xl">
                        {/*content*/}
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            {/*header*/}
                            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                <h3 className="text-3xl font-semibold">
                                    Update Traning Course
                                </h3>

                                <button
                                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={props.onClose}
                                >
                                    <span className="bg-transparent text-red-500 background-transparent font-bold h-6 w-6 text-2xl block outline-none focus:outline-none">
                                        x
                                    </span>
                                </button>
                            </div>

                            {/*body*/}
                            <div className="py-6 px-6 lg:px-8">
                                <div className="grid gap-6 mb-6 md:grid-cols-3">
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            Course Name
                                        </label>
                                        <input
                                            name="courseName"
                                            value={data?.courseName}
                                            onChange={handleInputChange}
                                            type="text"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            Description
                                        </label>
                                        <textarea
                                            name="description"
                                            value={data?.description}
                                            onChange={handleInputChange}
                                            rows={2}
                                            type="text"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            Price
                                        </label>
                                        <input
                                            name="price"
                                            value={data?.price}
                                            onChange={handleInputChange}
                                            type="text"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900">
                                            Image
                                        </label>
                                        <input
                                            name="image"
                                            type="file"
                                            onChange={handleInputChange}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5te"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*footer*/}
                            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                <button
                                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={props.onClose}
                                >
                                    Close
                                </button>
                                <button
                                    className="bg-fuchsia-800 text-white hover:bg-green-800 font-bold  text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={() => updateCourse(data.id)}
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </Fragment>
    );
}
