import React, { Fragment, useEffect, useState } from "react";
import StarRatings from "react-star-ratings";
import { RegisteredTrainingModal } from "../../components/Trining/registeredTrainingModal";
import RegisteredtrainingService from "../../services/Training/registeredtraining.service";
import TrainingContentDetService from "../../services/Training/trainingContentDet.service";
import TrainingContentService from "../../services/Training/trainingcontents.eervice";
import { DocsIcon } from "../Icons/docsIcon";
import { PdfIcon } from "../Icons/pdfIcon";
import { PptIcon } from "../Icons/pptIcon";
import { VideoIcon } from "../Icons/videoIcon";
import { TrainingContentDetModal } from "./trainingContentDetModal";

const TrainingContent = (props) => {
    const [data, setData] = useState(null);
    const [trainingContentDet, setTrainingContentDet] = useState(null);
    const [trainingContentDetId, setTrainingContentDetId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [videoSrc, setVideoSrc] = useState(null);
    const currentUserType = localStorage.getItem("userType");
    const currentUserEmail = localStorage.getItem("email");
    const [registeredModal, setRegisteredModal] = useState(false);
    const [rating, setRating] = useState(null);

    useEffect(() => {
        //console.log(props.training)
        if (props.training.id > 0) {
            getTrainingDetail(props.training.id);
            getRegisteredTraining(props.training.id);
        }
    }, [props]);

    // useEffect(() => {
    //     if (props.training.id > 0) {
    //         getTrainingDetail(props.training.id)
    //     }
    //     //console.log("called")
    // }, [rating]);

    const getTrainingDetail = (id) => {
        //console.log(id);
        TrainingContentService.get("?trainingId=" + id).then((response) => {
            setData(response.data);
            //console.log(response.data)
        });
    };

    const getTrainingContentDet = (id) => {
        //console.log(id);
        TrainingContentDetService.getPreviewAll(
            "?trainingContentId=" + id
        ).then((response) => {
            setTrainingContentDet(response.data);
            //console.log(response.data)
        });
    };

    const [toggle, setToggle] = useState(null);
    const handleToggle = (id) => {
        if (toggle === id) {
            setToggle(null);
            return false;
        }
        setToggle(id);
    };

    //get resigtered training
    const getRegisteredTraining = (trainingId) => {
        RegisteredtrainingService.getRegisteredTraining(
            trainingId,
            currentUserEmail
        ).then((res) => {
            //console.log(res?.data.userRating);
            setRating(res?.data.userRating);
        });
    };

    //save rating in database
    const updateRating = (r) => {
        //console.log(r);
        setRating(r);
        const data = {
            rating: r,
            email: currentUserEmail,
            trainingId: props.training.id,
        };
        RegisteredtrainingService.updateUserRating(data);
        //showSuccess(r + " star rating submitted");
        //console.log(data);
    };

    return (
        <Fragment>
            <div className="flex flex-wrap justify-between px-6">
                <div className="text-gray-900 md:w-full sm:w-full lg:w-2/3 xl:w-2/3 2xl:w-2/3 mb-6 lg:pr-6">
                    <div className="p-4 rounded-lg shadow-lg bg-gray-50 ">
                        <div className=" text-gray-900 ">
                            <div className=" font-bold text-2xl mb-4">
                                Taining Details
                            </div>
                        </div>
                        <hr />

                        <div
                            className="w-full h-72"
                            style={{
                                backgroundImage: `url("${props.training?.imgUrl}")`,
                                backgroundColor: "#cccccc",
                                backgroundPosition: "center top",
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                            }}
                        >
                            {/*<img className="mt-4 rounded-t-lg" src={props.training?.imgUrl} alt="" /> */}
                        </div>

                        <h5 className="text-gray-900 text-xl font-bold mt-2 mb-2">
                            {props.training?.courseName}
                        </h5>
                        <div className="mb-2 flex">
                            {rating != null ? (
                                <>
                                    <div>Your rating: </div>
                                    <div
                                        style={{
                                            marginTop: "-2px",
                                        }}
                                        className="ml-1"
                                    >
                                        <StarRatings
                                            rating={rating}
                                            starDimension="18px"
                                            starSpacing="0px"
                                            starRatedColor="#FDE047"
                                            starHoverColor="#FDE047"
                                            changeRating={(r) =>
                                                updateRating(r)
                                            }
                                        />
                                    </div>
                                </>
                            ) : (
                                <div>
                                    Please enroll the course to give rating.
                                </div>
                            )}
                        </div>

                        <hr />
                        {/*<div className="flex justify-between items-center ">
                            <span className="text-base font-medium text-gray-900"><b>Instructor:</b>{" "}{props.training?.firstName} {" "}{props.training?.lastName}</span>
                            <Link to={{ pathname: `/enrollTraining/${props.training?.id}` }} className="text-base font-bold text-fuchsia-800 hover:text-green-800">Buy For : ${props.training?.price}</Link>
                        </div>--*/}

                        {/*--<div className="flex justify-between items-center mt-3">

                            <span className="text-3xl font-bold text-gray-900>${props.training?.price}</span>
                            {currentUserType === "Standard" ?
                                <Link to={{ pathname: `/enrollTraining/${props.training?.id}` }} className="text-white bg-fuchsia-800  hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Buy</Link>
                                :
                                <button type='button' onClick={() => { setRegisteredModal(true) }} href="#" className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-fuchsia-800 rounded-lg focus:ring-4 focus:ring-blue-200 hover:bg-green-800">
                                    Register
                                </button>
                            }
                        </div>--*/}

                        <div>
                            <span className="mt-4 text-gray-900 text-xl font-bold">
                                Description:
                            </span>
                            <p className="justify-center text-gray-700 text-base mt-2 mb-4">
                                {props.training?.description}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="md:w-full sm:w-full lg:w-1/3 xl:w-1/3 2xl:w-1/3 mb-6">
                    <div className="p-4 rounded-lg shadow-lg bg-gray-50">
                        <span className=" text-gray-900 text-2xl font-bold">
                            Course Content:{" "}
                            <span className=" text-gray-900 text-xl font-medium">
                                (Preview available)
                            </span>
                        </span>
                        <div className="my-4"></div>
                        <hr />
                        <div className="mt-4">
                            {data?.map((item, i) => {
                                return (
                                    <>
                                        <button
                                            key={i}
                                            onClick={() => {
                                                getTrainingContentDet(item.id);
                                                handleToggle(item.id);
                                            }}
                                            type="button"
                                            className="flex items-center p-2 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100"
                                        >
                                            <span
                                                className="flex-1 ml-3 text-left whitespace-nowrap"
                                                sidebar-toggle-item
                                            >
                                                {item?.chapterName}-{}
                                                {item?.content}
                                            </span>

                                            <svg
                                                sidebar-toggle-item
                                                className="w-6 h-6"
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                    clipRule="evenodd"
                                                ></path>
                                            </svg>
                                        </button>

                                        {item.id === toggle ? (
                                            <>
                                                {/*--{isOpen && (--*/}

                                                <ul className="py-2 space-y-2">
                                                    {trainingContentDet?.map(
                                                        (item, index) =>
                                                            (item?.detailUrl
                                                                .split(".")
                                                                .pop() ===
                                                                "mp4" ||
                                                                item?.detailUrl
                                                                    .split(".")
                                                                    .pop() ===
                                                                    "pdf" ||
                                                                item?.detailUrl
                                                                    .split(".")
                                                                    .pop() ===
                                                                    "ppt" ||
                                                                item?.detailUrl
                                                                    .split(".")
                                                                    .pop() ===
                                                                    "pptx" ||
                                                                item?.detailUrl
                                                                    .split(".")
                                                                    .pop() ===
                                                                    "doc" ||
                                                                item?.detailUrl
                                                                    .split(".")
                                                                    .pop() ===
                                                                    "docx") && (
                                                                <li
                                                                    key={index}
                                                                    onClick={() => {
                                                                        setTrainingContentDetId(
                                                                            item.id
                                                                        );
                                                                        setVideoSrc(
                                                                            item?.detailUrl
                                                                        );
                                                                        setShowModal(
                                                                            true
                                                                        );
                                                                    }}
                                                                >
                                                                    {/*--<Link to={`/coursesamplevideo/${item.id}`} className="flex items-center p-1 pl-11 w-full  text-gray-900 rounded-lg transition duration-75 group hover:text-fuchsia-800">--*/}

                                                                    <div className="flex items-center p-2 pl-11 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 cursor-pointer">
                                                                        {item.detailUrl
                                                                            .split(
                                                                                "."
                                                                            )
                                                                            .pop() ===
                                                                            "pdf" && (
                                                                            <PdfIcon />
                                                                        )}
                                                                        {item.detailUrl
                                                                            .split(
                                                                                "."
                                                                            )
                                                                            .pop() ===
                                                                            "mp4" && (
                                                                            <VideoIcon />
                                                                        )}
                                                                        {item.detailUrl
                                                                            .split(
                                                                                "."
                                                                            )
                                                                            .pop() ===
                                                                            "ppt" && (
                                                                            <PptIcon />
                                                                        )}
                                                                        {item.detailUrl
                                                                            .split(
                                                                                "."
                                                                            )
                                                                            .pop() ===
                                                                            "pptx" && (
                                                                            <PptIcon />
                                                                        )}
                                                                        {item.detailUrl
                                                                            .split(
                                                                                "."
                                                                            )
                                                                            .pop() ===
                                                                            "doc" && (
                                                                            <DocsIcon />
                                                                        )}
                                                                        {item.detailUrl
                                                                            .split(
                                                                                "."
                                                                            )
                                                                            .pop() ===
                                                                            "docx" && (
                                                                            <DocsIcon />
                                                                        )}

                                                                        {/* <svg className="mr-2 w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={4} stroke="currentColor" >
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z" />
                                                                </svg> */}

                                                                        {
                                                                            item.name
                                                                        }
                                                                    </div>
                                                                    {/*--</Link>--*/}
                                                                </li>
                                                            )
                                                    )}
                                                </ul>
                                            </>
                                        ) : (
                                            ""
                                        )}
                                    </>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>

            {showModal ? (
                <TrainingContentDetModal
                    src={videoSrc}
                    trainingContentDetId={trainingContentDetId}
                    onClose={() => {
                        setShowModal(false);
                    }}
                />
            ) : null}

            {registeredModal ? (
                <RegisteredTrainingModal
                    trainingId={props.training?.id}
                    onClose={() => {
                        setRegisteredModal(false);
                    }}
                />
            ) : null}
        </Fragment>
    );
};
export default TrainingContent;
