import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { Fragment, useEffect, useState } from "react";
import { authService } from "../../services/Account/auth.service";
import { PostService } from "../../services/Post/post.service";

export default function PostCard({ selectedCategoryId, onNewPost }) {
    const initialFormState = {
        textContent: "",
        files: [],
    };
    const [post, setPost] = useState(initialFormState);
    const [file, setFile] = useState(null);
    const [image, setImage] = useState(null);
    const [emojiPicker, setEmojiPicker] = useState(false);

    const [profile, setProfile] = useState([]);
    const place = "What’s on your mind, " + profile + "?";

    useEffect(() => {
        getProfile();
    }, []);

    const getProfile = () => {
        authService.profile().then((response) => {
            setProfile(response.data?.firstName);
        });
    };

    const onEmojiClick = (e) => {
        let sym = e.unified.split("-");
        let codesArray = [];
        sym.forEach((el) => codesArray.push("0x" + el));
        let emoji = String.fromCodePoint(...codesArray);
        document.getElementById("textContent").value =
            document.getElementById("textContent").value + emoji;
        setPost({
            ...post,
            textContent: post.textContent + emoji,
        });
    };

    const removeImage = () => {
        setImage(null);
    };

    const removeFile = () => {
        setFile(null);
    };

    const handleInputChange = (event) => {
        const { name, value, files } = event.target;
        if (name === "files") {
            setPost({
                ...post,
                files: [...post.files, files[0]],
            });
            if (event.currentTarget.id === "image") {
                setImage(event.target.files[0]);
            } else {
                setFile(event.target.files[0]);
            }
        } else {
            setPost({
                ...post,
                [name]: value,
            });
        }
    };

    const saveForumPost = (event) => {
        event.preventDefault();
        const formData = new FormData(); //getFormData(post);
        formData.append("textContent", post.textContent);
        post?.files?.forEach((file) => {
            formData.append("files", file);
        });
        if (selectedCategoryId) {
            formData.append("postCategoryId", selectedCategoryId);
        }
        PostService.create(formData).then((response) => {
            setPost(initialFormState);
            onNewPost(response.data);
        });
    };

    return (
        <Fragment>
            <form onSubmit={saveForumPost}>
                <div className="mb-4 w-full bg-gray-50 rounded-lg border border-gray-200">
                    <div className="py-2 px-4 bg-white rounded-b-lg">
                        <textarea
                            rows="3"
                            name="textContent"
                            id="textContent"
                            value={post?.textContent}
                            onChange={handleInputChange}
                            className="block px-3 py-2 w-full text-sm font-medium text-black bg-white"
                            placeholder={place}
                        ></textarea>
                    </div>
                    {image && (
                        <div className="relative">
                            <img
                                className=" h-96 w-full "
                                src={URL.createObjectURL(image)}
                                alt=""
                            />
                            <button
                                onClick={removeImage}
                                className="absolute text-xl top-0 right-0 m-2 bg-white rounded-full p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100  "
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-6 h-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>
                    )}
                    {file ? (
                        <div className="relative mb-4">
                            <p className="ml-4"> {file.name}</p>
                            <button
                                onClick={removeFile}
                                className="absolute text-xl top-0 right-0 m-1 bg-white rounded-full p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100  "
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-6 h-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>
                    ) : null}
                    <div className="flex justify-between items-center py-2 px-3 border-b">
                        <div className="flex flex-wrap items-center divide-gray-200 sm:divide-x">
                            <div className="flex items-center space-x-1 sm:pr-4">
                                <label
                                    htmlFor="file"
                                    className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100"
                                >
                                    <svg
                                        aria-hidden="true"
                                        className="w-5 h-5"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                                            clipRule="evenodd"
                                        ></path>
                                    </svg>
                                    <span className="sr-only">Attach file</span>
                                    <input
                                        name="files"
                                        type="file"
                                        id="file"
                                        style={{ display: "none" }}
                                        accept=".pdf"
                                        onChange={handleInputChange}
                                    />
                                </label>
                                <label
                                    htmlFor="image"
                                    className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100"
                                >
                                    <svg
                                        aria-hidden="true"
                                        className="w-5 h-5"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                            clipRule="evenodd"
                                        ></path>{" "}
                                    </svg>
                                    <span className="sr-only">
                                        Upload image
                                    </span>
                                    <input
                                        name="files"
                                        type="file"
                                        id="image"
                                        accept=".png,.jpeg,.jpg"
                                        style={{ display: "none" }}
                                        onChange={handleInputChange}
                                    />
                                </label>
                                <button
                                    type="button"
                                    onClick={() => setEmojiPicker(!emojiPicker)}
                                    className="p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100"
                                >
                                    <svg
                                        aria-hidden="true"
                                        className="w-5 h-5"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 100-2 1 1 0 000 2zm7-1a1 1 0 11-2 0 1 1 0 012 0zm-.464 5.535a1 1 0 10-1.415-1.414 3 3 0 01-4.242 0 1 1 0 00-1.415 1.414 5 5 0 007.072 0z"
                                            clipRule="evenodd"
                                        ></path>
                                    </svg>
                                    <span className="sr-only">Add emoji</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {emojiPicker && (
                    <div className="absolute z-10">
                        <Picker data={data} onEmojiSelect={onEmojiClick} />
                    </div>
                )}
                <div className="text-right">
                    <button
                        type="submit"
                        className="inline-flex items-center px-5 py-2.5 text-sm font-medium text-center text-white bg-fuchsia-800 rounded-lg focus:ring-4 focus:ring-blue-200 hover:bg-green-800"
                    >
                        Publish
                    </button>
                </div>
            </form>
        </Fragment>
    );
}
