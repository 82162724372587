import { Fragment, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { showError } from "../../global/alertService";
import { InPersonMsgService } from "../../services/ContactUsMsg/inPersonMsg.service";

export function Message() {
    const mySwal = withReactContent(Swal);
    const initialFormState = {
        messageSubject: "",
        messageBody: "",
    };

    const [data, setData] = useState(initialFormState);
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setData({ ...data, [name]: value });
    };
    const saveMessage = () => {
        console.log(data);
        InPersonMsgService.create(data)
            .then((response) => {
                mySwal
                    .fire({
                        title: <strong>Success</strong>,
                        html: <i>Message saved successfully.</i>,
                        icon: "success",
                    })
                    .then(() => {
                        setData(initialFormState);
                        window.location.reload();
                    });
            })
            .catch((err) => {
                showError("Something went wrong");
                console.log(err);
            });
    };
    return (
        <Fragment>
            <div className="lg:basis-2/4 sm:w-full sm:m-12  items-center justify-center ">
                <h1 className="font-bold text-3xl text-fuchsia-800 py-6 mb-6  lg:block">
                    Get in Touch
                </h1>
                <form
                    onSubmit={(event) => {
                        event.preventDefault();
                        saveMessage();
                    }}
                    className="m-6 sm:flex-1 "
                >
                    <div className="mb-6">
                        <label className="block mb-2 text-sm font-medium text-black ">
                            Subject
                        </label>
                        <input
                            type="text"
                            name="messageSubject"
                            onChange={handleInputChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                            required
                        />
                    </div>
                    <div className="mb-6">
                        <label className="block mb-2 text-sm font-medium text-black ">
                            Message
                        </label>
                        <textarea
                            type="text"
                            name="messageBody"
                            onChange={handleInputChange}
                            rows={4}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                            required
                        />
                    </div>

                    <div className="text-right">
                        <button
                            type="submit"
                            className="inline-flex items-center px-5 py-2.5 text-sm font-medium text-center text-white bg-fuchsia-800 rounded-lg focus:ring-4 focus:ring-blue-200 hover:bg-green-800"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </Fragment>
    );
}
