import http from '../../global/http-common'

const route = '/States';

export const stateService = {
    getAll: function getAll() {
        return http.get(route);
    },
    getByCountry: function getByCountry(countryId) {
        return http.get(route + "?countryId=" + countryId);
    },
    get: function get(id) {
        return http.get(`${route}/${id}`);
    },
    create: function create(data) {
        return http.post(route, data);
    },

    update: function update(id, data) {
        return http.put(`${route}/${id}`, data);
    },

    delete: function deleteOne(id) {
        return http.delete(`${route}/${id}`);
    }

}